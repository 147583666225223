import React from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Tab,
  Tabs,
} from "@material-ui/core";
import { ConfigurationTabPanel } from "./ConfigurationTabPanel";
import { GenerateConfigToConfigXList } from "utils/applicationHelper";
import { spiltTextAndAcronyms } from "utils/stringHelper";
import { DebugLogging, Log } from "utils/loggingHelper";

export interface ConfigX {
  title: string;
  items: ConfigXSub[];
}

export interface ConfigXSub {
  dataName?: string;
  dataType:
    | string
    | "string"
    | "number"
    | "boolean"
    | "array"
    | "arrayString"
    | "arrayObject";
  value?: any | number | string | boolean | string[] | ConfigXSub[];
  isRootLevel: boolean;
}

interface IConfigurationDialogProps {
  editingConfigInJson: string;
  configTitle: string;
  open: boolean;
  onHandleCloseClick: () => void;
  onHandleProClick?: () => void;
  onHandleSaveClick: () => void;
  onChange: (value: string) => void;
}

export const ConfigurationDialog: React.FC<IConfigurationDialogProps> = ({
  editingConfigInJson,
  configTitle,
  open,
  onHandleCloseClick,
  onHandleProClick,
  onHandleSaveClick,
  onChange,
}) => {
  const [selectedTabId, setSelectedTabId] = React.useState(0);

  const [configList, setconfigList] = React.useState<ConfigX[]>([]);
  const [updatingConfig, setUpdatingConfig] = React.useState("");

  const handleOnTabChange = (event: React.ChangeEvent<{}>, value: any) => {
    setSelectedTabId(value);
  };

  React.useEffect(() => {
    //Experiment
    if (editingConfigInJson != undefined) {
      const list = GenerateConfigToConfigXList(
        editingConfigInJson,
        configTitle
      );
      setconfigList(list);

      Log(DebugLogging, "startConfigDialog");
      Log(DebugLogging, list);

      setUpdatingConfig(editingConfigInJson);
    }
  }, [editingConfigInJson]);

  const onClose = () => {
    setSelectedTabId(0);
    onHandleCloseClick();
  };

  const onConfigChange = (newValue: ConfigXSub) => {
    Log(DebugLogging, "onConfigChange");
    Log(DebugLogging, newValue);

    try {
      var updateConfig = configList[selectedTabId];
      var _updatingConfigObj = JSON.parse(updatingConfig);

      if (newValue.dataName != undefined) {
        if (newValue.isRootLevel) {
          _updatingConfigObj[newValue.dataName] = newValue.value;
        } else {
          if (newValue.dataType === "arrayString") {
            _updatingConfigObj[updateConfig.title][newValue.dataName] =
              JSON.parse(newValue.value);
          } else {
            _updatingConfigObj[updateConfig.title][newValue.dataName] =
              newValue.value;
          }
        }
      } else {
        //ArrayObject
        if (newValue.isRootLevel && newValue.dataType === "arrayObject") {
          var _updatingArrayItem = _updatingConfigObj[newValue.value.title];

          var _newValueForObjectProperties: ConfigX = newValue.value;

          _newValueForObjectProperties.items.forEach(
            (subConfig: ConfigXSub) => {
              _updatingArrayItem[subConfig.dataName!!] = subConfig.value;
            }
          );

          _updatingConfigObj[newValue.value.title] = _updatingArrayItem;
        }
      }

      Log(DebugLogging, updateConfig);
      Log(DebugLogging, _updatingConfigObj);

      setUpdatingConfig(JSON.stringify(_updatingConfigObj));
      onChange(JSON.stringify(_updatingConfigObj));
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle id="form-dialog-title">
        {configTitle != undefined
          ? `Editing ${configTitle}`
          : `Editing Configuration`}
        <Button onClick={onHandleProClick}>Pro</Button>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {configTitle != undefined
            ? `To update ${configTitle} details, change the detail below and save it.`
            : `To update configuration details, change the detail below and save it.`}
        </DialogContentText>

        <Paper square>
          <Tabs
            value={selectedTabId}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            onChange={handleOnTabChange}
          >
            {configList.map((item) => (
              <Tab key={item.title} label={spiltTextAndAcronyms(item.title)} />
            ))}
          </Tabs>
        </Paper>
        <ConfigurationTabPanel
          displayTabId={selectedTabId}
          configs={configList}
          onChange={onConfigChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={onHandleSaveClick} color="primary">
          {`Save & Update`}
        </Button>
      </DialogActions>
    </Dialog>
  );
};