import { useState } from "react";

//Component
import { DataGrid, GridColDef, GridColumns } from "@material-ui/data-grid";
import CustomLoadingOverlay from "components/DataGridLoadingOverlay";
import CustomNoRowsOverlay from "components/DataGridNoRowsOverlay";
//Icon

//Helper
import { MainDrawer } from "components/MainDrawer";
import {
  AccessControlButton,
  Base64ToFileDialog,
  Base64ToImageDialog,
  FileToBase64Dialog,
} from "components";

import { PM_EDIT_VERBIAGE_CODE } from "models/backend/constant/PermissionCode";
import { IDocScanDialog } from "components/iDocScanDialog";

export interface Tool {
  id: number;
  name: string;
}

export const ToolManagementScreen = () => {
  const [verbiageList, _] = useState<Tool[]>([
    {
      id: 1,
      name: "Convert Base 64 string to Image",
    },
    {
      id: 2,
      name: "Convert File to Base64",
    },
    {
      id: 3,
      name: "Base 64 to File",
    },
    {
      id: 4,
      name: "iDocscan",
    },
  ]);

  const [size, setSize] = useState<number>(10);
  const [openBase64ToImage, setOpenBase64ToImage] = useState<boolean>(false);
  const [openFileToBase64, setOpenFileToBase64] = useState<boolean>(false);
  const [openBase64ToFile, setOpenBase64ToFile] = useState<boolean>(false);
  const [openIDocscan, setOpenIDocscan] = useState<boolean>(false);

  //Setting up the each column header item
  const verbiageCodeHeader: GridColDef = {
    field: "name",
    headerName: "Name",
    sortable: false,
    renderHeader: (params: any) => <strong>{"Name"}</strong>,
    flex: 1,
  };

  const actionsHeader: GridColDef = {
    field: "id",
    headerName: "Actions",
    minWidth: 80,
    filterable: false,
    sortable: false,
    disableColumnMenu: true,
    renderHeader: () => <strong>{"Actions"}</strong>,
    renderCell: (params: any) => (
      <strong>
        <AccessControlButton
          code={PM_EDIT_VERBIAGE_CODE}
          variant="contained"
          color="primary"
          size="small"
          value={params.value}
          onClick={handleOpenToolsDialog}
        >
          Use
        </AccessControlButton>
      </strong>
    ),
  };

  const headerColumn: GridColumns = [verbiageCodeHeader, actionsHeader];

  const handleOpenToolsDialog = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    if (event.currentTarget.value == "1") {
      setOpenBase64ToImage(true);
    } else if (event.currentTarget.value == "2") {
      setOpenFileToBase64(true);
    } else if (event.currentTarget.value == "3") {
      setOpenBase64ToFile(true);
    } else if (event.currentTarget.value == "4") {
      setOpenIDocscan(true);
    }
  };

  return (
    <>
      <Base64ToImageDialog
        open={openBase64ToImage}
        onCancelClicked={() => setOpenBase64ToImage(false)}
      />

      <FileToBase64Dialog
        open={openFileToBase64}
        onCancelClicked={() => setOpenFileToBase64(false)}
      />

      <Base64ToFileDialog
        open={openBase64ToFile}
        onCancelClicked={() => setOpenBase64ToFile(false)}
      />

      <IDocScanDialog
        open={openIDocscan}
        onCancelClicked={() => setOpenIDocscan(false)}
      />

      <MainDrawer title="Tools Management">
        {/* Data Grid */}
        <div style={{ height: "80vh" }}>
          <div style={{ display: "flex", height: "100%" }}>
            <div style={{ flexGrow: 1 }}>
              <DataGrid
                pageSize={size}
                rowsPerPageOptions={[10, 20, 30]}
                onPageSizeChange={(size) => {
                  setSize(size);
                }}
                disableColumnMenu
                density="standard"
                components={{
                  LoadingOverlay: CustomLoadingOverlay,
                  NoRowsOverlay: CustomNoRowsOverlay,
                }}
                pagination
                paginationMode="server"
                rowCount={verbiageList.length}
                rows={verbiageList}
                columns={headerColumn}
                getRowId={(row) => row.id}
              />
            </div>
          </div>
        </div>
      </MainDrawer>
    </>
  );
};
