import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store/state";
import { ResponseModel } from "../models/common/ResponseModel";
import { AxiosError } from "axios";
import { ARIA_SuccessCode, GetLanguageSettingsResponseModel } from "models";
import { getLanguageSettingListAPI } from "services/languageSettingService";

const initialState: RootState.LanguageSettingState = {
  languageSettingList: [],
  error: undefined,
  isLoading: false,
};

//Actions
//More info here https://redux-toolkit.js.org/api/createAsyncThunk
export const fetchLanguageSettingList = createAsyncThunk<
  GetLanguageSettingsResponseModel,
  void,
  { rejectValue: AxiosError<Error> }
>("languageSetting/getLanguageSettingList", async (_, thunkApi) => {
  try {
    var pageIndex = 1;

    let data: GetLanguageSettingsResponseModel =
      await getLanguageSettingListAPI({ pageIndex });

    let tempData = data.result.items;

    while (data.result.pageCount > pageIndex) {
      pageIndex++;
      let nextData: GetLanguageSettingsResponseModel =
        await getLanguageSettingListAPI({ pageIndex });

      tempData.push(...nextData.result.items);
    }

    data.result.items = tempData;

    return data;
  } catch (err) {
    let error = err as AxiosError<Error>; // cast the error for access
    if (!error.response) {
      throw err;
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    return thunkApi.rejectWithValue(error);
  }
});

//Slice
//More info here https://redux-toolkit.js.org/api/createSlice
export const languageSettingSlice = createSlice({
  name: "languageSetting",
  initialState,
  reducers: {
    //local reducer
    resetLanguageSettingState() {
      return initialState;
    },
  },
  //services reducers
  extraReducers: (builder) => {
    builder.addCase(
      fetchLanguageSettingList.fulfilled,
      (state, { payload }) => {
        if (payload.resultCode === ARIA_SuccessCode) {
          state.languageSettingList = payload.result.items;
          state.error = undefined;
        } else {
          state.error = { ...payload };
        }

        state.error = undefined;
        state.isLoading = false;
      }
    ),
      builder.addCase(fetchLanguageSettingList.pending, (state, _) => {
        state.isLoading = true;
      }),
      builder.addCase(fetchLanguageSettingList.rejected, (state, action) => {
        if (action.payload != undefined) {
          let error: AxiosError<Error> = action.payload;

          const serverError: ResponseModel = {
            resultCode: error?.response?.status.toString() ?? "",
            resultDescription: error?.response?.data.message ?? "",
          };

          state.error = serverError;
          state.isLoading = false;
        }
      });
  },
});

export const { resetLanguageSettingState } = languageSettingSlice.actions;

export default languageSettingSlice.reducer;
