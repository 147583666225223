import React from "react";
import { useSelector } from "react-redux";

import {
  Checkbox,
  createStyles,
  FormControl,
  Input,
  InputLabel,
  ListItemText,
  makeStyles,
  MenuItem,
  Select,
  Theme,
} from "@material-ui/core";

import { RootState } from "reducers";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      width: "100%",
    },
  })
);

interface IProps {
  label?: string;
  disabled?: boolean;
  value: number[];
  onChange?: (ComponentList: number []) => void;
}

export const ComponentSelector: React.FC<IProps> = ({
  label = "Component",
  value,
  onChange,
  disabled = false,
}) => {
  const classes = useStyles();
  const { componentList } = useSelector((state: RootState) => state.component);

  const [components, setComponents] = React.useState<number[]>(
    value == undefined ? [] : value
  );

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectedValues = event.target.value as number[];

    if (onChange) {
        onChange(selectedValues);
    }

    setComponents(selectedValues);
  };

  return (
    <FormControl className={classes.formControl} disabled={disabled}>
      <InputLabel id="identityrole-mutiple-checkbox-label">{label}</InputLabel>
      <Select
        id="identityrole-mutiple-checkbox"
        multiple
        value={components}
        onChange={handleChange}
        input={<Input />}
        renderValue={(selected) =>  componentList.filter(c => (selected as number []).includes(c.id)).map(c => c.name).join(",")}
        // MenuProps={MenuProps}
      >
        {componentList.map((component, index) => (
          <MenuItem key={index} value={component.id}>
            <Checkbox
              color="primary"
              checked={components.indexOf(component.id) > -1}
            />
            <ListItemText primary={component.name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};