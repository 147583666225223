import { DepositTransactionInfo, TransactionInfo } from "models";
import { getShortDate, getTime } from "./dateHelper";

export function mapToApplicationTransaction(
  transactions: TransactionInfo[]
): TransactionInfo[] {
  return transactions.map((transaction) => ({
    ...transaction,
    guest: transaction.reservation.guest,
    transactionDateString: getShortDate(transaction.transactionDate),
    transactionTimeString: (transaction.transactionTimeString = getTime(
      transaction.transactionDate
    )),
  }));
}


export function mapToApplicationAdyenTransaction(
    adyenTransactions: DepositTransactionInfo[]
  ): DepositTransactionInfo[] {
    return adyenTransactions.map((transaction) => ({
      ...transaction,
      guest: transaction.reservation.guest,
      transactionDateString: getShortDate(transaction.transactionDate),
      transactionTimeString: (transaction.transactionTimeString = getTime(
        transaction.transactionDate
      )),
    }));
  }