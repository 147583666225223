import React from "react";
import { useSelector } from "react-redux";

import {
  Checkbox,
  createStyles,
  FormControl,
  Input,
  InputLabel,
  ListItemText,
  makeStyles,
  MenuItem,
  Select,
  Theme,
} from "@material-ui/core";

import { RootState } from "reducers";
import { IdentityRoleList } from "models";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      width: "100%",
    },
  })
);

// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;
// const MenuProps = {
//   PaperProps: {
//     style: {
//       maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//       width: 250,
//     },
//   },
// };

interface IProps {
  label?: string;
  disabled?: boolean;
  value: string[];
  onChange?: (FeatureList: string) => void;
}

export const FeatureSelector: React.FC<IProps> = ({
  label = "Feature",
  value,
  onChange,
  disabled = false,
}) => {
  const classes = useStyles();
  const { featureList } = useSelector(
    (state: RootState) => state.featureState
  );

  const [identityRoles, setIdentityRoles] = React.useState<string[]>(
    value == undefined ? [] : value
  );

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const sortingArr = IdentityRoleList;
    var sortedList = (event.target.value as string[]).sort(function (a, b) {
      return sortingArr.indexOf(a) - sortingArr.indexOf(b);
    });

    if(onChange){
        onChange(sortedList.join(","));
    }
    setIdentityRoles(sortedList);
  };

  return (
    <FormControl className={classes.formControl} disabled={disabled}>
      <InputLabel id="identityrole-mutiple-checkbox-label">{label}</InputLabel>
      <Select
        id="identityrole-mutiple-checkbox"
        multiple
        value={identityRoles}
        onChange={handleChange}
        input={<Input />}
        renderValue={(selected) => (selected as string[]).join(",")}
        // MenuProps={MenuProps}
      >
        {featureList.map((feature, index) => (
          <MenuItem key={index} value={feature.name}>
            <Checkbox
              color="primary"
              checked={identityRoles.indexOf(feature.name) > -1}
            />
            <ListItemText primary={feature.name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
