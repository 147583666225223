import { Paper } from "@material-ui/core";
import { GridContainer } from "components/GridContainer";
import React from "react";
import { ConfigX, ConfigXSub } from "..";
import { ConfigurationTab } from "../ConfigurationTab";
// @material-ui/core components

interface IConfigurationTabPanel {
  displayTabId: number;
  configs: ConfigX[];
  onChange: (newValue: ConfigXSub) => void;
}

export const ConfigurationTabPanel: React.FC<IConfigurationTabPanel> = ({
  displayTabId,
  configs,
  onChange,
}) => {
  const [editingConfigs] = React.useState<ConfigX[]>(configs);

  //   const onRemove = (value: ConfigXSub) => {
  //     console.log("Removing");
  //     console.log(value);
  //   };

  return (
    <div
      role="tabpanel"
      id={`tabpanel-${displayTabId}`}
      style={{ height: "50vh" }}
    >
      <Paper>
        <GridContainer
          direction="row"
          alignItems="center"
          spacing={2}
          justifyContent="flex-start"
          alignContent="flex-start"
          style={{ padding: "18px" }}
        >
          {editingConfigs.map((config, index) => {
            if (displayTabId == index) {
              return config.items.map((item, index) => (
                <ConfigurationTab
                  key={index}
                  config={item}
                  onChange={onChange}
                  //   onRemove={onRemove}
                />
              ));
            } else {
              return null;
            }
          })}
        </GridContainer>
      </Paper>
    </div>
  );
};
