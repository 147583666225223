import {
  CreateHotelRequestModel,
  CreateHotelResponseModel,
  DeleteHotelRequestModel,
  DeleteHotelResponseModel,
  GetHotelsRequestModel,
  GetHotelsResponseModel,
  UpdateHotelRequestModel,
  UpdateHotelResponseModel,
} from "models";
import { getServerFilter } from "utils/filterHelper";
import {
  getApiKeyForHotel,
  getLoginUser,
  privateHttpClient,
} from "../utils/httpClient";

export const createHotelAPI = async (
  request: CreateHotelRequestModel
): Promise<CreateHotelResponseModel> => {
  const { data } = await privateHttpClient.post(
    "/api/hotel",
    {
      ...request,
    },
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${getLoginUser().token}`,
        ApiKey: getApiKeyForHotel(),
      },
    }
  );

  return data;
};

export const getHotelListAPI = async (
  request: GetHotelsRequestModel
): Promise<GetHotelsResponseModel> => {
  const { data } = await privateHttpClient.get("/api/hotel", {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${getLoginUser().token}`,
      ApiKey: getApiKeyForHotel(),
    },
    params: {
      ...request,
      filter: getServerFilter(request?.filter),
    },
  });

  return data;
};

export const getHotelAPI = async (hotelCode: string) => {
  const { data } = await privateHttpClient.get("/api/hotel", {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${getLoginUser().token}`,
      ApiKey: getApiKeyForHotel(),
    },
    params: {
      filter: `code eq ${hotelCode}`,
    },
  });

  return data;
};

export const updateHotelAPI = async (
  request: UpdateHotelRequestModel
): Promise<UpdateHotelResponseModel> => {
  const { data } = await privateHttpClient.put(
    "/api/hotel",
    {
      ...request,
    },
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${getLoginUser().token}`,
        ApiKey: getApiKeyForHotel(),
      },
    }
  );

  return data;
};

export const deleteHotelAPI = async (
  request: DeleteHotelRequestModel
): Promise<DeleteHotelResponseModel> => {
  const { data } = await privateHttpClient.delete("/api/hotel", {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${getLoginUser().token}`,
      ApiKey: getApiKeyForHotel(),
    },
    data: {
      ...request,
    },
  });

  return data;
};

