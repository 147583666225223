import { ConfigX } from "components/ConfigurationDialog";
import { Config, Configuration, Reservation, ReservationModel } from "models";
import {
  ScreenMarketingConsent,
  ScreenReservationList,
  ScreenReservationPreAuthPayment,
  ScreenReservationPaymentProcessing,
  ScreenReservationRegistration,
  ScreenReservationPayment,
} from "./constantHelper";

export function hasReservationCompletedVerification(
  reservation: Reservation
): Boolean {
  return reservation.verified && reservation.healthDeclaration != undefined;
}

export function hasAllReservtionCompletedVerification(
  reservation: Reservation,
  appConfig: Config
): Boolean {
  //If adding new guest is allow check all the shared Reservation verification status.
  if (appConfig.reservationConfig.isAddingNewGuestAllow) {
    return (
      reservation != undefined &&
      hasReservationCompletedVerification(reservation) &&
      reservation.sharedReservations?.map(
        (shareRes) => hasReservationCompletedVerification(shareRes) === true
      ).length === reservation.sharedReservations?.length
    );
  } else {
    return (
      reservation != undefined &&
      hasReservationCompletedVerification(reservation)
    );
  }
}

export function setupScreenFlow(
  selectedReservation: Reservation,
  screenFlow: string[],
  Reservations: Reservation[]
): string[] {
  var _screenFlow = screenFlow;

  if (selectedReservation != undefined && screenFlow.length > 0) {
    if (Reservations != undefined && Reservations.length == 1) {
      _screenFlow = _screenFlow.filter(
        (screenName) => screenName !== ScreenReservationList
      );
    }

    if (selectedReservation.isPreRegistered) {
      _screenFlow = _screenFlow.filter(
        (screenName) => screenName !== ScreenMarketingConsent
      );
      _screenFlow = _screenFlow.filter(
        (screenName) => screenName !== ScreenReservationRegistration
      );
      _screenFlow = _screenFlow.filter(
        (screenName) => screenName !== ScreenReservationPreAuthPayment
      );
      _screenFlow = _screenFlow.filter(
        (screenName) => screenName !== ScreenReservationPaymentProcessing
      );
    }
  }

  return _screenFlow;
}

export function setupCheckOutScreenFlow(
  selectedReservation: Reservation,
  screenFlow: string[]
): string[] {
  var _screenFlow = screenFlow;

  if (selectedReservation != undefined && screenFlow.length > 0) {
    if (selectedReservation.totalPrice === 0) {
      _screenFlow = _screenFlow.filter(
        (screenName) => screenName !== ScreenReservationPayment
      );
      _screenFlow = _screenFlow.filter(
        (screenName) => screenName !== ScreenReservationPaymentProcessing
      );
    }
  }

  return _screenFlow;
}

export function constructPreRegisterReservationModel(
  selectedReservation: Reservation,
  appConfig: Config
): ReservationModel[] {
  var _reservations: ReservationModel[] = [];

  var _reservation: ReservationModel = {
    confirmationNo: selectedReservation.confirmationNo,
    primaryConfirmationNo: selectedReservation.confirmationNo,
    guestId: selectedReservation?.guest.id,
    guestProfileId: selectedReservation?.guest.profileId,
  };

  _reservations.push(_reservation);

  //Add sharer into the list for pre-register.
  if (appConfig?.reservationConfig.isAddingNewGuestAllow) {
    selectedReservation.sharedReservations?.forEach((res) => {
      var _reservation: ReservationModel = {
        confirmationNo: res.confirmationNo,
        primaryConfirmationNo: res.confirmationNo,
        guestId: res.guest.id,
        guestProfileId: res.guest.profileId,
      };

      _reservations.push(_reservation);
    });
  }

  return _reservations;
}

export function GetConfigList(editingConfiguration: Configuration): ConfigX[] {
  var list: ConfigX[] = [];
  var _main: ConfigX = {
    title: editingConfiguration.feature!!.name,
    items: [],
  };

  var item = JSON.parse(editingConfiguration.value);

  for (const key of Object.keys(item)) {
    try {
      if (typeof key === "string") {
        if (
          typeof item[key] === "string" ||
          typeof item[key] === "number" ||
          typeof item[key] === "boolean"
        ) {
          _main.items.push({
            dataName: key,
            dataType: typeof item[key],
            value: item[key],
            isRootLevel: true,
          });
        } else if (typeof item[key] === "object") {
          // console.log(typeof item[key]);
          // console.log(key, item[key]);
          // console.log(item)
          // console.log(Array.isArray(item[key]))

          //if the item is an array of object
          if (Array.isArray(item)) {
            var _arrayObjects = GetConfig(key, item[Number.parseInt(key)]);

            _main.items.push({
              dataName: undefined,
              dataType: "arrayObject",
              value: _arrayObjects,
              isRootLevel: true,
            });
          } else {
            list.push(GetConfig(key, item[key]));
          }

          // console.log(typeof item[key]);
          // console.log(key, item[key]);
        } else {
          console.log(typeof item[key]);
        }
      }
    } catch (err) {
      console.log(err);
    }
  }
  list.unshift(_main);

  return list;
}

function GetConfig(key: string, item: any): ConfigX {
  var _main: ConfigX = {
    title: key,
    items: [],
  };

  for (const key of Object.keys(item)) {
    try {
      if (typeof key === "string") {
        if (
          typeof item[key] === "string" ||
          typeof item[key] === "number" ||
          typeof item[key] === "boolean"
        ) {
          _main.items.push({
            dataName: key,
            dataType: typeof item[key],
            value: item[key],
            isRootLevel: false,
          });
        } else if (typeof item[key] === "object") {
          //TODO!!!! see is this okay??
          if (Array.isArray(item[key])) {
            _main.items.push({
              dataName: key,
              dataType: "arrayString",
              value: JSON.stringify(item[key]),
              isRootLevel: false,
            });
          }
        } else {
          console.log(typeof item[key]);
        }
      }

      // console.log(typeof item[key]);

      // console.log(key, item[key]);
    } catch (err) {
      console.log(err);
    }
  }

  return _main;
}

export function GenerateConfigToConfigXList(config: string, name: string ): ConfigX[] {
  var list: ConfigX[] = [];
  var _main: ConfigX = {
    title: name,
    items: [],
  };
  var item = JSON.parse(config);

  for (const key of Object.keys(item)) {
    try {
      if (typeof key === "string") {
        if (
          typeof item[key] === "string" ||
          typeof item[key] === "number" ||
          typeof item[key] === "boolean"
        ) {
          _main.items.push({
            dataName: key,
            dataType: typeof item[key],
            value: item[key],
            isRootLevel: true,
          });
        } else if (typeof item[key] === "object") {
          // console.log(typeof item[key]);
          // console.log(key, item[key]);
          // console.log(item)
          // console.log(Array.isArray(item[key]))

          //if the item is an array of object
          if (Array.isArray(item)) {
            var _arrayObjects = GetConfig(key, item[Number.parseInt(key)]);

            _main.items.push({
              dataName: undefined,
              dataType: "arrayObject",
              value: _arrayObjects,
              isRootLevel: true,
            });
          } else {
            list.push(GetConfig(key, item[key]));
          }

          // console.log(typeof item[key]);
          // console.log(key, item[key]);
        } else {
          console.log(typeof item[key]);
        }
      }
    } catch (err) {
      console.log(err);
    }
  }
  list.unshift(_main);

  return list;
}

