import React from "react";
// @material-ui/core components
import Container, { ContainerProps } from "@material-ui/core/Container";

export enum MainContainerBackDropType {
  light = "light",
  dark = "dark",
}

interface IProps extends ContainerProps {
  mode?: MainContainerBackDropType;
  backgroundImage?: string;
}

export const MainContainer: React.FC<IProps> = ({
  children,
  mode,
  backgroundImage,
  ...rest
}) => {
  backgroundImage = backgroundImage != null ? backgroundImage : "";

  return (
    <Container
      style={{
        minHeight: "100%",
        minWidth: "100%",
        backgroundPosition: "center",
        overflow: "hidden",
        left: 0,
        top: 0,
        position: "absolute",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundImage: `url(${backgroundImage})`,
      }}
      {...rest}
    >
      {/* {selectedHotel == undefined || appConfig == undefined || verbiages.length <= 0 ? (
        <GridContainer
          direction="row"
          spacing={0}
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: '100vh' }}
        >
          <GridItem>
            <CircularProgress />
          </GridItem>
        </GridContainer>
      ) :  */}
      {children}

      {/* } */}
    </Container>
  );
};
