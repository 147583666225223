import {
  CapturePreAuthRequestModel,
  CreateCheckoutPaymentSessionRequestModel,
  CreatePreAuthPaymentRequestModel,
  GetDepositTransactionRequestModel,
  GetDepositTransactionResponseModel,
  GetPreAuthInfoRequestModel,
  GetTransactionRequestModel,
  GetTransactionResponseModel,
  UpdatePaymentRequestModel,
} from "models";
import { getServerFilter } from "utils/filterHelper";
import {
  getApiKeyForHotel,
  getLoginUser,
  privateHttpClient,
} from "../utils/httpClient";

export const createCheckoutPaymentSessionAPI = async (
  request: CreateCheckoutPaymentSessionRequestModel
) => {
  const { data } = await privateHttpClient.post(
    "/api/Payment/adyen/PreAuth/CheckoutSession",
    {
      ...request,
    },
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${getLoginUser().token}`,
        ApiKey: getApiKeyForHotel(),
      },
    }
  );

  return data;
};

export const createPreAuthPaymentAPI = async (
  request: CreatePreAuthPaymentRequestModel
) => {
  const { data } = await privateHttpClient.post(
    "/api/Payment/adyen/PreAuth",
    {
      ...request,
    },
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${getLoginUser().token}`,
        ApiKey: getApiKeyForHotel(),
      },
    }
  );

  return data;
};

export const updatePaymentCompletionAPI = async (
  request: UpdatePaymentRequestModel
) => {
  const { data } = await privateHttpClient.post(
    "/api/Payment/adyen/PreAuth/Completion",
    {
      ...request,
    },
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${getLoginUser().token}`,
        ApiKey: getApiKeyForHotel(),
      },
    }
  );

  return data;
};

export const getPreAuthInfoAPI = async (
  request: GetPreAuthInfoRequestModel
) => {
  const { data } = await privateHttpClient.get("/api/Payment/adyen/PreAuth", {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${getLoginUser().token}`,
      ApiKey: getApiKeyForHotel(),
    },
    params: {
      ...request,
    },
  });

  return data;
};

export const capturePreAuthPaymentAPI = async (
  request: CapturePreAuthRequestModel
) => {
  const { data } = await privateHttpClient.post(
    "/api/Payment/adyen/PreAuth/Capture",
    {
      ...request,
    },
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${getLoginUser().token}`,
        ApiKey: getApiKeyForHotel(),
      },
    }
  );

  return data;
};

export const getTransactionAPI = async (
  request?: GetTransactionRequestModel
): Promise<GetTransactionResponseModel> => {
  const { data } = await privateHttpClient.get("/api/Payment/Transaction", {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${getLoginUser().token}`,
      ApiKey: getApiKeyForHotel(),
    },
    params: {
      ...request,
      filter: getServerFilter(request?.filter),
    },
  });

  return data;
};

export const getDepositTransactionAPI = async (
  request?: GetDepositTransactionRequestModel
): Promise<GetDepositTransactionResponseModel> => {
  const { data } = await privateHttpClient.get("/api/Payment/Deposit/Transaction", {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${getLoginUser().token}`,
      ApiKey: getApiKeyForHotel(),
    },
    params: {
      ...request,
      filter: getServerFilter(request?.filter),
    },
  });

  return data;
};
