import React, { useState } from "react";
import { useSelector } from "react-redux";

import { RootState } from "reducers";

//Component
import {
  DataGrid,
  GridColDef,
  GridColumns,
  GridToolbarContainer,
} from "@material-ui/data-grid";
import CustomLoadingOverlay from "components/DataGridLoadingOverlay";
import CustomNoRowsOverlay from "components/DataGridNoRowsOverlay";
import {
  ARIA_SuccessCode,
  KioskLocation,
  PM_ADD_KIOSK_LOCATION,
  PM_DELETE_KIOSK_LOCATION,
  PM_EDIT_KIOSK_LOCATION,
} from "models";
import { DataGridFilterToolbar } from "components/DataGridFilterToolbar";
import { GridColFilterDef } from "components/DataGridFilterSelection";
//Icon

//Helper
import { convertDateTimeToString } from "utils/dateHelper";
import { Data } from "react-csv/components/CommonPropTypes";
import { ExportButton } from "components/ExportButton";
import { MainDrawer } from "components/MainDrawer";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import {
  createKioskLocationAPI,
  deleteKioskLocationAPI,
  getKioskLocationListAPI,
  updateKioskLocationAPI,
} from "services/locationService";
import { AccessControlButton } from "components";

export const KioskLocationManagementScreen = () => {
  const { selectedHotel } = useSelector((state: RootState) => state.hotelState);

  const [index, setIndex] = useState<number>(1);
  const [size, setSize] = useState<number>(10);
  const [row, setRow] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isExporting, setIsExporting] = useState(false);

  const [requestDateTime, setRequestDateTime] = useState<Date>(new Date());

  const [kioskLocationList, setKioskLocationList] = useState<KioskLocation[]>(
    []
  );
  const [exportList, setExportList] = useState<Data>([]);
  const [filterList, setFilterList] = useState<GridColFilterDef[]>([]);

  const [loadList, setloadList] = useState(true);

  const [addKioskLocation, setAddKioskLocation] = useState<any | undefined>();
  const [editingKioskLocation, setEditingKioskLocation] = useState<
    KioskLocation | undefined
  >();
  const [removeKioskLocation, setRemoveKioskLocation] = useState<
    KioskLocation | undefined
  >();
  const [deleteConfirmation, setDeleteConfirmation] = useState("");

  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  //Setting up the each column header item
  const kioskLocationNameHeader: GridColDef = {
    field: "name",
    headerName: "Name",
    renderHeader: (params: any) => <strong>{"Location Name"}</strong>,
    minWidth: 240,
  };

  const kioskLocationCodeHeader: GridColDef = {
    field: "code",
    headerName: "Code",
    renderHeader: (params: any) => <strong>{"Code"}</strong>,
    minWidth: 240,
  };

  const kioskLocationDescHeader: GridColDef = {
    field: "description",
    headerName: "Description",
    renderHeader: (params: any) => <strong>{"Description"}</strong>,
    flex: 1,
  };

  const actionsHeader: GridColDef = {
    field: "id",
    headerName: "Actions",
    minWidth: 240,
    filterable: false,
    sortable: false,
    disableColumnMenu: true,
    renderHeader: () => <strong>{"Actions"}</strong>,
    renderCell: (params: any) => (
      <strong>
        <AccessControlButton
          code={PM_EDIT_KIOSK_LOCATION}
          variant="contained"
          color="primary"
          size="small"
          value={params.value}
          onClick={handleOpenEditDialog}
        >
          Edit
        </AccessControlButton>
        <AccessControlButton
          code={PM_ADD_KIOSK_LOCATION}
          variant="contained"
          color="primary"
          size="small"
          value={params.value}
          style={{ marginLeft: 12 }}
          onClick={handleDuplicateFunctionClick}
        >
          Clone
        </AccessControlButton>
        <AccessControlButton
          code={PM_DELETE_KIOSK_LOCATION}
          variant="contained"
          color="primary"
          size="small"
          value={params.value}
          style={{ marginLeft: 12 }}
          onClick={handleOpenDeleteDialog}
        >
          Delete
        </AccessControlButton>
      </strong>
    ),
  };

  const headerColumn: GridColumns = [
    kioskLocationNameHeader,
    kioskLocationCodeHeader,
    kioskLocationDescHeader,
    actionsHeader,
  ];

  React.useEffect(() => {
    if (selectedHotel != undefined) {
      setloadList(true);
    }
  }, [selectedHotel]);

  React.useEffect(() => {
    if (loadList) {
      setIsLoading(true);
      setloadList(false);
      setKioskLocationList([]);
      setRequestDateTime(new Date());

      getKioskLocationListAPI({
        filter: filterList,
        pageIndex: index,
        pageSize: size,
        orderBy: "name",
      })
        .then((response) => {
          if (response.resultCode === ARIA_SuccessCode) {
            setKioskLocationList(response.result.items);
            setRow(response.result.rowCount);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [index, size, filterList, loadList]);

  const getExportTransaction = async () => {
    setIsLoading(true);
    var _exportIndex = 1;
    var exportList: KioskLocation[] = [];

    try {
      do {
        var response = await getKioskLocationListAPI({
          filter: filterList,
          pageIndex: _exportIndex,
          pageSize: size,
          orderBy: "name",
        });

        exportList.push(...response.result.items);

        _exportIndex++;
      } while (response.result.pageCount >= _exportIndex);

      setExportList(exportList);
      setIsExporting(true);
      setIsLoading(false);
    } catch (err) {
      console.log("Oposs.. Something is wrong");
    }
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <DataGridFilterToolbar
          headerColumns={headerColumn}
          filterList={filterList}
          onFilterApply={(items) => {
            setFilterList(items.slice());
          }}
        />
        <ExportButton
          filename="KioskLocation"
          data={exportList}
          headers={[
            { label: "Name", key: "name" },
            { label: "Code", key: "code" },
            { label: "Description", key: "description" },
          ]}
          readyToExport={isExporting}
          onClick={getExportTransaction}
          onFinishDownload={() => {
            setExportList([]);
            setIsExporting(false);
          }}
        >
          Export
        </ExportButton>
        <div style={{ flexGrow: 1 }}></div>
        <AccessControlButton
          code={PM_ADD_KIOSK_LOCATION}
          variant="contained"
          color="primary"
          size="small"
          onClick={handleOpenCreateDialog}
        >
          New Kiosk Location
        </AccessControlButton>
        <div style={{ paddingLeft: "8px" }}>
          <b>Data as at </b>: {convertDateTimeToString(requestDateTime)}
        </div>
      </GridToolbarContainer>
    );
  }

  const handleOpenCreateDialog = () => {
    setOpenEditDialog(true);
  };

  const handleDuplicateFunctionClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    var _updateKioskLocation = kioskLocationList.find(
      (f) => f.id === parseInt(event.currentTarget.value)
    );

    setAddKioskLocation(_updateKioskLocation);
    setOpenEditDialog(true);
  };

  const handleOpenEditDialog = (event: React.MouseEvent<HTMLButtonElement>) => {
    var _updateKioskLocation = kioskLocationList.find(
      (f) => f.id === parseInt(event.currentTarget.value)
    );

    setEditingKioskLocation(_updateKioskLocation);
    setOpenEditDialog(true);
  };

  const handleOpenDeleteDialog = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    var _removeKioskLocation = kioskLocationList.find(
      (f) => f.id === parseInt(event.currentTarget.value)
    );
    setRemoveKioskLocation(_removeKioskLocation);
    setOpenDeleteDialog(true);
  };

  const handleEditOrAdd = () => {
    if (editingKioskLocation == null) {
      if (
        addKioskLocation === null ||
        addKioskLocation.name === null ||
        addKioskLocation.name === "" ||
        addKioskLocation.code === null ||
        addKioskLocation.code === ""
      ) {
        alert("Please fill up the details below to add a new Kiosk Location.");
      } else {
        createKioskLocationAPI({
          ...addKioskLocation,
        })
          .then((response) => {
            if (response.resultCode == ARIA_SuccessCode) {
              setloadList(true);
              handleEditClose();
            } else {
              alert(response.resultDescription);
            }
          })
          .catch((err) => {
            alert(err);
          });
      }
    } else {
      if (
        editingKioskLocation.name === null ||
        editingKioskLocation.name === "" ||
        editingKioskLocation.code === null ||
        editingKioskLocation.code === ""
      ) {
        alert("Please fill up the details below to edit kiosk location.");
      } else {
        updateKioskLocationAPI({
          ...editingKioskLocation,
        })
          .then((response) => {
            if (response.resultCode == ARIA_SuccessCode) {
              setloadList(true);
              handleEditClose();
            } else {
              alert(response.resultDescription);
            }
          })
          .catch((err) => {
            alert(err);
          });
      }
    }
  };

  const handleDelete = () => {
    if (deleteConfirmation !== removeKioskLocation?.code) {
      alert("Please key in the kiosk location code to confirm deletion.");
    } else {
      deleteKioskLocationAPI({ ...removeKioskLocation })
        .catch((err) => {
          alert(err);
        })
        .finally(() => {
          setDeleteConfirmation("");
          setloadList(true);
          handleEditClose();
        });
    }
  };

  const handleEditClose = () => {
    setAddKioskLocation(undefined);
    setEditingKioskLocation(undefined);
    setOpenEditDialog(false);
    setOpenDeleteDialog(false);
  };

  return (
    <>
      <MainDrawer title="Kiosk Locations">
        {/* Add and Edit Dialog */}
        <Dialog
          open={openEditDialog}
          onClose={handleEditClose}
          aria-labelledby="form-dialog-title"
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle id="form-dialog-title">
            {editingKioskLocation != undefined
              ? "Edit Kiosk Location"
              : "Add Kiosk Location"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {editingKioskLocation != undefined
                ? "Change the detail below and save it to update kiosk location details, ."
                : "Fill up the details below to add a new kiosk location."}
            </DialogContentText>
            <form noValidate autoComplete="off">
              <div>
                <TextField
                  margin="dense"
                  id="location_name"
                  label="Kiosk Location Name"
                  type="text"
                  value={
                    editingKioskLocation != undefined
                      ? editingKioskLocation.name
                      : addKioskLocation?.name != undefined
                      ? addKioskLocation?.name
                      : ""
                  }
                  onChange={(e) => {
                    editingKioskLocation != undefined
                      ? setEditingKioskLocation({
                          ...editingKioskLocation,
                          name: e.currentTarget.value,
                        })
                      : setAddKioskLocation({
                          ...addKioskLocation,
                          name: e.currentTarget.value,
                        });
                  }}
                  fullWidth
                />
                <TextField
                  margin="dense"
                  id="code"
                  label="Code"
                  type="text"
                  value={
                    editingKioskLocation != undefined
                      ? editingKioskLocation.code
                      : addKioskLocation?.code != undefined
                      ? addKioskLocation?.code
                      : ""
                  }
                  onChange={(e) => {
                    editingKioskLocation != undefined
                      ? setEditingKioskLocation({
                          ...editingKioskLocation,
                          code: e.currentTarget.value,
                        })
                      : setAddKioskLocation({
                          ...addKioskLocation,
                          code: e.currentTarget.value,
                        });
                  }}
                  fullWidth
                />
                <TextField
                  margin="dense"
                  id="desc"
                  label="Description"
                  type="text"
                  multiline
                  value={
                    editingKioskLocation != undefined
                      ? editingKioskLocation.description
                      : addKioskLocation?.description != undefined
                      ? addKioskLocation?.description
                      : ""
                  }
                  onChange={(e) => {
                    editingKioskLocation != undefined
                      ? setEditingKioskLocation({
                          ...editingKioskLocation,
                          description: e.currentTarget.value,
                        })
                      : setAddKioskLocation({
                          ...addKioskLocation,
                          description: e.currentTarget.value,
                        });
                  }}
                  fullWidth
                />
              </div>
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleEditClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleEditOrAdd} color="primary">
              {editingKioskLocation != null
                ? `Save & Update`
                : `Add Kiosk Location`}
            </Button>
          </DialogActions>
        </Dialog>

        {/* delete Dialog */}
        {removeKioskLocation != undefined ? (
          <Dialog
            open={openDeleteDialog}
            onClose={handleEditClose}
            aria-labelledby="form-dialog-title"
            fullWidth
            maxWidth="sm"
          >
            <DialogTitle id="form-dialog-title">
              Delete Kiosk Location
            </DialogTitle>
            <DialogContent>
              <form noValidate autoComplete="off">
                <div>
                  <TextField
                    margin="dense"
                    id="location_name"
                    label="Kiosk Location Name"
                    type="text"
                    disabled
                    value={removeKioskLocation.name}
                    fullWidth
                  />
                  <TextField
                    margin="dense"
                    id="code"
                    label="Code"
                    type="text"
                    disabled
                    value={removeKioskLocation.code}
                    fullWidth
                  />
                  <TextField
                    margin="dense"
                    id="description"
                    label="Description"
                    type="text"
                    disabled
                    multiline
                    value={removeKioskLocation.description}
                    fullWidth
                  />
                  <DialogContentText
                    color="textPrimary"
                    style={{ marginTop: "18px" }}
                  >
                    {`Are you sure you want to delete ${removeKioskLocation.name}?`}
                  </DialogContentText>
                  <DialogContentText>
                    Please type the following to confirm:
                  </DialogContentText>
                  <DialogContentText>
                    <i>
                      <b>{removeKioskLocation.code}</b>
                    </i>
                  </DialogContentText>
                  <TextField
                    margin="dense"
                    variant="outlined"
                    id="code"
                    type="text"
                    value={deleteConfirmation}
                    onChange={(e) =>
                      setDeleteConfirmation(e.currentTarget.value)
                    }
                    fullWidth
                  />
                </div>
              </form>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleEditClose} color="primary">
                Cancel
              </Button>
              <Button onClick={handleDelete} color="primary">
                {`Delete`}
              </Button>
            </DialogActions>
          </Dialog>
        ) : null}

        {/* Data Grid */}
        <div style={{ height: "80vh" }}>
          <div style={{ display: "flex", height: "100%" }}>
            <div style={{ flexGrow: 1 }}>
              <DataGrid
                pageSize={size}
                rowsPerPageOptions={[10, 20, 30]}
                onPageSizeChange={(size) => {
                  setSize(size);
                }}
                disableColumnMenu
                density="compact"
                components={{
                  LoadingOverlay: CustomLoadingOverlay,
                  NoRowsOverlay: CustomNoRowsOverlay,
                  Toolbar: CustomToolbar,
                }}
                pagination
                loading={isLoading}
                paginationMode="server"
                rowCount={row}
                filterMode="server"
                onPageChange={(newPage) => {
                  setIndex(++newPage);
                }}
                rows={kioskLocationList}
                columns={headerColumn}
                getRowId={(row) => row.id}
              />
            </div>
          </div>
        </div>
      </MainDrawer>
    </>
  );
};
