// FT = Filter Type

export const GRID_FILTER_TYPE_BOOLEAN = "boolean";
export const GRID_FILTER_TYPE_CHILDROLE = "childRole";
export const GRID_FILTER_TYPE_COMPONENTTYPE = "componentType";
export const GRID_FILTER_TYPE_COUNTRY = "country";
export const GRID_FILTER_TYPE_DATETIME = "dateTime";
export const GRID_FILTER_TYPE_OBJECT = "object";
export const GRID_FILTER_TYPE_HOTELGROUP = "hotelGroup";
export const GRID_FILTER_TYPE_IDENTITYROLE = "identityRole";
export const GRID_FILTER_TYPE_KIOSK_LOCATION = "kioskLocation";
export const GRID_FILTER_TYPE_LANGUAGE = "language";
export const GRID_FILTER_TYPE_NUMBER = "number";
