import React from "react";
import { useSelector } from "react-redux";

import {
  Checkbox,
  createStyles,
  FormControl,
  Input,
  InputLabel,
  ListItemText,
  makeStyles,
  MenuItem,
  Select,
  Theme,
} from "@material-ui/core";

import { RootState } from "reducers";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      width: "100%",
    },
  })
);

interface IProps {
  label?: string;
  disabled?: boolean;
  value: string[];
  onChange?: (languageList: string) => void;
}

export const LanguageSelector: React.FC<IProps> = ({
  label = "Language",
  value,
  onChange,
  disabled = false,
}) => {
  const classes = useStyles();
  const { languageList } = useSelector((state: RootState) => state.languageState);

  const [selectedLanguages, setSelectedLanguages] = React.useState<string[]>(
    value == undefined ? [] : value
  );

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    var _selectedLanguages = event.target.value as string[];

    if (onChange) {
      onChange(_selectedLanguages.join(","));
    }
    setSelectedLanguages(_selectedLanguages);
  };

  return (
    <FormControl className={classes.formControl} disabled={disabled}>
      <InputLabel id="hotel-mutiple-checkbox-label">{label}</InputLabel>
      <Select
        id="hotel-mutiple-checkbox"
        multiple
        value={selectedLanguages}
        onChange={handleChange}
        input={<Input />}
        renderValue={(selected) => (selected as string[]).join(",")}
      >
        {languageList.map((language, index) => (
          <MenuItem key={index} value={language.description}>
            <Checkbox
              color="primary"
              checked={selectedLanguages.indexOf(language.description) > -1}
            />
            <ListItemText primary={language.description} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
