import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "reducers";

//Component
import {
  DataGrid,
  GridColDef,
  GridColumns,
  GridToolbarContainer,
} from "@material-ui/data-grid";
import CustomLoadingOverlay from "components/DataGridLoadingOverlay";
import CustomNoRowsOverlay from "components/DataGridNoRowsOverlay";
import { ARIA_SuccessCode, Menu } from "models";
import { DataGridFilterToolbar } from "components/DataGridFilterToolbar";
import { GridColFilterDef } from "components/DataGridFilterSelection";
//Icon

//Helper
import { convertDateTimeToString } from "utils/dateHelper";
import { Data } from "react-csv/components/CommonPropTypes";
import { ExportButton } from "components/ExportButton";
import { MainDrawer } from "components/MainDrawer";
import { AccessControlButton } from "components";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  TextField,
} from "@material-ui/core";
import {
  createMenuAPI,
  deleteMenuAPI,
  getMenuListAPI,
  updateMenuAPI,
} from "services/menuService";
import { MenuTypeList } from "models/backend/constant/MenuType";
import { IdentityRoleSelector } from "components/IdentityRoleSelector";
import { fetchMenuList } from "reducers/menu";
import {
  PM_ADD_MENU_CODE,
  PM_DELETE_MENU_CODE,
  PM_EDIT_MENU_CODE,
} from "models/backend/constant/PermissionCode";
import { GRID_FILTER_TYPE_IDENTITYROLE } from "components/DataGridFilterSelection/type";

export const MenuManagementScreen = () => {
  const dispatch = useDispatch();

  const { selectedHotel } = useSelector((state: RootState) => state.hotelState);
  const { moduleList } = useSelector((state: RootState) => state.menuState);

  const [index, setIndex] = useState<number>(1);
  const [size, setSize] = useState<number>(10);
  const [row, setRow] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isExporting, setIsExporting] = useState(false);

  const [requestDateTime, setRequestDateTime] = useState<Date>(new Date());

  const [menuManagementList, setMenuManagementList] = useState<Menu[]>([]);
  const [exportList, setExportList] = useState<Data>([]);
  const [filterList, setFilterList] = useState<GridColFilterDef[]>([]);

  const [loadList, setloadList] = useState(true);

  const [addMenu, setAddMenu] = useState<any | undefined>();
  const [editMenu, setEditMenu] = useState<Menu | undefined>();
  const [removeMenu, setRemoveMenu] = useState<Menu | undefined>();
  const [deleteConfirmation, setDeleteConfirmation] = useState("");

  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  //Setting up the each column header item
  const menuNameHeader: GridColDef = {
    field: "name",
    headerName: "Name",
    sortable: false,
    renderHeader: () => <strong>{"Name"}</strong>,
    minWidth: 200,
    flex: 1,
  };
  console.log(((size * index) - row))

  const menuTypeHeader: GridColDef = {
    field: "type",
    headerName: "Type",
    sortable: false,
    renderHeader: (params: any) => <strong>{"Type"}</strong>,
    minWidth: 80,
  };

  const menuModuleHeader: GridColDef = {
    field: "parentId",
    headerName: "Module",
    sortable: false,
    filterable: false,
    renderHeader: () => <strong>{"Module"}</strong>,
    valueGetter: (params) => {
      if (params.value === 0) {
        return "-";
      } else {
        const module = moduleList.find((m) => m.id == params.value);
        return module?.name;
      }
    },
    minWidth: 120,
    flex: 1,
  };

  const menuPathHeader: GridColDef = {
    field: "path",
    headerName: "Path",
    sortable: false,
    renderHeader: () => <strong>{"Path"}</strong>,
    minWidth: 240,
    flex: 1,
  };

  const menuIdentityRoleHeader: GridColDef = {
    field: "identityRoles",
    headerName: "Authorized",
    sortable: false,
    type: GRID_FILTER_TYPE_IDENTITYROLE,
    renderHeader: () => <strong>{"Authorized"}</strong>,
    minWidth: 180,
    flex: 1,
  };

  const actionsHeader: GridColDef = {
    field: "id",
    headerName: "Actions",
    minWidth: 240,
    filterable: false,
    sortable: false,
    disableColumnMenu: true,
    renderHeader: () => <strong>{"Actions"}</strong>,
    renderCell: (params: any) => (
      <strong>
        <AccessControlButton
          code={PM_EDIT_MENU_CODE}
          variant="contained"
          color="primary"
          size="small"
          value={params.value}
          onClick={handleOpenEditDialog}
        >
          Edit
        </AccessControlButton>
        <AccessControlButton
          code={PM_ADD_MENU_CODE}
          variant="contained"
          color="primary"
          size="small"
          value={params.value}
          style={{ marginLeft: 12 }}
          onClick={handleDuplicateClick}
        >
          Clone
        </AccessControlButton>
        <AccessControlButton
          code={PM_DELETE_MENU_CODE}
          variant="contained"
          color="primary"
          size="small"
          value={params.value}
          style={{ marginLeft: 12 }}
          onClick={handleOpenDeleteDialog}
        >
          Delete
        </AccessControlButton>
      </strong>
    ),
  };

  const headerColumn: GridColumns = [
    menuNameHeader,
    menuTypeHeader,
    menuModuleHeader,
    menuPathHeader,
    menuIdentityRoleHeader,
    actionsHeader,
  ];

  React.useEffect(() => {
    dispatch(fetchMenuList());
  }, []);

  React.useEffect(() => {
    if (selectedHotel != undefined && loadList) {
      setIsLoading(true);
      setMenuManagementList([]);
      setRequestDateTime(new Date());

      getMenuListAPI({
        filter: filterList,
        pageIndex: index,
        pageSize: size,
      })
        .then((response) => {
          if (response.resultCode === ARIA_SuccessCode) {
            const menuList = response.result.items;
            setMenuManagementList(menuList);
            setRow(response.result.rowCount);
          }
        })
        .finally(() => {
          setloadList(false);
          setIsLoading(false);
        });
    }
  }, [index, size, filterList, selectedHotel, loadList]);

  const getExportTransaction = async () => {
    setIsLoading(true);
    var _exportIndex = 1;
    var exportList: Menu[] = [];

    try {
      do {
        var response = await getMenuListAPI({
          filter: filterList,
          pageIndex: _exportIndex,
          pageSize: size,
        });

        exportList.push(...response.result.items);

        _exportIndex++;
      } while (response.result.pageCount >= _exportIndex);

      setExportList(exportList);
      setIsExporting(true);
      setIsLoading(false);
    } catch (err) {
      console.log("Oposs.. Something is wrong");
    }
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <DataGridFilterToolbar
          headerColumns={headerColumn}
          filterList={filterList}
          onFilterApply={(items) => {
            setFilterList(items.slice());
            setloadList(true);
          }}
        />
        <ExportButton
          filename="Menu"
          data={exportList}
          headers={[
            { label: "Name", key: "name" },
            { label: "Type", key: "type" },
            { label: "Module", key: "module" },
            { label: "Path", key: "path" },
            // { label: "Authorized", key: "identityRoles" },
          ]}
          readyToExport={isExporting}
          onClick={getExportTransaction}
          onFinishDownload={() => {
            setExportList([]);
            setIsExporting(false);
          }}
        >
          Export
        </ExportButton>
        <div style={{ flexGrow: 1 }}></div>
        <AccessControlButton
          code={PM_ADD_MENU_CODE}
          variant="contained"
          color="primary"
          size="small"
          onClick={handleOpenCreateDialog}
        >
          New Menu
        </AccessControlButton>
        <div style={{ paddingLeft: "8px" }}>
          <b>Data as at </b>: {convertDateTimeToString(requestDateTime)}
        </div>
      </GridToolbarContainer>
    );
  }

  const handleOpenCreateDialog = () => {
    setOpenEditDialog(true);
  };

  const handleDuplicateClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    var _updateFunction = menuManagementList.find(
      (f) => f.id === parseInt(event.currentTarget.value)
    );

    setAddMenu(_updateFunction);
    setOpenEditDialog(true);
  };

  const handleOpenEditDialog = (event: React.MouseEvent<HTMLButtonElement>) => {
    var updateFunction = menuManagementList.find(
      (f) => f.id === parseInt(event.currentTarget.value)
    );
    setEditMenu(updateFunction);
    // setSelectedIsAudited(updateFunction.IsAudited)
    setOpenEditDialog(true);
  };

  const handleOpenDeleteDialog = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    var _removeFunction = menuManagementList.find(
      (f) => f.id === parseInt(event.currentTarget.value)
    );
    setRemoveMenu(_removeFunction);
    setOpenDeleteDialog(true);
  };

  const handleEditOrAdd = () => {
    if (editMenu == undefined) {
      if (
        addMenu == undefined ||
        addMenu.name == undefined ||
        addMenu.name === "" ||
        addMenu.type == undefined ||
        addMenu.type === "" ||
        addMenu.identityRoles == undefined ||
        addMenu.identityRoles === ""
      ) {
        alert("Please fill up the details below to add a new menu.");
      } else {
        createMenuAPI({ ...addMenu })
          .catch((err) => {
            alert(err);
          })
          .finally(() => {
            setloadList(true);
            handleEditClose();
          });
      }
    } else {
      if (
        editMenu.name == undefined ||
        editMenu.name === "" ||
        editMenu.type == undefined ||
        editMenu.type === "" ||
        editMenu.identityRoles == undefined ||
        editMenu.identityRoles === ""
      ) {
        alert("Please fill up the details below to edit menu.");
      } else {
        if (editMenu.type === MenuTypeList[0].value) {
          editMenu.parentId = 0;
          editMenu.path = "";
        }

        updateMenuAPI({ ...editMenu })
          .catch((err) => {
            alert(err);
          })
          .finally(() => {
            setloadList(true);
            handleEditClose();
          });
      }
    }
  };

  const handleDelete = () => {
    if (deleteConfirmation !== removeMenu?.name) {
      alert("Please key in the menu name to confirm deletion.");
    } else {
      deleteMenuAPI({ ...removeMenu })
        .catch((err) => {
          alert(err);
        })
        .finally(() => {
          setDeleteConfirmation("");
          setloadList(true);
          handleEditClose();
        });
    }
  };

  const handleEditClose = () => {
    setAddMenu(undefined);
    setEditMenu(undefined);
    setRemoveMenu(undefined);
    setOpenEditDialog(false);
    setOpenDeleteDialog(false);
  };

  return (
    <>
      <MainDrawer title="Menu Management">
        {/* Add and Edit Dialog */}
        <Dialog
          open={openEditDialog}
          onClose={handleEditClose}
          aria-labelledby="form-dialog-title"
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle id="form-dialog-title">
            {editMenu != undefined ? "Edit Menu" : "Add Menu"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {editMenu != undefined
                ? "Change the detail below and save it to update menu details, ."
                : "Fill up the details below to add a new menu."}
            </DialogContentText>
            <form noValidate autoComplete="off">
              <div>
                <TextField
                  margin="dense"
                  id="name"
                  label="Menu Name"
                  type="text"
                  value={editMenu != undefined ? editMenu.name : addMenu?.name}
                  onChange={(e) => {
                    editMenu != undefined
                      ? setEditMenu({
                          ...editMenu,
                          name: e.currentTarget.value,
                        })
                      : setAddMenu({
                          ...addMenu,
                          name: e.currentTarget.value,
                        });
                  }}
                  fullWidth
                />
                <TextField
                  margin="dense"
                  id="type"
                  label="Type"
                  select
                  value={editMenu != undefined ? editMenu.type : addMenu?.type}
                  onChange={(e) => {
                    editMenu != undefined
                      ? setEditMenu({
                          ...editMenu,
                          type: e.target.value,
                        })
                      : setAddMenu({
                          ...addMenu,
                          type: e.target.value,
                        });
                  }}
                  fullWidth
                >
                  {MenuTypeList.map((menuType, index) => (
                    <MenuItem key={index} value={menuType.value}>
                      {menuType.label}
                    </MenuItem>
                  ))}
                </TextField>

                {(editMenu != undefined &&
                  editMenu.type === MenuTypeList[1].value) ||
                addMenu?.type === MenuTypeList[1].value ? (
                  <>
                    <TextField
                      margin="dense"
                      id="module"
                      label="Module"
                      select
                      value={
                        editMenu != undefined
                          ? editMenu.parentId
                          : addMenu?.parentId
                      }
                      onChange={(e) => {
                        editMenu != undefined
                          ? setEditMenu({
                              ...editMenu,
                              parentId: parseInt(e.target.value),
                            })
                          : setAddMenu({
                              ...addMenu,
                              parentId: parseInt(e.target.value),
                            });
                      }}
                      fullWidth
                    >
                      {moduleList.map((module, index) => (
                        <MenuItem key={index} value={module.id}>
                          {module.name}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      margin="dense"
                      id="path"
                      label="Path"
                      type="text"
                      value={
                        editMenu != undefined ? editMenu.path : addMenu?.path
                      }
                      onChange={(e) => {
                        editMenu != undefined
                          ? setEditMenu({
                              ...editMenu,
                              path: e.currentTarget.value,
                            })
                          : setAddMenu({
                              ...addMenu,
                              path: e.currentTarget.value,
                            });
                      }}
                      fullWidth
                    />
                  </>
                ) : null}
                <IdentityRoleSelector
                  label="Authorized"
                  value={
                    editMenu != undefined
                      ? editMenu.identityRoles.split(",")
                      : addMenu?.identityRoles?.split(",")
                  }
                  onChange={(roles) => {
                    editMenu != undefined
                      ? setEditMenu({
                          ...editMenu,
                          identityRoles: roles,
                        })
                      : setAddMenu({
                          ...addMenu,
                          identityRoles: roles,
                        });
                  }}
                />
              </div>
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleEditClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleEditOrAdd} color="primary">
              {editMenu != undefined ? `Save & Update` : `Add Menu`}
            </Button>
          </DialogActions>
        </Dialog>

        {/* delete Dialog */}
        {removeMenu != undefined ? (
          <Dialog
            open={openDeleteDialog}
            onClose={handleEditClose}
            aria-labelledby="form-dialog-title"
            fullWidth
            maxWidth="sm"
          >
            <DialogTitle id="form-dialog-title">Delete Menu</DialogTitle>
            <DialogContent>
              <form noValidate autoComplete="off">
                <div>
                  <TextField
                    margin="dense"
                    id="name"
                    label="Menu Name"
                    type="text"
                    disabled
                    value={removeMenu.name}
                    fullWidth
                  />
                  <TextField
                    margin="dense"
                    id="type"
                    label="Type"
                    type="text"
                    disabled
                    value={removeMenu.type}
                    fullWidth
                  />
                  {removeMenu.type === MenuTypeList[1].value ? (
                    <>
                      <TextField
                        margin="dense"
                        id="module"
                        label="Module"
                        select
                        disabled
                        value={removeMenu.parentId}
                        fullWidth
                      >
                        {moduleList.map((module, index) => (
                          <MenuItem key={index} value={module.id}>
                            {module.name}
                          </MenuItem>
                        ))}
                      </TextField>
                      <TextField
                        margin="dense"
                        id="path"
                        label="Path"
                        type="text"
                        disabled
                        value={removeMenu.path}
                        fullWidth
                      />
                    </>
                  ) : null}
                  <IdentityRoleSelector
                    label="Authorized"
                    disabled
                    value={removeMenu.identityRoles.split(",")}
                  />

                  <DialogContentText
                    color="textPrimary"
                    style={{ marginTop: "18px" }}
                  >
                    {`Are you sure you want to delete ${removeMenu.name}?`}
                  </DialogContentText>
                  <DialogContentText>
                    Please type the following to confirm:
                  </DialogContentText>
                  <DialogContentText>
                    <i>
                      <b>{removeMenu.name}</b>
                    </i>
                  </DialogContentText>
                  <TextField
                    margin="dense"
                    variant="outlined"
                    id="name"
                    type="text"
                    value={deleteConfirmation}
                    onChange={(e) =>
                      setDeleteConfirmation(e.currentTarget.value)
                    }
                    fullWidth
                  />
                </div>
              </form>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleEditClose} color="primary">
                Cancel
              </Button>
              <Button onClick={handleDelete} color="primary">
                {`Delete`}
              </Button>
            </DialogActions>
          </Dialog>
        ) : null}

        {/* Data Grid */}
        <div style={{ height: "80vh" }}>
          <div style={{ display: "flex", height: "100%" }}>
            <div style={{ flexGrow: 1 }}>
              <DataGrid
                pageSize={size}
                rowsPerPageOptions={[10, 20, 30]}
                onPageSizeChange={(size) => {
                  setSize(size);
                  setloadList(true);
                }}
                disableColumnMenu
                density="compact"
                components={{
                  LoadingOverlay: CustomLoadingOverlay,
                  NoRowsOverlay: CustomNoRowsOverlay,
                  Toolbar: CustomToolbar,
                }}
                pagination
                loading={isLoading}
                paginationMode="server"
                rowCount={row}
                filterMode="server"
                onPageChange={(newPage) => {
                  setIndex(++newPage);
                  setloadList(true);
                }}
                rows={menuManagementList}
                columns={headerColumn}
                getRowId={(row) => row.id}
              />
            </div>
          </div>
        </div>
      </MainDrawer>
    </>
  );
};
