import {
  CreateComponentTypeRequestModel,
  CreateComponentTypeResponseModel,
  DeleteComponentTypeRequestModel,
  DeleteComponentTypeResponseModel,
  GetComponentTypeRequestModel,
  GetComponentTypeResponseModel,
  GetComponentTypesRequestModel,
  GetComponentTypesResponseModel,
  UpdateComponentTypeRequestModel,
  UpdateComponentTypeResponseModel,
} from "models";

import { getServerFilter } from "utils/filterHelper";
import {
  getApiKeyForHotel,
  getLoginUser,
  privateHttpClient,
} from "utils/httpClient";

export const createComponentTypeAPI = async (
  request: CreateComponentTypeRequestModel
): Promise<CreateComponentTypeResponseModel> => {
  const { data } = await privateHttpClient.post(
    "/api/componenttype",
    {
      ...request,
    },
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${getLoginUser().token}`,
        ApiKey: getApiKeyForHotel(),
      },
    }
  );

  return data;
};

export const getComponentTypeAPI = async (
  request: GetComponentTypeRequestModel
): Promise<GetComponentTypeResponseModel> => {
  const { data } = await privateHttpClient.get(
    `/api/componenttype/${request.id}`,
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${getLoginUser().token}`,
        ApiKey: getApiKeyForHotel(),
      },
    }
  );

  return data;
};

export const getComponentTypeListAPI = async (
  request: GetComponentTypesRequestModel
): Promise<GetComponentTypesResponseModel> => {
  const { data } = await privateHttpClient.get("/api/componenttype", {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${getLoginUser().token}`,
      ApiKey: getApiKeyForHotel(),
    },
    params: {
      ...request,
      filter: getServerFilter(request?.filter),
    },
  });

  return data;
};

export const updateComponentTypeAPI = async (
  request: UpdateComponentTypeRequestModel
): Promise<UpdateComponentTypeResponseModel> => {
  const { data } = await privateHttpClient.put(
    "/api/componenttype",
    {
      ...request,
    },
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${getLoginUser().token}`,
        ApiKey: getApiKeyForHotel(),
      },
    }
  );

  return data;
};

export const deleteComponentTypeAPI = async (
  request: DeleteComponentTypeRequestModel
): Promise<DeleteComponentTypeResponseModel> => {
  const { data } = await privateHttpClient.delete("/api/componenttype", {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${getLoginUser().token}`,
      ApiKey: getApiKeyForHotel(),
    },
    data: {
      ...request,
    },
  });

  return data;
};
