import {
  GetUserRequestModel,
  GetUserResponseModel,
  GetAppUsersRequestModel,
  GetAppUsersResponseModel,
  UpdateAppUserRequestModel,
  UpdateAppUserResponseModel,
  DeleteAppUserRequestModel,
  DeleteAppUserResponseModel,
  UpdateAppUserStatusRequestModel,
  UpdateAppUserStatusResponseModel,
} from "models";
import { getServerFilter } from "utils/filterHelper";
import {
  getApiKeyForHotel,
  getLoginUser,
  privateHttpClient,
} from "utils/httpClient";

export const getAppUserAPI = async (
  request: GetUserRequestModel
): Promise<GetUserResponseModel> => {
  const { data } = await privateHttpClient.get(`/api/appUser/${request.id}`, {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${getLoginUser().token}`,
      ApiKey: getApiKeyForHotel(),
    },
  });

  return data;
};

export const getAppUserListAPI = async (
  request: GetAppUsersRequestModel
): Promise<GetAppUsersResponseModel> => {
  const { data } = await privateHttpClient.get("/api/appUser", {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${getLoginUser().token}`,
      ApiKey: getApiKeyForHotel(),
    },
    params: {
      ...request,
      filter: getServerFilter(request?.filter),
    },
  });

  return data;
};

export const getAppUserActiveListAPI = async (
  request: GetAppUsersRequestModel
): Promise<GetAppUsersResponseModel> => {
  const additionalFilter = getServerFilter(request?.filter);
  var filter = "isActive eq true";

  if (additionalFilter !== undefined && additionalFilter !== "") {
    filter = filter + ` and ${additionalFilter}`;
  }

  const { data } = await privateHttpClient.get("/api/appUser", {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${getLoginUser().token}`,
      ApiKey: getApiKeyForHotel(),
    },
    params: {
      ...request,
      filter,
    },
  });

  return data;
};

export const updateAppUserAPI = async (
  request: UpdateAppUserRequestModel
): Promise<UpdateAppUserResponseModel> => {
  const { data } = await privateHttpClient.put(
    "/api/appUser",
    {
      ...request,
    },
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${getLoginUser().token}`,
        ApiKey: getApiKeyForHotel(),
      },
    }
  );

  return data;
};

export const updateAppUserStatusAPI = async (
  request: UpdateAppUserStatusRequestModel
): Promise<UpdateAppUserStatusResponseModel> => {
  const { data } = await privateHttpClient.put(
    "/api/appUser/status",
    {
      ...request,
    },
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${getLoginUser().token}`,
        ApiKey: getApiKeyForHotel(),
      },
    }
  );

  return data;
};

export const deleteAppUserAPI = async (
  request: DeleteAppUserRequestModel
): Promise<DeleteAppUserResponseModel> => {
  const { data } = await privateHttpClient.delete("/api/appUser", {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${getLoginUser().token}`,
      ApiKey: getApiKeyForHotel(),
    },
    data: {
      ...request,
    },
  });

  return data;
};
