import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { accountLoginAPI } from "../services/accountService";
import { RootState } from "../store/state";
import { ResponseModel } from "../models/common/ResponseModel";
import { AxiosError } from "axios";
import {
  AccountLoginRequestModel,
  AccountLoginResponseModel,
} from "models/backend/AccountModel";

const initialState: RootState.JwtTokenState = {
  token: null,
  error: null,
  isLoading: false,
};

//Actions
//More info here https://redux-toolkit.js.org/api/createAsyncThunk
export const authenticatUser = createAsyncThunk<
  AccountLoginResponseModel,
  { request: AccountLoginRequestModel },
  { rejectValue: AxiosError<Error> }
>("account/authenticateUser", async (input, thunkApi) => {
  const { request } = input;

  try {
    let data: AccountLoginResponseModel = await accountLoginAPI(request);
    return data;
  } catch (err) {
    let error = err as AxiosError<Error>; // cast the error for access
    if (!error.response) {
      throw err;
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    return thunkApi.rejectWithValue(error);
  }
});

//Slice
//More info here https://redux-toolkit.js.org/api/createSlice
export const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    //local reducer
  },
  //services reducers
  extraReducers: (builder) => {
    builder.addCase(authenticatUser.fulfilled, (state, { payload }) => {
      state.token = payload.result;

      const serverError: ResponseModel = {
        resultCode: payload.resultCode,
        resultDescription: payload.resultDescription,
      };

      window.sessionStorage.setItem("token", payload.result.accessToken);

      state.error = serverError;
      state.isLoading = false;
    }),
      builder.addCase(authenticatUser.pending, (state, _) => {
        state.isLoading = true;
      }),
      builder.addCase(authenticatUser.rejected, (state, action) => {
        if (action.payload != undefined) {
          let error: AxiosError<Error> = action.payload;

          const serverError: ResponseModel = {
            resultCode: error?.response?.status.toString() ?? "",
            resultDescription: error?.response?.data.message ?? "",
          };

          state.error = serverError;
          state.isLoading = false;
        }
      });
  },
});

export const {} = accountSlice.actions;

export default accountSlice.reducer;
