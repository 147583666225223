import {
  makeStyles,
  Theme,
  createStyles,
  useTheme,
} from "@material-ui/core/styles";
import React from "react";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";

// @material-ui/core components
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import HomeIcon from "@material-ui/icons/Home";
import {
  CssBaseline,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Drawer,
  Divider,
  List,
  Button,
  TextField,
  MenuItem,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@material-ui/core";

import { GridContainer } from "components/GridContainer";
import { GridItem } from "components/GridItem";

import { RootState } from "reducers";
import { useAuth } from "utils/authUser";
import { fetchCountryList } from "reducers/country";
import { fetchHotelList, selectHotel, setAuthorisedHotelList } from "reducers/hotel";
import jwtDecode from "jwt-decode";
// import { mockMenus } from "mock/menu";
import { SideMenu } from "./SideMenu";
import { fetchIdentityUserRoleList } from "reducers/identityRole";
import { fetchHoteGrouplList } from "reducers/hotelGroup";
import { fetchRole, fetchRoleList } from "reducers/role";
import { updateNavMenuState } from "reducers/menu";
import { useNavigate } from "react-router";
import { Hotel } from "models";
import { setMainDrawer, setMainDrawerLock } from "reducers/app";
import { fetchFunctionList } from "reducers/function";

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
    },
    hide: {
      display: "none",
    },
    empty: {
      flex: 2,
    },
    userContainer: {
      flex: 1,
      marginRight: theme.spacing(2),
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap",
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9) + 1,
      },
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
  })
);

interface IProps {
  title?: string;
}

export const MainDrawer: React.FC<IProps> = ({ title, children }) => {
  const classes = useStyles();
  const theme = useTheme();
  const auth = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isMainDrawerOpen, isMainDrawerLocked } = useSelector(
    (state: RootState) => state.appState
  );
  const { hotelList, selectedHotel, authorisedHotelList } = useSelector(
    (state: RootState) => state.hotelState
  );
  const { countryList } = useSelector((state: RootState) => state.country);
  const { navMenuList } = useSelector((state: RootState) => state.menuState);
  const { role } = useSelector((state: RootState) => state.roleState);

  // const [menuList] = React.useState(mockMenus);
  // const [isLock, setIsLock] = React.useState(false);
  // const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    if (role !== undefined && navMenuList.length == 0) {
      dispatch(updateNavMenuState(role.menus));
    }
  }, [role, navMenuList]);

  React.useEffect(() => {
    const fetchHotel = async () => {
      if (
        auth.user !== undefined &&
        auth.user != undefined &&
        auth.user.token != undefined &&
        hotelList.length === 0
      ) {
        // console.log("fetchHotelList");
        countryList.length === 0 && dispatch(fetchCountryList());
        dispatch(fetchHotelList());
      }
    };

    fetchHotel();
  }, [auth.user]);

  React.useEffect(() => {
    const Reinit = async () => {
      if (
        hotelList.length > 0 &&
        auth != undefined &&
        auth.user != undefined &&
        auth.user.token != undefined &&
        selectedHotel == undefined
      ) {
        const jwtToken = jwtDecode(auth.user.token);
        const jwtTokenObject = JSON.parse(JSON.stringify(jwtToken));
        const hotelIds = jwtTokenObject.hotel;
        const identityRoles = jwtTokenObject.role;
        const roleId = jwtTokenObject.roleId;

        //When ONLY 1 hotel is granted
        if (typeof hotelIds === "number") {
          if (hotelIds != 0) {
            var _hotel = hotelList.find((hotel) => hotel.id === hotelIds);

            if (_hotel != undefined) {
              dispatch(selectHotel(_hotel));
              dispatch(setAuthorisedHotelList([_hotel]));
            }
          }
        } else {
          if (hotelIds != null && hotelIds.length > 0) {
            var _hotels: Hotel[] = [];
            var _hotel: Hotel | undefined;

            //Get the selected hotel from local DB 
            var _existingHotelApiKey = sessionStorage.getItem("key");

            hotelIds.forEach((id: number, index: number) => {
              var h = hotelList.find((hotel) => hotel.id === id);
              if (h != undefined) {
                _hotels.push(h);

                if(_existingHotelApiKey != null && h.apiKey == _existingHotelApiKey){
                  _hotel = h
                } else if (index == 0) {
                  _hotel = h;
                }
              }
            });

            if (_hotel != undefined) {
              dispatch(selectHotel(_hotel));
              dispatch(setAuthorisedHotelList(_hotels));
            }
          }
        }

        if (roleId !== undefined) {
          dispatch(fetchRole({ request: { id: roleId } }));
        }

        //Special list for host
        if (typeof identityRoles === "string") {
          if (identityRoles === "Host") {
            dispatch(fetchIdentityUserRoleList());
            dispatch(fetchHoteGrouplList());
          }
        } else {
          var userIdentityRoles = identityRoles.find(
            (r: string) => r === "Host"
          );
          if (userIdentityRoles != undefined) {
            dispatch(fetchIdentityUserRoleList());
            dispatch(fetchHoteGrouplList());
          }
        }

        //******************************//
        //Load other important items here
        dispatch(fetchRoleList());
        dispatch(fetchFunctionList());
      }
    };

    Reinit();
  }, [auth, hotelList, selectedHotel]);

  const handleDrawerOpen = () => {
    // setIsLock(true);
    dispatch(setMainDrawerLock(true))
    dispatch(setMainDrawer(true))
  };

  const handleDrawerClose = () => {
    // setIsLock(false);
    dispatch(setMainDrawerLock(false))
    dispatch(setMainDrawer(false))
  };

  const onHotelChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const hotel = hotelList.find((x) => x.code === event.target.value);
    dispatch(selectHotel(hotel));
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: isMainDrawerOpen,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: isMainDrawerOpen,
            })}
          >
            <MenuIcon />
          </IconButton>
          {title != undefined ? (
            <Typography variant="h6" className={classes.empty} noWrap>
              {title}
            </Typography>
          ) : null}
          {/* <Typography variant="h6" className={classes.empty}></Typography> */}
          <GridContainer
            direction="column"
            alignContent="flex-end"
            alignItems="flex-end"
            className={classes.userContainer}
          >
            <GridItem>
              <Typography variant="body2">{selectedHotel?.name}</Typography>
            </GridItem>
            <GridItem>
              <Typography variant="body2">
                {auth.user != undefined ? auth.user.name : ""}
              </Typography>
            </GridItem>
          </GridContainer>
          <Button color="inherit" onClick={auth.signout}>
            Logout
          </Button>
        </Toolbar>
      </AppBar>

      {/* Side bar */}
      <Drawer
        variant="permanent"
        onMouseEnter={() => {
          if (!isMainDrawerLocked) {
            dispatch(setMainDrawer(true))
            // setOpen(true);
          }
        }}
        onMouseLeave={() => {
          if (!isMainDrawerLocked) {
            dispatch(setMainDrawer(false))
            // setOpen(false);
          }
        }}
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: isMainDrawerOpen,
          [classes.drawerClose]: !isMainDrawerOpen,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: isMainDrawerOpen,
            [classes.drawerClose]: !isMainDrawerOpen,
          }),
        }}
      >
        <div className={classes.toolbar}>
          {authorisedHotelList.length > 1 ? (
            <TextField
              id="selectedHotel"
              select
              fullWidth
              variant="outlined"
              value={selectedHotel?.code}
              onChange={onHotelChange}
            >
              {authorisedHotelList.map((hotel, index) => (
                <MenuItem key={index} value={hotel.code}>
                  {hotel.name}
                </MenuItem>
              ))}
            </TextField>
          ) : null}
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
        <Divider />

        <List>
          <ListItem button>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary={`Home`} onClick={() => navigate("/admin/dashboard")} />
          </ListItem>
          {navMenuList.map((module, index) => (
            <SideMenu
              key={index}
              title={module.title}
              icon={module.icon}
              subMenuItems={module.subMenu}
            />
          ))}
        </List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </main>
    </div>
  );
};
