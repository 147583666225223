import React from "react";
import { useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import { RootState } from "../../store/state";

//Component
import {
  GridContainer,
  GridItem,
  MainContainer,
  CustomButton,
  DynamicTypography,
} from "../../components";

//Icon
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

//Helper
import { IconButton, InputAdornment, TextField } from "@material-ui/core";
import { useAuth } from "utils/authUser";

export const Main = () => {
  const auth = useAuth();
  const navigate = useNavigate();

  const { appConfig } = useSelector((state: RootState) => state.appConfigState);

  const [username, setUsername] = React.useState<string>("");
  const [usernameHelptext, setUsernameHelptext] = React.useState("");

  const [password, setPassword] = React.useState<string>("");
  const [passwordHelptext, setPasswordHelptext] = React.useState("");

  const [isLoading, setIsLoading] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);

  const loginOnClick = () => {
    setIsLoading(true);
    auth
      .signin?.(username, password)
      .then((user) => {
        if (user.token != null) {
          navigate(`/admin/dashboard`);
        } else {
          alert("Invalid Username or password");
        }
      })
      .catch((error) => {
        console.log(error);
        alert("Invalid Username or password.");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <MainContainer backgroundImage={appConfig?.hotelConfig.backgroundImageUrl}>
      <>
        <GridContainer
          direction="column"
          spacing={2}
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: "100vh", backgroundColor: "#ffffff40" }}
        >
          <GridItem xs={12}>
            <DynamicTypography
              align="center"
              variant="h3"
              content={"Welcome to Hotel Management"}
            ></DynamicTypography>
          </GridItem>
          <GridItem xs={12} style={{ textAlign: "center" }}>
            <TextField
              required
              // disabled={isLoading}
              error={usernameHelptext != ""}
              helperText={usernameHelptext}
              style={{ minWidth: "35vw" }}
              id="outlined-required"
              label={"Username"}
              variant="outlined"
              value={username}
              onKeyDown={(e) => {
                if (e.key === "Enter") loginOnClick();
              }}
              onChange={(e) => {
                if (e.currentTarget.value == "") {
                  setUsernameHelptext("Username cannot be blank.");
                } else {
                  setUsernameHelptext("");
                }
                setUsername(e.currentTarget.value);
              }}
            />
          </GridItem>
          <GridItem xs={12} style={{ textAlign: "center" }}>
            <TextField
              required
              // disabled={isLoading}
              error={passwordHelptext != ""}
              helperText={passwordHelptext}
              type={showPassword ? "text" : "password"}
              style={{ minWidth: "35vw" }}
              id="outlined-required"
              label={"Password"}
              variant="outlined"
              value={password}
              onKeyDown={(e) => {
                if (e.key === "Enter") loginOnClick();
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      // onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                if (e.currentTarget.value == "") {
                  setPasswordHelptext("Password cannot be blank.");
                } else {
                  setPasswordHelptext("");
                }
                setPassword(e.currentTarget.value);
              }}
            />
          </GridItem>

          <GridItem xs={12} sm={12} md={3} style={{ textAlign: "center" }}>
            <CustomButton disabled={isLoading} onClick={() => loginOnClick()}>
              Login
            </CustomButton>
          </GridItem>
        </GridContainer>
      </>
      <Outlet />
    </MainContainer>
  );
};
