import { GetErrorLogRequestModel, GetErrorLogResponseModel, GetErrorLogsRequestModel, GetErrorLogsResponseModel } from 'models';
import { getServerFilter } from 'utils/filterHelper';
import { getApiKeyForHotel, getLoginUser, privateHttpClient } from '../utils/httpClient';

export const getErrorLogAPI = async (
  request: GetErrorLogRequestModel
): Promise<GetErrorLogResponseModel> => {
  const { data } = await privateHttpClient.get(`/api/errorlog/${request.id}`, {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${getLoginUser().token}`,
      ApiKey: getApiKeyForHotel(),
    },
  });

  return data;
};

export const getErrorLogListAPI = async (
  request: GetErrorLogsRequestModel
): Promise<GetErrorLogsResponseModel> => {
  const { data } = await privateHttpClient.get("/api/errorlog", {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${getLoginUser().token}`,
      ApiKey: getApiKeyForHotel(),
    },
    params: {
      ...request,
      filter: getServerFilter(request?.filter),
    },
  });

  return data;
};

