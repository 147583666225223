import React from "react";
import { Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { MuiThemeProvider } from "@material-ui/core/styles"; //, Theme } from '@material-ui/core/styles';
import { defaultTheme, getThemes } from "./themes";
import { Main } from "./pages/Main";
import { Product } from "./pages/Product";
// import { Dashboard } from "./pages/Dashboard";
import { PageNotFound } from "./pages/404";
import { RootState } from "reducers";
import { ProvideAuth } from "utils/authUser";
import { RequireAuth } from "components/RequireAuth";
import { MainLanding } from "pages/MainLanding";
import { TransactionDetailScreen } from "pages/TransactionDetail";
import { PreAuthTransactionScreen } from "pages/PreAuthTransaction";
import { ConfigurationScreen } from "pages/Configuration";
import { FunctionManagementScreen } from "pages/Function";
import { MenuManagementScreen } from "pages/Menu";
import { RoleManagementScreen } from "pages/Role";
import { AppUserManagementScreen } from "pages/AppUser";
import { HotelManagementScreen } from "pages/Hotel";
import { HotelGroupManagementScreen } from "pages/HotelGroup";
import { LanguageSettingManagementScreen } from "pages/LanguageSetting";
import { FeatureManagementScreen } from "pages/Feature";
import { ConfigurationGroupManagementScreen } from "pages/ConfigurationGroup";
import { RoomTypeManagementScreen } from "pages/RoomType";
import { VerbiageManagementScreen } from "pages/Verbiage";
import { ErrorLogManagementScreen } from "pages/ErrorLog";
import { ToolManagementScreen } from "pages/Tool";
import { ReservationManagementScreen } from "pages/ReservationDetail";
import { KioskLocationManagementScreen } from "pages/KioskLocation";
import { ComponentTypeManagementScreen } from "pages/ComponentType";
import { ComponentManagementScreen } from "pages/Component";
import { KioskManagementScreen } from "pages/Kiosk";

function App() {
  const { appConfig } = useSelector((state: RootState) => state.appConfigState);
  const [appTheme, setAppTheme] = React.useState(defaultTheme);

  React.useEffect(() => {
    if (appConfig?.themeConfig != undefined) {
      var serverTheme = getThemes({ ...appConfig.themeConfig });
      setAppTheme(serverTheme);
    }
  }, [appConfig]);

  return (
    <>
      <MuiThemeProvider theme={appTheme}>
        <ProvideAuth>
          <Routes>
            <Route path="/" element={<Main />} />
            <Route path="product" element={<Product />} />
            {/* <Route path=":hotelCode" element={<Main />} /> */}
            <Route
              path="/admin/transaction"
              element={
                <RequireAuth>
                  <TransactionDetailScreen />
                </RequireAuth>
              }
            />
            <Route
              path="/admin/preauth"
              element={
                <RequireAuth>
                  <PreAuthTransactionScreen />
                </RequireAuth>
              }
            />
            <Route
              path="/admin/configuration"
              element={
                <RequireAuth>
                  <ConfigurationScreen />
                </RequireAuth>
              }
            />
            <Route
              path="/admin/errorlog"
              element={
                <RequireAuth>
                  <ErrorLogManagementScreen />
                </RequireAuth>
              }
            />
            <Route
              path="/admin/function"
              element={
                <RequireAuth>
                  <FunctionManagementScreen />
                </RequireAuth>
              }
            />
            <Route
              path="/admin/menu"
              element={
                <RequireAuth>
                  <MenuManagementScreen />
                </RequireAuth>
              }
            />
            <Route
              path="/admin/role"
              element={
                <RequireAuth>
                  <RoleManagementScreen />
                </RequireAuth>
              }
            />
            <Route
              path="/admin/user"
              element={
                <RequireAuth>
                  <AppUserManagementScreen />
                </RequireAuth>
              }
            />
            {/* <Route
              path="/admin"
              element={
                <RequireAuth>
                  <MainLanding />
                </RequireAuth>
              }
            /> */}
            <Route
              path="/admin/hotel"
              element={
                <RequireAuth>
                  <HotelManagementScreen />
                </RequireAuth>
              }
            />
            <Route
              path="/admin/hotelgroup"
              element={
                <RequireAuth>
                  <HotelGroupManagementScreen />
                </RequireAuth>
              }
            />
            <Route
              path="/admin/languagesetting"
              element={
                <RequireAuth>
                  <LanguageSettingManagementScreen />
                </RequireAuth>
              }
            />
            <Route
              path="/admin/feature"
              element={
                <RequireAuth>
                  <FeatureManagementScreen />
                </RequireAuth>
              }
            />
            <Route
              path="/admin/configurationgroup"
              element={
                <RequireAuth>
                  <ConfigurationGroupManagementScreen />
                </RequireAuth>
              }
            />
            <Route
              path="/admin/roomtype"
              element={
                <RequireAuth>
                  <RoomTypeManagementScreen />
                </RequireAuth>
              }
            />
            <Route
              path="/admin/verbiage"
              element={
                <RequireAuth>
                  <VerbiageManagementScreen />
                </RequireAuth>
              }
            />
            <Route
              path="/admin/tool"
              element={
                <RequireAuth>
                  <ToolManagementScreen />
                </RequireAuth>
              }
            />
            <Route
              path="/admin/reservation"
              element={
                <RequireAuth>
                  <ReservationManagementScreen />
                </RequireAuth>
              }
            />
            <Route
              path="/admin/kiosk/location"
              element={
                <RequireAuth>
                  <KioskLocationManagementScreen />
                </RequireAuth>
              }
            />
            <Route
              path="/admin/kiosk/component"
              element={
                <RequireAuth>
                  <ComponentManagementScreen />
                </RequireAuth>
              }
            />
            <Route
              path="/admin/kiosk"
              element={
                <RequireAuth>
                  <KioskManagementScreen />
                </RequireAuth>
              }
            />
            <Route
              path="/admin/componenttype"
              element={
                <RequireAuth>
                  <ComponentTypeManagementScreen />
                </RequireAuth>
              }
            />
            <Route
              path="/admin/dashboard"
              element={
                <RequireAuth>
                  <MainLanding />
                </RequireAuth>
              }
            />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </ProvideAuth>
      </MuiThemeProvider>
    </>
  );
}

export default App;
