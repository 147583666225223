import {
  CreateHealthDeclarationRequestModel,
  GetHealthDeclarationRequestModel,
  UpdateHealthDeclarationRequestModel
} from 'models';
import { getApiKeyForHotel, getLoginUser, privateHttpClient } from 'utils/httpClient';

export const createHealthDeclarationAPI = async (request: CreateHealthDeclarationRequestModel) => {
  const { data } = await privateHttpClient.post(
    '/api/healthDeclaration',
    {
      ...request
    },
    {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${getLoginUser().token}`,
        ApiKey: getApiKeyForHotel()
      }
    }
  );

  return data;
};

export const updateHealthDeclarationAPI = async (request: UpdateHealthDeclarationRequestModel) => {
  const { data } = await privateHttpClient.put(
    '/api/healthDeclaration',
    {
      ...request
    },
    {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${getLoginUser().token}`,
        ApiKey: getApiKeyForHotel()
      }
    }
  );

  return data;
};

export const getHealthDeclarationAPI = async (request: GetHealthDeclarationRequestModel) => {
  const { data } = await privateHttpClient.get(`/api/healthDeclaration/${request.hotelId}/${request.confirmationNo}`, {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${getLoginUser().token}`,
      ApiKey: getApiKeyForHotel()
    }
  });

  return data;
};
