import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store/state";
import { ResponseModel } from "../models/common/ResponseModel";
import { AxiosError } from "axios";
import { ARIA_SuccessCode, GetHotelGroupsResponseModel } from "models";
import { getHotelGroupListAPI } from "services/hotelGroupService";

const initialState: RootState.HotelGroupState = {
  hotelGroupList: [],
  selectedHotelGroup: undefined,
  error: undefined,
  isLoading: false,
};

//Actions
//More info here https://redux-toolkit.js.org/api/createAsyncThunk
export const fetchHoteGrouplList = createAsyncThunk<
  GetHotelGroupsResponseModel,
  void,
  { rejectValue: AxiosError<Error> }
>("hotelGroup/getHotelGroupList", async (_, thunkApi) => {
  try {
    var pageIndex = 1;

    let data: GetHotelGroupsResponseModel = await getHotelGroupListAPI({
      pageIndex,
    });

    let tempData = data.result.items;

    while (data.result.pageCount > pageIndex) {
      pageIndex++;
      let nextData: GetHotelGroupsResponseModel = await getHotelGroupListAPI({
        pageIndex,
      });

      tempData.push(...nextData.result.items);
    }

    data.result.items = tempData;

    return data;
  } catch (err) {
    let error = err as AxiosError<Error>; // cast the error for access
    if (!error.response) {
      throw err;
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    return thunkApi.rejectWithValue(error);
  }
});

//Slice
//More info here https://redux-toolkit.js.org/api/createSlice
export const hotelGroupSlice = createSlice({
  name: "hotelGroup",
  initialState,
  reducers: {
    //local reducer
    selectHotelGroup(state, action) {
      state.selectedHotelGroup = action.payload;
    },
    resetHotelGroupState() {
      return initialState;
    },
  },
  //services reducers
  extraReducers: (builder) => {
    builder.addCase(fetchHoteGrouplList.fulfilled, (state, { payload }) => {
      if (payload.resultCode === ARIA_SuccessCode) {
        payload.result.items.unshift({ id: 0, name: "-", code: "-" });
        state.hotelGroupList = payload.result.items;
        state.error = undefined;
      } else {
        state.error = { ...payload };
      }

      state.error = undefined;
      state.isLoading = false;
    }),
      builder.addCase(fetchHoteGrouplList.pending, (state, _) => {
        state.isLoading = true;
      }),
      builder.addCase(fetchHoteGrouplList.rejected, (state, action) => {
        if (action.payload != undefined) {
          let error: AxiosError<Error> = action.payload;

          const serverError: ResponseModel = {
            resultCode: error?.response?.status.toString() ?? "",
            resultDescription: error?.response?.data.message ?? "",
          };

          state.error = serverError;
          state.isLoading = false;
        }
      });
  },
});

export const { selectHotelGroup, resetHotelGroupState } =
  hotelGroupSlice.actions;

export default hotelGroupSlice.reducer;
