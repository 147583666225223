import React from "react";

// @material-ui/core components
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
  Grid,
} from "@material-ui/core";
import { GridItem } from "components/GridItem";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

interface IProps {
  permissionsModule: PermissionModule;
  disable?: boolean | undefined;
  handleOnModuleChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
  handleOnSubItemChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
}

export interface PermissionModule {
  id: number;
  name: string;
  allow: boolean;
  indeterminate: boolean;
  permissionList: Permission[];
}

export interface Permission {
  id: number;
  name: string;
  allow: boolean;
}

export const AccordionPermission: React.FC<IProps> = ({
  permissionsModule,
  disable,
  handleOnModuleChange,
  handleOnSubItemChange,
}) => {
  return (
    <>
      <Accordion
        defaultExpanded={false}
        TransitionProps={{ unmountOnExit: true }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-label="expand"
          aria-controls="additional-actions1-content"
          id="additional-actions1-header"
        >
          <FormControlLabel
            style={{ height: "12px" }}
            onClick={(event) => event.stopPropagation()}
            onFocus={(event) => event.stopPropagation()}
            control={
              <Checkbox
                value={permissionsModule.id}
                checked={permissionsModule.allow}
                onChange={handleOnModuleChange}
                disabled={disable}
                color={"primary"}
                indeterminate={permissionsModule.indeterminate}
              />
            }
            label={`${permissionsModule.name}`}
          />
        </AccordionSummary>
        <AccordionDetails>
          <Grid container direction="row" alignContent="space-between">
            {permissionsModule.permissionList.length > 0
              ? permissionsModule.permissionList.map((permission, index) => (
                  <GridItem key={index}>
                    <FormControlLabel
                      style={{ height: "8px" }}
                      onFocus={(event) => event.stopPropagation()}
                      control={
                        <Checkbox
                          value={permission.id}
                          checked={permission.allow}
                          onChange={handleOnSubItemChange}
                          disabled={disable}
                          color={"primary"}
                        />
                      }
                      label={permission.name}
                    />
                  </GridItem>
                ))
              : null}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
};
