//Check in Screen Flow
export const ScreenSearchReservation = "searchReservation";
export const ScreenReservationList = "reservationList";
export const ScreenReservationDetail = "reservationDetail";
export const ScreenMarketingConsent = "marketingConsent";
export const ScreenReservationRegistration = "reservationRegistration";
export const ScreenReservationPreAuthPayment = "reservationPreAuthPayment";
export const ScreenReservationPaymentProcessing = "reservationPaymentProcessing";
export const ScreenReservationRoomAssignment = "reservationRoomAssignment";

//Check out Screen Flow
export const ScreenSearchCheckOutReservation = "searchCheckOutReservation";
export const ScreenReservationInvoice = "reservationInvoice";
export const ScreenReservationPayment = "reservationPayment";
export const ScreenReservationCheckOutCompleted = "reservationCheckOutCompleted";