import React, { createRef } from "react";
// @material-ui/core components
import Button, { ButtonProps } from "@material-ui/core/Button";
import { CSVLink } from "react-csv";
import { Data, Headers } from "react-csv/components/CommonPropTypes";

interface IProps extends ButtonProps {
  data: string | Data;
  headers: Headers | undefined;
  filename?: string;
  readyToExport: boolean;
  onClick: () => void;
  onFinishDownload: () => void;
}

export const ExportButton: React.FC<IProps> = ({
  data,
  headers,
  filename = `${new Date() + ".csv"}`,
  readyToExport,
  children,
  onFinishDownload,
  onClick,
  ...rest
}) => {
  const csvLinkRef = createRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >();

  React.useEffect(() => {
    if (readyToExport) {
      csvLinkRef?.current?.link.click();
      onFinishDownload();
    }
  }, [readyToExport]);

  const buttonClick = () => {
    console.log("buttonClick");
    onClick();
  };

  return (
    <>
      <Button color="primary" onClick={buttonClick} {...rest}>
        {children}
      </Button>
      {data != null ? (
        <CSVLink
          data={data}
          headers={headers}
          filename={filename}
          className="hidden"
          ref={csvLinkRef}
          target="_blank"
        />
      ) : null}
    </>
  );
};
