import {
  CreateGuestRequestModel,
  CreateGuestEmailRequestModel,
  UpdateGuestRequestModel,
  UpdateGuestEmailRequestModel
} from 'models';
import { getApiKeyForHotel, getLoginUser, privateHttpClient } from 'utils/httpClient';

export const createGuestAPI = async (request: CreateGuestRequestModel) => {
  const { data } = await privateHttpClient.post(
    '/api/guest',
    { ...request },
    {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${getLoginUser().token}`,
        ApiKey: getApiKeyForHotel()
      }
    }
  );

  return data;
};

export const updateGuestAPI = async (request: UpdateGuestRequestModel) => {
  const { data } = await privateHttpClient.put(
    '/api/guest',
    { ...request },
    {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${getLoginUser().token}`,
        ApiKey: getApiKeyForHotel()
      }
    }
  );

  return data;
};

export const createGuestEmailAPI = async (request: CreateGuestEmailRequestModel) => {
  const { data } = await privateHttpClient.post(
    '/api/guest/email',
    { ...request },
    {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${getLoginUser().token}`,
        ApiKey: getApiKeyForHotel()
      }
    }
  );

  return data;
};

export const updateGuestEmailAPI = async (request: UpdateGuestEmailRequestModel) => {
  const { data } = await privateHttpClient.put(
    '/api/guest/email',
    { ...request },
    {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${getLoginUser().token}`,
        ApiKey: getApiKeyForHotel()
      }
    }
  );

  return data;
};
