import { CreateVerbiageRequestModel, CreateVerbiageResponseModel, DeleteVerbiageRequestModel, DeleteVerbiageResponseModel, GetVerbiageRequestModel, GetVerbiageResponseModel, GetVerbiagesRequestModel, GetVerbiagesResponseModel, UpdateVerbiageRequestModel, UpdateVerbiageResponseModel } from 'models';
import { getServerFilter } from 'utils/filterHelper';
import { getApiKeyForHotel, getLoginUser, privateHttpClient } from '../utils/httpClient';

export const createVerbiageAPI = async (
  request: CreateVerbiageRequestModel
): Promise<CreateVerbiageResponseModel> => {
  const { data } = await privateHttpClient.post(
    "/api/verbiage",
    {
      ...request,
    },
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${getLoginUser().token}`,
        ApiKey: getApiKeyForHotel(),
      },
    }
  );

  return data;
};

export const getVerbiageAPI = async (
  request: GetVerbiageRequestModel
): Promise<GetVerbiageResponseModel> => {
  const { data } = await privateHttpClient.get(`/api/verbiage/${request.id}`, {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${getLoginUser().token}`,
      ApiKey: getApiKeyForHotel(),
    },
  });

  return data;
};

export const getVerbiageListAPI = async (
  request: GetVerbiagesRequestModel
): Promise<GetVerbiagesResponseModel> => {
  const { data } = await privateHttpClient.get("/api/verbiage", {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${getLoginUser().token}`,
      ApiKey: getApiKeyForHotel(),
    },
    params: {
      ...request,
      filter: getServerFilter(request?.filter),
    },
  });

  return data;
};

export const updateVerbiageAPI = async (
  request: UpdateVerbiageRequestModel
): Promise<UpdateVerbiageResponseModel> => {
  const { data } = await privateHttpClient.put(
    "/api/verbiage",
    {
      ...request,
    },
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${getLoginUser().token}`,
        ApiKey: getApiKeyForHotel(),
      },
    }
  );

  return data;
};

export const deleteVerbiageAPI = async (
  request: DeleteVerbiageRequestModel
): Promise<DeleteVerbiageResponseModel> => {
  const { data } = await privateHttpClient.delete("/api/verbiage", {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${getLoginUser().token}`,
      ApiKey: getApiKeyForHotel(),
    },
    data: {
      ...request,
    },
  });

  return data;
};
