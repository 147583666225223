import React from "react";

// @material-ui/core components
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import AppBar, { AppBarProps } from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { Button, Typography } from "@material-ui/core";
import { GridContainer } from "components/GridContainer";
import { GridItem } from "components/GridItem";
import { useAuth } from "utils/authUser";
import { useNavigate } from "react-router";
import jwtDecode from "jwt-decode";
import { RootState } from "reducers";
import { useSelector } from "react-redux";
// import { useSelector } from "react-redux";
// import { RootState } from "reducers";

//https://material-ui.com/components/app-bar/#fixed-placement

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      marginLeft: "16px",
      paddingLeft: "16px",
      marginRight: "16px",
      paddingRight: "16px",
    },
    empty: {
      flexGrow: 1,
    },
    userContainer: {
      marginRight: theme.spacing(2),
    },
  })
);

interface IProps extends AppBarProps {}

export const NavBar: React.FC<IProps> = () => {
  const classes = useStyles();
  const auth = useAuth();
  const navigate = useNavigate();

  // const [role, setRole] = React.useState("");
  const { selectedHotel } = useSelector((state: RootState) => state.hotelState);

  // const [user, setUser] = React.useState<LoginUser | undefined>(undefined);

  const handleOnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    // if (selectedHotel != undefined)
    // navigate(`/${selectedHotel.code}${event.currentTarget.value}`);

    navigate(event.currentTarget.value);
  };

  React.useEffect(() => {
    if (auth.user !== undefined && auth.user.token != undefined) {
      const jwtToken = jwtDecode(auth.user.token);
      const jwtTokenObject = JSON.parse(JSON.stringify(jwtToken));
      const role = jwtTokenObject.role;

      // setRole(role);
      console.log(role)
    }
  }, [auth.user]);

  return (
    <div className={classes.root}>
      <AppBar position="fixed">
        <Toolbar variant="dense">
          <Typography variant="h5" className={classes.title}>
            Dashboard
          </Typography>
          <Button
            color="inherit"
            size="small"
            className={classes.title}
            value={`/admin/transaction`}
            onClick={handleOnClick}
          >
            Transaction
          </Button>
          <Button
            color="inherit"
            size="small"
            className={classes.title}
            value={`/admin/configuration`}
            onClick={handleOnClick}
          >
            Configuration
          </Button>
          <Typography variant="h6" className={classes.empty}></Typography>
          <GridContainer
            direction="column"
            alignContent="flex-end"
            alignItems="flex-end"
            className={classes.userContainer}
          >
            <GridItem>
              <Typography variant="body2">{selectedHotel?.name}</Typography>
            </GridItem>
            <GridItem>
              <Typography variant="body2">{auth.user != undefined ? auth.user.name : ""}</Typography>
            </GridItem>
          </GridContainer>
          <Button color="inherit" onClick={auth.signout}>
            Logout
          </Button>
        </Toolbar>
      </AppBar>
    </div>
  );
};
