import React, { ChangeEvent } from "react";

import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  //   IconButton,
  TextField,
} from "@material-ui/core";
import { GridItem } from "components/GridItem";
import { checkDataTypeUsingName, spiltTextAndAcronyms } from "utils/stringHelper";
import { ConfigX, ConfigXSub } from "..";
import { GridContainer } from "components/GridContainer";
// import CancelIcon from "@material-ui/icons/Cancel";
import { InformationLogging, Log } from "utils/loggingHelper";
import { useSelector } from "react-redux";
import { RootState } from "reducers";
import {
  PM_EDIT_ALL_CONFIGURATION_CODE,
  PM_EDIT_CONFIGURATION_CODE_PREFIX,
} from "models/backend/constant/PermissionCode";

interface IConfigurationTab {
  config: ConfigXSub;
  onChange: (newValue: ConfigXSub) => void;
  //   onRemove: (value: ConfigXSub) => void;
}

export const ConfigurationTab: React.FC<IConfigurationTab> = ({
  config,
  onChange,
  //   onRemove,
}) => {
  const { role } = useSelector((state: RootState) => state.roleState);
  const { functionList } = useSelector(
    (state: RootState) => state.functionState
  );

  const [editingConfig, setEditingConfig] = React.useState<ConfigXSub>(config);
  const [arrayOfObject, setArrayOfObject] = React.useState<ConfigXSub[]>([]);

  React.useEffect(() => {
    //Experiment
    if (editingConfig.dataType === "arrayObject") {
      var rows: ConfigXSub[] = config.value.items;
      setArrayOfObject(rows);
    }
  }, [editingConfig]);

  const checkIsFunctionDisable = (): boolean => {
    var permission = true;

    var _funcPermissionCode = functionList.find(
      (func) =>
        func.code ===
        PM_EDIT_CONFIGURATION_CODE_PREFIX +
          editingConfig.dataName?.toLocaleLowerCase()
    );

    if (_funcPermissionCode != undefined) {
      permission = !role?.functions.some(
        (func) =>
          func.code ===
          PM_EDIT_CONFIGURATION_CODE_PREFIX +
            editingConfig.dataName?.toLocaleLowerCase()
      );
    } else {
      permission = !role?.functions.some(
        (func) => func.code === PM_EDIT_ALL_CONFIGURATION_CODE
      );
    }

    return permission;
  };

  const onTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    var updateConfig = { ...editingConfig, value: event.currentTarget.value };
    setEditingConfig(updateConfig);

    //when is arrayString check is valid only return.
    if (editingConfig.dataType === "arrayString") {
      try {
        JSON.parse(updateConfig.value);
        onChange({ ...updateConfig });
      } catch (_) {}
    } else {
      onChange(updateConfig);
    }
  };

  const onNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
    var updateConfig = {
      ...editingConfig,
      value: Number.parseInt(event.currentTarget.value),
    };
    setEditingConfig(updateConfig);
    onChange(updateConfig);
  };

  const onBooleanChange = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    var updateConfig = { ...editingConfig, value: checked };
    setEditingConfig(updateConfig);
    onChange(updateConfig);
  };

  const onArrayObjectChange = (newValue: ConfigXSub) => {
    Log(InformationLogging, "onArrayObjectChange");

    var _updatingArrayObjectItem = arrayOfObject.find(
      (i) => i.dataName === newValue.dataName
    );

    _updatingArrayObjectItem!!.value = newValue.value;

    var _objectProperties: ConfigX = {
      ...editingConfig.value,
      items: arrayOfObject.slice(),
    };

    var _arrayObject: ConfigXSub = {
      ...editingConfig,
      value: _objectProperties,
    };

    onChange(_arrayObject);
  };

  return (
    <>
      {editingConfig.dataType === "string" ||
      editingConfig.dataType === "arrayString" ? (
        <GridItem xs={editingConfig.value.length > 30 ? 12 : 6}>
          {editingConfig.dataName?.toLocaleLowerCase().includes("color") ||
          editingConfig.dataName?.toLocaleLowerCase().includes("datetime") ||
          editingConfig.dataName?.toLocaleLowerCase().includes("date") ||
          editingConfig.dataName?.toLocaleLowerCase().includes("time") ||
          editingConfig.dataName?.toLocaleLowerCase().includes("timezone") ? (
            <TextField
              margin="dense"
              id="name"
              disabled={checkIsFunctionDisable()}
              label={spiltTextAndAcronyms(editingConfig.dataName)}
              type={checkDataTypeUsingName(editingConfig.dataName)}
              value={editingConfig.value}
              fullWidth
              onChange={onTextChange}
            />
          ) : (
            <TextField
              margin="dense"
              id="name"
              disabled={checkIsFunctionDisable()}
              label={spiltTextAndAcronyms(editingConfig.dataName)}
              type="text"
              multiline
              value={editingConfig.value}
              fullWidth
              onChange={onTextChange}
            />
          )}
        </GridItem>
      ) : editingConfig.dataType === "number" ? (
        <GridItem xs={6}>
          <TextField
            margin="dense"
            id="name"
            disabled={checkIsFunctionDisable()}
            label={spiltTextAndAcronyms(editingConfig.dataName)}
            type="number"
            value={editingConfig.value}
            fullWidth
            onChange={onNumberChange}
          />
        </GridItem>
      ) : editingConfig.dataType === "boolean" ? (
        <GridItem xs={6}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  disabled={checkIsFunctionDisable()}
                  checked={editingConfig.value}
                  onChange={onBooleanChange}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label={spiltTextAndAcronyms(editingConfig.dataName)}
            />
          </FormGroup>
        </GridItem>
      ) : editingConfig.dataType === "arrayObject" ? (
        <>
          <GridContainer
            spacing={2}
            alignItems="center"
            style={{
              margin: "8px",
              padding: "8px",
              borderColor: "GrayText",
              borderStyle: "solid",
            }}
          >
            {/* <GridContainer
              style={{
                height: "1px",
                zIndex: 1,
                position: "relative",
                top: "-18px",
                right: "-18px",
              }}
              justifyContent="flex-end"
            >
              <GridItem>
                <IconButton onClick={() => onRemove(editingConfig)}>
                  <CancelIcon />
                </IconButton>
              </GridItem>
            </GridContainer> */}

            {arrayOfObject.map((item, index) => (
              <ConfigurationTab
                key={index}
                config={item}
                onChange={onArrayObjectChange}
                //onRemove={() => console.log("won't happen")}
              />
            ))}
          </GridContainer>
        </>
      ) : null}
    </>
  );
};
