import {
    CreateKioskLocationRequestModel,
    CreateKioskLocationResponseModel,
    DeleteKioskLocationRequestModel,
    DeleteKioskLocationResponseModel,
    GetKioskLocationRequestModel,
    GetKioskLocationResponseModel,
    GetKioskLocationsRequestModel,
    GetKioskLocationsResponseModel,
    UpdateKioskLocationRequestModel,
    UpdateKioskLocationResponseModel,

  } from "models";
  
  import { getServerFilter } from "utils/filterHelper";
  import {
    getApiKeyForHotel,
    getLoginUser,
    privateHttpClient,
  } from "utils/httpClient";
  
  export const createKioskLocationAPI = async (
    request: CreateKioskLocationRequestModel
  ): Promise<CreateKioskLocationResponseModel> => {
    const { data } = await privateHttpClient.post(
      "/api/location/kiosk",
      {
        ...request,
      },
      {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${getLoginUser().token}`,
          ApiKey: getApiKeyForHotel(),
        },
      }
    );
  
    return data;
  };
  
  export const getKioskLocationAPI = async (
    request: GetKioskLocationRequestModel
  ): Promise<GetKioskLocationResponseModel> => {
    const { data } = await privateHttpClient.get(
      `/api/location/kiosk/${request.id}`,
      {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${getLoginUser().token}`,
          ApiKey: getApiKeyForHotel(),
        },
      }
    );
  
    return data;
  };
  
  export const getKioskLocationListAPI = async (
    request: GetKioskLocationsRequestModel
  ): Promise<GetKioskLocationsResponseModel> => {
    const { data } = await privateHttpClient.get("/api/location/kiosk", {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${getLoginUser().token}`,
        ApiKey: getApiKeyForHotel(),
      },
      params: {
        ...request,
        filter: getServerFilter(request?.filter),
      },
    });
  
    return data;
  };
  
  export const updateKioskLocationAPI = async (
    request: UpdateKioskLocationRequestModel
  ): Promise<UpdateKioskLocationResponseModel> => {
    const { data } = await privateHttpClient.put(
      "/api/location/kiosk",
      {
        ...request,
      },
      {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${getLoginUser().token}`,
          ApiKey: getApiKeyForHotel(),
        },
      }
    );
  
    return data;
  };
  
  export const deleteKioskLocationAPI = async (
    request: DeleteKioskLocationRequestModel
  ): Promise<DeleteKioskLocationResponseModel> => {
    const { data } = await privateHttpClient.delete("/api/location/kiosk", {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${getLoginUser().token}`,
        ApiKey: getApiKeyForHotel(),
      },
      data: {
        ...request,
      },
    });
  
    return data;
  };
  