import { getApiKeyForHotel, getLoginUser, privateHttpClient } from '../utils/httpClient';

export const getCountryList = async (index: number) => {
  const { data } = await privateHttpClient.get('/api/country', {
    headers: {
      'Content-type': 'application/json',
      'Authorization': `Bearer ${getLoginUser().token}`,
      'ApiKey' : getApiKeyForHotel()
    },
    params: {
      PageIndex: index,
      PageSize: 100
    }
  });

  return data;
};
