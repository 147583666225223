import {
  CreateHotelGroupRequestModel,
  CreateHotelGroupResponseModel,
  DeleteHotelGroupRequestModel,
  DeleteHotelGroupResponseModel,
  GetHotelGroupRequestModel,
  GetHotelGroupResponseModel,
  GetHotelGroupsRequestModel,
  GetHotelGroupsResponseModel,
  UpdateHotelGroupRequestModel,
  UpdateHotelGroupResponseModel,
} from "models";
import { getServerFilter } from "utils/filterHelper";
import {
  getApiKeyForHotel,
  getLoginUser,
  privateHttpClient,
} from "../utils/httpClient";

export const createHotelGroupAPI = async (
  request: CreateHotelGroupRequestModel
): Promise<CreateHotelGroupResponseModel> => {
  const { data } = await privateHttpClient.post(
    "/api/hotelGroup",
    {
      ...request,
    },
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${getLoginUser().token}`,
        ApiKey: getApiKeyForHotel(),
      },
    }
  );

  return data;
};

export const getHotelGroupListAPI = async (
  request: GetHotelGroupsRequestModel
): Promise<GetHotelGroupsResponseModel> => {
  const { data } = await privateHttpClient.get("/api/hotelGroup", {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${getLoginUser().token}`,
      ApiKey: getApiKeyForHotel(),
    },
    params: {
      ...request,
      filter: getServerFilter(request?.filter),
    },
  });

  return data;
};

export const getHotelGroupAPI = async (
  request: GetHotelGroupRequestModel
): Promise<GetHotelGroupResponseModel> => {
  const { data } = await privateHttpClient.get(
    `/api/hotelGroup/${request.id}`,
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${getLoginUser().token}`,
        ApiKey: getApiKeyForHotel(),
      },
    }
  );

  return data;
};

export const updateHotelGroupAPI = async (
  request: UpdateHotelGroupRequestModel
): Promise<UpdateHotelGroupResponseModel> => {
  const { data } = await privateHttpClient.put(
    "/api/hotelGroup",
    {
      ...request,
    },
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${getLoginUser().token}`,
        ApiKey: getApiKeyForHotel(),
      },
    }
  );

  return data;
};

export const deleteHotelGroupAPI = async (
  request: DeleteHotelGroupRequestModel
): Promise<DeleteHotelGroupResponseModel> => {
  const { data } = await privateHttpClient.delete("/api/hotelGroup", {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${getLoginUser().token}`,
      ApiKey: getApiKeyForHotel(),
    },
    data: {
      ...request,
    },
  });

  return data;
};
