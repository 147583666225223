import React from 'react';

import imageCompression from 'browser-image-compression';

// @material-ui/core components
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import { GridContainer, GridItem, AlertDialog as PopUp, CustomButton } from '../../components';
import { makeStyles } from '@material-ui/core/styles';

import CloseIcon from '@material-ui/icons/Close';
import CancelIcon from '@material-ui/icons/Cancel';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
// import myInfoIcon from '../../images/myinfo.png';
import imageProcessIcon from '../../images/image-process.gif';
import facialIcon from '../../images/capture-selfie.gif';
import documentIcon from '../../images/id-scan.gif';

//Helper
import { convertFileToBase64, checkSupportedUploadImage } from '../../utils/fileHelper';
import { ScanDocument, ScanningUser, Image, DocumentTypeList } from '../../models';

//Service
import { livenessDetection, faceMatch } from '../../services/facialDetectService';
import { postDocumentForOCR } from '../../services/docscanService';
import { RootState } from 'reducers';
import { useSelector } from 'react-redux';
import { getVerbiage } from 'utils/stringHelper';
import Button from '@material-ui/core/Button';
import { combineFrontAndBackDocument } from 'utils/DocumentHelper';

//Mock
// import { mockScanDocument1, mockFacialResult } from '../../mock/document';

const useStyles = makeStyles((theme) => ({
  subtitle: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  appBar: {
    position: 'fixed',
    backgroundColor: theme.palette.primary.main
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  }
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const imageCompressionOptions = {
  maxSizeMB: 1,
  maxWidthOrHeight: 1920,
  useWebWorker: true
};

interface IProps extends DialogProps {
  onCompleted: (isCompleted: boolean, guest: ScanningUser | null) => void;
}

export const CaptureDocumentDialog: React.FC<IProps> = ({ onCompleted, open, children, ...rest }) => {
  const classes = useStyles();

  const { appConfig } = useSelector((state: RootState) => state.appConfigState);
  const { verbiages } = useSelector((state: RootState) => state.verbiages);

  const documentInputFileRef = React.useRef<HTMLInputElement>(null);
  const facialInputFileRef = React.useRef<HTMLInputElement>(null);

  const [openAlertDialog, setOpenAlertDialog] = React.useState(false);
  const [alertTitle, setAlertTitle] = React.useState('');
  const [alertContent, setAlertContent] = React.useState('');

  const [isProcessingImage, setIsProcessingImage] = React.useState(false);
  const [startTakingDocumentImage, setStartTakingDocumentImage] = React.useState(false);
  const [isTakingBackOfDocumentImage, setIsTakingBackOfDocumentImage] = React.useState(false);
  const [isTakingFacialImage, setIsTakingFacialImage] = React.useState(false);
  const [isResultFail, setIsResultFail] = React.useState(false);

  const [documentFrontResult, setDocumentFrontResult] = React.useState<ScanDocument | undefined>(undefined);
  const [documentResult, setDocumentResult] = React.useState<ScanDocument | undefined>(undefined);
  // const [facialResult, setFacialResult] = React.useState<FacialResult | null>(null);

  React.useEffect(() => {
    setStartTakingDocumentImage(false);
    setIsTakingBackOfDocumentImage(false);
    setIsTakingFacialImage(false);
    setIsProcessingImage(false);
    setIsResultFail(false);
  }, [open]);

  const handleClose = () => {
    onCompleted(false, null);
  };

  const handleBack = () => {
    if (startTakingDocumentImage) {
      setStartTakingDocumentImage(false);
      setIsTakingBackOfDocumentImage(false);
    } else if (isTakingFacialImage) {
      setIsTakingFacialImage(false);
    } else if (isResultFail) {
      setIsResultFail(false);
    } else {
      onCompleted(false, null);
    }
  };

  const handleCloseAlertDialog = () => {
    setOpenAlertDialog(false);
  };

  const openPrivacyPolicyPopUp = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAlertTitle(getVerbiage(verbiages, 'weblbl031'));
    setAlertContent(getVerbiage(verbiages, 'weblbl032'));
    setOpenAlertDialog(true);
  };

  const selectTakeDocumentAction = (event: React.MouseEvent<HTMLButtonElement>) => {
    setStartTakingDocumentImage(true);
    // documentInputFileRef.current?.click();
  };

  const selectTakeDocument = (event: React.MouseEvent<HTMLButtonElement>) => {
    documentInputFileRef.current?.click();
  };

  const selectTakeSelfie = (event: React.MouseEvent<HTMLButtonElement>) => {
    facialInputFileRef.current?.click();
  };

  const onInputChangeForDocument = async (event: React.FormEvent<HTMLInputElement>) => {
    setIsProcessingImage(true);
    const files: Array<File> = Array.prototype.slice.call(event.currentTarget.files);

    if (files != null) {
      const errs = checkSupportedUploadImage(files);

      if (errs.length) {
        setIsProcessingImage(false);
        const err = errs.pop();
        if (err != undefined) {
          setAlertTitle(err?.name);
          setAlertContent(err?.message);
        }
      } else {
        if (files && files.length) {
          try {
            const imageFile = files[0];
            //File compression
            const compressedFile = await imageCompression(imageFile, imageCompressionOptions);

            if (compressedFile.size / 1024 / 1024 > 1) {
              alert(`'${imageFile.name}' is too large, please pick a smaller file`);
            } else {
              //Convert file to base64
              const cameraImageBase64 = await convertFileToBase64(compressedFile);

              if (cameraImageBase64 != null) {
                {
                  const documentResult = await postDocumentForOCR(cameraImageBase64);

                  if (documentResult != null) {
                    if (
                      documentResult.idType !== DocumentTypeList[3].value.toUpperCase() &&
                      !isTakingBackOfDocumentImage
                    ) {
                      //check is the document a passport or ID
                      setDocumentFrontResult(documentResult);
                      setIsTakingBackOfDocumentImage(true);
                      setStartTakingDocumentImage(true);
                      setIsProcessingImage(false);
                    } else if (appConfig?.reservationConfig.isFacialRecognitionRequiredForVerification) {
                      //if FR is needed

                      setDocumentResult(documentResult);
                      setStartTakingDocumentImage(false);
                      setIsProcessingImage(false);
                      setIsTakingFacialImage(true);
                    } else {
                      //Finish document scanning
                      var images = [];
                      if (documentResult.image != undefined) {
                        images.push(documentResult.image);
                      }

                      var _document: ScanDocument = { ...documentResult, images: images };

                      if (documentFrontResult != undefined) {
                        _document = combineFrontAndBackDocument(documentFrontResult, documentResult);
                      }

                      const user: ScanningUser = {
                        document: _document,
                        UserFacialImage: undefined
                      };

                      onCompleted(true, user);
                    }
                  } else {
                    setIsProcessingImage(false);
                    setStartTakingDocumentImage(false);
                    setIsResultFail(true);
                  }
                }
              }
            }
          } catch (error) {
            //handle error
            console.log(error);
          }
        }
      }
    }
  };

  const onInputChangeForSelfie = async (event: React.FormEvent<HTMLInputElement>) => {
    setIsProcessingImage(true);
    const files: Array<File> = Array.prototype.slice.call(event.currentTarget.files);

    if (files != null) {
      const errs = checkSupportedUploadImage(files);

      if (errs.length) {
        setIsProcessingImage(false);
        const err = errs.pop();
        if (err != undefined) {
          setAlertTitle(err?.name);
          setAlertContent(err?.message);
        }
      } else {
        if (event.currentTarget.files && event.currentTarget.files.length) {
          try {
            const imageFile = event.currentTarget.files[0];

            const compressedFile = await imageCompression(imageFile, imageCompressionOptions);

            if (compressedFile.size / 1024 / 1024 > 1) {
              alert(`'${imageFile.name}' is too large, please pick a smaller file`);
            } else {
              const cameraImageBase64 = await convertFileToBase64(compressedFile);
              //do something with above data string

              if (cameraImageBase64 != null) {
                const livenessResult = await livenessDetection(cameraImageBase64);

                if (livenessResult.Error != null) {
                  setAlertTitle('Oposss');
                  setAlertContent(livenessResult.Error);
                } else {
                  if (livenessResult.LivenessResult != null) {
                    if (documentResult != null && documentResult.faceImage != null) {
                      const { IsMatch } = await faceMatch(cameraImageBase64, documentResult.faceImage);

                      if (livenessResult.LivenessResult.LivenessAssessment === 'NotLive') {
                        setAlertTitle('Please Re-take a Picture');
                        setAlertContent('Selfie is not a live picture.');
                      } else {
                        // setFacialResult({
                        //   isMatch: IsMatch,
                        //   matchScore: Score,
                        //   livenessResult: livenessResult.LivenessResult,
                        //   selfieImage: cameraImageBase64
                        // });

                        if (IsMatch) {
                          const faceImage: Image = {
                            Base64Image: cameraImageBase64
                          };

                          const user: ScanningUser = {
                            document: documentResult,
                            UserFacialImage: faceImage
                          };

                          onCompleted(true, user);
                        } else {
                          setAlertTitle('Please Re-take a Picture');
                          setAlertContent('Selfie is not a live picture.');
                        }
                      }
                    }
                  }
                }

                setIsTakingFacialImage(false);
              }
            }
          } catch (error) {
            //handle error
            console.log(error);
          }
        }
      }
    }
  };

  return (
    <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition} {...rest}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          {!isProcessingImage ? (
            <IconButton hidden={isProcessingImage} edge="start" color="inherit" onClick={handleBack} aria-label="close">
              <ArrowBackIosIcon />
            </IconButton>
          ) : null}
          <Typography variant="h6" className={classes.title}>
            {/* processing document / update guest data */}
            {isProcessingImage ? getVerbiage(verbiages, 'weblbl027') : getVerbiage(verbiages, 'weblbl026')}
          </Typography>
          {!isProcessingImage ? (
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          ) : null}
        </Toolbar>
      </AppBar>

      <Toolbar />

      <GridContainer>
        <GridItem xs={12}>
          <PopUp
            title={alertTitle}
            content={alertContent}
            open={openAlertDialog}
            onClose={handleCloseAlertDialog}
            buttonStyle="alert"
            onPositiveButtonClick={handleCloseAlertDialog}
          />
        </GridItem>
      </GridContainer>

      {isProcessingImage ? (
        <GridContainer
          spacing={2}
          direction="row"
          justifyContent="center"
          alignContent="center"
          style={{ minHeight: '80vh' }}
        >
          <GridItem xs={12}>
            <Typography align="center" variant="body1">
              <b>
                {/* Please do not exit the application while we are processing your document. */}
                {getVerbiage(verbiages, 'weblbl033')}
              </b>
            </Typography>
          </GridItem>
          <GridItem xs={12} style={{ textAlign: 'center' }}>
            <img src={imageProcessIcon} alt={imageProcessIcon} style={{ maxWidth: '75%' }} />
          </GridItem>
          <GridItem xs={12}>
            <Typography align="center" variant="h6">
              {/* PROCESSING... */}
              <b>{getVerbiage(verbiages, 'weblbl034')}</b>
            </Typography>
          </GridItem>
        </GridContainer>
      ) : isResultFail ? (
        <GridContainer direction="column" justifyContent="center" alignItems="center" style={{ minHeight: '80vh' }}>
          <GridItem>
            <CancelIcon color="error" />
          </GridItem>
          <GridItem>
            <Typography align="center" variant="subtitle1">
              {/* Invalid document */}
              <b>{getVerbiage(verbiages, 'weblbl035')}</b>
            </Typography>
          </GridItem>
          <GridItem>
            <Typography align="center" variant="body1">
              {/* Your document is not a valid document please scan your document again. */}
              <b>{getVerbiage(verbiages, 'weblbl036')}</b>
            </Typography>
          </GridItem>
          <GridItem style={{ marginTop: '48px' }}>
            {/* Take A Picture */}
            <CustomButton onClick={() => setIsResultFail(false)}>{getVerbiage(verbiages, 'webbtn010')}</CustomButton>
          </GridItem>
        </GridContainer>
      ) : startTakingDocumentImage ? (
        <GridContainer
          spacing={2}
          justifyContent="center"
          alignContent="center"
          direction="row"
          style={{ minHeight: '80vh' }}
        >
          <GridItem xs={12}>
            <Typography align="center" variant="body1">
              {isTakingBackOfDocumentImage ? (
                <b>{getVerbiage(verbiages, 'Please take a picture of your back document')}</b>
              ) : (
                <b>{getVerbiage(verbiages, 'weblbl037')}</b>
              )}
            </Typography>
          </GridItem>
          <input
            hidden
            type="file"
            ref={documentInputFileRef}
            onChange={onInputChangeForDocument}
            accept="image/*"
            capture="environment"
          ></input>
          <GridItem xs={12} style={{ textAlign: 'center' }}>
            <img src={documentIcon} alt={documentIcon} style={{ maxWidth: '100%', height: '100%' }} />
          </GridItem>
          <GridItem xs={12} style={{ textAlign: 'center' }}>
            <CustomButton variant="contained" color="primary" onClick={selectTakeDocument}>
              {getVerbiage(verbiages, 'webbtn010')}
            </CustomButton>
          </GridItem>
        </GridContainer>
      ) : isTakingFacialImage ? (
        <GridContainer
          spacing={2}
          justifyContent="center"
          alignContent="center"
          direction="row"
          style={{ minHeight: '80vh' }}
        >
          <GridItem xs={12} style={{ maxWidth: '80%' }}>
            <Typography align="center" variant="body1">
              {/* Please take a picture of yourself to verify document. */}
              <b>{getVerbiage(verbiages, 'weblbl038')} </b>
            </Typography>
          </GridItem>
          <input
            hidden
            type="file"
            ref={facialInputFileRef}
            onChange={onInputChangeForSelfie}
            accept="image/*"
            capture="user"
          ></input>
          <GridItem xs={12} style={{ textAlign: 'center' }}>
            <img src={facialIcon} alt={facialIcon} style={{ maxWidth: '80%' }} />
          </GridItem>
          <GridItem xs={12} style={{ textAlign: 'center' }}>
            <CustomButton variant="contained" color="primary" onClick={selectTakeSelfie}>
              {getVerbiage(verbiages, 'webbtn010')}
            </CustomButton>
          </GridItem>
        </GridContainer>
      ) : (
        //Initail View
        <GridContainer
          direction="column"
          spacing={3}
          alignContent="center"
          alignItems="flex-start"
          style={{ padding: '18px' }}
        >
          {/* <input
            hidden
            type="file"
            ref={documentInputFileRef}
            onChange={onInputChange}
            accept="image/*"
            capture="environment"
          ></input> */}
          <GridItem>
            <Card elevation={3}>
              <CardActionArea onClick={selectTakeDocumentAction}>
                <CardContent>
                  <GridContainer spacing={1} alignItems="center" justifyContent="space-between">
                    <GridItem xs={9}>
                      <Typography variant="h6">{getVerbiage(verbiages, 'weblbl028')}</Typography>
                      <Typography variant="body2" color="textSecondary">
                        {getVerbiage(verbiages, 'weblbl029')}
                      </Typography>
                    </GridItem>
                    <GridItem style={{ justifyContent: 'center', alignItems: 'center' }}>
                      <CameraAltIcon fontSize="inherit" style={{ fontSize: '3.5rem' }} />
                    </GridItem>
                  </GridContainer>
                </CardContent>
              </CardActionArea>
            </Card>
          </GridItem>
          <GridItem>
            {/* Button for Privacy Policy */}
            <Typography variant="body2">
              {getVerbiage(verbiages, 'weblbl030')}
              <Button onClick={openPrivacyPolicyPopUp} size="small" variant="text" style={{ textTransform: 'none' }}>
                <u>{getVerbiage(verbiages, 'webbtn009')}</u>
              </Button>
            </Typography>
          </GridItem>
          {/* <GridItem>
            <Card elevation={3}>
              <CardActionArea onClick={selectMyInfoAction}>
                <CardContent>
                  <GridContainer spacing={1} alignItems="center" justifyContent="space-between">
                    <GridItem xs={9}>
                      <Typography variant="h6">My Info</Typography>
                      <Typography variant="body2" color="textSecondary">
                        {'Use camera to obtain document data through Singpass'}
                      </Typography>
                    </GridItem>
                    <GridItem style={{ justifyContent: 'center', alignItems: 'center' }}>
                      <img src={myInfoIcon} alt={myInfoIcon} style={{ height: '30px', width: '56px' }} />
                    </GridItem>
                  </GridContainer>
                </CardContent>
              </CardActionArea>
            </Card>
          </GridItem> */}
        </GridContainer>
      )}
    </Dialog>
  );
};
