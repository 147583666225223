import React from "react";

import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import SettingsIcon from "@material-ui/icons/Settings";
import DashboardIcon from "@material-ui/icons/Dashboard";
import TuneIcon from "@material-ui/icons/Tune";
import DeveloperModeIcon from "@material-ui/icons/DeveloperMode";
import ImportantDevicesIcon from '@material-ui/icons/ImportantDevices';
// import EqualizerIcon from '@material-ui/icons/Equalizer';

import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { useNavigate } from "react-router";
import { NavMenu } from "models/Menu";

interface IProps {
  title: string;
  icon?: string;
  subMenuItems: NavMenu[];
}

export const SideMenu: React.FC<IProps> = ({ title, icon, subMenuItems }) => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <ListItem button key={title} onClick={() => setOpen(!open)}>
        <ListItemIcon>
          {icon == undefined ? null : icon == "transactions" ? (
            <AccountBalanceIcon />
          ) : icon == "settings" ? (
            <SettingsIcon />
          ) : icon == "dashboard" ? (
            <DashboardIcon />
          ) : icon == "system" ? (
            <TuneIcon />
          ) : icon == "developers" ? (
            <DeveloperModeIcon />
          ) : icon == "devices" ? (
            <ImportantDevicesIcon />
          ) : null}
        </ListItemIcon>
        <ListItemText primary={title} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {subMenuItems.map((s, index) => (
          <List key={index} component="div" disablePadding>
            <ListItem key={index} button onClick={() => navigate(s.uri)}>
              <ListItemIcon></ListItemIcon>
              <ListItemText primary={s.title} />
            </ListItem>
          </List>
        ))}
      </Collapse>
    </>
  );
};
