import {
  AccountLoginRequestModel,
  AccountLoginResponseModel,
  ResetPasswordRequestModel,
  ResetPasswordResponseModel,
  SignUpUserRequestModel,
  SignUpUserResponseModel,
} from "models";
import {
  getApiKeyForHotel,
  getLoginUser,
  privateHttpClient,
  publicHttpClient,
} from "../utils/httpClient";

export const accountLoginAPI = async (
  request: AccountLoginRequestModel
): Promise<AccountLoginResponseModel> => {
  const { data } = await publicHttpClient.post("/api/account/login", {
    ...request,
  });
  return data;
};

export const signUpTenantUserAPI = async (
  request: SignUpUserRequestModel
): Promise<SignUpUserResponseModel> => {
  const { data } = await privateHttpClient.post(
    "/api/account/signUpTenantUser",
    {
      ...request,
    },
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${getLoginUser().token}`,
        ApiKey: getApiKeyForHotel(),
      },
    }
  );
  return data;
};

export const resetPasswordAPI = async (
  request: ResetPasswordRequestModel
): Promise<ResetPasswordResponseModel> => {
  const { data } = await privateHttpClient.post(
    "/api/account/resetPassword",
    {
      ...request,
    },
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${getLoginUser().token}`,
        ApiKey: getApiKeyForHotel(),
      },
    }
  );
  return data;
};
