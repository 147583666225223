import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store/state";
import { ResponseModel } from "../models/common/ResponseModel";
import { AxiosError } from "axios";
import {
  ARIA_SuccessCode,
  GetMenusResponseModel,
  Menu,
  NavModuleMenu,
} from "models";
import { getMenuListAPI } from "services/menuService";
import { MenuTypeList } from "models/backend/constant/MenuType";

const initialState: RootState.MenuState = {
  menuList: [],
  moduleList: [],
  navMenuList: [],
  error: undefined,
  isLoading: false,
};

//Actions
//More info here https://redux-toolkit.js.org/api/createAsyncThunk
export const fetchMenuList = createAsyncThunk<
  GetMenusResponseModel,
  void,
  { rejectValue: AxiosError<Error> }
>("menu/getMenuList", async (_, thunkApi) => {
  try {
    var pageIndex = 1;

    let data: GetMenusResponseModel = await getMenuListAPI({
      pageIndex,
      pageSize: 100,
    });

    let tempData = data.result.items;

    while (data.result.pageCount > pageIndex) {
      pageIndex++;
      let nextData: GetMenusResponseModel = await getMenuListAPI({
        pageIndex,
        pageSize: 100,
      });

      tempData.push(...nextData.result.items);
    }

    data.result.items = tempData;

    return data;
  } catch (err) {
    let error = err as AxiosError<Error>; // cast the error for access
    if (!error.response) {
      throw err;
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    return thunkApi.rejectWithValue(error);
  }
});

//Slice
//More info here https://redux-toolkit.js.org/api/createSlice
export const menuSlice = createSlice({
  name: "menu",
  initialState,
  reducers: {
    //local reducer
    updateNavMenuState(state, { payload }) {
      state.navMenuList = convertMenuToNavModuleMenu(payload);
    },
    resetMenuState() {
      return initialState;
    },
  },
  //services reducers
  extraReducers: (builder) => {
    builder.addCase(fetchMenuList.fulfilled, (state, { payload }) => {
      if (payload.resultCode === ARIA_SuccessCode) {
        state.menuList = payload.result.items;

        state.moduleList = payload.result.items.filter(
          (menu) =>
            menu.type.toLowerCase() === MenuTypeList[0].value.toLowerCase()
        );

        state.error = undefined;
      } else {
        state.error = { ...payload };
      }

      state.error = undefined;
      state.isLoading = false;
    }),
      builder.addCase(fetchMenuList.pending, (state, _) => {
        state.isLoading = true;
      }),
      builder.addCase(fetchMenuList.rejected, (state, action) => {
        if (action.payload != undefined) {
          let error: AxiosError<Error> = action.payload;

          const serverError: ResponseModel = {
            resultCode: error?.response?.status.toString() ?? "",
            resultDescription: error?.response?.data.message ?? "",
          };

          state.error = serverError;
          state.isLoading = false;
        }
      });
  },
});

const convertMenuToNavModuleMenu = (menusList: Menu[]): NavModuleMenu[] => {
  const moduleList = menusList.filter(
    (menu) => menu.type === MenuTypeList[0].value
  );

  const menuList = menusList.filter(
    (menu) => menu.type === MenuTypeList[1].value
  );
  var menuNavListing: NavModuleMenu[] = [];

  moduleList.forEach((module) => {
    var navModule: NavModuleMenu = {
      title: module.name,
      icon: module.name.toLowerCase(),
      subMenu: [],
    };

    const submenu = menuList.filter((menu) => menu.parentId === module.id);

    submenu.forEach((menu) => {
      navModule.subMenu.push({
        title: menu.name,
        uri: menu.path != null ? menu.path : "",
      });
    });

    menuNavListing.push(navModule);
  });

  return menuNavListing;
};

export const { resetMenuState, updateNavMenuState } = menuSlice.actions;

export default menuSlice.reducer;
