import React from "react";
import { useSelector } from "react-redux";

import { RootState } from "reducers";

//Component
import { getDepositTransactionAPI } from "services/paymentService";
import {
  DataGrid,
  GridColDef,
  GridColumns,
  GridToolbarContainer,
} from "@material-ui/data-grid";
import CustomLoadingOverlay from "components/DataGridLoadingOverlay";
import CustomNoRowsOverlay from "components/DataGridNoRowsOverlay";
import {
  DepositTransactionInfo,
  ARIA_SuccessCode,
  Reservation,
  Guest,
} from "models";
import { DataGridFilterToolbar } from "components/DataGridFilterToolbar";
import { GridColFilterDef } from "components/DataGridFilterSelection";
//Icon

import CheckIcon from "@material-ui/icons/Check";

//Helper
import { convertDateTimeToString } from "utils/dateHelper";
import { Data } from "react-csv/components/CommonPropTypes";
import { ExportButton } from "components/ExportButton";
import { mapToApplicationAdyenTransaction } from "utils/reservationHelper";
import { MainDrawer } from "components/MainDrawer";
import { convertNumberToAmount } from "utils/stringHelper";

export const PreAuthTransactionScreen = () => {
  const { selectedHotel } = useSelector((state: RootState) => state.hotelState);

  const [index, setIndex] = React.useState<number>(1);
  const [size, setSize] = React.useState<number>(10);
  const [row, setRow] = React.useState<number>(0);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isExporting, setIsExporting] = React.useState(false);

  const [requestDateTime, setRequestDateTime] = React.useState<Date>(
    new Date()
  );

  const [preAuthList, setPreAuthList] = React.useState<DepositTransactionInfo[]>(
    []
  );

  const [exportList, setExportList] = React.useState<Data>([]);

  const [filterList, setFilterList] = React.useState<GridColFilterDef[]>([]);

  //Setting up the each column header item
  const transactionDateHeader: GridColDef = {
    field: "transactionDate",
    headerName: "Transaction Date",
    sortable: false,
    type: "dateTime",
    renderHeader: (params: any) => <strong>{"Transaction Date"}</strong>,
    renderCell: (params: any) => (
      <label>{convertDateTimeToString(params.value)}</label>
    ),
    width: 180,
    // flex: 1,
  };

  const transactionIdHeader: GridColDef = {
    field: "transactionId",
    headerName: "Transaction Id",
    sortable: false,
    renderHeader: () => <strong>{"Transaction Id"}</strong>,
    minWidth: 200,
    flex: 1,
  };

  const transactionTypeHeader: GridColDef = {
    field: "resultCode",
    headerName: "Status",
    sortable: false,
    type: "paymentType",
    renderHeader: () => <strong>{"Status"}</strong>,
    renderCell: (params: any) => (
      <>
        {params.value == "Authorised" ? (
          <>
            <div style={{ flex: 1 }}>{params.value}</div>
            <CheckIcon color="primary" />
          </>
        ) : (
          <div>{params.value}</div>
        )}
      </>
    ),
    minWidth: 150,
    flex: 0.8,
  };

  const transactionConfirmationNoHeader: GridColDef = {
    field: "reservation",
    headerName: "Confirmation No",
    sortable: false,
    type: "object",
    description: "reservation/confirmationNo",
    renderHeader: () => <strong>{"Confirmation No"}</strong>,
    valueGetter: (params) => {
      var jsonString = JSON.stringify(params.value);
      var reservation: Reservation = JSON.parse(jsonString);
      return reservation.confirmationNo;
    },
    minWidth: 150,
    flex: 0.8,
  };

  const transactionGuestNameHeader: GridColDef = {
    field: "guest",
    headerName: "Guest Name",
    sortable: false,
    type: "object",
    description: "reservation/guest/name",
    renderHeader: () => <strong>{"Name"}</strong>,
    valueGetter: (params) => {
      var jsonString = JSON.stringify(params.value);
      var guest: Guest = JSON.parse(jsonString);

      return guest.name;
    },
    minWidth: 180,
    flex: 1,
  };

  const transactionCurrencyHeader: GridColDef = {
    field: "currencyCode",
    headerName: "Currency",
    sortable: false,
    renderHeader: () => <strong>{"Currency"}</strong>,
    minWidth: 80,
    flex: 0.5,
  };

  const transactionAmountHeader: GridColDef = {
    field: "amount",
    headerName: "Amount",
    sortable: false,
    type: "number",
    renderHeader: () => <strong>{"Amount"}</strong>,
    renderCell: (params: any) => (
      <label>{convertNumberToAmount(params.value)}</label>
    ),
    minWidth: 100,
    flex: 0.5,
  };

  const transactionCardTypeHeader: GridColDef = {
    field: "cardType",
    headerName: "Card Type",
    sortable: false,
    renderHeader: () => <strong>{"Card Type"}</strong>,
    minWidth: 100,
    flex: 0.5,
  };

  const headerColumn: GridColumns = [
    transactionIdHeader,
    transactionDateHeader,
    transactionTypeHeader,
    transactionConfirmationNoHeader,
    transactionGuestNameHeader,
    transactionCurrencyHeader,
    transactionAmountHeader,
    transactionCardTypeHeader,
  ];

  React.useEffect(() => {
    if (selectedHotel != undefined) {
      setIsLoading(true);
      setPreAuthList([]);
      setRequestDateTime(new Date());

      getDepositTransactionAPI({
        filter: filterList,
        pageIndex: index,
        pageSize: size,
        orderBy: "transactionDate desc",
      })
        .then((response) => {
          if (response.resultCode === ARIA_SuccessCode) {
            var transactions = mapToApplicationAdyenTransaction(
              response.result.items
            );

            setPreAuthList(transactions);
            setRow(response.result.rowCount);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [index, size, filterList, selectedHotel]);

  const getExportTransaction = async () => {
    setIsLoading(true);
    var _exportIndex = 1;
    var exportTransactionList: DepositTransactionInfo[] = [];

    try {
      do {
        var response = await getDepositTransactionAPI({
          filter: filterList,
          pageIndex: _exportIndex,
          pageSize: size,
          orderBy: "transactionDate desc",
        });

        exportTransactionList.push(...response.result.items);

        _exportIndex++;
      } while (response.result.pageCount >= _exportIndex);

      const transactions = mapToApplicationAdyenTransaction(
        exportTransactionList
      );

      setExportList(transactions);
      setIsExporting(true);
      setIsLoading(false);
    } catch (err) {
      console.log("Oposs.. Something is wrong");
    }
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <DataGridFilterToolbar
          headerColumns={headerColumn}
          filterList={filterList}
          onFilterApply={(items) => {
            setFilterList(items.slice());
          }}
        />
        <ExportButton
          filename="Transaction"
          data={exportList}
          headers={[
            { label: "Transaction Id", key: "pspReference" },
            { label: "Transaction Date", key: "transactionDateString" },
            { label: "Transaction Time", key: "transactionTimeString" },
            { label: "Status", key: "resultCode" },
            { label: "Confirmation No", key: "reservation.confirmationNo" },
            { label: "Guest Name", key: "guest.name" },
            { label: "Currency", key: "currencyCode" },
            { label: "Amount", key: "amount" },
            { label: "Card Type", key: "cardType" },
          ]}
          readyToExport={isExporting}
          onClick={getExportTransaction}
          onFinishDownload={() => {
            setExportList([]);
            setIsExporting(false);
          }}
        >
          Export
        </ExportButton>
        <div style={{ flexGrow: 1 }}></div>
        <div>
          <b>Data as at </b>: {convertDateTimeToString(requestDateTime)}
        </div>
      </GridToolbarContainer>
    );
  }

  return (
    <>
      <MainDrawer title="Deposit">
        {/* Data Grid */}
        <div style={{ height: "80vh" }}>
          <div style={{ display: "flex", height: "100%" }}>
            <div style={{ flexGrow: 1 }}>
              <DataGrid
                pageSize={size}
                rowsPerPageOptions={[10, 20, 30]}
                onPageSizeChange={(size) => setSize(size)}
                disableColumnMenu
                density="compact"
                components={{
                  LoadingOverlay: CustomLoadingOverlay,
                  NoRowsOverlay: CustomNoRowsOverlay,
                  Toolbar: CustomToolbar,
                }}
                pagination
                loading={isLoading}
                paginationMode="server"
                rowCount={row}
                filterMode="server"
                onPageChange={(newPage) => setIndex(++newPage)}
                rows={preAuthList}
                columns={headerColumn}
                getRowId={(row) => row.id}
              />
            </div>
          </div>
        </div>
      </MainDrawer>
    </>
  );
};
