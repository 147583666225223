//Component
export const PM_ADD_COMPONENT = "add_component";
export const PM_EDIT_COMPONENT= "edit_component";
export const PM_DELETE_COMPONENT = "delete_component";

//Component Type
export const PM_ADD_COMPONENT_TYPE = "add_component_type";
export const PM_EDIT_COMPONENT_TYPE= "edit_component_type";
export const PM_DELETE_COMPONENT_TYPE = "delete_component_type";

//Configuration
export const PM_EDIT_CONFIGURATION_CODE_PREFIX = "config_edit_";
export const PM_EDIT_ALL_CONFIGURATION_CODE = "config_edit_others";

//Configuration Group
export const PM_ADD_CONFIG_GROUP_CODE = "add_configuration_group";
export const PM_EDIT_CONFIG_GROUP_CODE = "edit_configuration_group";
export const PM_DELETE_CONFIG_GROUP_CODE = "delete_configuration_group";

//Function
export const PM_ADD_FUNCTION_CODE = "add_function";
export const PM_EDIT_FUNCTION_CODE = "edit_function";
export const PM_DELETE_FUNCTION_CODE = "delete_function";

//Menu
export const PM_ADD_MENU_CODE = "add_menu";
export const PM_EDIT_MENU_CODE = "edit_menu";
export const PM_DELETE_MENU_CODE = "delete_menu";

//Role
export const PM_ADD_ROLE_CODE = "add_role";
export const PM_EDIT_ROLE_CODE = "edit_role";
export const PM_DELETE_ROLE_CODE = "delete_role";

//User
export const PM_ADD_USER_CODE = "add_user";
export const PM_EDIT_USER_CODE = "edit_user";
export const PM_CHANGE_USER_STATUS_CODE = "change_user_status";
export const PM_RESET_USER_PASSWORD_CODE = "reset_user_password";

//Hotel
export const PM_ADD_HOTEL_CODE = "add_hotel";
export const PM_EDIT_HOTEL_CODE = "edit_hotel";
export const PM_DELETE_HOTEL_CODE = "delete_hotel";

//Feature
export const PM_ADD_FEATURE_CODE = "add_feature";
export const PM_EDIT_FEATURE_CODE = "edit_feature";
export const PM_DELETE_FEATURE_CODE = "delete_feature";

//Kiosk Location
export const PM_ADD_KIOSK = "add_kiosk";
export const PM_EDIT_KIOSK = "edit_kiosk";
export const PM_DELETE_KIOSK = "delete_kiosk";

//Kiosk Location
export const PM_ADD_KIOSK_LOCATION = "add_kiosk_location";
export const PM_EDIT_KIOSK_LOCATION = "edit_kiosk_location";
export const PM_DELETE_KIOSK_LOCATION = "delete_kiosk_location";

//Room Type
export const PM_ADD_ROOM_TYPE_CODE = "add_room_type";
export const PM_EDIT_ROOM_TYPE_CODE = "edit_room_type";
export const PM_DELETE_ROOM_TYPE_CODE = "delete_room_type";

//Verbiage
export const PM_ADD_VERBIAGE_CODE = "add_verbiage";
export const PM_EDIT_VERBIAGE_CODE = "edit_verbiage";
export const PM_DELETE_VERBIAGE_CODE = "delete_verbiage";
