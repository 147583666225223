import {
  CreateConfigurationGroupRequestModel,
  CreateConfigurationGroupResponseModel,
  DeleteConfigurationGroupRequestModel,
  DeleteConfigurationGroupResponseModel,
  GetConfigurationGroupRequestModel,
  GetConfigurationGroupResponseModel,
  GetConfigurationGroupsRequestModel,
  GetConfigurationGroupsResponseModel,
  UpdateConfigurationGroupRequestModel,
  UpdateConfigurationGroupResponseModel,
} from "models";

import { getServerFilter } from "utils/filterHelper";
import {
  getApiKeyForHotel,
  getLoginUser,
  privateHttpClient,
} from "utils/httpClient";

export const createConfigurationGroupAPI = async (
  request: CreateConfigurationGroupRequestModel
): Promise<CreateConfigurationGroupResponseModel> => {
  const { data } = await privateHttpClient.post(
    "/api/configurationGroup",
    {
      ...request,
    },
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${getLoginUser().token}`,
        ApiKey: getApiKeyForHotel(),
      },
    }
  );

  return data;
};

export const getConfigurationGroupAPI = async (
  request: GetConfigurationGroupRequestModel
): Promise<GetConfigurationGroupResponseModel> => {
  const { data } = await privateHttpClient.get(
    `/api/configurationGroup/${request.id}`,
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${getLoginUser().token}`,
        ApiKey: getApiKeyForHotel(),
      },
    }
  );

  return data;
};

export const getConfigurationGroupListAPI = async (
  request: GetConfigurationGroupsRequestModel
): Promise<GetConfigurationGroupsResponseModel> => {
  const { data } = await privateHttpClient.get("/api/configurationGroup", {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${getLoginUser().token}`,
      ApiKey: getApiKeyForHotel(),
    },
    params: {
      ...request,
      filter: getServerFilter(request?.filter),
    },
  });

  return data;
};

export const updateConfigurationGroupAPI = async (
  request: UpdateConfigurationGroupRequestModel
): Promise<UpdateConfigurationGroupResponseModel> => {
  const { data } = await privateHttpClient.put(
    "/api/configurationGroup",
    {
      ...request,
    },
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${getLoginUser().token}`,
        ApiKey: getApiKeyForHotel(),
      },
    }
  );

  return data;
};

export const deleteConfigurationGroupAPI = async (
  request: DeleteConfigurationGroupRequestModel
): Promise<DeleteConfigurationGroupResponseModel> => {
  const { data } = await privateHttpClient.delete("/api/configurationGroup", {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${getLoginUser().token}`,
      ApiKey: getApiKeyForHotel(),
    },
    data: {
      ...request,
    },
  });

  return data;
};
