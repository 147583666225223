import React, { useState } from "react";
import { useSelector } from "react-redux";

// @material-ui/core components
import { Paper, Typography } from "@material-ui/core";
import {
  AccordionPermission,
  PermissionModule,
} from "components/AccordionPermission";
// import { getFunctionListAPI } from "services/functionService";
import { Function } from "models";
import { RootState } from "reducers";

interface IProps {
  disable?: boolean | undefined;
  allowedFunctionList: Function[];
  onChange: (permissionList: Function[]) => void;
}

const convertFunctionListToFunctionPermissionList = (
  functionList: Function[]
): PermissionModule[] => {
  const functionModuleList = [
    ...Array.from(new Set(functionList.map((f) => f.module))),
  ];

  var treeViewFunctionList: PermissionModule[] = [];

  functionModuleList.forEach((functionModule, index) => {
    const functions = functionList
      .filter((f) => f.module === functionModule)
      .map((f) => ({ ...f, allow: false }));

    treeViewFunctionList.push({
      id: index,
      name: functionModule,
      allow: false,
      indeterminate: false,
      permissionList: functions,
    });
  });

  return treeViewFunctionList;
};

//To check if module is selected then toggle the it to Indeterminate icon else it will be checked or unchecked.
const checkPermissionList = (module: PermissionModule) => {
  if (module.permissionList.every((menu) => menu.allow === false)) {
    module.allow = false;
    module.indeterminate = false;
  } else if (module.permissionList.every((menu) => menu.allow === true)) {
    module.allow = true;
    module.indeterminate = false;
  } else {
    module.allow = true;
    module.indeterminate = true;
  }
};

export const AccordionFunctionPermission: React.FC<IProps> = ({
  disable,
  allowedFunctionList,
  onChange,
}) => {
  const { functionList } = useSelector(
    (state: RootState) => state.functionState
  );

  const [allowFunctionList, setAllowFunctionList] =
    useState<Function[]>(allowedFunctionList);
  const [functionPermissionList, setFunctionPermissionList] = useState<
    PermissionModule[]
  >([]);

  React.useEffect(() => {
    if (functionList.length > 0) {
      var _permissionList =
        convertFunctionListToFunctionPermissionList(functionList);

      if (allowedFunctionList.length > 0) {
        allowedFunctionList.forEach((allowedFunc) => {
          _permissionList.forEach((module) => {
            //Handle when menu being checked.
            module.permissionList.forEach((func, index) => {
              if (allowedFunc.id === func.id) {
                func.allow = true;
              }
            });

            checkPermissionList(module);
          });
        });
      }

      setFunctionPermissionList(_permissionList);
    }
  }, [functionList]);

  const handleFunctionModuleCheck = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    var _allowFunctionList: Function[] = [];

    //Find which module needs to be update
    var updatingModule = functionPermissionList.find(
      (module) => parseInt(event.target.value) === module.id
    );

    if (updatingModule != undefined) {
      updatingModule.allow = checked;
      updatingModule.indeterminate = false;
      updatingModule.permissionList = updatingModule.permissionList.map(
        (func) => ({
          ...func,
          allow: checked,
        })
      );

      //if checked copy all function in module into allow list else remove it.

      if (checked) {
        _allowFunctionList = allowFunctionList.concat(
          functionList.filter((func) =>
            updatingModule?.permissionList.find((x) => x.id === func.id)
          )
        );
      } else {
        _allowFunctionList = allowFunctionList.filter(
          (func) =>
            !updatingModule?.permissionList.find((x) => x.id === func.id)
        );
      }
    }

    setAllowFunctionList(_allowFunctionList);
    onChange(_allowFunctionList);
  };

  const handleFunctionCheck = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    var _allowFunctionList: Function[] = [];

    //Get the function that's updating
    var updatingFunction = functionList.find(
      (func) => func.id === parseInt(event.target.value)
    );

    //Update the functionPermissionList
    if (updatingFunction != undefined) {
      var module = functionPermissionList.find(
        (module) => module.name === updatingFunction?.module
      );

      if (module != undefined) {
        module.permissionList.forEach((func) => {
          if (parseInt(event.target.value) === func.id) {
            func.allow = checked;
          }
        });

        checkPermissionList(module);
      }

      if (checked) {
        _allowFunctionList = allowFunctionList.concat(updatingFunction);
      } else {
        _allowFunctionList = allowFunctionList.filter(
          (func) => updatingFunction?.id !== func.id
        );
      }

      setAllowFunctionList(_allowFunctionList);
      onChange(_allowFunctionList);
    }
  };

  return (
    <>
      <Paper elevation={2} style={{ padding: "8px", marginTop: "8px" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            justifyItems: "start",
          }}
        >
          <Typography variant="subtitle2" style={{ padding: "8px" }}>
            Function permission
          </Typography>
          {/* <Button color="primary" onClick={() => setExpand(!expand)}>
            {expand ? `Collapse All` : `Expand All`}
          </Button> */}
        </div>

        {functionPermissionList.map((functionPermission, index) => (
          <AccordionPermission
            key={index}
            permissionsModule={functionPermission}
            disable={disable}
            handleOnModuleChange={handleFunctionModuleCheck}
            handleOnSubItemChange={handleFunctionCheck}
          />
        ))}
      </Paper>
    </>
  );
};
