import React, { useState } from "react";
import { useSelector } from "react-redux";

// @material-ui/core components
import { Paper, Typography } from "@material-ui/core";
import {
  AccordionPermission,
  Permission,
  PermissionModule,
} from "components/AccordionPermission";
// import { getFunctionListAPI } from "services/functionService";
import { Menu } from "models";
import { RootState } from "reducers";
import { MenuTypeList } from "models/backend/constant/MenuType";

interface IProps {
  disable?: boolean | undefined;
  allowedMenuList: Menu[];
  onChange: (permissionList: Menu[]) => void;
}

const convertMenuListToFunctionPermissionList = (
  menuList: Menu[]
): PermissionModule[] => {
  var treeViewMenuList: PermissionModule[] = [];

  const moduleList = menuList.filter(
    (menu) => menu.type === MenuTypeList[0].value
  );

  moduleList.forEach((module, index) => {
    const menus: Permission[] = menuList
      .filter((menu) => menu.parentId === module.id)
      .map((menu) => ({ ...menu, allow: false }));
    treeViewMenuList.push({
      id: module.id,
      name: module.name,
      allow: false,
      indeterminate: false,
      permissionList: menus,
    });
  });

  return treeViewMenuList;
};

//To check if module is selected then toggle the it to Indeterminate icon else it will be checked or unchecked.
const checkPermissionList = (module: PermissionModule) => {
  if (module.permissionList.every((menu) => menu.allow === false)) {
    module.allow = false;
    module.indeterminate = false;
  } else if (module.permissionList.every((menu) => menu.allow === true)) {
    module.allow = true;
    module.indeterminate = false;
  } else {
    module.allow = true;
    module.indeterminate = true;
  }
};

export const AccordionMenuPermission: React.FC<IProps> = ({
  disable,
  allowedMenuList,
  onChange,
}) => {
  const { menuList } = useSelector((state: RootState) => state.menuState);

  const [allowMenuList, setAllowMenuList] = useState<Menu[]>(allowedMenuList);
  const [menuPermissionList, setMenuPermissionList] = useState<
    PermissionModule[]
  >([]);

  React.useEffect(() => {
    if (menuList.length > 0) {
      var _permissionList = convertMenuListToFunctionPermissionList(menuList);

      if (allowedMenuList.length > 0) {
        allowedMenuList.forEach((allowedMenu) => {
          _permissionList.forEach((module) => {
            //Handle when menu being checked.
            module.permissionList.forEach((func, index) => {
              if (allowedMenu.id === func.id) {
                func.allow = true;
              }
            });

            checkPermissionList(module);
          });
        });
      }

      setMenuPermissionList(_permissionList);
    }
  }, [menuList]);

  const handleMenuModuleCheck = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    var _allowMenuList: Menu[] = [];

    //Find which module needs to be update
    var updatingModule = menuPermissionList.find(
      (module) => parseInt(event.target.value) === module.id
    );

    if (updatingModule != undefined) {
      updatingModule.allow = checked;
      updatingModule.indeterminate = false;
      updatingModule.permissionList = updatingModule.permissionList.map(
        (menu) => ({
          ...menu,
          allow: checked,
        })
      );

      //if checked copy all function in module into allow list else remove it.
      if (checked) {
        _allowMenuList = allowMenuList.concat(
          menuList.filter((menu) =>
            updatingModule?.permissionList.find((x) => x.id === menu.id)
          )
        );

        var a = menuList.find((module) => module.id === updatingModule?.id);
        if (a != undefined) {
          _allowMenuList.push(a);
        }
      } else {
        _allowMenuList = allowMenuList.filter(
          (menu) =>
            !updatingModule?.permissionList.find((x) => x.id === menu.id)
        );

        _allowMenuList = _allowMenuList.filter((menu) => {
          return menu.id !== updatingModule?.id;
        });
      }
    }
    console.log("Trace");
    console.log(_allowMenuList);

    setAllowMenuList(_allowMenuList);
    onChange(_allowMenuList);
  };

  const handleMenuCheck = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    var _updatingAllowMenuList: Menu[] = [];

    //Get the function that's updating
    var updatingMenu = menuList.find(
      (menu) => menu.id === parseInt(event.target.value)
    );

    //Update the functionPermissionList
    if (updatingMenu != undefined) {
      var module = menuPermissionList.find(
        (module) => module.id === updatingMenu?.parentId
      );

      if (module != undefined) {
        module.permissionList.forEach((func) => {
          if (parseInt(event.target.value) === func.id) {
            func.allow = checked;
          }
        });

        checkPermissionList(module);

        if (checked) {
          _updatingAllowMenuList = allowMenuList.concat(updatingMenu);

          //Special logic for module
          //check is module already exist, if not add the module menu into it.
          if(!allowMenuList.some(menu => menu.id === module?.id)) {
            var _moduleMenu = menuList.find((x) => x.id === module?.id);
            if (_moduleMenu != undefined) {
              _updatingAllowMenuList.push(_moduleMenu);
            }
          }
        } else {
          _updatingAllowMenuList = allowMenuList.filter(
            (menu) => updatingMenu?.id !== menu.id
          );

          //Special logic for module
          //remove module if no more child link to the module.
          if(!_updatingAllowMenuList.some(menu => menu.parentId === module?.id)){
            _updatingAllowMenuList = _updatingAllowMenuList.filter((menu) => {
              return menu.id !== module?.id;
            });
          }

          // var anyModule = _updatingAllowMenuList.filter(
          //   (x) => x.parentId === module?.id
          // );
          // if (anyModule.length == 0) {
          //   _updatingAllowMenuList = _updatingAllowMenuList.filter((menu) => {
          //     return menu.id !== module?.id;
          //   });
          // }
        }

        console.log("CHECK")
        console.log(_updatingAllowMenuList)

        setAllowMenuList(_updatingAllowMenuList);
        onChange(_updatingAllowMenuList);
      }
    }
  };

  return (
    <>
      <Paper elevation={2} style={{ padding: "8px", marginTop: "8px" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            justifyItems: "start",
          }}
        >
          <Typography variant="subtitle2" style={{ padding: "8px" }}>
            Menu permission
          </Typography>
          {/* <Button color="primary" onClick={() => setExpand(!expand)}>
            {expand ? `Collapse All` : `Expand All`}
          </Button> */}
        </div>

        {menuPermissionList.map((functionPermission, index) => (
          <AccordionPermission
            key={index}
            permissionsModule={functionPermission}
            disable={disable}
            handleOnModuleChange={handleMenuModuleCheck}
            handleOnSubItemChange={handleMenuCheck}
          />
        ))}
      </Paper>
    </>
  );
};
