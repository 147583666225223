import { Config } from 'models/Configuration';
import {
  //   ScreenMarketingConsent,
  ScreenReservationDetail,
  ScreenReservationList,
  ScreenReservationPreAuthPayment,
  ScreenReservationPaymentProcessing,
  ScreenReservationRegistration,
  ScreenReservationRoomAssignment,
  ScreenSearchReservation,
  ScreenReservationInvoice,
  ScreenSearchCheckOutReservation,
  ScreenReservationPayment,
  ScreenReservationCheckOutCompleted
} from 'utils/constantHelper';

export const mockAppConfig: Config = {
  themeConfig: {
    primaryButton: '#4791db',
    primaryText: '#000',
    secondaryText: '#C0C1C1',
    fontFamily: 'Arial'
  },
  hotelConfig: {
    logoUrl: 'https://ariacontainer.blob.core.windows.net/fsdh/logo1.png',
    backgroundImageUrl: 'https://ariacontainer.blob.core.windows.net/fsdh/background1.jpeg'
  },
  reservationConfig: {
    checkInScreenFlow: [
      ScreenSearchReservation,
      ScreenReservationList,
      ScreenReservationDetail,
      //   ScreenMarketingConsent,
      ScreenReservationRegistration,
      ScreenReservationPreAuthPayment,
      ScreenReservationPaymentProcessing,
      ScreenReservationRoomAssignment
    ],
    checkOutScreenFlow: [
      ScreenSearchCheckOutReservation,
      ScreenReservationInvoice,
      ScreenReservationPayment,
      ScreenReservationPaymentProcessing,
      ScreenReservationCheckOutCompleted,
     
    ],
    isAddingNewGuestAllow: false,
    isHealthDeclarationRequired: true,
    isFacialRecognitionRequiredForVerification: false,
    isMarketingConsentRequired: false,
    isEmailRequireWhenRoomNotReady: true,
    isPhoneNoRequireWhenRoomNotReady: false
  }
};
