import {
  CheckOutReservationRequestModel,
  GetPreCheckOutBillRequestModel,
  GetReservationsRequestModel,
  GetReservationsResponseModel,
  PreRegistrationRequestModel,
  ReservationStatisticRequestModel,
  ReservationStatisticResponseModel,
  SignAcknowledgementRequestModel,
  SubscribeRoomRequestModel,
  VerifyReservationRequestModel,
} from "models";
import {
  getApiKeyForHotel,
  getLoginUser,
  privateHttpClient,
} from "../utils/httpClient";
import { getServerFilter } from "utils/filterHelper";

export const getReservationByConfirmationNoAPI = async (
  hotelId: number,
  confirmationNo: string,
  lastName: string,
  isCheckout: boolean
) => {
  const { data } = await privateHttpClient.get("/api/reservation", {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${getLoginUser().token}`,
      ApiKey: getApiKeyForHotel(),
    },
    params: {
      HotelId: hotelId,
      ConfirmationNo: confirmationNo,
      LastName: lastName,
      IsCheckOut: isCheckout,
    },
  });

  return data;
};

export const getReservationByEmailAPI = async (
  hotelId: number,
  email: string
) => {
  const { data } = await privateHttpClient.get("/api/reservation", {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${getLoginUser().token}`,
      ApiKey: getApiKeyForHotel(),
    },
    params: {
      HotelId: hotelId,
      Email: email,
    },
  });

  return data;
};

export const getReservationByGuidEAPI = async (guid: string) => {
  const { data } = await privateHttpClient.get("/api/reservation", {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${getLoginUser().token}`,
      ApiKey: getApiKeyForHotel(),
    },
    params: {
      Guid: guid,
    },
  });

  return data;
};

export const getCheckOutReservationByConfirmationIdAPI = async (
  hotelId: number,
  roomNo: string,
  lastName: string
) => {
  const { data } = await privateHttpClient.get("/api/reservation", {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${getLoginUser().token}`,
      ApiKey: getApiKeyForHotel(),
    },
    params: {
      HotelId: hotelId,
      RoomNo: roomNo,
      LastName: lastName,
      IsCheckOut: true,
      IsSaving: true,
    },
  });

  return data;
};

export const verifyReservationAPI = async (
  request: VerifyReservationRequestModel
) => {
  const { data } = await privateHttpClient.put(
    "/api/reservation/verify",
    {
      ...request,
    },
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${getLoginUser().token}`,
        ApiKey: getApiKeyForHotel(),
      },
    }
  );

  return data;
};

export const combineSharerAPI = async (
  hotelId: number,
  mainConfirmationNo: string,
  shareProfileId: string
) => {
  const { data } = await privateHttpClient.post(
    "/api/reservation/combineshare",
    {
      hotelId: hotelId,
      mainConfirmationNo: mainConfirmationNo,
      shareProfileId: shareProfileId,
    },
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${getLoginUser().token}`,
        ApiKey: getApiKeyForHotel(),
      },
    }
  );

  return data;
};

export const breakSharer = async (
  hotelId: number,
  mainConfirmationNo: string,
  shareConfirmationNo: string
) => {
  const { data } = await privateHttpClient.post(
    "/api/reservation/breakshare",
    {
      hotelId: hotelId,
      mainConfirmationNo: mainConfirmationNo,
      shareConfirmationNo: shareConfirmationNo,
    },
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${getLoginUser().token}`,
        ApiKey: getApiKeyForHotel(),
      },
    }
  );

  return data;
};

export const updateSignAcknowledgementAPI = async (
  request: SignAcknowledgementRequestModel
) => {
  const { data } = await privateHttpClient.put(
    "/api/reservation/signedacknowledgement",
    {
      ...request,
    },
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${getLoginUser().token}`,
        ApiKey: getApiKeyForHotel(),
      },
    }
  );

  return data;
};

export const preRegisterAPI = async (request: PreRegistrationRequestModel) => {
  const { data } = await privateHttpClient.put(
    "/api/reservation/preregister",
    {
      ...request,
    },
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${getLoginUser().token}`,
        ApiKey: getApiKeyForHotel(),
      },
    }
  );

  return data;
};

export const subscribeRoomAPI = async (request: SubscribeRoomRequestModel) => {
  const { data } = await privateHttpClient.put(
    "/api/reservation/subscribeRoom",
    {
      ...request,
    },
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${getLoginUser().token}`,
        ApiKey: getApiKeyForHotel(),
      },
    }
  );

  return data;
};

export const getPreCheckOutBillAPI = async (
  request: GetPreCheckOutBillRequestModel
) => {
  const { data } = await privateHttpClient.get(
    "/api/reservation/getprecheckoutbill",
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${getLoginUser().token}`,
        ApiKey: getApiKeyForHotel(),
      },
      params: {
        ...request,
      },
    }
  );

  return data;
};

export const checkOutReservationAPI = async (
  request: CheckOutReservationRequestModel
) => {
  const { data } = await privateHttpClient.put(
    "/api/reservation/CheckOut",
    {
      ...request,
    },
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${getLoginUser().token}`,
        ApiKey: getApiKeyForHotel(),
      },
    }
  );

  return data;
};

export const getReservationStatisticAPI = async (
  request: ReservationStatisticRequestModel
): Promise<ReservationStatisticResponseModel> => {
  const { data } = await privateHttpClient.get(
    "/api/reservation/getstatistics",
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${getLoginUser().token}`,
        ApiKey: getApiKeyForHotel(),
      },
      params: {
        ...request,
      },
    }
  );

  return data;
};

export const getReservationListAPI = async (
  request: GetReservationsRequestModel
): Promise<GetReservationsResponseModel> => {
  const { data } = await privateHttpClient.get(
    "/api/reservation/getdetaillist",
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${getLoginUser().token}`,
        ApiKey: getApiKeyForHotel(),
      },
      params: {
        ...request,
        filter: getServerFilter(request?.filter),
      },
    }
  );

  return data;
};
