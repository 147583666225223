import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store/state";
import { ResponseModel } from "../models/common/ResponseModel";
import { AxiosError } from "axios";
import { GetHotelsResponseModel } from "models";
import { getHotelListAPI } from "services/hotelService";

const initialState: RootState.HotelState = {
  hotelList: [],
  authorisedHotelList: [],
  selectedHotel: undefined,
  error: undefined,
  isLoading: false,
};

//Actions
//More info here https://redux-toolkit.js.org/api/createAsyncThunk
export const fetchHotelList = createAsyncThunk<
  GetHotelsResponseModel,
  void,
  { rejectValue: AxiosError<Error> }
>("hotel/getHotelList", async (_, thunkApi) => {
  try {
    var pageIndex = 1;

    let data: GetHotelsResponseModel = await getHotelListAPI({
      pageIndex,
      pageSize: 100,
    });

    let tempData = data.result.items;

    while (data.result.pageCount > pageIndex) {
      pageIndex++;
      let nextData: GetHotelsResponseModel = await getHotelListAPI({
        pageIndex,
        pageSize: 100,
      });

      tempData.push(...nextData.result.items);
    }

    data.result.items = tempData;

    return data;
  } catch (err) {
    let error = err as AxiosError<Error>; // cast the error for access
    if (!error.response) {
      throw err;
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    return thunkApi.rejectWithValue(error);
  }
});

//Slice
//More info here https://redux-toolkit.js.org/api/createSlice
export const hotelSlice = createSlice({
  name: "hotel",
  initialState,
  reducers: {
    //local reducer
    selectHotel(state, action) {
      state.selectedHotel = action.payload;
      window.sessionStorage.setItem("key", action.payload.apiKey);
    },
    setAuthorisedHotelList(state, action) {
      state.authorisedHotelList = action.payload;
    },
    resetHotelState() {
      window.sessionStorage.removeItem("key");
      return initialState;
    },
  },
  //services reducers
  extraReducers: (builder) => {
    builder.addCase(fetchHotelList.fulfilled, (state, { payload }) => {
      state.hotelList = payload.result.items;

      // const serverError: ResponseModel = {
      //   resultCode: payload.resultCode,
      //   resultDescription: payload.resultDescription
      // };

      state.error = undefined;
      state.isLoading = false;
    }),
      builder.addCase(fetchHotelList.pending, (state, _) => {
        state.isLoading = true;
      }),
      builder.addCase(fetchHotelList.rejected, (state, action) => {
        if (action.payload != undefined) {
          let error: AxiosError<Error> = action.payload;

          const serverError: ResponseModel = {
            resultCode: error?.response?.status.toString() ?? "",
            resultDescription: error?.response?.data.message ?? "",
          };

          state.error = serverError;
          state.isLoading = false;
        }
      });
  },
});

export const { selectHotel, setAuthorisedHotelList, resetHotelState } = hotelSlice.actions;

export default hotelSlice.reducer;
