import {
  CreateMenuRequestModel,
  CreateMenuResponseModel,
  DeleteMenuRequestModel,
  DeleteMenuResponseModel,
  GetMenuRequestModel,
  GetMenuResponseModel,
  GetMenusRequestModel,
  GetMenusResponseModel,
  UpdateMenuRequestModel,
  UpdateMenuResponseModel,
} from "models";
import { getServerFilter } from "utils/filterHelper";
import {
  getApiKeyForHotel,
  getLoginUser,
  privateHttpClient,
} from "utils/httpClient";

export const createMenuAPI = async (
  request: CreateMenuRequestModel
): Promise<CreateMenuResponseModel> => {
  const { data } = await privateHttpClient.post(
    "/api/menu",
    {
      ...request,
    },
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${getLoginUser().token}`,
        ApiKey: getApiKeyForHotel(),
      },
    }
  );

  return data;
};

export const getMenuAPI = async (
  request: GetMenuRequestModel
): Promise<GetMenuResponseModel> => {
  const { data } = await privateHttpClient.get(`/api/menu/${request.id}`, {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${getLoginUser().token}`,
      ApiKey: getApiKeyForHotel(),
    },
  });

  return data;
};

export const getMenuListAPI = async (
  request: GetMenusRequestModel
): Promise<GetMenusResponseModel> => {
  const { data } = await privateHttpClient.get("/api/menu", {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${getLoginUser().token}`,
      ApiKey: getApiKeyForHotel(),
    },
    params: {
      ...request,
      filter: getServerFilter(request?.filter),
    },
  });

  return data;
};

export const updateMenuAPI = async (
  request: UpdateMenuRequestModel
): Promise<UpdateMenuResponseModel> => {
  const { data } = await privateHttpClient.put(
    "/api/menu",
    {
      ...request,
    },
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${getLoginUser().token}`,
        ApiKey: getApiKeyForHotel(),
      },
    }
  );

  return data;
};

export const deleteMenuAPI = async (
  request: DeleteMenuRequestModel
): Promise<DeleteMenuResponseModel> => {
  const { data } = await privateHttpClient.delete("/api/menu", {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${getLoginUser().token}`,
      ApiKey: getApiKeyForHotel(),
    },
    data: {
      ...request,
    },
  });

  return data;
};
