import React from "react";

interface IProps {
  color: string;
  width?: number;
  height?: number;
}

export const ColoredCircle: React.FC<IProps> = ({ color, width, height }) => {
  var circleStyle = {
    padding: 0,
    margin: 0,
    display: "inline-block",
    // position:'absolute',
    backgroundColor: color,
    borderRadius: "50%",
    width: width ? width : 12,
    height: height ? height : 12,
    marginLeft: 5,
  };

  return color ? (
    <>
      <span style={circleStyle} />
    </>
  ) : null;
};
