import React, { useState } from "react";
// @material-ui/core components
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { DynamicTypography } from "components/DynamicTypography";
import { GridContainer } from "components/GridContainer";
import { GridItem } from "components/GridItem";
import { TextField } from "@material-ui/core";

interface IProps {
  open: boolean;
  onCancelClicked?: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

export const FileToBase64Dialog: React.FC<IProps> = ({
  open,
  onCancelClicked,
}) => {
  const [base64String, setBase64String] = useState<string>("");
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    if (!open) {
      setBase64String("");
    }
  }, [open]);

  const getBase64 = (file: File): Promise<string> => {
    return new Promise((resolve) => {
      let fileInfo;
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        // console.log("Called", reader);
        let baseURL = reader.result;
        // console.log(baseURL);
        if (typeof baseURL === "string") {
          var stringItem = baseURL.toString().split(",");
          resolve(stringItem[1]);
        } else {
          resolve("");
        }
      };
      console.log(fileInfo);
    });
  };

  const handleFileInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files != null) {
      setIsLoading(true);

      var file = event.target.files[0];

      getBase64(file)
        .then((result) => {
          setBase64String(result);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={open} fullWidth maxWidth="xl">
      <DialogTitle>File to Base64 string</DialogTitle>
      <DialogContent>
        <DynamicTypography
          content={"Select a file to convert to base64 string"}
        />
        <GridContainer
          spacing={2}
          direction="column"
          alignItems="center"
          alignContent="center"
        >
          <GridItem>
            <input
              type="file"
              disabled={isLoading}
              name="file"
              onChange={handleFileInputChange}
            />
          </GridItem>
          <GridItem style={{ width: "90vw" }}>
            <TextField
              variant="outlined"
              value={base64String}
              multiline
              maxRows={10}
              fullWidth
              onChange={(event) => setBase64String(event.currentTarget.value)}
            />
          </GridItem>
        </GridContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancelClicked}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};
