import React from "react";
import { useSelector } from "react-redux";

import {
  Checkbox,
  createStyles,
  FormControl,
  Input,
  InputLabel,
  ListItemText,
  makeStyles,
  MenuItem,
  Select,
  Theme,
} from "@material-ui/core";

import { RootState } from "reducers";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      width: "100%",
    },
  })
);

interface IProps {
  label?: string;
  disabled?: boolean;
  value: string[];
  onChange?: (hotelList: string) => void;
}

export const HotelSelector: React.FC<IProps> = ({
  label = "Hotels",
  value,
  onChange,
  disabled = false,
}) => {
  const classes = useStyles();
  const { hotelList } = useSelector((state: RootState) => state.hotelState);

  const [personName, setPersonName] = React.useState<string[]>(
    value == undefined ? [] : value
  );

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    var selectedHotels = event.target.value as string[];

    if (onChange) {
      onChange(selectedHotels.join(","));
    }
    setPersonName(selectedHotels);
  };

  return (
    <FormControl className={classes.formControl} disabled={disabled}>
      <InputLabel id="hotel-mutiple-checkbox-label">{label}</InputLabel>
      <Select
        id="hotel-mutiple-checkbox"
        multiple
        value={personName}
        onChange={handleChange}
        input={<Input />}
        renderValue={(selected) => (selected as string[]).join(",")}
      >
        {hotelList.map((hotel, index) => (
          <MenuItem key={index} value={hotel.name}>
            <Checkbox
              color="primary"
              checked={personName.indexOf(hotel.name) > -1}
            />
            <ListItemText primary={hotel.name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
