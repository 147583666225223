import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import {
  ARIA_SuccessCode,
  CapturePreAuthRequestModel,
  CapturePreAuthResponseModel,
  CreateCheckoutPaymentSessionRequestModel,
  CreateCheckoutPaymentSessionResponseModel,
  CreatePreAuthPaymentRequestModel,
  CreatePreAuthPaymentResponseModel,
  GetPreAuthInfoRequestModel,
  GetPreAuthInfoResponseModel,
  //   CreateCheckoutPaymentSessionResponseModel,
  ResponseModel,
  UpdatePaymentRequestModel,
  UpdatePaymentResponseModel
} from 'models';
import { PAYMENT_CODE_AUTHORISED } from 'models/backend/constant/Payment';
import {
  capturePreAuthPaymentAPI,
  createCheckoutPaymentSessionAPI,
  createPreAuthPaymentAPI,
  getPreAuthInfoAPI,
  updatePaymentCompletionAPI
} from 'services/paymentService';
import { RootState } from '../store/state';

const initialState: RootState.PaymentState = {
  checkoutSession: undefined,
  paymentResponse: undefined,
  completedPayment: undefined,
  capturedPayment: undefined,
  preAuthCardInfo: undefined,
  error: undefined,
  isLoading: false
};

//Actions
//More info here https://redux-toolkit.js.org/api/createAsyncThunk
export const createCheckoutPaymentSession = createAsyncThunk<
  CreateCheckoutPaymentSessionResponseModel,
  { request: CreateCheckoutPaymentSessionRequestModel },
  { rejectValue: AxiosError<Error> }
>('payment/createCheckoutPaymentSession', async (input, thunkApi) => {
  const { request } = input;
  try {
    let data: CreateCheckoutPaymentSessionResponseModel = await createCheckoutPaymentSessionAPI(request);
    return data;
  } catch (err) {
    let error = err as AxiosError<Error>; // cast the error for access
    if (!error.response) {
      throw err;
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    return thunkApi.rejectWithValue(error);
  }
});

export const createPreAuthPayment = createAsyncThunk<
  CreatePreAuthPaymentResponseModel,
  { request: CreatePreAuthPaymentRequestModel },
  { rejectValue: AxiosError<Error> }
>('payment/createPreAuthPayment', async (input, thunkApi) => {
  const { request } = input;
  try {
    let data: CreatePreAuthPaymentResponseModel = await createPreAuthPaymentAPI(request);
    return data;
  } catch (err) {
    let error = err as AxiosError<Error>; // cast the error for access
    if (!error.response) {
      throw err;
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    return thunkApi.rejectWithValue(error);
  }
});

export const updatePaymentCompletion = createAsyncThunk<
  UpdatePaymentResponseModel,
  { request: UpdatePaymentRequestModel },
  { rejectValue: AxiosError<Error> }
>('payment/updatePaymentCompletion', async (input, thunkApi) => {
  const { request } = input;
  try {
    let data = await updatePaymentCompletionAPI(request);
    return data;
  } catch (err) {
    let error = err as AxiosError<Error>; // cast the error for access
    if (!error.response) {
      throw err;
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    return thunkApi.rejectWithValue(error);
  }
});

export const getPreAuthInfo = createAsyncThunk<
  GetPreAuthInfoResponseModel,
  { request: GetPreAuthInfoRequestModel },
  { rejectValue: AxiosError<Error> }
>('payment/getPreAuthInfo', async (input, thunkApi) => {
  const { request } = input;
  try {
    let data: GetPreAuthInfoResponseModel = await getPreAuthInfoAPI(request);
    return data;
  } catch (err) {
    let error = err as AxiosError<Error>; // cast the error for access
    if (!error.response) {
      throw err;
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    return thunkApi.rejectWithValue(error);
  }
});

export const capturePreAuthPayment = createAsyncThunk<
  CapturePreAuthResponseModel,
  { request: CapturePreAuthRequestModel },
  { rejectValue: AxiosError<Error> }
>('payment/capturePreAuthPayment', async (input, thunkApi) => {
  const { request } = input;
  try {
    let data: CapturePreAuthResponseModel = await capturePreAuthPaymentAPI(request);
    return data;
  } catch (err) {
    let error = err as AxiosError<Error>; // cast the error for access
    if (!error.response) {
      throw err;
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    return thunkApi.rejectWithValue(error);
  }
});

//Slice
//More info here https://redux-toolkit.js.org/api/createSlice
export const paymentSlice = createSlice({
  name: 'payment',
  initialState: initialState,
  reducers: {
    //local reducer
    resetCheckoutSession(state, _) {
      state.checkoutSession = undefined;
    },
    resetPaymentState() {
      return initialState;
    }
  },
  //services reducers
  extraReducers: (builder) => {
    builder.addCase(createCheckoutPaymentSession.fulfilled, (state, { payload }) => {
      if (payload != undefined) {
        state.checkoutSession = { ...payload.result };
      }

      state.error = undefined;
      state.isLoading = false;
    }),
      builder.addCase(createCheckoutPaymentSession.pending, (state, _) => {
        state.isLoading = true;
      }),
      builder.addCase(createCheckoutPaymentSession.rejected, (state, action) => {
        if (action.payload != undefined) {
          let error: AxiosError<Error> = action.payload;

          const serverError: ResponseModel = {
            resultCode: error?.response?.status.toString() ?? '',
            resultDescription: error?.response?.data.message ?? ''
          };

          state.error = serverError;
          state.isLoading = false;
        }
      });

    //createPreAuthPayment
    builder.addCase(createPreAuthPayment.fulfilled, (state, { payload }) => {
      if (payload != undefined && payload.resultCode === ARIA_SuccessCode) {
        if (payload.result.code === PAYMENT_CODE_AUTHORISED) {
          state.completedPayment = true;
        }
        state.paymentResponse = payload.result;

        state.error = undefined;
      } else {
        const serverError: ResponseModel = {
          resultCode: payload.resultCode,
          resultDescription: payload.resultDescription
        };

        state.error = serverError;
      }

      state.isLoading = false;
    }),
      builder.addCase(createPreAuthPayment.pending, (state, _) => {
        state.isLoading = true;
      }),
      builder.addCase(createPreAuthPayment.rejected, (state, action) => {
        if (action.payload != undefined) {
          let error: AxiosError<Error> = action.payload;

          const serverError: ResponseModel = {
            resultCode: error?.response?.status.toString() ?? '',
            resultDescription: error?.response?.data.message ?? ''
          };

          state.error = serverError;
          state.isLoading = false;
        }
      });

    //updatePayment
    builder.addCase(updatePaymentCompletion.fulfilled, (state, { payload }) => {
      if (payload != undefined && payload.resultCode === ARIA_SuccessCode) {
        state.completedPayment = true;

        state.error = undefined;
        state.isLoading = false;
      } else {
        const serverError: ResponseModel = {
          resultCode: payload.resultCode,
          resultDescription: payload.resultDescription
        };

        state.error = serverError;
        state.isLoading = false;
      }
    }),
      builder.addCase(updatePaymentCompletion.pending, (state, _) => {
        state.isLoading = true;
      }),
      builder.addCase(updatePaymentCompletion.rejected, (state, action) => {
        if (action.payload != undefined) {
          let error: AxiosError<Error> = action.payload;

          const serverError: ResponseModel = {
            resultCode: error?.response?.status.toString() ?? '',
            resultDescription: error?.response?.data.message ?? ''
          };

          state.error = serverError;
          state.isLoading = false;
        }
      });

    //GetPreAuthInfo
    builder.addCase(getPreAuthInfo.fulfilled, (state, { payload }) => {
      if (payload != undefined && payload.resultCode === ARIA_SuccessCode) {
        state.preAuthCardInfo = { ...payload.result };

        state.error = undefined;
        state.isLoading = false;
      } else {
        const serverError: ResponseModel = {
          resultCode: payload.resultCode,
          resultDescription: payload.resultDescription
        };

        state.error = serverError;
        state.isLoading = false;
      }
    }),
      builder.addCase(getPreAuthInfo.pending, (state, _) => {
        state.isLoading = true;
      }),
      builder.addCase(getPreAuthInfo.rejected, (state, action) => {
        if (action.payload != undefined) {
          let error: AxiosError<Error> = action.payload;

          const serverError: ResponseModel = {
            resultCode: error?.response?.status.toString() ?? '',
            resultDescription: error?.response?.data.message ?? ''
          };

          state.error = serverError;
          state.isLoading = false;
        }
      });

    //capturePreAuthPayment
    builder.addCase(capturePreAuthPayment.fulfilled, (state, { payload }) => {
      if (payload != undefined && payload.resultCode === ARIA_SuccessCode) {
        state.completedPayment = true;

        state.error = undefined;
        state.isLoading = false;
      } else {
        const serverError: ResponseModel = {
          resultCode: payload.resultCode,
          resultDescription: payload.resultDescription
        };

        state.error = serverError;
        state.isLoading = false;
      }
    }),
      builder.addCase(capturePreAuthPayment.pending, (state, _) => {
        state.isLoading = true;
      }),
      builder.addCase(capturePreAuthPayment.rejected, (state, action) => {
        if (action.payload != undefined) {
          let error: AxiosError<Error> = action.payload;

          const serverError: ResponseModel = {
            resultCode: error?.response?.status.toString() ?? '',
            resultDescription: error?.response?.data.message ?? ''
          };

          state.error = serverError;
          state.isLoading = false;
        }
      });
  }
});

export const { resetCheckoutSession, resetPaymentState } = paymentSlice.actions;

export default paymentSlice.reducer;
