import {
    CreateKioskRequestModel,
    CreateKioskResponseModel,
    DeleteKioskRequestModel,
    DeleteKioskResponseModel,
    GetKioskRequestModel,
    GetKioskResponseModel,
    GetKiosksRequestModel,
    GetKiosksResponseModel,
    UpdateKioskRequestModel,
    UpdateKioskResponseModel,

  } from "models";
  
  import { getServerFilter } from "utils/filterHelper";
  import {
    getApiKeyForHotel,
    getLoginUser,
    privateHttpClient,
  } from "utils/httpClient";
  
  export const createKioskAPI = async (
    request: CreateKioskRequestModel
  ): Promise<CreateKioskResponseModel> => {
    const { data } = await privateHttpClient.post(
      "/api/kiosk",
      {
        ...request,
      },
      {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${getLoginUser().token}`,
          ApiKey: getApiKeyForHotel(),
        },
      }
    );
  
    return data;
  };
  
  export const getKioskAPI = async (
    request: GetKioskRequestModel
  ): Promise<GetKioskResponseModel> => {
    const { data } = await privateHttpClient.get(
      `/api/kiosk/${request.id}`,
      {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${getLoginUser().token}`,
          ApiKey: getApiKeyForHotel(),
        },
      }
    );
  
    return data;
  };
  
  export const getKioskListAPI = async (
    request: GetKiosksRequestModel
  ): Promise<GetKiosksResponseModel> => {
    const { data } = await privateHttpClient.get("/api/kiosk", {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${getLoginUser().token}`,
        ApiKey: getApiKeyForHotel(),
      },
      params: {
        ...request,
        filter: getServerFilter(request?.filter),
      },
    });
  
    return data;
  };
  
  export const updateKioskAPI = async (
    request: UpdateKioskRequestModel
  ): Promise<UpdateKioskResponseModel> => {
    const { data } = await privateHttpClient.put(
      "/api/kiosk",
      {
        ...request,
      },
      {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${getLoginUser().token}`,
          ApiKey: getApiKeyForHotel(),
        },
      }
    );
  
    return data;
  };
  
  export const deleteKioskAPI = async (
    request: DeleteKioskRequestModel
  ): Promise<DeleteKioskResponseModel> => {
    const { data } = await privateHttpClient.delete("/api/kiosk", {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${getLoginUser().token}`,
        ApiKey: getApiKeyForHotel(),
      },
      data: {
        ...request,
      },
    });
  
    return data;
  };
  