import { GetIdentityRoleResponseModel } from "models/backend/IdentityRoleModel";
import {
  getApiKeyForHotel,
  getLoginUser,
  privateHttpClient,
} from "../utils/httpClient";

export const getIdentityUserRoleList =
  async (): Promise<GetIdentityRoleResponseModel> => {
    const { data } = await privateHttpClient.get("/api/identityRole", {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${getLoginUser().token}`,
        ApiKey: getApiKeyForHotel(),
      },
    });

    return data;
  };
