import {
  booleanList,
  filterOperators,
  GridColFilterDef,
  operators,
  stringFilterOperators,
} from "components/DataGridFilterSelection";
import {
  GRID_FILTER_TYPE_BOOLEAN,
  GRID_FILTER_TYPE_CHILDROLE,
  GRID_FILTER_TYPE_COMPONENTTYPE,
  GRID_FILTER_TYPE_COUNTRY,
  GRID_FILTER_TYPE_DATETIME,
  GRID_FILTER_TYPE_HOTELGROUP,
  GRID_FILTER_TYPE_KIOSK_LOCATION,
  GRID_FILTER_TYPE_LANGUAGE,
  GRID_FILTER_TYPE_NUMBER,
  GRID_FILTER_TYPE_OBJECT,
} from "components/DataGridFilterSelection/type";
import { addDay, convertDateTimeToDate, subtractDay } from "./dateHelper";

export function getServerFilter(
  filterItems?: GridColFilterDef[],
  objects?: object[]
): string | undefined {
  if (filterItems == undefined) {
    return undefined;
  }

  var _string: string = "";

  filterItems.forEach((filter) => {
    if (filter.index != 0) {
      _string = `${_string} ${filter.operator}`;
    }

    // Filtering Type to ODATA FORMAT
    if (
      filter.columnName?.type === GRID_FILTER_TYPE_CHILDROLE ||
      filter.columnName?.type === GRID_FILTER_TYPE_COMPONENTTYPE ||
      filter.columnName?.type === GRID_FILTER_TYPE_COUNTRY ||
      filter.columnName?.type === GRID_FILTER_TYPE_HOTELGROUP ||
      filter.columnName?.type === GRID_FILTER_TYPE_KIOSK_LOCATION ||
      filter.columnName?.type === GRID_FILTER_TYPE_LANGUAGE ||
      filter.columnName?.type == GRID_FILTER_TYPE_NUMBER 
    ) {
      //Number, Id Selectors.
      if(filter.columnName?.description != undefined && filter.columnName?.description != null) {
        if (filter.filterOperator == stringFilterOperators[1].value) {
          _string = `${_string} ${filter.columnName?.description} ${filter.filterOperator} '${filter.value}'`;
        } else {
          _string = `${_string} ${filter.filterOperator}('${filter.value}', ${filter.columnName?.description})`;
        }
      } else {
        _string = `${_string} ${filter.columnName?.field} ${filter.filterOperator} ${filter.value}`;
      }
    } else if (filter.columnName?.type == GRID_FILTER_TYPE_BOOLEAN) {
      if (filter.value === booleanList[0].value) {
        _string = `${_string} ${filter.columnName?.field} ${filter.filterOperator} true`;
      } else {
        _string = `${_string} ${filter.columnName?.field} ${filter.filterOperator} false`;
      }
    } else if (filter.columnName?.type == GRID_FILTER_TYPE_DATETIME) {
      //DATE TIME FILTERS
      if (filter.filterOperator == filterOperators[0].value) {
        var date = new Date(filter.value);
        var date = addDay(date, 1);

        _string = `${_string} ${filter.columnName?.field} ${
          filterOperators[4].value
        } datetime'${filter.value}' ${operators[0].value} ${
          filter.columnName?.field
        } ${filterOperators[3].value} datetime'${convertDateTimeToDate(date)}'`;
      } else if (filter.filterOperator == filterOperators[1].value) {
        var date = new Date(filter.value);
        var date = addDay(date, 1);
        var xdate = subtractDay(date, 1);

        _string = `${_string} ${filter.columnName?.field} ${
          filterOperators[2].value
        } datetime'${convertDateTimeToDate(xdate)}' ${operators[1].value} ${
          filter.columnName?.field
        } ${filterOperators[3].value} datetime'${convertDateTimeToDate(date)}'`;
      } else {
        _string = `${_string} ${filter.columnName?.field} ${filter.filterOperator} datetime'${filter.value}'`;
      }
    } else if (filter.columnName?.type == GRID_FILTER_TYPE_OBJECT) {
      //Object Filters
      if (filter.filterOperator == stringFilterOperators[1].value) {
        _string = `${_string} ${filter.columnName?.description} ${filter.filterOperator} '${filter.value}'`;
      } else if (filter.filterOperator == stringFilterOperators[0].value) {
        _string = `${_string} ${filter.filterOperator}('${filter.value}', ${filter.columnName?.description})`;
      } else {
        _string = `${_string} ${filter.filterOperator}(${filter.columnName?.description}, '${filter.value}')`;
      }
    } else {
      //Strings Filters
      if (filter.filterOperator == stringFilterOperators[1].value) {
        _string = `${_string} ${filter.columnName?.field} ${filter.filterOperator} '${filter.value}'`;
      } else if (filter.filterOperator == stringFilterOperators[0].value) {
        _string = `${_string} ${filter.filterOperator}('${filter.value}', ${filter.columnName?.field})`;
      } else {
        _string = `${_string} ${filter.filterOperator}(${filter.columnName?.field}, '${filter.value}')`;
      }
    }
  });

  return _string.trim();
}
