import React, { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "reducers";
//Component
import { GridContainer, GridItem, SummaryCard } from "components";
import { MainDrawer } from "components/MainDrawer";
import { IconButton, Typography } from "@material-ui/core";
import { ARIA_SuccessCode, ReservationStatistic } from "models";
import { getReservationStatisticAPI } from "services/reservationService";
import { convertDateTimeToString } from "utils/dateHelper";
import { DatePicker } from "@material-ui/pickers";
//Icon
import RefreshIcon from "@material-ui/icons/Refresh";
//Helper

const defaultValue: ReservationStatistic = {
  date: new Date(),
  arrivalsTotal: 0,
  departuresTotal: 0,
  preRegisteredTotal: 0,
  checkedInTotal: 0,
  checkedOutTotal: 0,
};

export const MainLanding = () => {
  const { selectedHotel } = useSelector((state: RootState) => state.hotelState);

  const [loadList, setloadList] = useState(true);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());

  const [isLoading, setIsLoading] = useState(false);
  const [requestDateTime, setRequestDateTime] = useState<Date>(new Date());

  const [reservationStatisticList, setReservationStatisticList] =
    useState<ReservationStatistic>(defaultValue);

  React.useEffect(() => {
    if (selectedHotel != undefined && loadList) {
      setIsLoading(true);
      setReservationStatisticList(defaultValue);
      setRequestDateTime(new Date());

      getReservationStatisticAPI({
        startDate: selectedDate,
        endDate: selectedDate,
      })
        .then((response) => {
          if (response.resultCode === ARIA_SuccessCode) {
            setReservationStatisticList(response.result[0]);
          }
        })
        .finally(() => {
          setloadList(false);
          setIsLoading(false);
        });
    }
  }, [selectedDate, loadList, selectedHotel]);

  return (
    <MainDrawer>
      <GridContainer direction="column" spacing={1}>
        <GridItem xs={12}>
          <Typography variant="h6">
            Welcome to Hotel Management System
          </Typography>
          <div style={{ display: "flex" }}>
            <div style={{ flexGrow: 1 }}> </div>
            <div>
              <IconButton size="small" onClick={() => setloadList(true)}>
                <RefreshIcon />
              </IconButton>
              <b>Data as at </b>: {convertDateTimeToString(requestDateTime)}
            </div>
          </div>
        </GridItem>
        <GridContainer
          spacing={2}
          direction="row-reverse"
          justifyContent="center"
        >
          <GridItem xs={12} md={3}>
            <GridContainer spacing={2} direction="row" justifyContent="center">
              <GridItem>
                <DatePicker
                  variant="static"
                  format="d MMM yyyy"
                  value={selectedDate}
                  onChange={(date) => {
                    if (date != null) {
                      setSelectedDate(date);
                      setloadList(true);
                    }
                  }}
                />
              </GridItem>
            </GridContainer>
          </GridItem>

          <GridItem xs={12} md={9}>
            <GridContainer spacing={2} direction="row" justifyContent="center">
              <GridItem>
                <SummaryCard
                  isLoading={isLoading}
                  title="Total Arrival"
                  currentTotal={reservationStatisticList.arrivalsTotal}
                />
              </GridItem>
              <GridItem>
                <SummaryCard
                  isLoading={isLoading}
                  title="Total Depature"
                  currentTotal={reservationStatisticList.departuresTotal}
                />
              </GridItem>
            </GridContainer>

            <GridContainer spacing={2} direction="row" justifyContent="center">
              <GridItem>
                <SummaryCard
                  isLoading={isLoading}
                  title="Total Web Registration"
                  currentTotal={
                    reservationStatisticList.preRegisteredTotal
                  }
                />
              </GridItem>
              <GridItem>
                <SummaryCard
                  isLoading={isLoading}
                  title="Total Kiosk Check-In"
                  currentTotal={
                    reservationStatisticList.checkedInTotal
                  }
                />
              </GridItem>
              <GridItem>
                <SummaryCard
                  isLoading={isLoading}
                  title="Total Web Checked-Out"
                  currentTotal={
                    reservationStatisticList.checkedOutTotal
                  }
                />
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </GridContainer>
    </MainDrawer>
  );
};
