import axios from 'axios';
import { LoginUser } from './authUser';

// const debug = {
//     BASEURL: 'http://localhost:5000',
//     URLPREFIX: '/api'
// };

const demo = {
  BASEURL: 'https://spcp-stg.assimilated.com.sg',
  URLPREFIX: '/api'
};

const dev = {
  BASEURL: 'https://dev.assimilated.com.sg/ariana',
  URLPREFIX: '/aria-dashboard'
};

const sit = {
  BASEURL: 'https://sit.assimilated.com.sg/ariana',
  URLPREFIX: '/aria-dashboard'
};

const uat = {
  BASEURL: 'https://arina-api.assimilated.com.sg',
  URLPREFIX: '/api'
};

const prod = {
  BASEURL: 'https://arina-api.assimilated.com.sg',
  URLPREFIX: '/api'
};

function getUrl() {
  return process.env.REACT_APP_MODE === 'production'
    ? prod
    : process.env.REACT_APP_MODE === 'uat'
    ? uat
    : process.env.REACT_APP_MODE === 'demo'
    ? demo
    : process.env.REACT_APP_MODE === 'sit'
    ? sit
    : process.env.REACT_APP_MODE === 'dev'
    ? dev
    : dev;
}

export function getToken() {
    return window.sessionStorage.getItem("token");
}

export function getLoginUser() : LoginUser {

  var existingUser = window.sessionStorage.getItem("user");

  return existingUser ? JSON.parse(existingUser) : undefined

}

export function getApiKeyForHotel() {
  return window.sessionStorage.getItem("key") != null ? window.sessionStorage.getItem("key") : "";
} 

export const publicHttpClient = axios.create({
  //More setting can be found here https://github.com/axios/axios
  baseURL: getUrl().BASEURL,
  headers: {
    'Content-type': 'application/json'
  },
  // auth: {
  //   username: 'assimilated',
  //   password: 'P@s5uu0rD'
  // }
});

export const privateHttpClient = axios.create({
  //More setting can be found here https://github.com/axios/axios
  baseURL: getUrl().BASEURL
});

export const docscanHttpClient = axios.create({
  //More setting can be found here https://github.com/axios/axios
  baseURL: 'https://api-mobile-docscan.azurewebsites.net',
  headers: {
    'Content-type': 'application/json',
    AccountId: 'assimilated_admin',
    AccessSecret: 'TCE70eEkQLjg739PwgmW3OpxMOeb1IOT',
    ApiKey: 'assim-15b5-1674-pc08-cf9454a3799d'
  }
});

export const livenessHttpClient = axios.create({
  baseURL: 'https://aus.passlive.acuant.net',
  headers: {
    'Content-type': 'application/json'
  },
  auth: {
    username: 'cdd3ad73-142f-494a-87ec-3f38c741ef7a;admin_assimilated_dy2Bhn',
    password: 'wM8z3^lanX#LIMEID7E'
  }
});

export const facialHttpClient = axios.create({
  baseURL: 'https://aus.frm.acuant.net',
  headers: {
    'Content-type': 'application/json'
  },
  auth: {
    username: 'cdd3ad73-142f-494a-87ec-3f38c741ef7a;admin_assimilated_dy2Bhn',
    password: 'wM8z3^lanX#LIMEID7E'
  }
});
