import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getLanguageListAPI } from "../services/languageService";
import { RootState } from "../store/state";
import { ResponseModel } from "../models/common/ResponseModel";
import { AxiosError } from "axios";
import { GetLanguagesResponseModel } from "models/backend/LanguageModel";

const initialState: RootState.LanguageState = {
  languageList: [],
  selectedLanguage: undefined,
  error: undefined,
  isLoading: false,
};

//Actions
//More info here https://redux-toolkit.js.org/api/createAsyncThunk
export const fetchLanguageList = createAsyncThunk<
  GetLanguagesResponseModel,
  void,
  { rejectValue: AxiosError<Error> }
>("language/getLanguages", async (_, thunkApi) => {
  try {
    var pageIndex = 1;

    let data: GetLanguagesResponseModel = await getLanguageListAPI({
      pageIndex,
    });

    let tempData = data.result.items;

    while (data.result.pageCount > pageIndex) {
      pageIndex++;
      let nextData: GetLanguagesResponseModel = await getLanguageListAPI({
        pageIndex,
      });

      tempData.push(...nextData.result.items);
    }

    data.result.items = tempData;

    return data;
  } catch (err) {
    let error = err as AxiosError<Error>; // cast the error for access
    if (!error.response) {
      throw err;
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    return thunkApi.rejectWithValue(error);
  }
});

//Slice
//More info here https://redux-toolkit.js.org/api/createSlice
export const languageSlice = createSlice({
  name: "language",
  initialState,
  reducers: {
    //local reducer
    selectLanguage(state, action) {
      state.selectedLanguage = action.payload;
    },
    resetLanguageState() {
      return initialState;
    },
  },
  //services reducers
  extraReducers: (builder) => {
    builder.addCase(fetchLanguageList.fulfilled, (state, { payload }) => {
      state.languageList = payload.result.items;

      if (
        state.selectedLanguage == null &&
        payload.result.items.length > 0
      ) {
        state.selectedLanguage = payload.result.items[0];
      }

      const serverError: ResponseModel = {
        resultCode: payload.resultCode,
        resultDescription: payload.resultDescription,
      };

      state.error = serverError;
      state.isLoading = false;
    }),
      builder.addCase(fetchLanguageList.pending, (state, _) => {
        state.isLoading = true;
      }),
      builder.addCase(fetchLanguageList.rejected, (state, action) => {
        if (action.payload != undefined) {
          let error: AxiosError<Error> = action.payload;

          const serverError: ResponseModel = {
            resultCode: error?.response?.status.toString() ?? "",
            resultDescription: error?.response?.data.message ?? "",
          };

          state.error = serverError;
          state.isLoading = false;
        }
      });
  },
});

export const { selectLanguage, resetLanguageState } = languageSlice.actions;

export default languageSlice.reducer;
