import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "reducers";

//Component
import {
  DataGrid,
  GridColDef,
  GridColumns,
  GridToolbarContainer,
} from "@material-ui/data-grid";
import CustomLoadingOverlay from "components/DataGridLoadingOverlay";
import CustomNoRowsOverlay from "components/DataGridNoRowsOverlay";
import { ARIA_SuccessCode, Language, LanguageSetting } from "models";
import { DataGridFilterToolbar } from "components/DataGridFilterToolbar";
import { GridColFilterDef } from "components/DataGridFilterSelection";
//Icon

//Helper
import { convertDateTimeToString } from "utils/dateHelper";
import { Data } from "react-csv/components/CommonPropTypes";
import { ExportButton } from "components/ExportButton";
import { MainDrawer } from "components/MainDrawer";
import { AccessControlButton, LanguageSelector } from "components";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import {
  PM_ADD_HOTEL_CODE,
  PM_DELETE_HOTEL_CODE,
  PM_EDIT_HOTEL_CODE,
} from "models/backend/constant/PermissionCode";
import {
  createLanguageSettingAPI,
  deleteLanguageSettingAPI,
  getLanguageSettingListAPI,
  updateLanguageSettingAPI,
} from "services/languageSettingService";
import { fetchLanguageList } from "reducers/language";

export const LanguageSettingManagementScreen = () => {
  const dispatch = useDispatch();

  const { languageList } = useSelector(
    (state: RootState) => state.languageState
  );

  const [index, setIndex] = useState<number>(1);
  const [size, setSize] = useState<number>(10);
  const [row, setRow] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isExporting, setIsExporting] = useState(false);

  const [requestDateTime, setRequestDateTime] = useState<Date>(new Date());

  const [languageSettingManagementList, setLanguageSettingList] = useState<
    LanguageSetting[]
  >([]);
  const [exportList, setExportList] = useState<Data>([]);
  const [filterList, setFilterList] = useState<GridColFilterDef[]>([]);

  const [loadList, setloadList] = useState(true);

  const [addLanguageSetting, setAddLanguageSetting] = useState<
    any | undefined
  >();
  const [editLanguageSetting, setEditLanguageSetting] = useState<
    LanguageSetting | undefined
  >();
  const [removeLanguageSetting, setRemoveLanguageSetting] = useState<
    LanguageSetting | undefined
  >();
  const [deleteConfirmation, setDeleteConfirmation] = useState("");

  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  //Setting up the each column header item
  const hotelGroupCodeHeader: GridColDef = {
    field: "languages",
    headerName: "Supported Languages",
    sortable: false,
    filterable: false,
    renderHeader: (params: any) => <strong>{"Supported Languages"}</strong>,
    valueGetter: (params) => {
      var jsonString = JSON.stringify(params.value);
      var languages: Language[] = JSON.parse(jsonString);
      var str = languages.map((h) => h.description).join(", ");
      return str;
    },
    minWidth: 300,
    flex: 2,
  };

  const hotelGroupNameHeader: GridColDef = {
    field: "name",
    headerName: "Name",
    sortable: false,
    renderHeader: (params: any) => <strong>{"Name"}</strong>,
    minWidth: 200,
    flex: 1,
  };

  const actionsHeader: GridColDef = {
    field: "id",
    headerName: "Actions",
    minWidth: 240,
    filterable: false,
    sortable: false,
    disableColumnMenu: true,
    renderHeader: () => <strong>{"Actions"}</strong>,
    renderCell: (params: any) => (
      <strong>
        <AccessControlButton
          code={PM_EDIT_HOTEL_CODE}
          variant="contained"
          color="primary"
          size="small"
          value={params.value}
          onClick={handleOpenEditDialog}
        >
          Edit
        </AccessControlButton>
        <AccessControlButton
          code={PM_ADD_HOTEL_CODE}
          variant="contained"
          color="primary"
          size="small"
          value={params.value}
          style={{ marginLeft: 12 }}
          onClick={handleDuplicateFunctionClick}
        >
          Clone
        </AccessControlButton>
        <AccessControlButton
          code={PM_DELETE_HOTEL_CODE}
          variant="contained"
          color="primary"
          size="small"
          value={params.value}
          style={{ marginLeft: 12 }}
          onClick={handleOpenDeleteDialog}
        >
          Delete
        </AccessControlButton>
      </strong>
    ),
  };

  const headerColumn: GridColumns = [
    hotelGroupNameHeader,
    hotelGroupCodeHeader,
    actionsHeader,
  ];

  React.useEffect(() => {
    dispatch(fetchLanguageList());
  }, []);

  React.useEffect(() => {
    if (loadList) {
      setIsLoading(true);
      setLanguageSettingList([]);
      setRequestDateTime(new Date());

      getLanguageSettingListAPI({
        filter: filterList,
        pageIndex: index,
        pageSize: size,
        // orderBy: "module",
      })
        .then((response) => {
          if (response.resultCode === ARIA_SuccessCode) {
            setLanguageSettingList(response.result.items);
            setRow(response.result.rowCount);
          }
        })
        .finally(() => {
          setloadList(false);
          setIsLoading(false);
        });
    }
  }, [index, size, filterList, loadList]);

  const getExportTransaction = async () => {
    setIsLoading(true);
    var _exportIndex = 1;
    var exportList: LanguageSetting[] = [];

    try {
      do {
        var response = await getLanguageSettingListAPI({
          filter: filterList,
          pageIndex: _exportIndex,
          pageSize: size,
          //   orderBy: "module",
        });

        exportList.push(...response.result.items);

        _exportIndex++;
      } while (response.result.pageCount >= _exportIndex);

      setExportList(exportList);
      setIsExporting(true);
      setIsLoading(false);
    } catch (err) {
      console.log("Oposs.. Something is wrong");
    }
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <DataGridFilterToolbar
          headerColumns={headerColumn}
          filterList={filterList}
          onFilterApply={(items) => {
            setFilterList(items.slice());
            setloadList(true);
          }}
        />
        <ExportButton
          filename="Hotel"
          data={exportList}
          headers={[
            { label: "Name", key: "name" },
            { label: "Code", key: "code" },
          ]}
          readyToExport={isExporting}
          onClick={getExportTransaction}
          onFinishDownload={() => {
            setExportList([]);
            setIsExporting(false);
          }}
        >
          Export
        </ExportButton>
        <div style={{ flexGrow: 1 }}></div>
        <AccessControlButton
          code={PM_ADD_HOTEL_CODE}
          variant="contained"
          color="primary"
          size="small"
          onClick={handleOpenCreateDialog}
        >
          New Language Setting
        </AccessControlButton>
        <div style={{ paddingLeft: "8px" }}>
          <b>Data as at </b>: {convertDateTimeToString(requestDateTime)}
        </div>
      </GridToolbarContainer>
    );
  }

  const handleOpenCreateDialog = () => {
    setOpenEditDialog(true);
  };

  const handleDuplicateFunctionClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    var _updateLanguageSetting = languageSettingManagementList.find(
      (f) => f.id === parseInt(event.currentTarget.value)
    );

    setAddLanguageSetting(_updateLanguageSetting);
    setOpenEditDialog(true);
  };

  const handleOpenEditDialog = (event: React.MouseEvent<HTMLButtonElement>) => {
    var _updateLanguageSetting = languageSettingManagementList.find(
      (f) => f.id === parseInt(event.currentTarget.value)
    );
    setEditLanguageSetting(_updateLanguageSetting);
    setOpenEditDialog(true);
  };

  const handleOpenDeleteDialog = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    var _removeLanguageSetting = languageSettingManagementList.find(
      (f) => f.id === parseInt(event.currentTarget.value)
    );
    setRemoveLanguageSetting(_removeLanguageSetting);
    setOpenDeleteDialog(true);
  };

  const handleEditOrAdd = () => {
    if (editLanguageSetting == null) {
      if (
        addLanguageSetting === null ||
        addLanguageSetting.name === null ||
        addLanguageSetting.name === "" ||
        addLanguageSetting.languages === null ||
        addLanguageSetting.languages.length === 0
      ) {
        alert(
          "Please fill up the details below to add a new language setting."
        );
      } else {
        const languageIds = addLanguageSetting.languages.map(
          (x: Language) => x.id
        );

        createLanguageSettingAPI({ ...addLanguageSetting, languageIds })
          .catch((err) => {
            alert(err);
          })
          .finally(() => {
            setloadList(true);
            handleEditClose();
          });
      }
    } else {
      if (
        editLanguageSetting.name === null ||
        editLanguageSetting.name === "" ||
        editLanguageSetting.languages === null ||
        editLanguageSetting.languages.length === 0
      ) {
        alert("Please fill up the details below to edit language setting.");
      } else {
        const languageIds = editLanguageSetting.languages.map(
          (x: Language) => x.id
        );

        updateLanguageSettingAPI({ ...editLanguageSetting, languageIds })
          .catch((err) => {
            alert(err);
          })
          .finally(() => {
            setloadList(true);
            handleEditClose();
          });
      }
    }
  };

  const handleDelete = () => {
    if (deleteConfirmation !== removeLanguageSetting?.name) {
      alert("Please key in the language setting name to confirm deletion.");
    } else {
      deleteLanguageSettingAPI({ ...removeLanguageSetting })
        .catch((err) => {
          alert(err);
        })
        .finally(() => {
          setDeleteConfirmation("");
          setloadList(true);
          handleEditClose();
        });
    }
  };

  const handleEditClose = () => {
    setAddLanguageSetting(undefined);
    setEditLanguageSetting(undefined);
    setRemoveLanguageSetting(undefined);
    setOpenEditDialog(false);
    setOpenDeleteDialog(false);
  };

  return (
    <>
      <MainDrawer title="Language Setting Management">
        {/* Add and Edit Dialog */}
        <Dialog
          open={openEditDialog}
          onClose={handleEditClose}
          aria-labelledby="form-dialog-title"
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle id="form-dialog-title">
            {editLanguageSetting != undefined
              ? "Edit Language Setting"
              : "Add Language Setting"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {editLanguageSetting != undefined
                ? "Change the detail below and save it to update language setting details, ."
                : "Fill up the details below to add a new language setting."}
            </DialogContentText>
            <form noValidate autoComplete="off">
              <div>
                <TextField
                  margin="dense"
                  id="name"
                  label="Language Setting Name"
                  type="text"
                  value={
                    editLanguageSetting != undefined
                      ? editLanguageSetting.name
                      : addLanguageSetting?.name != undefined
                      ? addLanguageSetting?.name
                      : ""
                  }
                  onChange={(e) => {
                    editLanguageSetting != undefined
                      ? setEditLanguageSetting({
                          ...editLanguageSetting,
                          name: e.currentTarget.value,
                        })
                      : setAddLanguageSetting({
                          ...addLanguageSetting,
                          name: e.currentTarget.value,
                        });
                  }}
                  fullWidth
                />
                <LanguageSelector
                  label="Supporting languages"
                  value={
                    editLanguageSetting != undefined
                      ? editLanguageSetting.languages.map((h) => h.description)
                      : addLanguageSetting?.hotels
                          ?.map((h: { description: string }) => h.description)
                          .join(",")
                  }
                  onChange={(hotels) => {
                    var _selectedLanguageList: Language[] = [];

                    const _selectedName = hotels.split(",");

                    _selectedName.forEach((desc) => {
                      var _languages = languageList.find(
                        (language) => language.description === desc
                      );

                      if (_languages != undefined) {
                        _selectedLanguageList.push(_languages);
                      }
                    });

                    editLanguageSetting != undefined
                      ? setEditLanguageSetting({
                          ...editLanguageSetting,
                          languages: _selectedLanguageList,
                        })
                      : setAddLanguageSetting({
                          ...addLanguageSetting,
                          languages: _selectedLanguageList,
                        });
                  }}
                />
              </div>
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleEditClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleEditOrAdd} color="primary">
              {editLanguageSetting != null ? `Save & Update` : `Add Hotel`}
            </Button>
          </DialogActions>
        </Dialog>

        {/* delete Dialog */}
        {removeLanguageSetting != undefined ? (
          <Dialog
            open={openDeleteDialog}
            onClose={handleEditClose}
            aria-labelledby="form-dialog-title"
            fullWidth
            maxWidth="sm"
          >
            <DialogTitle id="form-dialog-title">
              Delete Language Setting
            </DialogTitle>
            <DialogContent>
              <form noValidate autoComplete="off">
                <div>
                  <TextField
                    margin="dense"
                    id="name"
                    label="Languag Setting Name"
                    type="text"
                    disabled
                    value={removeLanguageSetting.name}
                    fullWidth
                  />

                  <LanguageSelector
                    label="Supporting languages"
                    disabled
                    value={
                      removeLanguageSetting != undefined
                        ? removeLanguageSetting.languages.map(
                            (h) => h.description
                          )
                        : []
                    }
                  />
                  <DialogContentText
                    color="textPrimary"
                    style={{ marginTop: "18px" }}
                  >
                    {`Are you sure you want to delete ${removeLanguageSetting.name}?`}
                  </DialogContentText>
                  <DialogContentText>
                    Please type the following to confirm:
                  </DialogContentText>
                  <DialogContentText>
                    <i>
                      <b>{removeLanguageSetting.name}</b>
                    </i>
                  </DialogContentText>
                  <TextField
                    margin="dense"
                    variant="outlined"
                    id="name"
                    type="text"
                    value={deleteConfirmation}
                    onChange={(e) =>
                      setDeleteConfirmation(e.currentTarget.value)
                    }
                    fullWidth
                  />
                </div>
              </form>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleEditClose} color="primary">
                Cancel
              </Button>
              <Button onClick={handleDelete} color="primary">
                {`Delete`}
              </Button>
            </DialogActions>
          </Dialog>
        ) : null}

        {/* Data Grid */}
        <div style={{ height: "80vh" }}>
          <div style={{ display: "flex", height: "100%" }}>
            <div style={{ flexGrow: 1 }}>
              <DataGrid
                pageSize={size}
                rowsPerPageOptions={[10, 20, 30]}
                onPageSizeChange={(size) => {
                  setSize(size);
                  setloadList(true);
                }}
                disableColumnMenu
                density="compact"
                components={{
                  LoadingOverlay: CustomLoadingOverlay,
                  NoRowsOverlay: CustomNoRowsOverlay,
                  Toolbar: CustomToolbar,
                }}
                pagination
                loading={isLoading}
                paginationMode="server"
                rowCount={row}
                filterMode="server"
                onPageChange={(newPage) => {
                  setIndex(++newPage);
                  setloadList(true);
                }}
                rows={languageSettingManagementList}
                columns={headerColumn}
                getRowId={(row) => row.id}
              />
            </div>
          </div>
        </div>
      </MainDrawer>
    </>
  );
};
