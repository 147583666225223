import {
  CreateLanguageSettingRequestModel,
  CreateLanguageSettingResponseModel,
  DeleteLanguageSettingRequestModel,
  DeleteLanguageSettingResponseModel,
  GetLanguageSettingRequestModel,
  GetLanguageSettingResponseModel,
  GetLanguageSettingsRequestModel,
  GetLanguageSettingsResponseModel,
  UpdateLanguageSettingRequestModel,
  UpdateLanguageSettingResponseModel,
} from "models";
import { getServerFilter } from "utils/filterHelper";
import {
  getApiKeyForHotel,
  getLoginUser,
  privateHttpClient,
} from "utils/httpClient";

export const createLanguageSettingAPI = async (
  request: CreateLanguageSettingRequestModel
): Promise<CreateLanguageSettingResponseModel> => {
  const { data } = await privateHttpClient.post(
    "/api/languageSetting",
    {
      ...request,
    },
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${getLoginUser().token}`,
        ApiKey: getApiKeyForHotel(),
      },
    }
  );

  return data;
};

export const getLanguageSettingAPI = async (
  request: GetLanguageSettingRequestModel
): Promise<GetLanguageSettingResponseModel> => {
  const { data } = await privateHttpClient.get(
    `/api/languageSetting/${request.id}`,
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${getLoginUser().token}`,
        ApiKey: getApiKeyForHotel(),
      },
    }
  );

  return data;
};

export const getLanguageSettingListAPI = async (
  request: GetLanguageSettingsRequestModel
): Promise<GetLanguageSettingsResponseModel> => {
  const { data } = await privateHttpClient.get("/api/languageSetting", {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${getLoginUser().token}`,
      ApiKey: getApiKeyForHotel(),
    },
    params: {
      ...request,
      filter: getServerFilter(request?.filter),
    },
  });

  return data;
};

export const updateLanguageSettingAPI = async (
  request: UpdateLanguageSettingRequestModel
): Promise<UpdateLanguageSettingResponseModel> => {
  const { data } = await privateHttpClient.put(
    "/api/languageSetting",
    {
      ...request,
    },
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${getLoginUser().token}`,
        ApiKey: getApiKeyForHotel(),
      },
    }
  );

  return data;
};

export const deleteLanguageSettingAPI = async (
  request: DeleteLanguageSettingRequestModel
): Promise<DeleteLanguageSettingResponseModel> => {
  const { data } = await privateHttpClient.delete("/api/languageSetting", {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${getLoginUser().token}`,
      ApiKey: getApiKeyForHotel(),
    },
    data: {
      ...request,
    },
  });

  return data;
};
