import {
  CreateFunctionRequestModel,
  CreateFunctionResponseModel,
  DeleteFunctionRequestModel,
  DeleteFunctionResponseModel,
  GetFunctionRequestModel,
  GetFunctionResponseModel,
  GetFunctionsRequestModel,
  GetFunctionsResponseModel,
  UpdateFunctionRequestModel,
  UpdateFunctionResponseModel,
} from "models";
import { getServerFilter } from "utils/filterHelper";
import {
  getApiKeyForHotel,
  getLoginUser,
  privateHttpClient,
} from "utils/httpClient";

export const createFunctionAPI = async (
  request: CreateFunctionRequestModel
): Promise<CreateFunctionResponseModel> => {
  const { data } = await privateHttpClient.post(
    "/api/function",
    {
      ...request,
    },
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${getLoginUser().token}`,
        ApiKey: getApiKeyForHotel(),
      },
    }
  );

  return data;
};

export const getFunctionAPI = async (
  request: GetFunctionRequestModel
): Promise<GetFunctionResponseModel> => {
  const { data } = await privateHttpClient.get(`/api/function/${request.id}`, {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${getLoginUser().token}`,
      ApiKey: getApiKeyForHotel(),
    },
  });

  return data;
};

export const getFunctionListAPI = async (
  request: GetFunctionsRequestModel
): Promise<GetFunctionsResponseModel> => {
  const { data } = await privateHttpClient.get("/api/function", {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${getLoginUser().token}`,
      ApiKey: getApiKeyForHotel(),
    },
    params: {
      ...request,
      filter: getServerFilter(request?.filter),
    },
  });

  return data;
};

export const updateFunctionAPI = async (
  request: UpdateFunctionRequestModel
): Promise<UpdateFunctionResponseModel> => {
  const { data } = await privateHttpClient.put(
    "/api/function",
    {
      ...request,
    },
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${getLoginUser().token}`,
        ApiKey: getApiKeyForHotel(),
      },
    }
  );

  return data;
};

export const deleteFunctionAPI = async (
  request: DeleteFunctionRequestModel
): Promise<DeleteFunctionResponseModel> => {
  const { data } = await privateHttpClient.delete("/api/function", {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${getLoginUser().token}`,
      ApiKey: getApiKeyForHotel(),
    },
    data: {
      ...request,
    },
  });

  return data;
};
