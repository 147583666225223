import {
  CreateRoomTypeRequestModel,
  CreateRoomTypeResponseModel,
  DeleteRoomTypeRequestModel,
  DeleteRoomTypeResponseModel,
  GetRoomTypeRequestModel,
  GetRoomTypeResponseModel,
  GetRoomTypesRequestModel,
  GetRoomTypesResponseModel,
  UpdateRoomTypeRequestModel,
  UpdateRoomTypeResponseModel,
} from "models";
import { getServerFilter } from "utils/filterHelper";
import {
  getApiKeyForHotel,
  getLoginUser,
  privateHttpClient,
} from "utils/httpClient";

export const createRoomTypeAPI = async (
  request: CreateRoomTypeRequestModel
): Promise<CreateRoomTypeResponseModel> => {
  const { data } = await privateHttpClient.post(
    "/api/roomType",
    {
      ...request,
    },
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${getLoginUser().token}`,
        ApiKey: getApiKeyForHotel(),
      },
    }
  );

  return data;
};

export const getRoomTypeAPI = async (
  request: GetRoomTypeRequestModel
): Promise<GetRoomTypeResponseModel> => {
  const { data } = await privateHttpClient.get(`/api/roomType/${request.id}`, {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${getLoginUser().token}`,
      ApiKey: getApiKeyForHotel(),
    },
  });

  return data;
};

export const getRoomTypeListAPI = async (
  request: GetRoomTypesRequestModel
): Promise<GetRoomTypesResponseModel> => {
  const { data } = await privateHttpClient.get("/api/roomType", {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${getLoginUser().token}`,
      ApiKey: getApiKeyForHotel(),
    },
    params: {
      ...request,
      filter: getServerFilter(request?.filter),
    },
  });

  return data;
};

export const updateRoomTypeAPI = async (
  request: UpdateRoomTypeRequestModel
): Promise<UpdateRoomTypeResponseModel> => {
  const { data } = await privateHttpClient.put(
    "/api/roomType",
    {
      ...request,
    },
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${getLoginUser().token}`,
        ApiKey: getApiKeyForHotel(),
      },
    }
  );

  return data;
};

export const deleteRoomTypeAPI = async (
  request: DeleteRoomTypeRequestModel
): Promise<DeleteRoomTypeResponseModel> => {
  const { data } = await privateHttpClient.delete("/api/roomType", {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${getLoginUser().token}`,
      ApiKey: getApiKeyForHotel(),
    },
    data: {
      ...request,
    },
  });

  return data;
};
