import React, { useState } from "react";
// @material-ui/core components
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { ARIA_SuccessCode, Component, Kiosk, PM_EDIT_COMPONENT } from "models";
import { DataGrid, GridColDef, GridColumns } from "@material-ui/data-grid";
import CustomLoadingOverlay from "components/DataGridLoadingOverlay";
import CustomNoRowsOverlay from "components/DataGridNoRowsOverlay";
import { ColoredCircle } from "components/ColoredCircle";
import { AccessControlButton } from "components/AccessControlButton";
import { TextField } from "@material-ui/core";
import {
  getComponentAPI,
  updateComponentAPI,
} from "services/componentServices";
import { CodeMirrorDialog } from "components/CodeMirrorDialog";
import { useSelector } from "react-redux";
import { RootState } from "reducers";
import { ConfigurationDialog } from "components/ConfigurationDialog";

interface IProps {
  open: boolean;
  kiosk: Kiosk;
  onCancelClicked?: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

export const KioskComponentDialog: React.FC<IProps> = ({
  open,
  kiosk,
  onCancelClicked,
}) => {
  const { role } = useSelector((state: RootState) => state.roleState);

  const [editingComponentConfig, setEditingComponentConfig] = useState<
    Component | undefined
  >(undefined);

  const [editingValue, setEditingValue] = React.useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [openEditConfigDialog, setOpenEditConfigDialog] = useState(false);
  const [openProEditConfigDialog, setOpenProEditConfigDialog] = useState(false);

  const componentNameHeader: GridColDef = {
    field: "name",
    headerName: "Name",
    renderHeader: (params: any) => <strong>{"Kiosk Name"}</strong>,
    minWidth: 240,
    flex: 1,
  };

  const kioskIsOnlineHeader: GridColDef = {
    field: "isOnline",
    headerName: "Status",
    align: "center",
    headerAlign: "center",
    sortable: false,
    filterable: false,
    renderHeader: () => <strong>{"Status"}</strong>,
    renderCell: (params: any) => {
      if (params.value) {
        return <ColoredCircle color="#297373" />;
      } else {
        return <ColoredCircle color="#E94F37" />;
      }
    },
    minWidth: 40,
  };

  const kioskIsOnMaintaineceHeader: GridColDef = {
    field: "isOnMaintenanceMode",
    headerName: "Maintenance Mode",
    align: "center",
    headerAlign: "center",
    sortable: false,
    filterable: false,
    renderHeader: () => <strong>{"Maintenance Mode"}</strong>,
    renderCell: (params: any) => {
      if (params.value) {
        return <ColoredCircle color="#297373" />;
      } else {
        return <ColoredCircle color="#E94F37" />;
      }
    },
    minWidth: 160,
  };

  const actionsHeader: GridColDef = {
    field: "id",
    headerName: "Actions",
    minWidth: 80,
    align: "center",
    headerAlign: "center",
    filterable: false,
    sortable: false,
    disableColumnMenu: true,
    renderHeader: () => <strong>{"Actions"}</strong>,
    renderCell: (params: any) => (
      <strong>
        <AccessControlButton
          code={PM_EDIT_COMPONENT}
          variant="contained"
          color="primary"
          size="small"
          value={params.value}
          onClick={handleOpenEditDialog}
        >
          Edit Config
        </AccessControlButton>
      </strong>
    ),
  };

  const headerWithEditColumn: GridColumns = [
    componentNameHeader,
    kioskIsOnlineHeader,
    kioskIsOnMaintaineceHeader,
    actionsHeader,
  ];

  const headerWithoutEditColumn: GridColumns = [
    componentNameHeader,
    kioskIsOnlineHeader,
    kioskIsOnMaintaineceHeader,
  ];

  const handleOpenEditDialog = (event: React.MouseEvent<HTMLButtonElement>) => {
    console.log(parseInt(event.currentTarget.value));
    setIsLoading(true);

    getComponentAPI({
      id: parseInt(event.currentTarget.value),
    })
      .then((response) => {
        if (response.resultCode == ARIA_SuccessCode) {
          if(response.result.configValue != null) {
            response.result.configValue = JSON.stringify(JSON.parse(response.result.configValue), null, 2);

            setEditingComponentConfig(response.result);
            setOpenEditConfigDialog(true);
          } else {
            response.result.configValue = "";
            setEditingComponentConfig(response.result);
            setOpenProEditConfigDialog(true)
          }

        } else {
          alert(response.resultDescription);
        }
      })
      .catch((err) => {
        alert(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSaveConfiguration = () => {
    //find the editing configuration
    if (editingComponentConfig != undefined) {
      try {
        updateComponentAPI({
          ...editingComponentConfig,
          componentTypeId: editingComponentConfig.type.id,
          configValue: openProEditConfigDialog
            ? JSON.stringify(JSON.parse(editingValue))
            : JSON.stringify(JSON.parse(editingComponentConfig.configValue)),
        })
          .then((response) => {
            if (response.resultCode === ARIA_SuccessCode) {
              setOpenEditConfigDialog(false);
              setOpenProEditConfigDialog(false);
              setEditingComponentConfig(undefined);
            }
          })
          .catch((err) => {
            alert(err);
          });
      } catch (err) {
        alert("JSON Syntax Error!!");
      }
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onCancelClicked}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle id="form-dialog-title">{`Kiosk Devices - ${kiosk.name} @ ${
        kiosk.location != undefined ? kiosk.location?.name : ""
      }`}</DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          id="location"
          label="Location"
          type="text"
          multiline
          value={kiosk.location != undefined ? kiosk.location.name : "-"}
          disabled
          fullWidth
        />

        {/* Data Grid */}
        <div style={{ marginTop: "10px", height: "30vh" }}>
          <div style={{ display: "flex", height: "100%" }}>
            <div style={{ flexGrow: 1 }}>
              <DataGrid
                pageSize={kiosk.components.length}
                disableColumnMenu
                density="compact"
                components={{
                  LoadingOverlay: CustomLoadingOverlay,
                  NoRowsOverlay: CustomNoRowsOverlay,
                }}
                pagination
                paginationMode="server"
                loading={isLoading}
                rowCount={kiosk.components.length}
                hideFooterSelectedRowCount={true}
                filterMode="server"
                rows={kiosk.components}
                columns={
                  role?.functions.some(
                    (func) => func.code === PM_EDIT_COMPONENT
                  )
                    ? headerWithEditColumn
                    : headerWithoutEditColumn
                }
                getRowId={(row) => row.id}
              />
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancelClicked} color="primary">
          Cancel
        </Button>
      </DialogActions>

      {/* Edit Component Config */}
      {editingComponentConfig != undefined && openEditConfigDialog ? (
        <>
          <ConfigurationDialog
            open={openEditConfigDialog}
            configTitle={editingComponentConfig.name}
            editingConfigInJson={editingComponentConfig.configValue}
            onHandleCloseClick={() => setOpenEditConfigDialog(false)}
            onHandleSaveClick={handleSaveConfiguration}
            onChange={(value: string) => {
              setEditingComponentConfig({
                ...editingComponentConfig,
                configValue: value,
              });
            }}
            onHandleProClick={() => setOpenProEditConfigDialog(true)}
          />
        </>
      ) : null}

      {editingComponentConfig != undefined && openProEditConfigDialog ? (
        <CodeMirrorDialog
          open={openProEditConfigDialog}
          title={editingComponentConfig.name}
          editingContent={editingComponentConfig.configValue}
          onChange={(value) => setEditingValue(value)}
          onHandleCloseClick={() => setOpenProEditConfigDialog(false)}
          onHandleSaveClick={handleSaveConfiguration}
          onHandleBeautifyClick={() => {
            setEditingValue(JSON.stringify(JSON.parse(editingValue), null, 2));
          }}
        />
      ) : null}
    </Dialog>
  );
};
