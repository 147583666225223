import {
  CreateFeatureRequestModel,
  CreateFeatureResponseModel,
  DeleteFeatureRequestModel,
  DeleteFeatureResponseModel,
  GetFeatureRequestModel,
  GetFeatureResponseModel,
  GetFeaturesRequestModel,
  GetFeaturesResponseModel,
  UpdateFeatureRequestModel,
  UpdateFeatureResponseModel,
} from "models";
import { getServerFilter } from "utils/filterHelper";
import {
  getApiKeyForHotel,
  getLoginUser,
  privateHttpClient,
} from "utils/httpClient";

export const createFeatureAPI = async (
  request: CreateFeatureRequestModel
): Promise<CreateFeatureResponseModel> => {
  const { data } = await privateHttpClient.post(
    "/api/feature",
    {
      ...request,
    },
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${getLoginUser().token}`,
        ApiKey: getApiKeyForHotel(),
      },
    }
  );

  return data;
};

export const getFeatureAPI = async (
  request: GetFeatureRequestModel
): Promise<GetFeatureResponseModel> => {
  const { data } = await privateHttpClient.get(`/api/feature/${request.id}`, {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${getLoginUser().token}`,
      ApiKey: getApiKeyForHotel(),
    },
  });

  return data;
};

export const getFeatureListAPI = async (
  request: GetFeaturesRequestModel
): Promise<GetFeaturesResponseModel> => {
  const { data } = await privateHttpClient.get("/api/feature", {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${getLoginUser().token}`,
      ApiKey: getApiKeyForHotel(),
    },
    params: {
      ...request,
      filter: getServerFilter(request?.filter),
    },
  });

  return data;
};

export const updateFeatureAPI = async (
  request: UpdateFeatureRequestModel
): Promise<UpdateFeatureResponseModel> => {
  const { data } = await privateHttpClient.put(
    "/api/feature",
    {
      ...request,
    },
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${getLoginUser().token}`,
        ApiKey: getApiKeyForHotel(),
      },
    }
  );

  return data;
};

export const deleteFeatureAPI = async (
  request: DeleteFeatureRequestModel
): Promise<DeleteFeatureResponseModel> => {
  const { data } = await privateHttpClient.delete("/api/feature", {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${getLoginUser().token}`,
      ApiKey: getApiKeyForHotel(),
    },
    data: {
      ...request,
    },
  });

  return data;
};
