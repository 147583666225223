import { PageNotFound } from "pages/404";
import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router";
import { RootState } from "reducers";
import { useAuth } from "utils/authUser";
// @material-ui/core components

interface IProps {
  children: JSX.Element;
}

export const RequireAuth: React.FC<IProps> = ({ children }) => {
  let { user } = useAuth();
  const { role } = useSelector((state: RootState) => state.roleState);

  if (user == undefined) {
    return <Navigate to={"/"} />;
  } else if (
    user != undefined &&
    user.token != undefined &&
    (role?.menus
      .filter((x) => x.path !== null && x.path !== "")
      .some((menu) => window.location.href.includes(menu.path!!)) ||
      window.location.href.includes("/admin/dashboard"))
  ) {
    return children;
  } else {
    return <PageNotFound />;
  }
};
