import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "reducers";

//Component
import {
  DataGrid,
  GridColDef,
  GridColumns,
  GridToolbarContainer,
} from "@material-ui/data-grid";
import CustomLoadingOverlay from "components/DataGridLoadingOverlay";
import CustomNoRowsOverlay from "components/DataGridNoRowsOverlay";
import {
  ARIA_SuccessCode,
  AppUser,
  HotelSummary,
  RoleSummary,
  ID_HOST,
  Hotel,
  ARIA_INVALID_PASSWORD,
  AppUserResetPassword,
} from "models";
import { DataGridFilterToolbar } from "components/DataGridFilterToolbar";
import { GridColFilterDef } from "components/DataGridFilterSelection";
//Icon

//Helper
import { convertDateTimeToString } from "utils/dateHelper";
import { Data } from "react-csv/components/CommonPropTypes";
import { ExportButton } from "components/ExportButton";
import { MainDrawer } from "components/MainDrawer";
import { AccessControlButton, ColoredCircle, GridContainer } from "components";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { resetPasswordAPI, signUpTenantUserAPI } from "services/accountService";
import {
  getAppUserListAPI,
  updateAppUserAPI,
  updateAppUserStatusAPI,
} from "services/appUserServices";
import { HotelSelector } from "components/HotelSelector";
import { fetchRoleList } from "reducers/role";
import {
  PM_ADD_USER_CODE,
  PM_CHANGE_USER_STATUS_CODE,
  PM_EDIT_USER_CODE,
  PM_RESET_USER_PASSWORD_CODE,
} from "models/backend/constant/PermissionCode";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { GRID_FILTER_TYPE_BOOLEAN, GRID_FILTER_TYPE_IDENTITYROLE } from "components/DataGridFilterSelection/type";

export const AppUserManagementScreen = () => {
  const dispatch = useDispatch();

  const { selectedHotel, hotelList } = useSelector(
    (state: RootState) => state.hotelState
  );
  const { hotelGroupList } = useSelector(
    (state: RootState) => state.hotelGroupState
  );
  const { roleList, role } = useSelector((state: RootState) => state.roleState);

  const [index, setIndex] = useState<number>(1);
  const [size, setSize] = useState<number>(10);
  const [row, setRow] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isExporting, setIsExporting] = useState(false);

  const [requestDateTime, setRequestDateTime] = useState<Date>(new Date());

  const [appUserList, setAppUserList] = useState<AppUser[]>([]);
  const [exportList, setExportList] = useState<Data>([]);
  const [filterList, setFilterList] = useState<GridColFilterDef[]>([]);

  const [loadList, setloadList] = useState(true);

  const [addUser, setAddUser] = useState<any | undefined>();
  const [editUser, setEditUser] = useState<AppUser | undefined>();
  const [removeUser, setRemoveUser] = useState<AppUser | undefined>();
  const [resetPasswordUser, setResetPasswordUser] = useState<
    AppUserResetPassword | undefined
  >();
  const [deleteConfirmation, setDeleteConfirmation] = useState("");

  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openResetPasswordDialog, setOpenResetPasswordDialog] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  //Setting up the each column header item
  const appUserNameHeader: GridColDef = {
    field: "name",
    headerName: "Name",
    sortable: false,
    renderHeader: () => <strong>{"Name"}</strong>,
    minWidth: 300,
    flex: 1,
  };

  const appUserHotelsHeader: GridColDef = {
    field: "hotels",
    headerName: "Authorised Hotels",
    sortable: false,
    type: "object",
    description: "hotels/name",
    filterable: false,
    renderHeader: () => <strong>{"Authorised Hotels"}</strong>,
    valueGetter: (params) => {
      var jsonString = JSON.stringify(params.value);
      var hotels: HotelSummary[] = JSON.parse(jsonString);
      var str = hotels.map((h) => h.name).join(", ");
      return str;
    },
    minWidth: 300,
    flex: 1,
  };

  const appUserActiveHeader: GridColDef = {
    field: "isActive",
    headerName: "Status",
    sortable: false,
    type: GRID_FILTER_TYPE_BOOLEAN,
    renderHeader: () => <strong>{"Status"}</strong>,
    renderCell: (params: any) => {
      if (params.value) {
        return <ColoredCircle color="#297373" />;
      } else {
        return <ColoredCircle color="#E94F37" />;
      }
    },
    minWidth: 40,
  };

  const appUserRoleHeader: GridColDef = {
    field: "role",
    headerName: "Role",
    sortable: false,
    filterable: false,
    renderHeader: () => <strong>{"Role"}</strong>,
    valueGetter: (params) => {
      var jsonString = JSON.stringify(params.value);
      var role: RoleSummary = JSON.parse(jsonString);

      var strRole = "-";

      if (role != undefined) {
        strRole = role.name;
        if (role.hotelGroupId > 0 && hotelGroupList.length > 0) {
          strRole =
            strRole +
            ` (${
              hotelGroupList.find((x) => x.id === role.hotelGroupId)?.name
            })`;
        }
      }

      return strRole;
    },
    minWidth: 300,
    flex: 1,
  };

  const appUserIdentityRoleHeader: GridColDef = {
    field: "identityRoles",
    headerName: "Identity",
    sortable: false,
    type: GRID_FILTER_TYPE_IDENTITYROLE,
    renderHeader: () => <strong>{"Identity"}</strong>,
    width: 100,
  };

  const actionsHeader: GridColDef = {
    field: "id",
    headerName: "Actions",
    minWidth: 220,
    filterable: false,
    sortable: false,
    disableColumnMenu: true,
    renderHeader: () => <strong>{"Actions"}</strong>,
    renderCell: (params: any) => (
      <strong>
        <AccessControlButton
          code={PM_EDIT_USER_CODE}
          variant="contained"
          color="primary"
          size="small"
          value={params.value}
          onClick={handleOpenEditDialog}
        >
          Edit
        </AccessControlButton>
        {/* <AccessControlButton
          code={PM_ADD_USER_CODE}
          variant="contained"
          color="primary"
          size="small"
          value={params.value}
          style={{ marginLeft: 12 }}
          onClick={handleDuplicateFunctionClick}
        >
          Clone
        </AccessControlButton> */}
        <AccessControlButton
          code={PM_RESET_USER_PASSWORD_CODE}
          variant="contained"
          color="primary"
          size="small"
          value={params.value}
          style={{ marginLeft: 12 }}
          onClick={handleOpenResetPasswordDialog}
        >
          Reset Password
        </AccessControlButton>
      </strong>
    ),
  };

  const headerColumnForHost: GridColumns = [
    appUserNameHeader,
    appUserHotelsHeader,
    appUserRoleHeader,
    appUserIdentityRoleHeader,
    appUserActiveHeader,
    actionsHeader,
  ];

  const headerColumnForTenant: GridColumns = [
    appUserNameHeader,
    appUserHotelsHeader,
    appUserRoleHeader,
    appUserActiveHeader,
    actionsHeader,
  ];

  React.useEffect(() => {
    dispatch(fetchRoleList());
  }, []);

  React.useEffect(() => {
    if (selectedHotel != undefined && loadList) {
      setIsLoading(true);
      setAppUserList([]);
      setRequestDateTime(new Date());

      getAppUserListAPI({
        filter: filterList,
        pageIndex: index,
        pageSize: size,
        orderBy: "identityRoles, name",
      })
        .then((response) => {
          if (response.resultCode === ARIA_SuccessCode) {
            setAppUserList(response.result.items);
            setRow(response.result.rowCount);
          }
        })
        .finally(() => {
          setloadList(false);
          setIsLoading(false);
        });
    }
  }, [index, size, filterList, selectedHotel, loadList]);

  const getExportTransaction = async () => {
    setIsLoading(true);
    var _exportIndex = 1;
    var exportList: AppUser[] = [];

    try {
      do {
        var response = await getAppUserListAPI({
          filter: filterList,
          pageIndex: _exportIndex,
          pageSize: size,
          //   orderBy: "transactionDate desc",
        });

        exportList.push(...response.result.items);

        _exportIndex++;
      } while (response.result.pageCount >= _exportIndex);

      setExportList(exportList);
      setIsExporting(true);
      setIsLoading(false);
    } catch (err) {
      console.log("Oposs.. Something is wrong");
    }
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <DataGridFilterToolbar
          headerColumns={
            role?.identityRoles === ID_HOST
              ? headerColumnForHost
              : headerColumnForTenant
          }
          filterList={filterList}
          onFilterApply={(items) => {
            setFilterList(items.slice());
            setloadList(true);
          }}
        />
        <ExportButton
          filename="Users"
          data={exportList}
          headers={[
            { label: "Name", key: "name" },
            { label: "Code", key: "code" },
            { label: "Module", key: "module" },
            { label: "Method", key: "httpMethod" },
            { label: "Path", key: "path" },
          ]}
          readyToExport={isExporting}
          onClick={getExportTransaction}
          onFinishDownload={() => {
            setExportList([]);
            setIsExporting(false);
          }}
        >
          Export
        </ExportButton>
        <div style={{ flexGrow: 1 }}></div>
        <AccessControlButton
          code={PM_ADD_USER_CODE}
          variant="contained"
          color="primary"
          size="small"
          onClick={handleOpenCreateDialog}
        >
          New Users
        </AccessControlButton>
        <div style={{ paddingLeft: "8px" }}>
          <b>Data as at </b>: {convertDateTimeToString(requestDateTime)}
        </div>
      </GridToolbarContainer>
    );
  }

  const handleOpenCreateDialog = () => {
    setOpenEditDialog(true);
  };

  const handleOpenResetPasswordDialog = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    var _resetPasswordUser = appUserList.find(
      (f) => f.id === parseInt(event.currentTarget.value)
    );

    setResetPasswordUser(_resetPasswordUser);
    setOpenResetPasswordDialog(true);
  };

  // const handleDuplicateFunctionClick = (
  //   event: React.MouseEvent<HTMLButtonElement>
  // ) => {
  //   var _updateUser = appUserList.find(
  //     (f) => f.id === parseInt(event.currentTarget.value)
  //   );

  //   setAddUser(_updateUser);
  //   setOpenEditDialog(true);
  // };

  const handleOpenEditDialog = (event: React.MouseEvent<HTMLButtonElement>) => {
    var updateUser = appUserList.find(
      (f) => f.id === parseInt(event.currentTarget.value)
    );

    setEditUser(updateUser);
    setOpenEditDialog(true);
  };

  // const handleOpenDeleteDialog = (
  //   event: React.MouseEvent<HTMLButtonElement>
  // ) => {
  //   var _removeFunction = appUserList.find(
  //     (f) => f.id === parseInt(event.currentTarget.value)
  //   );
  //   setRemoveUser(_removeFunction);
  //   setOpenDeleteDialog(true);
  // };

  const handleChangeUserStatus = () => {
    if (editUser != undefined) {
      updateAppUserStatusAPI({ id: editUser.id, isActive: !editUser.isActive })
        .catch((err) => {
          console.log(err);
          alert(err.data);
        })
        .finally(() => {
          setloadList(true);
          handleEditClose();
        });
    }
  };

  const handleEditOrAdd = () => {
    if (editUser == undefined) {
      if (
        addUser == undefined ||
        addUser.username == undefined ||
        addUser.username === "" ||
        addUser.role == undefined ||
        addUser.hotels == undefined ||
        addUser.hotels.length < 0
      ) {
        alert("Please fill up the details below to add a new user.");
      } else {
        const hotelIds = addUser.hotels.map((x: Hotel) => x.id);

        signUpTenantUserAPI({
          username: addUser.username,
          password: addUser.password,
          hotelIds,
          roleId: addUser.role.id,
        })
          .then((response) => {
            if (response.resultCode === ARIA_SuccessCode) {
              setloadList(true);
              handleEditClose();
            } else if (response.resultCode === ARIA_INVALID_PASSWORD) {
              alert(response.resultDescription);
            }
          })
          .catch((err) => {
            alert(err);
          });
      }
    } else {
      if (
        editUser.name == undefined ||
        editUser.name === "" ||
        editUser.identityRoles == undefined ||
        editUser.identityRoles === "" ||
        editUser.role == undefined ||
        editUser.hotels == undefined ||
        editUser.hotels.length < 0
      ) {
        alert("Please fill up the details below to edit user.");
      } else {
        const hotelIds = editUser.hotels.map((x) => x.id);

        updateAppUserAPI({ ...editUser, hotelIds, roleId: editUser.role.id })
          .catch((err) => {
            alert(err);
          })
          .finally(() => {
            setloadList(true);
            handleEditClose();
          });
      }
    }
  };

  const handleResetPassword = () => {
    if (
      resetPasswordUser != undefined &&
      resetPasswordUser.newPassword !== undefined &&
      resetPasswordUser.newPassword !== ""
    ) {
      resetPasswordAPI({
        userId: resetPasswordUser.guid,
        newPassword: resetPasswordUser.newPassword,
      })
        .catch((err) => {
          alert(err);
        })
        .finally(() => {
          setloadList(true);
          handleEditClose();
        });
    } else {
      alert("Please fill up the details below to reset user password.");
    }
  };

  const handleDelete = () => {
    if (deleteConfirmation !== removeUser?.name) {
      alert("Please key in the user name to confirm deletion.");
    } else {
      updateAppUserStatusAPI({ id: removeUser.id, isActive: false })
        // deleteAppUserAPI({ ...removeUser })
        .catch((err) => {
          alert(err);
        })
        .finally(() => {
          setDeleteConfirmation("");
          setloadList(true);
          handleEditClose();
        });
    }
  };

  const handleEditClose = () => {
    setOpenEditDialog(false);
    setOpenDeleteDialog(false);
    setOpenResetPasswordDialog(false);
    setAddUser(undefined);
    setEditUser(undefined);
    setRemoveUser(undefined);
    setResetPasswordUser(undefined);
  };

  return (
    <>
      <MainDrawer title="User Management">
        {/* Add and Edit Dialog */}
        <Dialog
          open={openEditDialog}
          onClose={handleEditClose}
          aria-labelledby="form-dialog-title"
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle id="form-dialog-title">
            {editUser != undefined ? "Edit User" : "Add User"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {editUser != undefined
                ? "Change the detail below and save it to update user details, ."
                : "Fill up the details below to add a new user."}
            </DialogContentText>
            <form noValidate autoComplete="off">
              <div>
                <TextField
                  margin="dense"
                  id="name"
                  label="Username"
                  type="text"
                  disabled={editUser != undefined}
                  value={
                    editUser != undefined
                      ? editUser.name
                      : addUser != undefined && addUser.username != undefined
                      ? addUser.username
                      : ""
                  }
                  onChange={(e) => {
                    editUser != undefined
                      ? setEditUser({
                          ...editUser,
                          name: e.currentTarget.value,
                        })
                      : setAddUser({
                          ...addUser,
                          username: e.currentTarget.value,
                        });
                  }}
                  fullWidth
                />
                {editUser == undefined ? (
                  <TextField
                    margin="dense"
                    id="password"
                    label="Password"
                    type={showPassword ? "text" : "password"}
                    autoComplete="new-password"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                            // onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? (
                              <VisibilityIcon />
                            ) : (
                              <VisibilityOffIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    value={
                      addUser != undefined && addUser.password != undefined
                        ? addUser.password
                        : ""
                    }
                    onChange={(e) => {
                      setAddUser({
                        ...addUser,
                        password: e.currentTarget.value,
                      });
                    }}
                    fullWidth
                  />
                ) : null}
                <TextField
                  margin="dense"
                  id="role"
                  label="Role"
                  select
                  value={
                    editUser != undefined
                      ? editUser.role != undefined
                        ? editUser.role?.id
                        : ""
                      : addUser != undefined && addUser.role != undefined
                      ? addUser.role.id
                      : ""
                  }
                  onChange={(e) => {
                    var role = roleList.find(
                      (x) => x.id == parseInt(e.target.value)
                    );

                    editUser != undefined
                      ? setEditUser({
                          ...editUser,
                          role: role,
                        })
                      : setAddUser({
                          ...addUser,
                          role: role,
                        });
                  }}
                  fullWidth
                >
                  {roleList.map((role, index) => (
                    <MenuItem key={index} value={role.id}>
                      {role.hotelGroupId != 0 && hotelGroupList.length > 0
                        ? `${role.name} (${
                            hotelGroupList.find(
                              (x) => x.id === role.hotelGroupId
                            )?.name
                          })`
                        : role.name}
                    </MenuItem>
                  ))}
                </TextField>

                <HotelSelector
                  label="Authorised Hotels"
                  value={
                    editUser != undefined
                      ? editUser.hotels.map((h) => h.name)
                      : addUser?.hotels
                          ?.map((h: { name: string }) => h.name)
                          .join(",")
                  }
                  onChange={(hotels) => {
                    var _selectedHotelList: HotelSummary[] = [];

                    const _selectedName = hotels.split(",");

                    _selectedName.forEach((name) => {
                      var _hotel = hotelList.find(
                        (hotel) => hotel.name === name
                      );

                      if (_hotel != undefined) {
                        _selectedHotelList.push(_hotel);
                      }
                    });

                    editUser != undefined
                      ? setEditUser({
                          ...editUser,
                          hotels: _selectedHotelList,
                        })
                      : setAddUser({
                          ...addUser,
                          hotels: _selectedHotelList,
                        });
                  }}
                />
                {editUser != undefined && editUser.isActive ? (
                  <GridContainer
                    style={{ marginTop: "8px", marginLeft: "-12px" }}
                    justifyContent="flex-start"
                  >
                    <AccessControlButton
                      code={PM_CHANGE_USER_STATUS_CODE}
                      variant="outlined"
                      color="secondary"
                      size="small"
                      value={editUser.id}
                      style={{ marginLeft: 12 }}
                      onClick={handleChangeUserStatus}
                    >
                      Deactivate User
                    </AccessControlButton>
                  </GridContainer>
                ) : editUser != undefined && !editUser.isActive ? (
                  <GridContainer
                    style={{ marginTop: "8px", marginLeft: "-12px" }}
                    justifyContent="flex-start"
                  >
                    <AccessControlButton
                      code={PM_CHANGE_USER_STATUS_CODE}
                      variant="contained"
                      color="primary"
                      size="small"
                      value={editUser.id}
                      style={{ marginLeft: 12 }}
                      onClick={handleChangeUserStatus}
                    >
                      Activate User
                    </AccessControlButton>
                  </GridContainer>
                ) : null}
              </div>
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleEditClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleEditOrAdd} color="primary">
              {editUser != undefined ? `Save & Update` : `Add User`}
            </Button>
          </DialogActions>
        </Dialog>

        {/* Reset password Dialog */}
        {resetPasswordUser != undefined ? (
          <Dialog
            open={openResetPasswordDialog}
            onClose={handleEditClose}
            aria-labelledby="form-dialog-title"
            fullWidth
            maxWidth="sm"
          >
            <DialogTitle id="form-dialog-title">Reset Password</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {`Reseting password for ${resetPasswordUser.name}`}
              </DialogContentText>
              <form noValidate autoComplete="off">
                <div>
                  <TextField
                    margin="dense"
                    id="password"
                    label="New Password"
                    type={showPassword ? "text" : "password"}
                    autoComplete="new-password"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                            // onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? (
                              <VisibilityIcon />
                            ) : (
                              <VisibilityOffIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    value={
                      resetPasswordUser != undefined &&
                      resetPasswordUser.newPassword != undefined
                        ? resetPasswordUser.newPassword
                        : ""
                    }
                    onChange={(e) => {
                      setResetPasswordUser({
                        ...resetPasswordUser,
                        newPassword: e.currentTarget.value,
                      });
                    }}
                    fullWidth
                  />
                </div>
              </form>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleEditClose} color="primary">
                Cancel
              </Button>
              <Button onClick={handleResetPassword} color="primary">
                {`Reset Password`}
              </Button>
            </DialogActions>
          </Dialog>
        ) : null}

        {/* delete Dialog */}
        {removeUser != undefined ? (
          <Dialog
            open={openDeleteDialog}
            onClose={handleEditClose}
            aria-labelledby="form-dialog-title"
            fullWidth
            maxWidth="sm"
          >
            <DialogTitle id="form-dialog-title">Deactivate User</DialogTitle>
            <DialogContent>
              <form noValidate autoComplete="off">
                <div>
                  <TextField
                    margin="dense"
                    id="name"
                    label="User Name"
                    type="text"
                    disabled
                    value={removeUser.name}
                    fullWidth
                  />

                  <DialogContentText
                    color="textPrimary"
                    style={{ marginTop: "18px" }}
                  >
                    {`Are you sure you want to Deactivate ${removeUser.name}?`}
                  </DialogContentText>
                  <DialogContentText>
                    Please type the following to confirm:
                  </DialogContentText>
                  <DialogContentText>
                    <i>
                      <b>{removeUser.name}</b>
                    </i>
                  </DialogContentText>
                  <TextField
                    margin="dense"
                    variant="outlined"
                    id="name"
                    type="text"
                    value={deleteConfirmation}
                    onChange={(e) =>
                      setDeleteConfirmation(e.currentTarget.value)
                    }
                    fullWidth
                  />
                </div>
              </form>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleEditClose} color="primary">
                Cancel
              </Button>
              <Button onClick={handleDelete} color="primary">
                {`Deactivate User`}
              </Button>
            </DialogActions>
          </Dialog>
        ) : null}

        {/* Data Grid */}
        <div style={{ height: "80vh" }}>
          <div style={{ display: "flex", height: "100%" }}>
            <div style={{ flexGrow: 1 }}>
              <DataGrid
                pageSize={size}
                rowsPerPageOptions={[10, 20, 30]}
                onPageSizeChange={(size) => {
                  setSize(size)
                  setloadList(true)
                }}
                disableColumnMenu
                density="compact"
                components={{
                  LoadingOverlay: CustomLoadingOverlay,
                  NoRowsOverlay: CustomNoRowsOverlay,
                  Toolbar: CustomToolbar,
                }}
                pagination
                loading={isLoading}
                paginationMode="server"
                rowCount={row}
                filterMode="server"
                onPageChange={(newPage) => {
                  setIndex(++newPage)
                  setloadList(true)
                }}
                rows={appUserList}
                columns={
                  role?.identityRoles === ID_HOST
                    ? headerColumnForHost
                    : headerColumnForTenant
                }
                getRowId={(row) => row.id}
              />
            </div>
          </div>
        </div>
      </MainDrawer>
    </>
  );
};
