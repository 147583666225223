import React from "react";
// @material-ui/core components
import {
  Card,
  CardContent,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { DynamicTypography } from "components/DynamicTypography";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import InfoIcon from "@material-ui/icons/Info";

interface IProps {
  title: string;
  titleColor?:
    | "initial"
    | "inherit"
    | "primary"
    | "secondary"
    | "textPrimary"
    | "textSecondary"
    | "error";
  currentTotal: number;
  unit?: string;
  subtitle?: string;
  subtitleColor?:
    | "initial"
    | "inherit"
    | "primary"
    | "secondary"
    | "textPrimary"
    | "textSecondary"
    | "error";
  isLoading: boolean;
  toolTipText?: string;
}

export const SummaryCard: React.FC<IProps> = ({
  title,
  titleColor = "textPrimary",
  currentTotal = 0,
  unit,
  subtitle,
  subtitleColor = "textPrimary",
  isLoading,
  toolTipText,
}) => {
  return (
    <Card style={{ width: "240px" }}>
      <CardContent>
        {toolTipText != undefined ? (
          <div style={{ position: "absolute" }}>
            <Tooltip
              style={{ position: "inherit", top: "-16px", left: "204px" }}
              title={toolTipText}
            >
              <IconButton size="small">
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </div>
        ) : null}
        <Typography
          style={{ height: "48px" }}
          align="left"
          variant="subtitle1"
          color={titleColor}
        >
          <b>{title}</b>
        </Typography>
        <Typography
          // style={{ margin: "4px" }}
          align="center"
          variant="h1"
          color={"primary"}
        >
          {isLoading ? <Skeleton /> : currentTotal}
        </Typography>

        {unit !== undefined ? (
          <Typography
            align="center"
            variant="subtitle1"
            color={subtitleColor}
            gutterBottom
          >
            {unit}
          </Typography>
        ) : null}

        {isLoading ? (
          <Skeleton />
        ) : subtitle != undefined ? (
          <>
            <Divider />
            <DynamicTypography
              style={{ height: "24px" }}
              align="left"
              variant="subtitle1"
              color={subtitleColor}
              content={subtitle}
            />
          </>
        ) : (
          <div style={{ height: "24px" }}></div>
        )}
      </CardContent>
    </Card>
  );
};
