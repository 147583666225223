import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "reducers";
//Component
import {
  DataGrid,
  GridColDef,
  GridColumns,
  GridToolbarContainer,
} from "@material-ui/data-grid";
import CustomLoadingOverlay from "components/DataGridLoadingOverlay";
import CustomNoRowsOverlay from "components/DataGridNoRowsOverlay";
import {
  ARIA_SuccessCode,
  Component,
  PM_ADD_COMPONENT,
  PM_DELETE_COMPONENT,
  PM_EDIT_COMPONENT,
} from "models";
import { DataGridFilterToolbar } from "components/DataGridFilterToolbar";
import { GridColFilterDef } from "components/DataGridFilterSelection";
//Icon

//Helper
import { convertDateTimeToString } from "utils/dateHelper";
import { Data } from "react-csv/components/CommonPropTypes";
import { ExportButton } from "components/ExportButton";
import { MainDrawer } from "components/MainDrawer";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  AccessControlButton,
  CodeMirrorTextField,
  ColoredCircle,
} from "components";
import {
  createComponentAPI,
  deleteComponentAPI,
  getComponentAPI,
  getComponentListAPI,
  updateComponentAPI,
} from "services/componentServices";
import { GRID_FILTER_TYPE_BOOLEAN, GRID_FILTER_TYPE_COMPONENTTYPE } from "components/DataGridFilterSelection/type";
import { fetchComponentTypeList } from "reducers/componentType";

export const ComponentManagementScreen = () => {
  const dispatch = useDispatch();

  const { selectedHotel } = useSelector((state: RootState) => state.hotelState);
  const { componentTypeList } = useSelector(
    (state: RootState) => state.componentType
  );

  const [index, setIndex] = useState<number>(1);
  const [size, setSize] = useState<number>(10);
  const [row, setRow] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isExporting, setIsExporting] = useState(false);

  const [requestDateTime, setRequestDateTime] = useState<Date>(new Date());

  const [componentList, setComponentList] = useState<Component[]>([]);
  const [exportList, setExportList] = useState<Data>([]);
  const [filterList, setFilterList] = useState<GridColFilterDef[]>([]);

  const [loadList, setloadList] = useState(true);

  const [addComponent, setAddComponent] = useState<any | undefined>();
  const [editComponent, setEditComponent] = useState<Component | undefined>();
  const [removeComponent, setRemoveComponent] = useState<
    Component | undefined
  >();
  const [deleteConfirmation, setDeleteConfirmation] = useState("");

  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  //Setting up the each column header item
  const componentNameHeader: GridColDef = {
    field: "name",
    headerName: "Name",
    sortable: false,
    renderHeader: (params: any) => <strong>{"Name"}</strong>,
    minWidth: 240,
    flex: 1,
  };

  const componentCodeHeader: GridColDef = {
    field: "code",
    headerName: "Code",
    sortable: false,
    renderHeader: (params: any) => <strong>{"Code"}</strong>,
    minWidth: 180,
    flex: 1,
  };

  const componentTypeHeader: GridColDef = {
    field: "type",
    headerName: "Type",
    sortable: false,
    type: GRID_FILTER_TYPE_COMPONENTTYPE,
    description: "type/code",
    renderHeader: (params: any) => <strong>{"Type"}</strong>,
    renderCell: (params: any) => {
      return <Typography variant="body2">{params.value.name}</Typography>;
    },
    minWidth: 240,
    flex: 1,
  };

  const componentDescHeader: GridColDef = {
    field: "description",
    headerName: "Description",
    sortable: false,
    renderHeader: (params: any) => <strong>{"Description"}</strong>,
    minWidth: 400,
    flex: 2,
  };

  const componentIsOnlineHeader: GridColDef = {
    field: "isOnline",
    headerName: "Status",
    sortable: false,
    type: GRID_FILTER_TYPE_BOOLEAN,
    renderHeader: () => <strong>{"Status"}</strong>,
    renderCell: (params: any) => {
      if (params.value) {
        return <ColoredCircle color="#297373" />;
      } else {
        return <ColoredCircle color="#E94F37" />;
      }
    },
    minWidth: 40,
  };

  const componentIsOnMaintaineceHeader: GridColDef = {
    field: "IsOnMaintenanceMode",
    headerName: "Maintenance Mode",
    sortable: false,
    type: GRID_FILTER_TYPE_BOOLEAN,
    renderHeader: () => <strong>{"Maintenance Mode"}</strong>,
    renderCell: (params: any) => {
      if (params.value) {
        return <ColoredCircle color="#297373" />;
      } else {
        return <ColoredCircle color="#E94F37" />;
      }
    },
    minWidth: 160,
  };

  const actionsHeader: GridColDef = {
    field: "id",
    headerName: "Actions",
    minWidth: 240,
    filterable: false,
    sortable: false,
    disableColumnMenu: true,
    renderHeader: () => <strong>{"Actions"}</strong>,
    renderCell: (params: any) => (
      <strong>
        <AccessControlButton
          code={PM_EDIT_COMPONENT}
          variant="contained"
          color="primary"
          size="small"
          value={params.value}
          onClick={handleOpenEditDialog}
        >
          Edit
        </AccessControlButton>
        <AccessControlButton
          code={PM_ADD_COMPONENT}
          variant="contained"
          color="primary"
          size="small"
          value={params.value}
          style={{ marginLeft: 12 }}
          onClick={handleDuplicateFunctionClick}
        >
          Clone
        </AccessControlButton>
        <AccessControlButton
          code={PM_DELETE_COMPONENT}
          variant="contained"
          color="primary"
          size="small"
          value={params.value}
          style={{ marginLeft: 12 }}
          onClick={handleOpenDeleteDialog}
        >
          Delete
        </AccessControlButton>
      </strong>
    ),
  };

  const headerColumn: GridColumns = [
    componentNameHeader,
    componentCodeHeader,
    componentTypeHeader,
    componentDescHeader,
    componentIsOnlineHeader,
    componentIsOnMaintaineceHeader,
    actionsHeader,
  ];

  React.useEffect(() => {
    dispatch(fetchComponentTypeList());
  }, []);

  React.useEffect(() => {
    if (selectedHotel != undefined) {
      setloadList(true);
    }
  }, [selectedHotel]);

  React.useEffect(() => {
    if (loadList) {
      setIsLoading(true);
      setloadList(false);
      setComponentList([]);
      setRequestDateTime(new Date());

      getComponentListAPI({
        filter: filterList,
        pageIndex: index,
        pageSize: size,
        orderBy: "name",
      })
        .then((response) => {
          if (response.resultCode === ARIA_SuccessCode) {
            setComponentList(response.result.items);
            setRow(response.result.rowCount);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [index, size, filterList, loadList]);

  const getExportTransaction = async () => {
    setIsLoading(true);
    var _exportIndex = 1;
    var exportList: Component[] = [];

    try {
      do {
        var response = await getComponentListAPI({
          filter: filterList,
          pageIndex: _exportIndex,
          pageSize: size,
          orderBy: "name",
        });

        exportList.push(...response.result.items);

        _exportIndex++;
      } while (response.result.pageCount >= _exportIndex);

      setExportList(exportList);
      setIsExporting(true);
      setIsLoading(false);
    } catch (err) {
      console.log("Oposs.. Something is wrong");
    }
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <DataGridFilterToolbar
          headerColumns={headerColumn}
          filterList={filterList}
          onFilterApply={(items) => {
            setFilterList(items.slice());
            setloadList(true)
          }}
        />
        <ExportButton
          filename="Component"
          data={exportList}
          headers={[
            { label: "Name", key: "name" },
            { label: "Code", key: "code" },
            { label: "Description", key: "description" },
          ]}
          readyToExport={isExporting}
          onClick={getExportTransaction}
          onFinishDownload={() => {
            setExportList([]);
            setIsExporting(false);
          }}
        >
          Export
        </ExportButton>
        <div style={{ flexGrow: 1 }}></div>
        <AccessControlButton
          code={PM_ADD_COMPONENT}
          variant="contained"
          color="primary"
          size="small"
          onClick={handleOpenCreateDialog}
        >
          New Component
        </AccessControlButton>
        <div style={{ paddingLeft: "8px" }}>
          <b>Data as at </b>: {convertDateTimeToString(requestDateTime)}
        </div>
      </GridToolbarContainer>
    );
  }

  const handleOpenCreateDialog = () => {
    setOpenEditDialog(true);
  };

  const handleDuplicateFunctionClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    var _updateComponent = componentList.find(
      (f) => f.id === parseInt(event.currentTarget.value)
    );

    setAddComponent(_updateComponent);
    setOpenEditDialog(true);
  };

  const handleOpenEditDialog = (event: React.MouseEvent<HTMLButtonElement>) => {
    setIsLoading(true);

    getComponentAPI({
        id: parseInt(event.currentTarget.value)
      })
        .then((response) => {
          if (response.resultCode === ARIA_SuccessCode) {
            var _updateComponent = response.result;

            if (_updateComponent != null && _updateComponent != undefined) {
                _updateComponent.componentTypeId = _updateComponent.type?.id;
              }

              setEditComponent(_updateComponent);
              setOpenEditDialog(true);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });

  };

  const handleOpenDeleteDialog = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    var _removeComponent = componentList.find(
      (f) => f.id === parseInt(event.currentTarget.value)
    );
    setRemoveComponent(_removeComponent);
    setOpenDeleteDialog(true);
  };

  const handleEditOrAdd = () => {
    if (editComponent == null) {
      if (
        addComponent === null ||
        addComponent.name === null ||
        addComponent.name === "" ||
        addComponent.code === null ||
        addComponent.code === ""
      ) {
        alert("Please fill up the details below to add a new Component.");
      } else {
        createComponentAPI({
          name: addComponent.name,
          code: addComponent.code,
          description: addComponent.description,
          configValue: addComponent.configValue,
          componentTypeId: addComponent.componentTypeId,
        })
          .then((response) => {
            if (response.resultCode == ARIA_SuccessCode) {
              setloadList(true);
              handleEditClose();
            } else {
              alert(response.resultDescription);
            }
          })
          .catch((err) => {
            alert(err);
          });
      }
    } else {
      if (
        editComponent.name === null ||
        editComponent.name === "" ||
        editComponent.code === null ||
        editComponent.code === ""
      ) {
        alert("Please fill up the details below to edit component.");
      } else {
        console.log(editComponent)

        updateComponentAPI({
          id: editComponent.id,
          name: editComponent.name,
          code: editComponent.code,
          description: editComponent.description,
          configValue: JSON.stringify(JSON.parse(editComponent.configValue)),
          componentTypeId: editComponent.componentTypeId,
        })
          .then((response) => {
            if (response.resultCode == ARIA_SuccessCode) {
              setloadList(true);
              handleEditClose();
            } else {
              alert(response.resultDescription);
            }
          })
          .catch((err) => {
            alert(err);
          });
      }
    }
  };

  const handleDelete = () => {
    if (deleteConfirmation !== removeComponent?.code) {
      alert("Please key in the component code to confirm deletion.");
    } else {
      deleteComponentAPI({ ...removeComponent })
        .catch((err) => {
          alert(err);
        })
        .finally(() => {
          setDeleteConfirmation("");
          setloadList(true);
          handleEditClose();
        });
    }
  };

  const handleEditClose = () => {
    setAddComponent(undefined);
    setEditComponent(undefined);
    setOpenEditDialog(false);
    setOpenDeleteDialog(false);
  };

  return (
    <>
      <MainDrawer title="Kiosk Components">
        {/* Add and Edit Dialog */}
        <Dialog
          open={openEditDialog}
          onClose={handleEditClose}
          aria-labelledby="form-dialog-title"
          fullWidth
          maxWidth="lg"
        >
          <DialogTitle id="form-dialog-title">
            {editComponent != undefined ? "Edit Component" : "Add Component"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {editComponent != undefined
                ? "Change the detail below and save it to update component details."
                : "Fill up the details below to add a new component."}
            </DialogContentText>
            <form noValidate autoComplete="off">
              <div>
                <TextField
                  margin="dense"
                  id="name"
                  label="Component Name"
                  type="text"
                  value={
                    editComponent != undefined
                      ? editComponent.name
                      : addComponent?.name != undefined
                      ? addComponent?.name
                      : ""
                  }
                  onChange={(e) => {
                    editComponent != undefined
                      ? setEditComponent({
                          ...editComponent,
                          name: e.currentTarget.value,
                        })
                      : setAddComponent({
                          ...addComponent,
                          name: e.currentTarget.value,
                        });
                  }}
                  fullWidth
                />
                <TextField
                  margin="dense"
                  id="code"
                  label="Code"
                  type="text"
                  value={
                    editComponent != undefined
                      ? editComponent.code
                      : addComponent?.code != undefined
                      ? addComponent?.code
                      : ""
                  }
                  onChange={(e) => {
                    editComponent != undefined
                      ? setEditComponent({
                          ...editComponent,
                          code: e.currentTarget.value,
                        })
                      : setAddComponent({
                          ...addComponent,
                          code: e.currentTarget.value,
                        });
                  }}
                  fullWidth
                />
                <TextField
                  margin="dense"
                  id="desc"
                  label="Description"
                  type="text"
                  multiline
                  value={
                    editComponent != undefined
                      ? editComponent.description
                      : addComponent?.description != undefined
                      ? addComponent?.description
                      : ""
                  }
                  onChange={(e) => {
                    editComponent != undefined
                      ? setEditComponent({
                          ...editComponent,
                          description: e.currentTarget.value,
                        })
                      : setAddComponent({
                          ...addComponent,
                          description: e.currentTarget.value,
                        });
                  }}
                  fullWidth
                />
                <TextField
                  margin="dense"
                  id="module"
                  label="Module"
                  select
                  value={
                    editComponent != undefined
                      ? editComponent.componentTypeId
                      : addComponent?.componentTypeId
                  }
                  onChange={(e) => {
                    editComponent != undefined
                      ? setEditComponent({
                          ...editComponent,
                          componentTypeId: parseInt(e.target.value),
                        })
                      : setAddComponent({
                          ...addComponent,
                          componentTypeId: parseInt(e.target.value),
                        });
                  }}
                  fullWidth
                >
                  {componentTypeList.map((module, index) => (
                    <MenuItem key={index} value={module.id}>
                      {module.name}
                    </MenuItem>
                  ))}
                </TextField>
                <CodeMirrorTextField
                  value={
                    editComponent != undefined
                      ? editComponent.configValue
                      : addComponent?.defaultConfig != undefined
                      ? addComponent?.defaultConfig
                      : ""
                  }
                  onChange={(value) => {
                    editComponent != undefined
                      ? setEditComponent({
                          ...editComponent,
                          configValue: value,
                        })
                      : setAddComponent({
                          ...addComponent,
                          configValue: value,
                        });
                  }}
                />
              </div>
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleEditClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleEditOrAdd} color="primary">
              {editComponent != null ? `Save & Update` : `Add Component`}
            </Button>
          </DialogActions>
        </Dialog>

        {/* delete Dialog */}
        {removeComponent != undefined ? (
          <Dialog
            open={openDeleteDialog}
            onClose={handleEditClose}
            aria-labelledby="form-dialog-title"
            fullWidth
            maxWidth="sm"
          >
            <DialogTitle id="form-dialog-title">Delete Component</DialogTitle>
            <DialogContent>
              <form noValidate autoComplete="off">
                <div>
                  <TextField
                    margin="dense"
                    id="name"
                    label="Component Name"
                    type="text"
                    disabled
                    value={removeComponent.name}
                    fullWidth
                  />
                  <TextField
                    margin="dense"
                    id="code"
                    label="Code"
                    type="text"
                    disabled
                    value={removeComponent.code}
                    fullWidth
                  />
                  <TextField
                    margin="dense"
                    id="description"
                    label="Description"
                    type="text"
                    disabled
                    multiline
                    value={removeComponent.description}
                    fullWidth
                  />
                  <DialogContentText
                    color="textPrimary"
                    style={{ marginTop: "18px" }}
                  >
                    {`Are you sure you want to delete ${removeComponent.name}?`}
                  </DialogContentText>
                  <DialogContentText>
                    Please type the following to confirm:
                  </DialogContentText>
                  <DialogContentText>
                    <i>
                      <b>{removeComponent.code}</b>
                    </i>
                  </DialogContentText>
                  <TextField
                    margin="dense"
                    variant="outlined"
                    id="code"
                    type="text"
                    value={deleteConfirmation}
                    onChange={(e) =>
                      setDeleteConfirmation(e.currentTarget.value)
                    }
                    fullWidth
                  />
                </div>
              </form>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleEditClose} color="primary">
                Cancel
              </Button>
              <Button onClick={handleDelete} color="primary">
                {`Delete`}
              </Button>
            </DialogActions>
          </Dialog>
        ) : null}

        {/* Data Grid */}
        <div style={{ height: "80vh" }}>
          <div style={{ display: "flex", height: "100%" }}>
            <div style={{ flexGrow: 1 }}>
              <DataGrid
                pageSize={size}
                rowsPerPageOptions={[10, 20, 30]}
                onPageSizeChange={(size) => {
                  setSize(size);
                  setloadList(true)
                }}
                disableColumnMenu
                density="compact"
                components={{
                  LoadingOverlay: CustomLoadingOverlay,
                  NoRowsOverlay: CustomNoRowsOverlay,
                  Toolbar: CustomToolbar,
                }}
                pagination
                loading={isLoading}
                paginationMode="server"
                rowCount={row}
                filterMode="server"
                onPageChange={(newPage) => {
                  setIndex(++newPage);
                  setloadList(true)
                }}
                rows={componentList}
                columns={headerColumn}
                getRowId={(row) => row.id}
              />
            </div>
          </div>
        </div>
      </MainDrawer>
    </>
  );
};
