import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "reducers";

//Component
import {
  DataGrid,
  GridColDef,
  GridColumns,
  GridRowParams,
  GridToolbarContainer,
  MuiEvent,
} from "@material-ui/data-grid";
import CustomLoadingOverlay from "components/DataGridLoadingOverlay";
import CustomNoRowsOverlay from "components/DataGridNoRowsOverlay";
import { ARIA_SuccessCode, ErrorLog } from "models";
import { DataGridFilterToolbar } from "components/DataGridFilterToolbar";
import { GridColFilterDef } from "components/DataGridFilterSelection";
//Icon

//Helper
import { convertDateTimeToString } from "utils/dateHelper";
import { Data } from "react-csv/components/CommonPropTypes";
import { ExportButton } from "components/ExportButton";
import { MainDrawer } from "components/MainDrawer";
import { getErrorLogListAPI } from "services/errorLogService";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@material-ui/core";

import { fetchLanguageList } from "reducers/language";

export const ErrorLogManagementScreen = () => {
  const { selectedHotel } = useSelector((state: RootState) => state.hotelState);
  const dispatch = useDispatch();

  const [index, setIndex] = useState<number>(1);
  const [size, setSize] = useState<number>(10);
  const [row, setRow] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isExporting, setIsExporting] = useState(false);

  const [requestDateTime, setRequestDateTime] = useState<Date>(new Date());

  const [errorLogList, setErrorLogList] = useState<ErrorLog[]>([]);
  const [exportList, setExportList] = useState<Data>([]);
  const [filterList, setFilterList] = useState<GridColFilterDef[]>([]);

  const [viewingErrorLog, setViewingErrorLog] = useState<
    ErrorLog | undefined
  >();

  //Setting up the each column header item
  const errorLogCreatedHeader: GridColDef = {
    field: "created",
    headerName: "Date",
    type: "dateTime",
    renderHeader: (params: any) => <strong>{"Date"}</strong>,
    renderCell: (params: any) => (
      <label>{convertDateTimeToString(params.value)}</label>
    ),
    minWidth: 180,
  };

  const errorLogPlatformHeader: GridColDef = {
    field: "platform",
    headerName: "Platform",
    renderHeader: (params: any) => <strong>{"Platform"}</strong>,
    minWidth: 80,
  };

  const errorLogSessionHeader: GridColDef = {
    field: "session",
    headerName: "Session",
    renderHeader: (params: any) => <strong>{"Session"}</strong>,
    minWidth: 160,
  };

  const errorLogConfirmationNoHeader: GridColDef = {
    field: "confirmationNo",
    headerName: "Confirmation No",
    renderHeader: (params: any) => <strong>{"Confirmation No"}</strong>,
    minWidth: 160,
  };

  const errorLogServerityHeader: GridColDef = {
    field: "serverityNumber",
    headerName: "Serverity",
    type: "number",
    renderHeader: (params: any) => <strong>{"Serverity"}</strong>,
  };

  const errorLogMessageHeader: GridColDef = {
    field: "errorMessage",
    headerName: "Error Message",
    renderHeader: () => <strong>{"Error Message"}</strong>,
    minWidth: 200,
    flex: 1,
  };

  const headerColumn: GridColumns = [
    errorLogCreatedHeader,
    errorLogPlatformHeader,
    errorLogSessionHeader,
    errorLogConfirmationNoHeader,
    errorLogMessageHeader,
    errorLogServerityHeader,
  ];

  React.useEffect(() => {
    dispatch(fetchLanguageList());
  }, []);

  React.useEffect(() => {
    if (selectedHotel != undefined) {
      setIsLoading(true);
      setErrorLogList([]);
      setRequestDateTime(new Date());

      getErrorLogListAPI({
        filter: filterList,
        pageIndex: index,
        pageSize: size,
        orderBy: "created desc",
      })
        .then((response) => {
          if (response.resultCode === ARIA_SuccessCode) {
            setErrorLogList(response.result.items);
            setRow(response.result.rowCount);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [index, size, filterList, selectedHotel]);

  const getExportTransaction = async () => {
    setIsLoading(true);
    var _exportIndex = 1;
    var exportList: ErrorLog[] = [];

    try {
      do {
        var response = await getErrorLogListAPI({
          filter: filterList,
          pageIndex: _exportIndex,
          pageSize: size,
          orderBy: "created desc",
        });

        exportList.push(...response.result.items);

        _exportIndex++;
      } while (response.result.pageCount >= _exportIndex);

      setExportList(exportList);
      setIsExporting(true);
      setIsLoading(false);
    } catch (err) {
      console.log("Oposs.. Something is wrong");
    }
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <DataGridFilterToolbar
          headerColumns={headerColumn}
          filterList={filterList}
          onFilterApply={(items) => {
            setFilterList(items.slice());
          }}
        />
        <ExportButton
          filename="ErrorLog"
          data={exportList}
          headers={[
            { label: "Date", key: "created" },
            { label: "Platform", key: "platform" },
            { label: "Session", key: "session" },
            { label: "Confirmation No", key: "confirmationNo" },
            { label: "Error Message", key: "errorMessage" },
            { label: "Serverity", key: "serverityNumber" },
            { label: "Serverity Description", key: "serverity" },
          ]}
          readyToExport={isExporting}
          onClick={getExportTransaction}
          onFinishDownload={() => {
            setExportList([]);
            setIsExporting(false);
          }}
        >
          Export
        </ExportButton>
        <div style={{ flexGrow: 1 }}></div>
        <div style={{ paddingLeft: "8px" }}>
          <b>Data as at </b>: {convertDateTimeToString(requestDateTime)}
        </div>
      </GridToolbarContainer>
    );
  }

  const handleOpenEditDialog = (
    params: GridRowParams,
    event: MuiEvent<React.SyntheticEvent>,
    details?: any
  ) => {
    var _updateVerbiage = errorLogList.find((f) => f.id === params.id);
    setViewingErrorLog(_updateVerbiage);
    // setOpenEditDialog(true);
  };

  const handleEditClose = () => {
    setViewingErrorLog(undefined);
    // setOpenEditDialog(false);
  };

  return (
    <>
      <MainDrawer title="Error Logs">
        {/* Add and Edit Dialog */}
        {viewingErrorLog != undefined ? (
          <Dialog
            open={true}
            onClose={handleEditClose}
            aria-labelledby="form-dialog-title"
            fullWidth
            maxWidth="xl"
          >
            <DialogTitle id="form-dialog-title">
              {"Error Log Details"}
            </DialogTitle>
            <DialogContent>
              <form noValidate autoComplete="off">
                <div>
                  <TextField
                    margin="dense"
                    id="Date"
                    label="Date"
                    type="text"
                    disabled
                    value={convertDateTimeToString(viewingErrorLog.created)}
                    fullWidth
                  />
                  <TextField
                    margin="dense"
                    id="platform"
                    label="Platform"
                    type="text"
                    disabled
                    value={viewingErrorLog.platform}
                    fullWidth
                  />
                  <TextField
                    margin="dense"
                    id="session"
                    label="Session"
                    type="text"
                    disabled
                    value={viewingErrorLog.session}
                    fullWidth
                  />
                  <TextField
                    margin="dense"
                    id="confirmationNo"
                    label="Confirmation No"
                    type="text"
                    disabled
                    value={viewingErrorLog.confirmationNo}
                    fullWidth
                  />
                  <TextField
                    margin="dense"
                    id="serverity"
                    label="Serverity"
                    type="text"
                    disabled
                    value={viewingErrorLog.serverity}
                    fullWidth
                  />
                  <TextField
                    margin="dense"
                    id="serverityNumber"
                    label="Serverity Number"
                    type="text"
                    disabled
                    value={viewingErrorLog.serverityNumber}
                    fullWidth
                  />
                  <TextField
                    margin="dense"
                    id="errorMessage"
                    label="Error Message"
                    multiline
                    type="text"
                    disabled
                    value={viewingErrorLog.errorMessage}
                    fullWidth
                  />
                </div>
              </form>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleEditClose} color="primary">
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        ) : null}

        {/* Data Grid */}
        <div style={{ height: "80vh" }}>
          <div style={{ display: "flex", height: "100%" }}>
            <div style={{ flexGrow: 1 }}>
              <DataGrid
                pageSize={size}
                rowsPerPageOptions={[10, 20, 30]}
                onPageSizeChange={(size) => {
                  setSize(size);
                }}
                disableColumnMenu
                density="compact"
                components={{
                  LoadingOverlay: CustomLoadingOverlay,
                  NoRowsOverlay: CustomNoRowsOverlay,
                  Toolbar: CustomToolbar,
                }}
                pagination
                loading={isLoading}
                paginationMode="server"
                rowCount={row}
                filterMode="server"
                onPageChange={(newPage) => {
                  setIndex(++newPage);
                }}
                rows={errorLogList}
                onRowClick={handleOpenEditDialog}
                columns={headerColumn}
                getRowId={(row) => row.id}
              />
            </div>
          </div>
        </div>
      </MainDrawer>
    </>
  );
};
