import React from "react";

//Component
import {
  AppBar,
  Button,
  Dialog,
  IconButton,
  Toolbar,
  Typography,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";

import CodeMirror from "@uiw/react-codemirror";
import { json } from "@codemirror/lang-json";
// import "codemirror/addon/display/autorefresh";
// import "codemirror/addon/comment/comment";
// import "codemirror/addon/edit/closetag";
// import "codemirror/addon/edit/matchbrackets";
// import "codemirror/addon/edit/matchtags";
// import "codemirror/keymap/sublime";
// import "codemirror/theme/ttcn.css";

interface ICodeMirrorDialogProps {
  title: string;
  editingContent?: string;
  open: boolean;
  onHandleCloseClick: () => void;
  onHandleBeautifyClick?: () => void;
  onHandleSaveClick?: () => void;
  onChange?: (value: string) => void;
}

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  button: {
    marginRight: theme.spacing(2),
  },
}));

export const CodeMirrorDialog: React.FC<ICodeMirrorDialogProps> = ({
  title,
  editingContent,
  open,
  onHandleCloseClick,
  onHandleBeautifyClick,
  onHandleSaveClick,
  onChange,
}) => {
  const classes = useStyles();

  return (
    <Dialog fullScreen open={open} onClose={onHandleCloseClick}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onHandleCloseClick}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {title}
          </Typography>
          {onHandleBeautifyClick != undefined ? (
            <Button
              autoFocus
              variant="outlined"
              color="inherit"
              className={classes.button}
              onClick={onHandleBeautifyClick}
            >
              Beautify
            </Button>
          ) : null}
          {onHandleSaveClick != undefined ? (
            <Button
              autoFocus
              variant="outlined"
              color="inherit"
              onClick={onHandleSaveClick}
            >
              Save
            </Button>
          ) : null}
        </Toolbar>
      </AppBar>
      <CodeMirror
        value={editingContent}
        height="90vh"
        theme={"dark"}
        editable={!(onHandleSaveClick == undefined)}
        extensions={[json()]}
        onChange={(value) =>
          onChange != undefined ? onChange(value) : undefined
        }
        // options={{
        //     theme: 'ttcn',
        //     keyMap: 'sublime',
        //     mode: 'xml',
        //     autoCloseTags: true,
        //     matchBrackets: true,
        //     matchTags: true,
        //     lineWrapping: true,
        // }}
      />
    </Dialog>
  );
};
