import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store/state';

//Component
import { makeStyles } from '@material-ui/core/styles';
import { GridItem, GridContainer, CaptureDocumentDialog } from 'components';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import ErrorIcon from '@material-ui/icons/Error';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import { Reservation, convertToCreateGuestRequestModel, convertToUpdateGuestRequestModel, ScanningUser } from 'models';
import { displayLastFourChar, getVerbiage } from 'utils/stringHelper';
import { addNewGuestToReservation, removeGuestForReservation, updateGuestForReservation } from 'reducers/reservation';
import { GuestInfoDialog } from 'components/GuestInfoDialog';
import { SafeMeasureDialog } from 'components/SafeMeasureDialog';
import { hasReservationCompletedVerification } from 'utils/applicationHelper';

const useStyles = makeStyles((theme) => ({
  subtitle: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap'
  }
}));

interface IProps {}

export const GuestCardList: React.FC<IProps> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { appConfig } = useSelector((state: RootState) => state.appConfigState);
  const { verbiages } = useSelector((state: RootState) => state.verbiages);
  const { selectedReservation } = useSelector((state: RootState) => state.reservationState);
  const { selectedHotel } = useSelector((state: RootState) => state.hotelState);
  const { countryList } = useSelector((state: RootState) => state.country);
  // const { newGuest } = useSelector((state: RootState) => state.guest);

  const [openCaptureGuestDocumentDialog, setOpenCaptureGuestDocumentDialog] = React.useState(false);
  const [openEditGuestInfoDialog, setOpenEditGuestInfoDialog] = React.useState(false);
  const [openSafeMeasurefoDialog, setOpenSafeMeasureDialog] = React.useState(false);
  const [editingGuestReservation, setEditingGuestReservation] = React.useState<Reservation | undefined>(undefined);
  // const [maxGuest, setMaxGuest] = React.useState<number>(0);
  const [maxSharer, setMaxSharer] = React.useState<number>(0);

  React.useEffect(() => {
    if (selectedReservation != null) {
      const maxOccupancy = selectedReservation.room.maxAdultNo + selectedReservation.room.maxChildNo;

      setMaxSharer(maxOccupancy - 1);
    }
  }, [selectedReservation]);

  const generateUserCard = (reservation: Reservation, isEditAllow: boolean, isDeleteAllow: boolean) => {
    const { id, firstName, lastName, travelDocuments } = reservation.guest;

    return (
      <GridItem xs={12} key={id}>
        <Card elevation={3}>
          <CardContent>
            <GridContainer spacing={1}>
              <GridItem xs={10}>
                <GridContainer spacing={1}>
                  {/* Full Name */}
                  <GridItem xs={12}>
                    <Typography variant="subtitle1" color="textSecondary">
                      {getVerbiage(verbiages, 'weblbl013')}
                    </Typography>
                    <Typography variant="body1">
                      {firstName != undefined ? lastName + ' ' + firstName : lastName}
                    </Typography>
                  </GridItem>
                  {/* Document Number */}
                  <GridItem xs={12}>
                    <Typography variant="subtitle1" color="textSecondary">
                      {getVerbiage(verbiages, 'weblbl014')}
                    </Typography>
                    <Typography variant="body1">
                      {travelDocuments != undefined && travelDocuments.length > 0 && travelDocuments[0].documentNo
                        ? reservation.isPreRegistered
                          ? displayLastFourChar(travelDocuments[0].documentNo)
                          : travelDocuments[0].documentNo
                        : getVerbiage(verbiages, 'weblbl010')}
                    </Typography>
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem xs={2}>
                {isEditAllow ? (
                  <Button value={id} onClick={captureGuestDocument}>
                    <AssignmentIndIcon />
                  </Button>
                ) : null}
                {isEditAllow && hasReservationCompletedVerification(reservation) ? (
                  <Button value={id} onClick={editingGuestDetail}>
                    <EditIcon />
                  </Button>
                ) : null}
                {isDeleteAllow ? (
                  <Button value={id} onClick={selectingDeleteGuest}>
                    <DeleteIcon />
                  </Button>
                ) : null}
              </GridItem>
              {isEditAllow || isDeleteAllow ? (
                <GridItem xs={12}>
                  {hasReservationCompletedVerification(reservation) ? (
                    <Typography variant="subtitle2" color="textSecondary" className={classes.subtitle}>
                      <CheckCircleIcon style={{ color: 'green' }} />
                      {/* Completed */}
                      {getVerbiage(verbiages, 'weblbl016')}
                    </Typography>
                  ) : (
                    <Typography variant="subtitle2" color="textSecondary" className={classes.subtitle}>
                      <ErrorIcon style={{ color: 'orange' }} />
                      {/* Incompleted */}
                      {getVerbiage(verbiages, 'weblbl015')}
                    </Typography>
                  )}
                </GridItem>
              ) : null}
            </GridContainer>
          </CardContent>
        </Card>
      </GridItem>
    );
  };

  const selectAddGuest = (event: React.MouseEvent<HTMLButtonElement>) => {
    setEditingGuestReservation(undefined);
    setOpenCaptureGuestDocumentDialog(true);
  };

  const captureGuestDocument = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (selectedReservation !== undefined) {
      var reservation = selectedReservation.sharedReservations?.find(
        (x) => x.guest.id === Number.parseInt(event.currentTarget.value)
      );

      if (reservation == undefined) {
        reservation = selectedReservation;
      }

      setEditingGuestReservation({ ...reservation });
      setOpenCaptureGuestDocumentDialog(true);
    }
  };

  const editingGuestDetail = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (selectedReservation !== undefined) {
      var reservation = selectedReservation.sharedReservations?.find(
        (x) => x.guest.id === Number.parseInt(event.currentTarget.value)
      );

      if (reservation == undefined) {
        reservation = selectedReservation;
      }

      setEditingGuestReservation({ ...reservation });
      setOpenEditGuestInfoDialog(true);
    }
  };

  const selectingDeleteGuest = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (
      selectedReservation !== null &&
      selectedHotel !== undefined &&
      selectedReservation?.sharedReservations != null
    ) {
      const sharerReservation = selectedReservation?.sharedReservations?.find(
        (rvs) => rvs.guest.id === Number.parseInt(event.currentTarget.value)
      );
      if (sharerReservation != null) {
        dispatch(
          removeGuestForReservation({
            hotelId: selectedHotel.id,
            mainConfirmationId: selectedReservation!.confirmationNo,
            shareConfirmationId: sharerReservation.confirmationNo
          })
        );
      }
    }
  };

  const onGuestDocumentScanningCompleted = async (isCompleted: boolean, guest: ScanningUser | null) => {
    if (guest && guest.document && selectedHotel) {
      if (editingGuestReservation != null && editingGuestReservation.guest) {
        const guestInfo = convertToUpdateGuestRequestModel(
          guest.document,
          selectedHotel,
          editingGuestReservation.guest.profileId,
          countryList
        );
        await dispatch(updateGuestForReservation({ guestInfo: guestInfo }));
      } else {
        const guestInfo = convertToCreateGuestRequestModel(guest.document, selectedHotel, countryList);
        await dispatch(addNewGuestToReservation({ guestInfo: guestInfo }));
      }

      // if (editingGuestReservation?.healthDeclaration == undefined) {
        setOpenSafeMeasureDialog(true);
      // }
    }

    setOpenCaptureGuestDocumentDialog(false);
  };

  return (
    <>
      <CaptureDocumentDialog open={openCaptureGuestDocumentDialog} onCompleted={onGuestDocumentScanningCompleted} />

      <GuestInfoDialog
        open={openEditGuestInfoDialog}
        updateUser={editingGuestReservation?.guest}
        onCompleted={() => setOpenEditGuestInfoDialog(false)}
      />

      <SafeMeasureDialog
        open={openSafeMeasurefoDialog}
        updatingGuestReservation={editingGuestReservation!}
        onCompleted={() => setOpenSafeMeasureDialog(false)}
      />

      <GridContainer spacing={2} justifyContent="center">
        <GridItem xs={12} style={{ marginLeft: '8px', marginRight: '8px' }}>
          <Typography variant="h6" color="textPrimary" className={classes.subtitle}>
            <PeopleAltIcon />
            {/* Guest detail Title */}
            {getVerbiage(verbiages, 'weblbl011')}
          </Typography>
          {/* Guest detail sub title */}
          <Typography variant="body2" color="textPrimary">
            {getVerbiage(verbiages, 'weblbl012')}
          </Typography>
        </GridItem>

        {selectedReservation?.guest != null
          ? generateUserCard(selectedReservation, !selectedReservation.isPreRegistered, false)
          : null}
        {selectedReservation?.sharedReservations != null && selectedReservation?.sharedReservations.length > 0
          ? selectedReservation?.sharedReservations.map((sharedReservation) => {
              return generateUserCard(
                sharedReservation,
                appConfig!.reservationConfig.isAddingNewGuestAllow && !selectedReservation.isPreRegistered,
                appConfig!.reservationConfig.isAddingNewGuestAllow && !selectedReservation.isPreRegistered
              );
            })
          : null}
        {appConfig?.reservationConfig?.isAddingNewGuestAllow &&
        !selectedReservation?.isPreRegistered &&
        selectedReservation?.sharedReservations &&
        selectedReservation?.sharedReservations.length < maxSharer ? (
          <GridItem>
            <Button variant="contained" color="primary" style={{ borderRadius: 100 / 2 }} onClick={selectAddGuest}>
              <PersonAddIcon />
            </Button>
          </GridItem>
        ) : null}
      </GridContainer>
    </>
  );
};
