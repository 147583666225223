export const DebugLogging = "debug";
export const InformationLogging = "information";

export function Log(
    type: "information" | "debug",
    message : any
  ) {
    const loggingType = process.env.REACT_APP_LOGGING; 

    if (loggingType === DebugLogging) {
        console.log(message);
    } else if(loggingType == InformationLogging && type == InformationLogging) {
        console.log(message);
    }
  }