import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  breakSharer,
  checkOutReservationAPI,
  combineSharerAPI,
  getCheckOutReservationByConfirmationIdAPI,
  getPreCheckOutBillAPI,
  getReservationByConfirmationNoAPI,
  getReservationByEmailAPI,
  getReservationByGuidEAPI,
  preRegisterAPI,
  subscribeRoomAPI,
  updateSignAcknowledgementAPI,
  verifyReservationAPI
} from '../services/reservationService';
import {
  BreakSharerResponseModel,
  ReservationResponseModel,
  ResponseModel,
  ARIA_SuccessCode,
  CombineSharerResponseModel,
  CreateGuestResponseModel,
  CreateGuestRequestModel,
  UpdateGuestResponseModel,
  UpdateGuestRequestModel,
  CreateHealthDeclarationResponseModel,
  CreateHealthDeclarationRequestModel,
  VerifyReservationResponseModel,
  GetHealthDeclarationResponseModel,
  GetHealthDeclarationRequestModel,
  PreRegistrationRequestModel,
  PreRegistrationResponseModel,
  SubscribeRoomRequestModel,
  SubscribeRoomResponseModel,
  SignAcknowledgementResponseModel,
  ARIA_RESERVATION_ALREADY_PREREGISTERED,
  GetPreCheckOutBillRequestModel,
  GetPreCheckOutBillResponseModel,
  ARIA_PMS_CONNECTOR_ERROR,
  Attachment,
  CreateGuestEmailResponseModel,
  UpdateGuestEmailResponseModel,
  UpdateGuestEmailRequestModel,
  UpdateGuestEmailModel,
  CheckOutReservationResponseModel,
  CheckOutReservationRequestModel,
  UpdateHealthDeclarationRequestModel,
  UpdateHealthDeclarationResponseModel,
  HealthDeclaration
} from 'models';
import { RootState } from '../store/state';
import { AxiosError } from 'axios';
import { createGuestAPI, createGuestEmailAPI, updateGuestAPI, updateGuestEmailAPI } from 'services/guestService';
import {
  createHealthDeclarationAPI,
  getHealthDeclarationAPI,
  updateHealthDeclarationAPI
} from 'services/healthDeclaration';
import { mockAttachment2 } from 'mock/attachment';

const initialState: RootState.ReservationState = {
  reservations: [],
  selectedReservation: undefined,
  completedReservationDetail: undefined,
  isLoading: false,
  reservationServiceError: undefined
};

//Actions
//More info here https://redux-toolkit.js.org/api/createAsyncThunk
export const getCheckInReservationWithConfirmationId = createAsyncThunk<
  ReservationResponseModel,
  { hotelId: number; confirmationNo: string; lastName: string },
  { rejectValue: AxiosError<Error> }
>('reservation/getReservationByConfirmationId', async (input, thunkApi) => {
  const { hotelId, confirmationNo, lastName } = input;
  try {
    let data: ReservationResponseModel = await getReservationByConfirmationNoAPI(
      hotelId,
      confirmationNo,
      lastName,
      false
    );

    return data;
  } catch (err) {
    let error = err as AxiosError<Error>; // cast the error for access
    if (!error.response) {
      throw err;
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    return thunkApi.rejectWithValue(error);
  }
});

export const getReservationWithEmail = createAsyncThunk<
  ReservationResponseModel,
  { hotelId: number; email: string },
  { rejectValue: AxiosError<Error> }
>('reservation/getReservationByEmail', async (input, thunkApi) => {
  const { hotelId, email } = input;
  try {
    let data: ReservationResponseModel = await getReservationByEmailAPI(hotelId, email);

    return data;
  } catch (err) {
    let error = err as AxiosError<Error>; // cast the error for access
    if (!error.response) {
      throw err;
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    return thunkApi.rejectWithValue(error);
  }
});

export const getReservationWithGuid = createAsyncThunk<
  ReservationResponseModel,
  { guid: string },
  { rejectValue: AxiosError<Error> }
>('reservation/getReservationByGuid', async (input, thunkApi) => {
  const { guid } = input;
  try {
    let data: ReservationResponseModel = await getReservationByGuidEAPI(guid);

    return data;
  } catch (err) {
    let error = err as AxiosError<Error>; // cast the error for access
    if (!error.response) {
      throw err;
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    return thunkApi.rejectWithValue(error);
  }
});

export const getCheckoutReservationWithRoomNo = createAsyncThunk<
  ReservationResponseModel,
  { hotelId: number; roomNo: string; lastName: string },
  { rejectValue: AxiosError<Error> }
>('reservation/getCheckoutReservationByConfirmationId', async (input, thunkApi) => {
  const { hotelId, roomNo, lastName } = input;
  try {
    let data: ReservationResponseModel = await getCheckOutReservationByConfirmationIdAPI(hotelId, roomNo, lastName);

    return data;
  } catch (err) {
    let error = err as AxiosError<Error>; // cast the error for access
    if (!error.response) {
      throw err;
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    return thunkApi.rejectWithValue(error);
  }
});

export const addNewGuestToReservation = createAsyncThunk<
  CombineSharerResponseModel | null,
  { guestInfo: CreateGuestRequestModel },
  { rejectValue: AxiosError<Error> }
>('reservation/addNewGuestToReservation', async (input, thunkApi) => {
  const { guestInfo } = input;
  const { hotelState, reservationState }: RootState = thunkApi.getState() as RootState;

  try {
    const createGuestResponse: CreateGuestResponseModel = await createGuestAPI(guestInfo);

    if (createGuestResponse.resultCode === ARIA_SuccessCode) {
      if (hotelState.selectedHotel != null && reservationState.selectedReservation != undefined) {
        var updatingReservation = reservationState.selectedReservation.sharedReservations?.find(
          (res) => res.guest.profileId === createGuestResponse.result.profileId
        );

        if (
          updatingReservation == undefined &&
          reservationState.selectedReservation.guest.profileId === createGuestResponse.result.profileId
        ) {
          updatingReservation = reservationState.selectedReservation;
        }

        let verifyResponse: VerifyReservationResponseModel = await verifyReservationAPI({
          hotelId: hotelState.selectedHotel.id,
          confirmationNo: updatingReservation!.confirmationNo,
          guestId: createGuestResponse.result.id,
          primaryConfirmationNo: reservationState.selectedReservation.confirmationNo,
          verified: true
        });

        if (verifyResponse.resultCode === ARIA_SuccessCode) {
          let data: CombineSharerResponseModel = await combineSharerAPI(
            hotelState.selectedHotel?.id,
            reservationState.selectedReservation.confirmationNo,
            createGuestResponse.result.profileId
          );

          return data;
        }
      }
    }

    return null;
  } catch (err) {
    let error = err as AxiosError<Error>; // cast the error for access
    if (!error.response) {
      throw err;
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    return thunkApi.rejectWithValue(error);
  }
});

export const updateGuestForReservation = createAsyncThunk<
  UpdateGuestResponseModel,
  { guestInfo: UpdateGuestRequestModel },
  { rejectValue: AxiosError<Error> }
>('reservation/updateGuestToReservation', async (input, thunkApi) => {
  const { guestInfo } = input;
  const { hotelState, reservationState }: RootState = thunkApi.getState() as RootState;

  try {
    console.log(guestInfo);
    let updateGuestResponse: UpdateGuestResponseModel = await updateGuestAPI(guestInfo);
    if (hotelState.selectedHotel != undefined && reservationState.selectedReservation != undefined) {
      var updatingReservation = reservationState.selectedReservation.sharedReservations?.find(
        (res) => res.guest.profileId === updateGuestResponse.result.profileId
      );

      if (
        updatingReservation == undefined &&
        reservationState.selectedReservation.guest.profileId === updateGuestResponse.result.profileId
      ) {
        updatingReservation = reservationState.selectedReservation;
      }

      verifyReservationAPI({
        hotelId: hotelState.selectedHotel.id,
        confirmationNo: updatingReservation!.confirmationNo,
        guestId: updateGuestResponse.result.id,
        primaryConfirmationNo: reservationState.selectedReservation.confirmationNo,
        verified: true
      });
    }

    return updateGuestResponse;
  } catch (err) {
    let error = err as AxiosError<Error>; // cast the error for access
    if (!error.response) {
      throw err;
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    return thunkApi.rejectWithValue(error);
  }
});

export const removeGuestForReservation = createAsyncThunk<
  string,
  { hotelId: number; mainConfirmationId: string; shareConfirmationId: string },
  { rejectValue: AxiosError<Error> }
>('reservation/removeGuestForReservation', async (input, thunkApi) => {
  const { hotelId, mainConfirmationId, shareConfirmationId } = input;
  try {
    let data: BreakSharerResponseModel = await breakSharer(hotelId, mainConfirmationId, shareConfirmationId);

    if (data.resultCode === ARIA_SuccessCode) {
      return shareConfirmationId;
    } else {
      return '';
    }
  } catch (err) {
    let error = err as AxiosError<Error>; // cast the error for access
    if (!error.response) {
      throw err;
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    return thunkApi.rejectWithValue(error);
  }
});

export const createHealthDeclarationForGuest = createAsyncThunk<
  CreateHealthDeclarationResponseModel,
  { request: CreateHealthDeclarationRequestModel },
  { rejectValue: AxiosError<Error> }
>('reservation/createHealthDeclarationForGuest', async (input, thunkApi) => {
  const { request } = input;
  try {
    let data: CreateHealthDeclarationResponseModel = await createHealthDeclarationAPI(request);
    return data;
  } catch (err) {
    let error = err as AxiosError<Error>; // cast the error for access
    if (!error.response) {
      throw err;
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    return thunkApi.rejectWithValue(error);
  }
});

export const updateHealthDeclarationForGuest = createAsyncThunk<
  HealthDeclaration,
  { request: UpdateHealthDeclarationRequestModel },
  { rejectValue: AxiosError<Error> }
>('reservation/updateHealthDeclarationForGuest', async (input, thunkApi) => {
  const { request } = input;
  try {
    let data: UpdateHealthDeclarationResponseModel = await updateHealthDeclarationAPI(request);

    if(data.resultCode === ARIA_SuccessCode) {
      return ({...request});
    } else {
      throw data.resultCode
    }
    
  } catch (err) {
    let error = err as AxiosError<Error>; // cast the error for access
    if (!error.response) {
      throw err;
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    return thunkApi.rejectWithValue(error);
  }
});

export const getHealthDeclarationForGuest = createAsyncThunk<
  GetHealthDeclarationResponseModel,
  { request: GetHealthDeclarationRequestModel },
  { rejectValue: AxiosError<Error> }
>('reservation/getHealthDeclarationForGuest', async (input, thunkApi) => {
  const { request } = input;
  try {
    let data: GetHealthDeclarationResponseModel = await getHealthDeclarationAPI(request);
    return data;
  } catch (err) {
    let error = err as AxiosError<Error>; // cast the error for access
    if (!error.response) {
      throw err;
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    return thunkApi.rejectWithValue(error);
  }
});

export const updateSignedAcknowledgement = createAsyncThunk<
  SignAcknowledgementResponseModel,
  void,
  { rejectValue: AxiosError<Error> }
>('reservation/updateSignedAcknowledgement', async (_, thunkApi) => {
  try {
    const { reservationState, hotelState }: RootState = thunkApi.getState() as RootState;
    var signedConfirmationNos: string[] = [];

    if (reservationState.selectedReservation?.signedAcknowledgement) {
      signedConfirmationNos.push(reservationState.selectedReservation.confirmationNo);
    }

    reservationState.selectedReservation?.sharedReservations?.filter((reservation) => {
      if (reservation.signedAcknowledgement) {
        signedConfirmationNos.push(reservation.confirmationNo);
      }
    });

    let data: SignAcknowledgementResponseModel = await updateSignAcknowledgementAPI({
      hotelId: hotelState.selectedHotel!.id,
      confirmationNos: signedConfirmationNos
    });
    return data;
  } catch (err) {
    let error = err as AxiosError<Error>; // cast the error for access
    if (!error.response) {
      throw err;
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    return thunkApi.rejectWithValue(error);
  }
});

export const preRegisterReservation = createAsyncThunk<
  PreRegistrationResponseModel,
  { request: PreRegistrationRequestModel },
  { rejectValue: AxiosError<Error> }
>('reservation/preRegisterReservation', async (input, thunkApi) => {
  const { request } = input;
  try {
    let data: PreRegistrationResponseModel = await preRegisterAPI(request);
    return data;
  } catch (err) {
    let error = err as AxiosError<Error>; // cast the error for access
    if (!error.response) {
      throw err;
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    return thunkApi.rejectWithValue(error);
  }
});

export const subscribeRoomForReservation = createAsyncThunk<
  SubscribeRoomResponseModel,
  { request: SubscribeRoomRequestModel },
  { rejectValue: AxiosError<Error> }
>('reservation/subscribeRoomForReservation', async (input, thunkApi) => {
  const { request } = input;

  try {
    let data: SubscribeRoomResponseModel = await subscribeRoomAPI(request);
    return data;
  } catch (err) {
    let error = err as AxiosError<Error>; // cast the error for access
    if (!error.response) {
      throw err;
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    return thunkApi.rejectWithValue(error);
  }
});

export const getPreCheckOutBill = createAsyncThunk<
  GetPreCheckOutBillResponseModel,
  { request: GetPreCheckOutBillRequestModel },
  { rejectValue: AxiosError<Error> }
>('reservation/getPreCheckOutBill', async (input, thunkApi) => {
  const { request } = input;
  try {
    let data: GetPreCheckOutBillResponseModel = await getPreCheckOutBillAPI(request);

    return data;
  } catch (err) {
    let error = err as AxiosError<Error>; // cast the error for access
    if (!error.response) {
      throw err;
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    return thunkApi.rejectWithValue(error);
  }
});

export const createGuestEmail = createAsyncThunk<
  CreateGuestEmailResponseModel,
  { email: string },
  { rejectValue: AxiosError<Error> }
>('reservation/createGuestEmail', async (input, thunkApi) => {
  try {
    const { reservationState, hotelState }: RootState = thunkApi.getState() as RootState;
    const { selectedReservation } = reservationState;
    const { email } = input;

    let data: CreateGuestEmailResponseModel = await createGuestEmailAPI({
      hotelId: hotelState.selectedHotel!.id,
      profileId: selectedReservation!.guest.profileId,
      email: email
    });

    return data;
  } catch (err) {
    let error = err as AxiosError<Error>; // cast the error for access
    if (!error.response) {
      throw err;
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    return thunkApi.rejectWithValue(error);
  }
});

export const updateGuestEmail = createAsyncThunk<
  UpdateGuestEmailModel,
  { request: UpdateGuestEmailRequestModel },
  { rejectValue: AxiosError<Error> }
>('reservation/updateGuestEmail', async (input, thunkApi) => {
  try {
    const { request } = input;

    let response: UpdateGuestEmailResponseModel = await updateGuestEmailAPI(request);

    let data: UpdateGuestEmailModel = {
      result: { ...request },
      resultCode: response.resultCode,
      resultDescription: response.resultDescription
    };

    return data;
  } catch (err) {
    let error = err as AxiosError<Error>; // cast the error for access
    if (!error.response) {
      throw err;
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    return thunkApi.rejectWithValue(error);
  }
});

export const checkOutReservation = createAsyncThunk<
  CheckOutReservationResponseModel,
  { request: CheckOutReservationRequestModel },
  { rejectValue: AxiosError<Error> }
>('reservation/checkOutReservation', async (input, thunkApi) => {
  try {
    const { request } = input;

    let data: CheckOutReservationResponseModel = await checkOutReservationAPI(request);

    return data;
  } catch (err) {
    let error = err as AxiosError<Error>; // cast the error for access
    if (!error.response) {
      throw err;
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    return thunkApi.rejectWithValue(error);
  }
});

//Slice
//More info here https://redux-toolkit.js.org/api/createSlice
export const reservationSlice = createSlice({
  name: 'reservation',
  initialState,
  reducers: {
    //local reducer
    selectReservation(state, action) {
      if (action.payload == null) {
        state.reservations = [];
      }
      state.selectedReservation = action.payload;
    },
    checkAcknowledgement(state, { payload }) {
      if (state.selectedReservation != null) {
        if (state.selectedReservation.guest.profileId === payload) {
          state.selectedReservation = { ...state.selectedReservation, signedAcknowledgement: true };
        } else {
          const index = state.selectedReservation?.sharedReservations?.findIndex((x) => x.guest.profileId === payload);

          if (
            index !== undefined &&
            index > -1 &&
            state.selectedReservation &&
            state.selectedReservation.sharedReservations &&
            state.selectedReservation.sharedReservations.length > 0
          ) {
            state.selectedReservation.sharedReservations[index] = {
              ...state.selectedReservation.sharedReservations[index],
              signedAcknowledgement: true
            };
          }
        }
      }
    },
    uncheckAcknowledgement(state, { payload }) {
      if (state.selectedReservation != null) {
        if (state.selectedReservation.guest.profileId === payload) {
          state.selectedReservation = { ...state.selectedReservation, signedAcknowledgement: false };
        } else {
          const index = state.selectedReservation?.sharedReservations?.findIndex((x) => x.guest.profileId === payload);

          if (
            index !== undefined &&
            index > -1 &&
            state.selectedReservation &&
            state.selectedReservation.sharedReservations &&
            state.selectedReservation.sharedReservations.length > 0
          ) {
            state.selectedReservation.sharedReservations[index] = {
              ...state.selectedReservation.sharedReservations[index],
              signedAcknowledgement: false
            };
          }
        }
      }
    },
    completedPaymentForSelectedReservation(state) {
      if (state.selectedReservation != null) {
        state.selectedReservation = { ...state.selectedReservation, paid: true };
      }
    },
    resetReservationState() {
      return initialState;
    }
  },
  //services reducers
  extraReducers: (builder) => {
    //getReservationWithConfirmationId
    builder.addCase(getCheckInReservationWithConfirmationId.fulfilled, (state, { payload }) => {
      if (payload.resultCode === ARIA_SuccessCode) {
        state.reservations = payload.result;

        if (payload.result.length == 1) {
          state.selectedReservation = payload.result[0];
        }
        state.reservationServiceError = undefined;
      } else {
        state.reservationServiceError = { ...payload };
      }

      state.isLoading = false;
    }),
      builder.addCase(getCheckInReservationWithConfirmationId.pending, (state, _) => {
        state.isLoading = true;
      }),
      builder.addCase(getCheckInReservationWithConfirmationId.rejected, (state, { payload }) => {
        if (payload != undefined) {
          let error: AxiosError<Error> = payload;
          let serverError: ResponseModel = error.response?.data as unknown as ResponseModel;

          state.reservationServiceError = serverError;
          state.isLoading = false;
        }
      });

    //getReservationWithEmail
    builder.addCase(getReservationWithEmail.fulfilled, (state, { payload }) => {
      state.reservations = payload.result;

      if (payload.result.length == 1) {
        state.selectedReservation = payload.result[0];
      }

      state.reservationServiceError = undefined;
      state.isLoading = false;
    }),
      builder.addCase(getReservationWithEmail.pending, (state, _) => {
        state.isLoading = true;
      }),
      builder.addCase(getReservationWithEmail.rejected, (state, { payload }) => {
        if (payload != undefined) {
          let error: AxiosError<Error> = payload;
          let serverError: ResponseModel = error.response?.data as unknown as ResponseModel;

          state.reservationServiceError = serverError;
          state.isLoading = false;
        }
      });

    //getReservationByGuid
    builder.addCase(getReservationWithGuid.fulfilled, (state, { payload }) => {
      state.reservations = payload.result;

      if (payload.result.length == 1) {
        state.selectedReservation = payload.result[0];
      }

      state.reservationServiceError = undefined;
      state.isLoading = false;
    }),
      builder.addCase(getReservationWithGuid.pending, (state, _) => {
        state.isLoading = true;
      }),
      builder.addCase(getReservationWithGuid.rejected, (state, { payload }) => {
        if (payload != undefined) {
          let error: AxiosError<Error> = payload;
          let serverError: ResponseModel = error.response?.data as unknown as ResponseModel;

          state.reservationServiceError = serverError;
          state.isLoading = false;
        }
      });

    //getCheckoutReservationWithConfirmationId
    builder.addCase(getCheckoutReservationWithRoomNo.fulfilled, (state, { payload }) => {
      if (payload.resultCode === ARIA_SuccessCode) {
        state.reservations = payload.result;

        if (payload.result.length == 1) {
          state.selectedReservation = payload.result[0];
        }
        state.reservationServiceError = undefined;
      } else {
        state.reservationServiceError = { ...payload };
      }

      state.isLoading = false;
    }),
      builder.addCase(getCheckoutReservationWithRoomNo.pending, (state, _) => {
        state.isLoading = true;
      }),
      builder.addCase(getCheckoutReservationWithRoomNo.rejected, (state, { payload }) => {
        if (payload != undefined) {
          let error: AxiosError<Error> = payload;
          let serverError: ResponseModel = error.response?.data as unknown as ResponseModel;

          state.reservationServiceError = serverError;
          state.isLoading = false;
        }
      });

    //Combine Sharer
    builder.addCase(addNewGuestToReservation.fulfilled, (state, { payload }) => {
      if (payload != undefined) {
        state.selectedReservation?.sharedReservations?.push(payload.result);
      }

      state.reservationServiceError = undefined;
      state.isLoading = false;
    }),
      builder.addCase(addNewGuestToReservation.pending, (state, _) => {
        state.isLoading = true;
      }),
      builder.addCase(addNewGuestToReservation.rejected, (state, action) => {
        if (action.payload != undefined) {
          let error: AxiosError<Error> = action.payload;
          let serverError: ResponseModel = error.response?.data as unknown as ResponseModel;

          state.reservationServiceError = serverError;
          state.isLoading = false;
        }
      });

    //Update Sharer
    builder.addCase(updateGuestForReservation.fulfilled, (state, { payload }) => {
      if (payload != undefined) {
        if (state.selectedReservation != null) {
          if (state.selectedReservation.guest.profileId === payload.result.profileId) {
            state.selectedReservation = { ...state.selectedReservation, verified: true, guest: payload.result };
          } else {
            const index = state.selectedReservation?.sharedReservations?.findIndex(
              (x) => x.guest.profileId === payload.result.profileId
            );

            if (
              index !== undefined &&
              index > -1 &&
              state.selectedReservation &&
              state.selectedReservation.sharedReservations &&
              state.selectedReservation.sharedReservations.length > 0
            ) {
              state.selectedReservation.sharedReservations[index] = {
                ...state.selectedReservation.sharedReservations[index],
                verified: true,
                guest: payload.result
              };
            }
          }
        }
      }

      state.reservationServiceError = undefined;
      state.isLoading = false;
    }),
      builder.addCase(updateGuestForReservation.pending, (state, _) => {
        state.isLoading = true;
      }),
      builder.addCase(updateGuestForReservation.rejected, (state, action) => {
        if (action.payload != undefined) {
          let error: AxiosError<Error> = action.payload;
          let serverError: ResponseModel = error.response?.data as unknown as ResponseModel;

          state.reservationServiceError = serverError;
          state.isLoading = false;
        }
      });

    //BreakSharer
    builder.addCase(removeGuestForReservation.fulfilled, (state, { payload }) => {
      const guestIndex = state.selectedReservation?.sharedReservations?.findIndex((x) => x.confirmationNo === payload);
      if (guestIndex != undefined) {
        state.selectedReservation?.sharedReservations?.splice(guestIndex, 1);
      }

      state.reservationServiceError = undefined;
      state.isLoading = false;
    }),
      builder.addCase(removeGuestForReservation.pending, (state, _) => {
        state.isLoading = true;
      }),
      builder.addCase(removeGuestForReservation.rejected, (state, action) => {
        if (action.payload != undefined) {
          let error: AxiosError<Error> = action.payload;
          let serverError: ResponseModel = error.response?.data as unknown as ResponseModel;

          state.reservationServiceError = serverError;
          state.isLoading = false;
        }
      });

    //create Health Declaration
    builder.addCase(createHealthDeclarationForGuest.fulfilled, (state, { payload }) => {
      if (state.selectedReservation != null) {
        console.log(payload.result);

        if (state.selectedReservation.confirmationNo == payload.result.confirmationNo) {
          console.log('here');
          state.selectedReservation.healthDeclaration = payload.result;
        } else {
          const guestIndex = state.selectedReservation?.sharedReservations?.findIndex(
            (x) => x.confirmationNo === payload.result.confirmationNo
          );

          if (
            guestIndex !== undefined &&
            guestIndex > -1 &&
            state.selectedReservation &&
            state.selectedReservation.sharedReservations &&
            state.selectedReservation.sharedReservations.length > 0
          ) {
            state.selectedReservation.sharedReservations[guestIndex] = {
              ...state.selectedReservation.sharedReservations[guestIndex],
              healthDeclaration: payload.result
            };
          }
        }
      }

      state.reservationServiceError = undefined;
      state.isLoading = false;
    }),
      builder.addCase(createHealthDeclarationForGuest.pending, (state, _) => {
        state.isLoading = true;
      }),
      builder.addCase(createHealthDeclarationForGuest.rejected, (state, action) => {
        if (action.payload != undefined) {
          let error: AxiosError<Error> = action.payload;
          let serverError: ResponseModel = error.response?.data as unknown as ResponseModel;

          state.reservationServiceError = serverError;
          state.isLoading = false;
        }
      });

    //Update Health Declaration
    builder.addCase(updateHealthDeclarationForGuest.fulfilled, (state, { payload }) => {
      if (state.selectedReservation != null) {

        if (state.selectedReservation.confirmationNo == payload.confirmationNo) {
          state.selectedReservation.healthDeclaration = payload;
        } else {
          const guestIndex = state.selectedReservation?.sharedReservations?.findIndex(
            (x) => x.confirmationNo === payload.confirmationNo
          );

          if (
            guestIndex !== undefined &&
            guestIndex > -1 &&
            state.selectedReservation &&
            state.selectedReservation.sharedReservations &&
            state.selectedReservation.sharedReservations.length > 0
          ) {
            state.selectedReservation.sharedReservations[guestIndex] = {
              ...state.selectedReservation.sharedReservations[guestIndex],
              healthDeclaration: payload
            };
          }
        }
      }

      state.reservationServiceError = undefined;
      state.isLoading = false;
    }),
      builder.addCase(updateHealthDeclarationForGuest.pending, (state, _) => {
        state.isLoading = true;
      }),
      builder.addCase(updateHealthDeclarationForGuest.rejected, (state, action) => {
        if (action.payload != undefined) {
          let error: AxiosError<Error> = action.payload;
          let serverError: ResponseModel = error.response?.data as unknown as ResponseModel;

          state.reservationServiceError = serverError;
          state.isLoading = false;
        }
      });

    //Get Health declaration
    builder.addCase(getHealthDeclarationForGuest.fulfilled, (state, { payload }) => {
      if (payload.resultCode === ARIA_SuccessCode) {
        if (state.selectedReservation != null) {
          if (state.selectedReservation.confirmationNo == payload.result.confirmationNo) {
            state.selectedReservation.healthDeclaration = payload.result;
          } else {
            const guestIndex = state.selectedReservation?.sharedReservations?.findIndex(
              (x) => x.confirmationNo === payload.result.confirmationNo
            );

            if (
              guestIndex !== undefined &&
              guestIndex > -1 &&
              state.selectedReservation &&
              state.selectedReservation.sharedReservations &&
              state.selectedReservation.sharedReservations.length > 0
            ) {
              state.selectedReservation.sharedReservations[guestIndex] = {
                ...state.selectedReservation.sharedReservations[guestIndex],
                healthDeclaration: payload.result
              };
            }
          }
        }
        state.reservationServiceError = undefined;
      } else {
        const serverError: ResponseModel = {
          resultCode: payload.resultCode,
          resultDescription: payload.resultDescription
        };

        state.reservationServiceError = serverError;
      }

      state.isLoading = false;
    }),
      builder.addCase(getHealthDeclarationForGuest.pending, (state, _) => {
        state.isLoading = true;
      }),
      builder.addCase(getHealthDeclarationForGuest.rejected, (state, action) => {
        if (action.payload != undefined) {
          let error: AxiosError<Error> = action.payload;
          let serverError: ResponseModel = error.response?.data as unknown as ResponseModel;

          state.reservationServiceError = serverError;
          state.isLoading = false;
        }
      });

    //Update Signed Acknowledgement
    builder.addCase(updateSignedAcknowledgement.fulfilled, (state, { payload }) => {
      if (payload.resultCode === ARIA_SuccessCode) {
        state.reservationServiceError = undefined;
      } else {
        const serverError: ResponseModel = {
          resultCode: payload.resultCode,
          resultDescription: payload.resultDescription
        };

        state.reservationServiceError = serverError;
      }

      state.isLoading = false;
    }),
      builder.addCase(updateSignedAcknowledgement.pending, (state, _) => {
        state.isLoading = true;
      }),
      builder.addCase(updateSignedAcknowledgement.rejected, (state, action) => {
        if (action.payload != undefined) {
          let error: AxiosError<Error> = action.payload;
          let serverError: ResponseModel = error.response?.data as unknown as ResponseModel;

          state.reservationServiceError = serverError;
          state.isLoading = false;
        }
      });

    builder.addCase(preRegisterReservation.fulfilled, (state, { payload }) => {
      if (payload.resultCode === ARIA_SuccessCode || payload.resultCode === ARIA_RESERVATION_ALREADY_PREREGISTERED) {
        if (state.selectedReservation != undefined) {
          state.selectedReservation = {
            ...state.selectedReservation,
            room: { ...state.selectedReservation.room, ...payload.result },
            isPreRegistered: true
          };
        }
        state.reservationServiceError = undefined;
      } else {
        const serverError: ResponseModel = {
          resultCode: payload.resultCode,
          resultDescription: payload.resultDescription
        };

        state.reservationServiceError = serverError;
      }

      state.isLoading = false;
    }),
      builder.addCase(preRegisterReservation.pending, (state, _) => {
        state.isLoading = true;
      }),
      builder.addCase(preRegisterReservation.rejected, (state, action) => {
        if (action.payload != undefined) {
          let error: AxiosError<Error> = action.payload;
          let serverError: ResponseModel = error.response?.data as unknown as ResponseModel;

          state.reservationServiceError = serverError;
          state.isLoading = false;
        }
      });

    //Subscribe to Room
    builder.addCase(subscribeRoomForReservation.fulfilled, (state, { payload }) => {
      if (payload.resultCode === ARIA_SuccessCode) {
        if (state.selectedReservation != undefined) {
          state.selectedReservation = {
            ...state.selectedReservation,
            isSubscribedRoom: true
          };
        }
        state.reservationServiceError = undefined;
      } else {
        const serverError: ResponseModel = {
          resultCode: payload.resultCode,
          resultDescription: payload.resultDescription
        };

        state.reservationServiceError = serverError;
      }

      state.isLoading = false;
    }),
      builder.addCase(subscribeRoomForReservation.pending, (state, _) => {
        state.isLoading = true;
      }),
      builder.addCase(subscribeRoomForReservation.rejected, (state, action) => {
        if (action.payload != undefined) {
          let error: AxiosError<Error> = action.payload;
          let serverError: ResponseModel = error.response?.data as unknown as ResponseModel;

          state.reservationServiceError = serverError;
          state.isLoading = false;
        }
      });

    //Get PreCheckOutBill
    builder.addCase(getPreCheckOutBill.fulfilled, (state, { payload }) => {
      if (payload.resultCode === ARIA_SuccessCode) {
        if (state.selectedReservation != undefined) {
          state.selectedReservation = {
            ...state.selectedReservation,
            attachments: payload.result.attactments
          };
        }
        state.reservationServiceError = undefined;
      } else {
        state.reservationServiceError = { ...payload };
      }

      state.isLoading = false;
    }),
      builder.addCase(getPreCheckOutBill.pending, (state, _) => {
        state.isLoading = true;
      }),
      builder.addCase(getPreCheckOutBill.rejected, (state, action) => {
        if (action.payload != undefined) {
          //////////////////////////////
          //For testing purpose only!!!!
          const response = action.payload.response?.data as unknown as ResponseModel;
          if (response.resultCode == ARIA_PMS_CONNECTOR_ERROR) {
            var attachments: Attachment[] = [];
            attachments.push(mockAttachment2);

            if (state.selectedReservation != undefined) {
              state.selectedReservation = {
                ...state.selectedReservation,
                attachments: attachments
              };
              state.reservationServiceError = undefined;
            }
          } else {
            let error: AxiosError<Error> = action.payload;
            let serverError: ResponseModel = error.response?.data as unknown as ResponseModel;

            state.reservationServiceError = serverError;
          }
          //For testing purpose only!!!!
          //////////////////////////////

          // let error: AxiosError<Error> = action.payload;
          // let serverError: ResponseModel = error.response?.data as unknown as ResponseModel;

          // state.reservationServiceError = serverError;
          state.isLoading = false;
        }
      });

    //Create Guest Email
    builder.addCase(createGuestEmail.fulfilled, (state, { payload }) => {
      if (payload.resultCode === ARIA_SuccessCode) {
        if (state.selectedReservation != undefined) {
          var guestNewEmail = [];
          guestNewEmail.push(payload.result);

          state.selectedReservation = {
            ...state.selectedReservation,
            guest: { ...state.selectedReservation.guest, emails: guestNewEmail }
          };
        }
        state.reservationServiceError = undefined;
      } else {
        state.reservationServiceError = { ...payload };
      }

      state.isLoading = false;
    }),
      builder.addCase(createGuestEmail.pending, (state, _) => {
        state.isLoading = true;
      }),
      builder.addCase(createGuestEmail.rejected, (state, action) => {
        if (action.payload != undefined) {
          let error: AxiosError<Error> = action.payload;
          let serverError: ResponseModel = error.response?.data as unknown as ResponseModel;

          state.reservationServiceError = serverError;
          state.isLoading = false;
        }
      });

    //Update Guest Email
    builder.addCase(updateGuestEmail.fulfilled, (state, { payload }) => {
      if (payload.resultCode === ARIA_SuccessCode) {
        if (state.selectedReservation != undefined && state.selectedReservation.guest.emails != undefined) {
          var index = state.selectedReservation.guest.emails.findIndex(
            (email) => email.contactId == payload.result.contactId
          );

          if (index > -1) {
            state.selectedReservation.guest.emails[index].email = payload.result.email;
          }
        }
        state.reservationServiceError = undefined;
      } else {
        state.reservationServiceError = { ...payload };
      }

      state.isLoading = false;
    }),
      builder.addCase(updateGuestEmail.pending, (state, _) => {
        state.isLoading = true;
      }),
      builder.addCase(updateGuestEmail.rejected, (state, action) => {
        if (action.payload != undefined) {
          let error: AxiosError<Error> = action.payload;
          let serverError: ResponseModel = error.response?.data as unknown as ResponseModel;

          state.reservationServiceError = serverError;
          state.isLoading = false;
        }
      });

    //CheckOut Reservation
    builder.addCase(checkOutReservation.fulfilled, (state, { payload }) => {
      if (payload.resultCode === ARIA_SuccessCode) {
        state.reservationServiceError = undefined;
      } else {
        state.reservationServiceError = { ...payload };
      }

      state.isLoading = false;
    }),
      builder.addCase(checkOutReservation.pending, (state, _) => {
        state.isLoading = true;
      }),
      builder.addCase(checkOutReservation.rejected, (state, action) => {
        if (action.payload != undefined) {
          let error: AxiosError<Error> = action.payload;
          let serverError: ResponseModel = error.response?.data as unknown as ResponseModel;

          state.reservationServiceError = serverError;
          state.isLoading = false;
        }
      });
  }
});

export const {
  selectReservation,
  checkAcknowledgement,
  uncheckAcknowledgement,
  completedPaymentForSelectedReservation,
  resetReservationState
} = reservationSlice.actions;

export default reservationSlice.reducer;
