import { createSlice } from "@reduxjs/toolkit";

import { RootState } from "../store/state";

const initialState: RootState.AppState = {
  isMainDrawerOpen: false,
  isMainDrawerLocked: false,
};

//Actions
//More info here https://redux-toolkit.js.org/api/createAsyncThunk

//Slice
//More info here https://redux-toolkit.js.org/api/createSlice
export const appSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    //local reducer
    setMainDrawer(state, action) {
      state.isMainDrawerOpen = action.payload;
    },
    setMainDrawerLock(state, action) {
      state.isMainDrawerLocked = action.payload;
    },
  },
  //services reducers
  extraReducers: (builder) => {},
});

export const { setMainDrawer, setMainDrawerLock } = appSlice.actions;

export default appSlice.reducer;
