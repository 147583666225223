import React, { useState } from "react";
import { useSelector } from "react-redux";

import { RootState } from "reducers";

//Component
import {
  DataGrid,
  GridColDef,
  GridColumns,
  GridToolbarContainer,
} from "@material-ui/data-grid";
import CustomLoadingOverlay from "components/DataGridLoadingOverlay";
import CustomNoRowsOverlay from "components/DataGridNoRowsOverlay";
import { Function, ARIA_SuccessCode } from "models";
import { DataGridFilterToolbar } from "components/DataGridFilterToolbar";
import { GridColFilterDef } from "components/DataGridFilterSelection";
//Icon

//Helper
import { convertDateTimeToString } from "utils/dateHelper";
import { Data } from "react-csv/components/CommonPropTypes";
import { ExportButton } from "components/ExportButton";
import { MainDrawer } from "components/MainDrawer";
import {
  createFunctionAPI,
  deleteFunctionAPI,
  getFunctionListAPI,
  updateFunctionAPI,
} from "services/functionService";
import { AccessControlButton } from "components";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { HttpMethodList } from "models/backend/constant/HttpMethod";
import { IdentityRoleSelector } from "components/IdentityRoleSelector";
import {
  PM_ADD_FUNCTION_CODE,
  PM_DELETE_FUNCTION_CODE,
  PM_EDIT_FUNCTION_CODE,
} from "models/backend/constant/PermissionCode";
import { GRID_FILTER_TYPE_IDENTITYROLE } from "components/DataGridFilterSelection/type";

export const FunctionManagementScreen = () => {
  const { selectedHotel } = useSelector((state: RootState) => state.hotelState);

  const [index, setIndex] = useState<number>(1);
  const [size, setSize] = useState<number>(10);
  const [row, setRow] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isExporting, setIsExporting] = useState(false);

  const [requestDateTime, setRequestDateTime] = useState<Date>(new Date());

  const [functionList, setFunctionList] = useState<Function[]>([]);
  const [exportList, setExportList] = useState<Data>([]);
  const [filterList, setFilterList] = useState<GridColFilterDef[]>([]);

  const [loadList, setloadList] = useState(true);

  const [addFunction, setAddFunction] = useState<any | undefined>();
  const [editFunction, setEditFunction] = useState<Function | undefined>();
  const [removeFunction, setRemoveFunction] = useState<Function | undefined>();
  const [deleteConfirmation, setDeleteConfirmation] = useState("");

  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  //Setting up the each column header item
  const functionCodeHeader: GridColDef = {
    field: "code",
    headerName: "Code",
    sortable: false,
    renderHeader: (params: any) => <strong>{"Code"}</strong>,
    minWidth: 180,
    flex: 1,
  };

  const functionNameHeader: GridColDef = {
    field: "name",
    headerName: "Name",
    sortable: false,
    renderHeader: () => <strong>{"Name"}</strong>,
    minWidth: 200,
    flex: 1,
  };

  const functionModuleHeader: GridColDef = {
    field: "module",
    headerName: "Module",
    sortable: false,
    renderHeader: () => <strong>{"Module"}</strong>,
    minWidth: 80,
    flex: 1,
  };

  const functionMethodHeader: GridColDef = {
    field: "httpMethod",
    headerName: "Method",
    sortable: false,
    renderHeader: () => <strong>{"Method"}</strong>,
    width: 80,
  };

  const functionPathHeader: GridColDef = {
    field: "path",
    headerName: "Path",
    sortable: false,
    renderHeader: () => <strong>{"Path"}</strong>,
    minWidth: 200,
    flex: 1,
  };

  const functionIdentityRoleHeader: GridColDef = {
    field: "identityRoles",
    headerName: "Authorized",
    sortable: false,
    type: GRID_FILTER_TYPE_IDENTITYROLE,
    renderHeader: () => <strong>{"Authorized"}</strong>,
    minWidth: 180,
    flex: 1,
  };

  const actionsHeader: GridColDef = {
    field: "id",
    headerName: "Actions",
    minWidth: 240,
    filterable: false,
    sortable: false,
    disableColumnMenu: true,
    renderHeader: () => <strong>{"Actions"}</strong>,
    renderCell: (params: any) => (
      <strong>
        <AccessControlButton
          code={PM_EDIT_FUNCTION_CODE}
          variant="contained"
          color="primary"
          size="small"
          value={params.value}
          onClick={handleOpenEditDialog}
        >
          Edit
        </AccessControlButton>
        <AccessControlButton
          code={PM_ADD_FUNCTION_CODE}
          variant="contained"
          color="primary"
          size="small"
          value={params.value}
          style={{ marginLeft: 12 }}
          onClick={handleDuplicateFunctionClick}
        >
          Clone
        </AccessControlButton>
        <AccessControlButton
          code={PM_DELETE_FUNCTION_CODE}
          variant="contained"
          color="primary"
          size="small"
          value={params.value}
          style={{ marginLeft: 12 }}
          onClick={handleOpenDeleteDialog}
        >
          Delete
        </AccessControlButton>
      </strong>
    ),
  };

  const headerColumn: GridColumns = [
    functionNameHeader,
    functionCodeHeader,
    functionModuleHeader,
    functionMethodHeader,
    functionPathHeader,
    functionIdentityRoleHeader,
    actionsHeader,
  ];

  React.useEffect(() => {
    if (selectedHotel != undefined && loadList) {
      setIsLoading(true);
      setFunctionList([]);
      setRequestDateTime(new Date());

      getFunctionListAPI({
        filter: filterList,
        pageIndex: index,
        pageSize: size,
        orderBy: "module",
      })
        .then((response) => {
          if (response.resultCode === ARIA_SuccessCode) {
            setFunctionList(response.result.items);
            setRow(response.result.rowCount);
          }
        })
        .finally(() => {
          setloadList(false);
          setIsLoading(false);
        });
    }
  }, [index, size, filterList, selectedHotel, loadList]);

  const getExportTransaction = async () => {
    setIsLoading(true);
    var _exportIndex = 1;
    var exportList: Function[] = [];

    try {
      do {
        var response = await getFunctionListAPI({
          filter: filterList,
          pageIndex: _exportIndex,
          pageSize: size,
          orderBy: "module",
        });

        exportList.push(...response.result.items);

        _exportIndex++;
      } while (response.result.pageCount >= _exportIndex);

      setExportList(exportList);
      setIsExporting(true);
      setIsLoading(false);
    } catch (err) {
      console.log("Oposs.. Something is wrong");
    }
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <DataGridFilterToolbar
          headerColumns={headerColumn}
          filterList={filterList}
          onFilterApply={(items) => {
            setFilterList(items.slice());
            setloadList(true);
          }}
        />
        <ExportButton
          filename="Function"
          data={exportList}
          headers={[
            { label: "Name", key: "name" },
            { label: "Code", key: "code" },
            { label: "Module", key: "module" },
            { label: "Method", key: "httpMethod" },
            { label: "Path", key: "path" },
            { label: "Authorized", key: "identityRoles" },
          ]}
          readyToExport={isExporting}
          onClick={getExportTransaction}
          onFinishDownload={() => {
            setExportList([]);
            setIsExporting(false);
          }}
        >
          Export
        </ExportButton>
        <div style={{ flexGrow: 1 }}></div>
        <AccessControlButton
          code={PM_ADD_FUNCTION_CODE}
          variant="contained"
          color="primary"
          size="small"
          onClick={handleOpenCreateDialog}
        >
          New Function
        </AccessControlButton>
        <div style={{ paddingLeft: "8px" }}>
          <b>Data as at </b>: {convertDateTimeToString(requestDateTime)}
        </div>
      </GridToolbarContainer>
    );
  }

  const handleOpenCreateDialog = () => {
    setOpenEditDialog(true);
  };

  const handleDuplicateFunctionClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    var _updateFunction = functionList.find(
      (f) => f.id === parseInt(event.currentTarget.value)
    );

    setAddFunction(_updateFunction);
    setOpenEditDialog(true);
  };

  const handleOpenEditDialog = (event: React.MouseEvent<HTMLButtonElement>) => {
    var updateFunction = functionList.find(
      (f) => f.id === parseInt(event.currentTarget.value)
    );
    setEditFunction(updateFunction);
    // setSelectedIsAudited(updateFunction.IsAudited)
    setOpenEditDialog(true);
  };

  const handleOpenDeleteDialog = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    var _removeFunction = functionList.find(
      (f) => f.id === parseInt(event.currentTarget.value)
    );
    setRemoveFunction(_removeFunction);
    setOpenDeleteDialog(true);
  };

  const handleEditOrAdd = () => {
    if (editFunction == undefined) {
      if (
        addFunction == undefined ||
        addFunction.name == undefined ||
        addFunction.name === "" ||
        addFunction.code == undefined ||
        addFunction.code === "" ||
        addFunction.module == undefined ||
        addFunction.module === "" ||
        addFunction.path == undefined ||
        addFunction.path === "" ||
        addFunction.identityRoles == undefined ||
        addFunction.identityRoles === ""
      ) {
        alert("Please fill up the details below to add a new function.");
      } else {
        createFunctionAPI({ ...addFunction })
          .catch((err) => {
            alert(err);
          })
          .finally(() => {
            setloadList(true);
            handleEditClose();
          });
      }
    } else {
      if (
        editFunction.name == undefined ||
        editFunction.name === "" ||
        editFunction.code == undefined ||
        editFunction.code === "" ||
        editFunction.module == undefined ||
        editFunction.module === "" ||
        editFunction.path == undefined ||
        editFunction.path === "" ||
        editFunction.identityRoles == undefined ||
        editFunction.identityRoles === ""
      ) {
        alert("Please fill up the details below to edit function.");
      } else {
        updateFunctionAPI({ ...editFunction })
          .catch((err) => {
            alert(err);
          })
          .finally(() => {
            setloadList(true);
            handleEditClose();
          });
      }
    }
  };

  const handleDelete = () => {
    if (deleteConfirmation !== removeFunction?.name) {
      alert("Please key in the function name to confirm deletion.");
    } else {
      deleteFunctionAPI({ ...removeFunction })
        .catch((err) => {
          alert(err);
        })
        .finally(() => {
          setDeleteConfirmation("");
          setloadList(true);
          handleEditClose();
        });
    }
  };

  const handleEditClose = () => {
    setAddFunction(undefined);
    setEditFunction(undefined);
    setRemoveFunction(undefined);
    setOpenEditDialog(false);
    setOpenDeleteDialog(false);
  };

  return (
    <>
      <MainDrawer title="Function Management">
        {/* Add and Edit Dialog */}
        <Dialog
          open={openEditDialog}
          onClose={handleEditClose}
          aria-labelledby="form-dialog-title"
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle id="form-dialog-title">
            {editFunction != undefined ? "Edit Function" : "Add Function"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {editFunction != undefined
                ? "Change the detail below and save it to update function details, ."
                : "Fill up the details below to add a new function."}
            </DialogContentText>
            <form noValidate autoComplete="off">
              <div>
                <TextField
                  margin="dense"
                  id="name"
                  label="Function Name"
                  type="text"
                  value={
                    editFunction != undefined
                      ? editFunction.name
                      : addFunction?.name
                  }
                  onChange={(e) => {
                    editFunction != undefined
                      ? setEditFunction({
                          ...editFunction,
                          name: e.currentTarget.value,
                        })
                      : setAddFunction({
                          ...addFunction,
                          name: e.currentTarget.value,
                        });
                  }}
                  fullWidth
                />
                <TextField
                  margin="dense"
                  id="code"
                  label="Code"
                  type="text"
                  value={
                    editFunction != undefined
                      ? editFunction.code
                      : addFunction?.code
                  }
                  onChange={(e) => {
                    editFunction != undefined
                      ? setEditFunction({
                          ...editFunction,
                          code: e.currentTarget.value,
                        })
                      : setAddFunction({
                          ...addFunction,
                          code: e.currentTarget.value,
                        });
                  }}
                  fullWidth
                />
                <TextField
                  margin="dense"
                  id="path"
                  label="Module"
                  type="text"
                  value={
                    editFunction != undefined
                      ? editFunction.module
                      : addFunction?.module
                  }
                  onChange={(e) => {
                    editFunction != undefined
                      ? setEditFunction({
                          ...editFunction,
                          module: e.currentTarget.value,
                        })
                      : setAddFunction({
                          ...addFunction,
                          module: e.currentTarget.value,
                        });
                  }}
                  fullWidth
                />
                <TextField
                  margin="dense"
                  id="httpMethod"
                  label="HTTP Method"
                  select
                  value={
                    editFunction != undefined
                      ? editFunction.httpMethod.toUpperCase()
                      : addFunction?.httpMethod != undefined
                      ? addFunction.httpMethod.toUpperCase()
                      : ""
                  }
                  onChange={(e) => {
                    editFunction != undefined
                      ? setEditFunction({
                          ...editFunction,
                          httpMethod: e.target.value,
                        })
                      : setAddFunction({
                          ...addFunction,
                          httpMethod: e.target.value,
                        });
                  }}
                  fullWidth
                >
                  {HttpMethodList.map((method, index) => (
                    <MenuItem key={index} value={method.value}>
                      {method.label}
                    </MenuItem>
                  ))}
                </TextField>

                <TextField
                  margin="dense"
                  id="path"
                  label="Path"
                  type="text"
                  value={
                    editFunction != undefined
                      ? editFunction.path
                      : addFunction?.path
                  }
                  onChange={(e) => {
                    editFunction != undefined
                      ? setEditFunction({
                          ...editFunction,
                          path: e.currentTarget.value,
                        })
                      : setAddFunction({
                          ...addFunction,
                          path: e.currentTarget.value,
                        });
                  }}
                  fullWidth
                />
                <IdentityRoleSelector
                  label="Authorized"
                  value={
                    editFunction != undefined
                      ? editFunction.identityRoles.split(",")
                      : addFunction?.identityRoles?.split(",")
                  }
                  onChange={(roles) => {
                    editFunction != undefined
                      ? setEditFunction({
                          ...editFunction,
                          identityRoles: roles,
                        })
                      : setAddFunction({
                          ...addFunction,
                          identityRoles: roles,
                        });
                  }}
                />

                {/* {isAuditedList.length > 0 ? (
                <TextField
                  id="isAudited"
                  select
                  margin="dense"
                  fullWidth
                  label="Logging"
                  value={selectedIsAudited}
                  onChange={(e) => setSelectedIsAudited(e.target.value)}
                  helperText="Is this function going to be log?"
                >
                  {isAuditedList.map((choice) => (
                    <MenuItem key={choice.name} value={choice.value}>
                      {choice.name}
                    </MenuItem>
                  ))}
                </TextField>
              ) : null} */}
              </div>
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleEditClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleEditOrAdd} color="primary">
              {editFunction != undefined ? `Save & Update` : `Add Function`}
            </Button>
          </DialogActions>
        </Dialog>

        {/* delete Dialog */}
        {removeFunction != undefined ? (
          <Dialog
            open={openDeleteDialog}
            onClose={handleEditClose}
            aria-labelledby="form-dialog-title"
            fullWidth
            maxWidth="sm"
          >
            <DialogTitle id="form-dialog-title">Delete Function</DialogTitle>
            <DialogContent>
              <form noValidate autoComplete="off">
                <div>
                  <TextField
                    margin="dense"
                    id="name"
                    label="Function Name"
                    type="text"
                    disabled
                    value={removeFunction.name}
                    fullWidth
                  />
                  <TextField
                    margin="dense"
                    id="code"
                    label="Code"
                    type="text"
                    disabled
                    value={removeFunction.code}
                    fullWidth
                  />
                  <TextField
                    margin="dense"
                    id="module"
                    label="Module"
                    type="text"
                    disabled
                    value={removeFunction.module}
                    fullWidth
                  />
                  <TextField
                    margin="dense"
                    id="httpMethod"
                    label="HTTP Method"
                    select
                    disabled
                    value={removeFunction.httpMethod}
                    fullWidth
                  >
                    {HttpMethodList.map((method, index) => (
                      <MenuItem key={index} value={method.value}>
                        {method.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    margin="dense"
                    id="path"
                    label="Path"
                    type="text"
                    disabled
                    value={removeFunction.path}
                    fullWidth
                  />
                  <IdentityRoleSelector
                    label="Use by"
                    disabled
                    value={removeFunction.identityRoles.split(",")}
                  />

                  <DialogContentText
                    color="textPrimary"
                    style={{ marginTop: "18px" }}
                  >
                    {`Are you sure you want to delete ${removeFunction.name}?`}
                  </DialogContentText>
                  <DialogContentText>
                    Please type the following to confirm:
                  </DialogContentText>
                  <DialogContentText>
                    <i>
                      <b>{removeFunction.name}</b>
                    </i>
                  </DialogContentText>
                  <TextField
                    margin="dense"
                    variant="outlined"
                    id="name"
                    type="text"
                    value={deleteConfirmation}
                    onChange={(e) =>
                      setDeleteConfirmation(e.currentTarget.value)
                    }
                    fullWidth
                  />
                </div>
              </form>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleEditClose} color="primary">
                Cancel
              </Button>
              <Button onClick={handleDelete} color="primary">
                {`Delete`}
              </Button>
            </DialogActions>
          </Dialog>
        ) : null}

        {/* Data Grid */}
        <div style={{ height: "80vh" }}>
          <div style={{ display: "flex", height: "100%" }}>
            <div style={{ flexGrow: 1 }}>
              <DataGrid
                pageSize={size}
                rowsPerPageOptions={[10, 20, 30]}
                onPageSizeChange={(size) => {
                  setSize(size);
                  setloadList(true);
                }}
                disableColumnMenu
                density="compact"
                components={{
                  LoadingOverlay: CustomLoadingOverlay,
                  NoRowsOverlay: CustomNoRowsOverlay,
                  Toolbar: CustomToolbar,
                }}
                pagination
                loading={isLoading}
                paginationMode="server"
                rowCount={row}
                filterMode="server"
                onPageChange={(newPage) => {
                  setIndex(++newPage);
                  setloadList(true);
                }}
                rows={functionList}
                columns={headerColumn}
                getRowId={(row) => row.id}
              />
            </div>
          </div>
        </div>
      </MainDrawer>
    </>
  );
};
