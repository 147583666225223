import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "reducers";
import { fetchFunctionList } from "reducers/function";
import { fetchMenuList } from "reducers/menu";

//Component
import {
  DataGrid,
  GridColDef,
  GridColumns,
  GridToolbarContainer,
} from "@material-ui/data-grid";
import CustomLoadingOverlay from "components/DataGridLoadingOverlay";
import CustomNoRowsOverlay from "components/DataGridNoRowsOverlay";
import { ARIA_SuccessCode, Menu, Role, Function, ID_HOST } from "models";
import { DataGridFilterToolbar } from "components/DataGridFilterToolbar";
import { GridColFilterDef } from "components/DataGridFilterSelection";
import { Data } from "react-csv/components/CommonPropTypes";
import { ExportButton } from "components/ExportButton";
import { MainDrawer } from "components/MainDrawer";
import { AccessControlButton, AccordionFunctionPermission } from "components";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { IdentityRoleSelector } from "components/IdentityRoleSelector";
import { AccordionMenuPermission } from "components/AccordionMenuPermission";

//Helper
import { convertDateTimeToString } from "utils/dateHelper";
import {
  createRoleAPI,
  deleteRoleAPI,
  getRoleListAPI,
  updateRoleAPI,
} from "services/roleService";
import { GRID_FILTER_TYPE_CHILDROLE, GRID_FILTER_TYPE_IDENTITYROLE } from "components/DataGridFilterSelection/type";
import {
  PM_ADD_ROLE_CODE,
  PM_DELETE_ROLE_CODE,
  PM_EDIT_ROLE_CODE,
} from "models/backend/constant/PermissionCode";

export const RoleManagementScreen = () => {
  const dispatch = useDispatch();

  const { selectedHotel } = useSelector((state: RootState) => state.hotelState);
  const { hotelGroupList } = useSelector(
    (state: RootState) => state.hotelGroupState
  );
  const { role, childRoleList, roleList } = useSelector(
    (state: RootState) => state.roleState
  );

  const [index, setIndex] = useState<number>(1);
  const [size, setSize] = useState<number>(10);
  const [row, setRow] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isExporting, setIsExporting] = useState(false);

  const [requestDateTime, setRequestDateTime] = useState<Date>(new Date());

  const [roleManagementList, setRoleManagementList] = useState<Role[]>([]);
  const [exportList, setExportList] = useState<Data>([]);
  const [filterList, setFilterList] = useState<GridColFilterDef[]>([]);

  const [loadList, setloadList] = useState(true);

  const [addRole, setAddRole] = useState<any | undefined>();
  const [editRole, setEditRole] = useState<Role | undefined>();
  const [removeRole, setRemoveRole] = useState<Role | undefined>();
  const [deleteConfirmation, setDeleteConfirmation] = useState("");

  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  //Setting up the each column header item
  const roleNameHeader: GridColDef = {
    field: "name",
    headerName: "Name",
    sortable: false,
    renderHeader: () => <strong>{"Name"}</strong>,
    minWidth: 200,
    flex: 1,
  };

  const roleSupervisorHeader: GridColDef = {
    field: "parentRoleId",
    headerName: "Supervisor",
    sortable: false,
    type: GRID_FILTER_TYPE_CHILDROLE,
    renderHeader: () => <strong>{"Supervisor"}</strong>,
    valueGetter: (params) => {
      if (params.value === 0) {
        return "-";
      } else {
        const role = roleList.find((m) => m.id == params.value);

        if (
          role != undefined &&
          role?.hotelGroupId > 0 &&
          hotelGroupList.length > 0
        ) {
          const hotelGroup = hotelGroupList.find(
            (hg) => hg.id === role?.hotelGroupId
          );

          return `${role?.name} (${hotelGroup?.name})`;
        }

        return role?.name;
      }
    },
    minWidth: 200,
    flex: 1,
  };

  const roleHotelGroupHeader: GridColDef = {
    field: "hotelGroupId",
    headerName: "Hotel Group",
    sortable: false,
    type: "hotelGroup",
    renderHeader: () => <strong>{"Hotel Group"}</strong>,
    valueGetter: (params) => {
      if (params.value === 0) {
        return "-";
      } else {
        const hotelGroup = hotelGroupList.find((hg) => hg.id == params.value);
        return hotelGroup?.name;
      }
    },
    minWidth: 200,
    flex: 1,
  };

  const functionIdentityRoleHeader: GridColDef = {
    field: "identityRoles",
    headerName: "Authorized",
    sortable: false,
    type: GRID_FILTER_TYPE_IDENTITYROLE,
    renderHeader: () => <strong>{"Authorized"}</strong>,
    minWidth: 180,
    flex: 1,
  };

  const actionsHeader: GridColDef = {
    field: "id",
    headerName: "Actions",
    minWidth: 240,
    filterable: false,
    sortable: false,
    disableColumnMenu: true,
    renderHeader: () => <strong>{"Actions"}</strong>,
    renderCell: (params: any) => (
      <strong>
        <AccessControlButton
          code={PM_EDIT_ROLE_CODE}
          variant="contained"
          color="primary"
          size="small"
          value={params.value}
          onClick={handleOpenEditDialog}
        >
          Edit
        </AccessControlButton>
        <AccessControlButton
          code={PM_ADD_ROLE_CODE}
          variant="contained"
          color="primary"
          size="small"
          value={params.value}
          style={{ marginLeft: 12 }}
          onClick={handleCloneClick}
        >
          Clone
        </AccessControlButton>
        <AccessControlButton
          code={PM_DELETE_ROLE_CODE}
          variant="contained"
          color="primary"
          size="small"
          value={params.value}
          style={{ marginLeft: 12 }}
          onClick={handleOpenDeleteDialog}
        >
          Delete
        </AccessControlButton>
      </strong>
    ),
  };

  const headerColumnForHost: GridColumns = [
    roleNameHeader,
    roleHotelGroupHeader,
    roleSupervisorHeader,
    functionIdentityRoleHeader,
    actionsHeader,
  ];

  const headerColumnForTenant: GridColumns = [
    roleNameHeader,
    roleSupervisorHeader,
    actionsHeader,
  ];

  React.useEffect(() => {
    dispatch(fetchFunctionList());
    dispatch(fetchMenuList());
  }, []);

  React.useEffect(() => {
    if (selectedHotel != undefined && loadList) {
      setIsLoading(true);
      setRoleManagementList([]);
      setRequestDateTime(new Date());

      getRoleListAPI({
        filter: filterList,
        pageIndex: index,
        pageSize: size,
        orderBy: "hotelGroupId, parentRoleId",
      })
        .then((response) => {
          if (response.resultCode === ARIA_SuccessCode) {
            setRoleManagementList(response.result.items);
            setRow(response.result.rowCount);
          }
        })
        .finally(() => {
          setloadList(false);
          setIsLoading(false);
        });
    }
  }, [index, size, filterList, selectedHotel, loadList]);

  const getExportTransaction = async () => {
    setIsLoading(true);
    var _exportIndex = 1;
    var exportList: Role[] = [];

    try {
      do {
        var response = await getRoleListAPI({
          filter: filterList,
          pageIndex: _exportIndex,
          pageSize: size,
          orderBy: "hotelGroupId",
        });

        exportList.push(...response.result.items);

        _exportIndex++;
      } while (response.result.pageCount >= _exportIndex);

      setExportList(exportList);
      setIsExporting(true);
      setIsLoading(false);
    } catch (err) {
      console.log("Oposs.. Something is wrong");
    }
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <DataGridFilterToolbar
          headerColumns={
            role?.identityRoles === ID_HOST
              ? headerColumnForHost
              : headerColumnForTenant
          }
          filterList={filterList}
          onFilterApply={(items) => {
            setFilterList(items.slice());
            setloadList(true);
          }}
        />
        <ExportButton
          filename="Role"
          data={exportList}
          headers={[
            { label: "Name", key: "name" },
            // { label: "Use By", key: "identityRoles" },
          ]}
          readyToExport={isExporting}
          onClick={getExportTransaction}
          onFinishDownload={() => {
            setExportList([]);
            setIsExporting(false);
          }}
        >
          Export
        </ExportButton>
        <div style={{ flexGrow: 1 }}></div>
        <AccessControlButton
          code={PM_ADD_ROLE_CODE}
          variant="contained"
          color="primary"
          size="small"
          onClick={handleOpenCreateDialog}
        >
          New Role
        </AccessControlButton>
        <div style={{ paddingLeft: "8px" }}>
          <b>Data as at </b>: {convertDateTimeToString(requestDateTime)}
        </div>
      </GridToolbarContainer>
    );
  }

  const handleOpenCreateDialog = () => {
    setOpenEditDialog(true);
  };

  const handleCloneClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    var _cloneItem = Object.assign(
      {},
      roleManagementList.find(
        (f) => f.id === parseInt(event.currentTarget.value)
      )
    );

    setAddRole(_cloneItem);
    setOpenEditDialog(true);
  };

  const handleOpenEditDialog = (event: React.MouseEvent<HTMLButtonElement>) => {
    const _updateItem = Object.assign(
      {},
      roleManagementList.find(
        (f) => f.id === parseInt(event.currentTarget.value)
      )
    );
    setEditRole(_updateItem);
    setOpenEditDialog(true);
  };

  const handleOpenDeleteDialog = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    var _removeItem = roleManagementList.find(
      (f) => f.id === parseInt(event.currentTarget.value)
    );
    setRemoveRole(_removeItem);
    setOpenDeleteDialog(true);
  };

  const handleEditOrAdd = () => {
    if (editRole == undefined) {
      if (
        addRole == undefined ||
        addRole.name == undefined ||
        addRole.name === "" ||
        addRole.functions == undefined ||
        // addRole.functions.length === 0 ||
        addRole.menus == undefined 
        // addRole.menus.length === 0
      ) {
        alert("Please fill up the details below to add a new role.");
      } else {
        const funcIds = addRole.functions.map((func: Function) => func.id);
        const menuIds = addRole.menus.map((menu: Menu) => menu.id);

        if (role?.identityRoles !== ID_HOST) {
          addRole.identityRoles = role?.identityRoles;
          addRole.hotelGroupId = role?.hotelGroupId;
        }

        createRoleAPI({ ...addRole, functionIds: funcIds, menuIds: menuIds })
          .catch((err) => {
            alert(err);
          })
          .finally(() => {
            setloadList(true);
            handleEditClose();
          });
      }
    } else {
      if (
        editRole.name == undefined ||
        editRole.name === "" ||
        editRole.functions == undefined ||
        // editRole.functions.length === 0 ||
        editRole.menus == undefined 
        // editRole.menus.length === 0
      ) {
        alert("Please fill up the details below to edit role.");
      } else {
        const funcIds = editRole.functions.map((func) => func.id);
        const menuIds = editRole.menus.map((menu) => menu.id);

        updateRoleAPI({ ...editRole, functionIds: funcIds, menuIds: menuIds })
          .catch((err) => {
            alert(err);
          })
          .finally(() => {
            setloadList(true);
            handleEditClose();
          });
      }
    }
  };

  const handleDelete = () => {
    if (deleteConfirmation !== removeRole?.name) {
      alert("Please key in the role name to confirm deletion.");
    } else {
      deleteRoleAPI({ ...removeRole })
        .catch((err) => {
          alert(err);
        })
        .finally(() => {
          setDeleteConfirmation("");
          setloadList(true);
          handleEditClose();
        });
    }
  };

  const handleEditClose = () => {
    setAddRole(undefined);
    setEditRole(undefined);
    setRemoveRole(undefined);
    setOpenEditDialog(false);
    setOpenDeleteDialog(false);
  };

  return (
    <>
      <MainDrawer title="Role Management">
        {/* Add and Edit Dialog */}
        <Dialog
          open={openEditDialog}
          onClose={handleEditClose}
          aria-labelledby="form-dialog-title"
          fullWidth
          maxWidth="md"
        >
          <DialogTitle id="form-dialog-title">
            {editRole != undefined ? "Edit Role" : "Add Role"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {editRole != undefined
                ? "Change the detail below and save it to update role details, ."
                : "Fill up the details below to add a new role."}
            </DialogContentText>
            <form noValidate autoComplete="off">
              <div>
                <TextField
                  margin="dense"
                  id="name"
                  label="Role Name"
                  type="text"
                  value={
                    editRole != undefined
                      ? editRole.name
                      : addRole?.name === undefined
                      ? ""
                      : addRole?.name
                  }
                  onChange={(e) => {
                    editRole != undefined
                      ? setEditRole({
                          ...editRole,
                          name: e.currentTarget.value,
                        })
                      : setAddRole({
                          ...addRole,
                          name: e.currentTarget.value,
                        });
                  }}
                  fullWidth
                />
                <TextField
                  margin="dense"
                  id="desc"
                  label="Description"
                  type="text"
                  value={
                    editRole != undefined
                      ? editRole.description
                      : addRole?.description === undefined
                      ? ""
                      : addRole?.description
                  }
                  onChange={(e) => {
                    editRole != undefined
                      ? setEditRole({
                          ...editRole,
                          description: e.currentTarget.value,
                        })
                      : setAddRole({
                          ...addRole,
                          description: e.currentTarget.value,
                        });
                  }}
                  fullWidth
                />

                {roleManagementList.length > 0 ? (
                  <TextField
                    margin="dense"
                    id="supervisor"
                    label="Supervisor"
                    select
                    value={
                      editRole != undefined
                        ? editRole.parentRoleId
                        : addRole?.parentRoleId != undefined
                        ? addRole.parentRoleId
                        : ""
                    }
                    onChange={(e) => {
                      editRole != undefined
                        ? setEditRole({
                            ...editRole,
                            parentRoleId: parseInt(e.target.value),
                          })
                        : setAddRole({
                            ...addRole,
                            parentRoleId: parseInt(e.target.value),
                          });
                    }}
                    fullWidth
                  >
                    {childRoleList.map((supervisorRole, index) => (
                      <MenuItem key={index} value={supervisorRole.id}>
                        {role?.identityRoles === ID_HOST &&
                        supervisorRole.hotelGroupId != 0 &&
                        hotelGroupList.length > 0
                          ? `${supervisorRole.name} (${
                              hotelGroupList.find(
                                (x) => x.id === supervisorRole.hotelGroupId
                              )?.name
                            })`
                          : supervisorRole.name}
                      </MenuItem>
                    ))}
                  </TextField>
                ) : null}

                {role?.identityRoles === ID_HOST && hotelGroupList.length > 0 ? (
                  <TextField
                    margin="dense"
                    id="hotelGroup"
                    label="Hotel Group"
                    select
                    value={
                      editRole != undefined
                        ? editRole.hotelGroupId
                        : addRole?.hotelGroupId != undefined
                        ? addRole.hotelGroupId
                        : ""
                    }
                    onChange={(e) => {
                      editRole != undefined
                        ? setEditRole({
                            ...editRole,
                            hotelGroupId: parseInt(e.target.value),
                          })
                        : setAddRole({
                            ...addRole,
                            hotelGroupId: parseInt(e.target.value),
                          });
                    }}
                    fullWidth
                  >
                    {hotelGroupList.map((hotelGroup, index) => (
                      <MenuItem key={index} value={hotelGroup.id}>
                        {hotelGroup.name}
                      </MenuItem>
                    ))}
                  </TextField>
                ) : null}

                {/* only show this when the user is a HOST */}
                {role?.identityRoles === ID_HOST ? (
                  <IdentityRoleSelector
                    label="Authorized"
                    value={
                      editRole != undefined
                        ? editRole.identityRoles.split(",")
                        : addRole?.identityRoles != undefined
                        ? addRole?.identityRoles.split(",")
                        : undefined
                    }
                    onChange={(roles) => {
                      editRole != undefined
                        ? setEditRole({
                            ...editRole,
                            identityRoles: roles,
                          })
                        : setAddRole({
                            ...addRole,
                            identityRoles: roles,
                          });
                    }}
                  />
                ) : null}

                <AccordionFunctionPermission
                  allowedFunctionList={
                    editRole != undefined
                      ? editRole.functions
                      : addRole != undefined
                      ? addRole.functions
                      : []
                  }
                  onChange={(functionPermissionList) =>
                    editRole != undefined
                      ? setEditRole({
                          ...editRole,
                          functions: functionPermissionList,
                        })
                      : setAddRole({
                          ...addRole,
                          functions: functionPermissionList,
                        })
                  }
                />

                <AccordionMenuPermission
                  allowedMenuList={
                    editRole != undefined
                      ? editRole.menus
                      : addRole != undefined
                      ? addRole.menus
                      : []
                  }
                  onChange={(menuPermissionList) =>
                    editRole != undefined
                      ? setEditRole({
                          ...editRole,
                          menus: menuPermissionList,
                        })
                      : setAddRole({
                          ...addRole,
                          menus: menuPermissionList,
                        })
                  }
                />
              </div>
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleEditClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleEditOrAdd} color="primary">
              {editRole != undefined ? `Save & Update` : `Add Role`}
            </Button>
          </DialogActions>
        </Dialog>

        {/* delete Dialog */}
        {removeRole != undefined ? (
          <Dialog
            open={openDeleteDialog}
            onClose={handleEditClose}
            aria-labelledby="form-dialog-title"
            fullWidth
            maxWidth="sm"
          >
            <DialogTitle id="form-dialog-title">Delete Role</DialogTitle>
            <DialogContent>
              <form noValidate autoComplete="off">
                <div>
                  <TextField
                    margin="dense"
                    id="name"
                    label="Role Name"
                    type="text"
                    disabled
                    value={removeRole.name}
                    fullWidth
                  />

                  <DialogContentText
                    color="textPrimary"
                    style={{ marginTop: "18px" }}
                  >
                    {`Are you sure you want to delete ${removeRole.name}?`}
                  </DialogContentText>
                  <DialogContentText>
                    Please type the following to confirm:
                  </DialogContentText>
                  <DialogContentText>
                    <i>
                      <b>{removeRole.name}</b>
                    </i>
                  </DialogContentText>
                  <TextField
                    margin="dense"
                    variant="outlined"
                    id="name"
                    type="text"
                    value={deleteConfirmation}
                    onChange={(e) =>
                      setDeleteConfirmation(e.currentTarget.value)
                    }
                    fullWidth
                  />
                </div>
              </form>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleEditClose} color="primary">
                Cancel
              </Button>
              <Button onClick={handleDelete} color="primary">
                {`Delete`}
              </Button>
            </DialogActions>
          </Dialog>
        ) : null}

        {/* Data Grid */}
        <div style={{ height: "80vh" }}>
          <div style={{ display: "flex", height: "100%" }}>
            <div style={{ flexGrow: 1 }}>
              <DataGrid
                pageSize={size}
                rowsPerPageOptions={[10, 20, 30]}
                onPageSizeChange={(size) => setSize(size)}
                disableColumnMenu
                density="compact"
                components={{
                  LoadingOverlay: CustomLoadingOverlay,
                  NoRowsOverlay: CustomNoRowsOverlay,
                  Toolbar: CustomToolbar,
                }}
                pagination
                loading={isLoading}
                paginationMode="server"
                rowCount={row}
                filterMode="server"
                onPageChange={(newPage) => setIndex(++newPage)}
                rows={roleManagementList}
                columns={
                  role?.identityRoles === ID_HOST
                    ? headerColumnForHost
                    : headerColumnForTenant
                }
                getRowId={(row) => row.id}
              />
            </div>
          </div>
        </div>
      </MainDrawer>
    </>
  );
};
