import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store/state";
import { ResponseModel } from "../models/common/ResponseModel";
import { AxiosError } from "axios";
import {
  ARIA_SuccessCode,
  GetRoleRequestModel,
  GetRoleResponseModel,
  GetRolesResponseModel,
} from "models";
import { getRoleAPI, getRoleListAPI } from "services/roleService";

const initialState: RootState.RoleState = {
  roleList: [],
  childRoleList: [],
  role: undefined,
  error: undefined,
  isLoading: false,
};

//Actions
//More info here https://redux-toolkit.js.org/api/createAsyncThunk
export const fetchRoleList = createAsyncThunk<
  GetRolesResponseModel,
  void,
  { rejectValue: AxiosError<Error> }
>("role/fetchRoleList", async (_, thunkApi) => {
  try {
    var pageIndex = 1;

    let data: GetRolesResponseModel = await getRoleListAPI({
      pageIndex,
      pageSize: 100,
    });

    let tempData = data.result.items;

    while (data.result.pageCount > pageIndex) {
      pageIndex++;
      let nextData: GetRolesResponseModel = await getRoleListAPI({
        pageIndex,
        pageSize: 100,
      });

      tempData.push(...nextData.result.items);
    }

    data.result.items = tempData;

    return data;
  } catch (err) {
    let error = err as AxiosError<Error>; // cast the error for access
    if (!error.response) {
      throw err;
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    return thunkApi.rejectWithValue(error);
  }
});

export const fetchRole = createAsyncThunk<
  GetRoleResponseModel,
  { request: GetRoleRequestModel },
  { rejectValue: AxiosError<Error> }
>("role/fetchRole", async (input, thunkApi) => {
  try {
    const { request } = input;
    let data: GetRoleResponseModel = await getRoleAPI(request);

    return data;
  } catch (err) {
    let error = err as AxiosError<Error>; // cast the error for access
    if (!error.response) {
      throw err;
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    return thunkApi.rejectWithValue(error);
  }
});

//Slice
//More info here https://redux-toolkit.js.org/api/createSlice
export const roleSlice = createSlice({
  name: "role",
  initialState,
  reducers: {
    //local reducer
    resetRoleState() {
      return initialState;
    },
  },
  //services reducers
  extraReducers: (builder) => {
    builder.addCase(fetchRoleList.fulfilled, (state, { payload }) => {
      if (payload.resultCode === ARIA_SuccessCode) {
        state.roleList = payload.result.items;
        const _childRoleList = payload.result.items.filter(
          (x) => x.id !== state.role?.id
        );
        _childRoleList.unshift({
          id: 0,
          name: "-",
          functions: [],
          hotelGroupId: 0,
          menus: [],
          parentRoleId: 0,
          identityRoles: "",
        });
        state.childRoleList = _childRoleList;
        state.error = undefined;
      } else {
        state.error = { ...payload };
      }

      state.isLoading = false;
    }),
      builder.addCase(fetchRoleList.pending, (state, _) => {
        state.isLoading = true;
      }),
      builder.addCase(fetchRoleList.rejected, (state, action) => {
        if (action.payload != undefined) {
          let error: AxiosError<Error> = action.payload;

          const serverError: ResponseModel = {
            resultCode: error?.response?.status.toString() ?? "",
            resultDescription: error?.response?.data.message ?? "",
          };

          state.error = serverError;
          state.isLoading = false;
        }
      });

    builder.addCase(fetchRole.fulfilled, (state, { payload }) => {
      if (payload.resultCode === ARIA_SuccessCode) {
        state.role = { ...payload.result };
        state.error = undefined;
      } else {
        state.error = { ...payload };
      }

      state.isLoading = false;
    }),
      builder.addCase(fetchRole.pending, (state, _) => {
        state.isLoading = true;
      }),
      builder.addCase(fetchRole.rejected, (state, action) => {
        if (action.payload != undefined) {
          let error: AxiosError<Error> = action.payload;

          const serverError: ResponseModel = {
            resultCode: error?.response?.status.toString() ?? "",
            resultDescription: error?.response?.data.message ?? "",
          };

          state.error = serverError;
          state.isLoading = false;
        }
      });
  },
});

export const { resetRoleState } = roleSlice.actions;

export default roleSlice.reducer;
