import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "reducers";

//Component
import {
  DataGrid,
  GridColDef,
  GridColumns,
  GridToolbarContainer,
} from "@material-ui/data-grid";
import CustomLoadingOverlay from "components/DataGridLoadingOverlay";
import CustomNoRowsOverlay from "components/DataGridNoRowsOverlay";
import { ARIA_SuccessCode, ID_HOST, Verbiage } from "models";
import { DataGridFilterToolbar } from "components/DataGridFilterToolbar";
import { GridColFilterDef } from "components/DataGridFilterSelection";
//Icon

//Helper
import { convertDateTimeToString } from "utils/dateHelper";
import { Data } from "react-csv/components/CommonPropTypes";
import { ExportButton } from "components/ExportButton";
import { MainDrawer } from "components/MainDrawer";
import {
  createVerbiageAPI,
  deleteVerbiageAPI,
  getVerbiageListAPI,
  updateVerbiageAPI,
} from "services/verbiagesService";
import {
  AccessControlButton,
  GridContainer,
  GridItem,
  ImportCSVButton,
} from "components";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  PM_ADD_VERBIAGE_CODE,
  PM_EDIT_VERBIAGE_CODE,
  PM_DELETE_VERBIAGE_CODE,
} from "models/backend/constant/PermissionCode";

import { fetchLanguageList } from "reducers/language";
import { GRID_FILTER_TYPE_LANGUAGE } from "components/DataGridFilterSelection/type";

export const VerbiageManagementScreen = () => {
  const { selectedHotel } = useSelector((state: RootState) => state.hotelState);
  const { languageList } = useSelector(
    (state: RootState) => state.languageState
  );
  const { role } = useSelector(
    (state: RootState) => state.roleState
  );
  const dispatch = useDispatch();

  const [index, setIndex] = useState<number>(1);
  const [size, setSize] = useState<number>(10);
  const [row, setRow] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  //batch Upload
  const [isUploading, setIsUploading] = useState(false);
  const [successfulUploadCount, setSuccessfulUploadCount] = useState(0);
  const [successfulUploadCreateCount, setSuccessfulUploadCreateCount] =
    useState(0);
  const [successfulUploadUpdateCount, setSuccessfulUploadUpdateCount] =
    useState(0);
  const [errorUploadCount, setErrorUploadCount] = useState(0);

  const [requestDateTime, setRequestDateTime] = useState<Date>(new Date());

  const [verbiageList, setVerbiageList] = useState<Verbiage[]>([]);
  const [exportList, setExportList] = useState<Data>([]);
  const [filterList, setFilterList] = useState<GridColFilterDef[]>([]);

  const [loadList, setloadList] = useState(true);

  const [addVerbiage, setAddVerbiage] = useState<any | undefined>();
  const [editVerbiage, setEditVerbiage] = useState<Verbiage | undefined>();
  const [removeVerbiage, setRemoveVerbiage] = useState<Verbiage | undefined>();
  const [deleteConfirmation, setDeleteConfirmation] = useState("");

  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  //Setting up the each column header item
  const verbiageCodeHeader: GridColDef = {
    field: "code",
    headerName: "Code",
    sortable: false,
    renderHeader: (params: any) => <strong>{"Code"}</strong>,
    minWidth: 120,
  };

  const verbiageValueHeader: GridColDef = {
    field: "value",
    headerName: "Value",
    sortable: false,
    renderHeader: () => <strong>{"Value"}</strong>,
    minWidth: 200,
    flex: 2,
  };

  const verbiageLanguageHeader: GridColDef = {
    field: "languageId",
    headerName: "Language",
    sortable: false,
    type: GRID_FILTER_TYPE_LANGUAGE,
    renderHeader: (params: any) => <strong>{"Language"}</strong>,
    valueGetter: (params) => {
      var language = languageList.find((x) => x.id === params.value);

      return language !== undefined ? language.description : "NA";
    },
    minWidth: 100,
  };

  const actionsHeader: GridColDef = {
    field: "id",
    headerName: "Actions",
    minWidth: 240,
    filterable: false,
    sortable: false,
    disableColumnMenu: true,
    renderHeader: () => <strong>{"Actions"}</strong>,
    renderCell: (params: any) => (
      <strong>
        <AccessControlButton
          code={PM_EDIT_VERBIAGE_CODE}
          variant="contained"
          color="primary"
          size="small"
          value={params.value}
          onClick={handleOpenEditDialog}
        >
          Edit
        </AccessControlButton>
        <AccessControlButton
          code={PM_ADD_VERBIAGE_CODE}
          variant="contained"
          color="primary"
          size="small"
          value={params.value}
          style={{ marginLeft: 12 }}
          onClick={handleDuplicateClick}
        >
          Clone
        </AccessControlButton>
        <AccessControlButton
          code={PM_DELETE_VERBIAGE_CODE}
          variant="contained"
          color="primary"
          size="small"
          value={params.value}
          style={{ marginLeft: 12 }}
          onClick={handleOpenDeleteDialog}
        >
          Delete
        </AccessControlButton>
      </strong>
    ),
  };

  const headerColumn: GridColumns = [
    verbiageCodeHeader,
    verbiageValueHeader,
    verbiageLanguageHeader,
    actionsHeader,
  ];

  React.useEffect(() => {
    dispatch(fetchLanguageList());
  }, []);

  React.useEffect(() => {
    if (selectedHotel != undefined ) {
      setloadList(true);
    }
  }, [selectedHotel]);

  React.useEffect(() => {
    if (selectedHotel != undefined && loadList) {
      setIsLoading(true);
      setVerbiageList([]);
      setRequestDateTime(new Date());

      getVerbiageListAPI({
        filter: filterList,
        pageIndex: index,
        pageSize: size,
        orderBy: "code",
      })
        .then((response) => {
          if (response.resultCode === ARIA_SuccessCode) {
            setVerbiageList(response.result.items);
            setRow(response.result.rowCount);
          }
        })
        .finally(() => {
          setloadList(false);
          setIsLoading(false);
        });
    }
  }, [index, size, filterList, selectedHotel, loadList]);

  const getExportTransaction = async () => {
    setIsLoading(true);
    var _exportIndex = 1;
    var exportList: Verbiage[] = [];

    try {
      do {
        var response = await getVerbiageListAPI({
          filter: filterList,
          pageIndex: _exportIndex,
          pageSize: size,
          orderBy: "code",
        });

        exportList.push(...response.result.items);

        _exportIndex++;
      } while (response.result.pageCount >= _exportIndex);

      setExportList(exportList);
      setIsExporting(true);
      setIsLoading(false);
    } catch (err) {
      console.log("Oposs.. Something is wrong");
    }
  };

  const batchUpdate = async (result: any) => {
    if (result !== undefined && result !== null && result.length > 0) {
      for (var i = 0; i < result.length; i++) {
        var _item = result[i];

        if (_item.id == undefined || _item.id == null || _item.id == "") {
          createVerbiageAPI({
            ..._item,
            languageId: Number.parseInt(_item.languageId),
          })
            .then((response) => {
              if (response.resultCode == ARIA_SuccessCode) {
                setSuccessfulUploadCreateCount((prev) => prev + 1);
              } else {
                setErrorUploadCount((prev) => prev + 1);
              }
            })
            .catch(() => {
              setErrorUploadCount((prev) => prev + 1);
            });
        } else {
          updateVerbiageAPI({
            ..._item,
            id: Number.parseInt(_item.id),
            languageId: Number.parseInt(_item.languageId),
          })
            .then((response) => {
              if (response.resultCode == ARIA_SuccessCode) {
                setSuccessfulUploadUpdateCount((prev) => prev + 1);
              } else {
                setErrorUploadCount((prev) => prev + 1);
              }
            })
            .catch(() => {
              setErrorUploadCount((prev) => prev + 1);
            });
        }
      }
    }
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <DataGridFilterToolbar
          headerColumns={headerColumn}
          filterList={filterList}
          onFilterApply={(items) => {
            setFilterList(items.slice());
            setloadList(true);
          }}
        />
        <ExportButton
          filename="Verbiage"
          data={exportList}
          headers={[
            { label: "id", key: "id" },
            { label: "code", key: "code" },
            { label: "value", key: "value" },
            { label: "languageId", key: "languageId" },
          ]}
          readyToExport={isExporting}
          onClick={getExportTransaction}
          onFinishDownload={() => {
            setExportList([]);
            setIsExporting(false);
          }}
        >
          Export
        </ExportButton>
        <ImportCSVButton
          onProcessing={() => setIsUploading(true)}
          onExtractCompleted={batchUpdate}
        >
          Batch Update
        </ImportCSVButton>
        <div style={{ flexGrow: 1 }}></div>
        <AccessControlButton
          code={PM_ADD_VERBIAGE_CODE}
          variant="contained"
          color="primary"
          size="small"
          onClick={handleOpenCreateDialog}
        >
          New Verbiage
        </AccessControlButton>
        <div style={{ paddingLeft: "8px" }}>
          <b>Data as at </b>: {convertDateTimeToString(requestDateTime)}
        </div>
      </GridToolbarContainer>
    );
  }

  const handleOpenCreateDialog = () => {
    setAddVerbiage({
      ...addVerbiage,
      languageId: 1,
    });
    setOpenEditDialog(true);
  };

  const handleDuplicateClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    var _updateVerbiage = verbiageList.find(
      (f) => f.id === parseInt(event.currentTarget.value)
    );
    setAddVerbiage(_updateVerbiage);
    setOpenEditDialog(true);
  };

  const handleOpenEditDialog = (event: React.MouseEvent<HTMLButtonElement>) => {
    var _updateVerbiage = verbiageList.find(
      (f) => f.id === parseInt(event.currentTarget.value)
    );
    setEditVerbiage(_updateVerbiage);
    setOpenEditDialog(true);
  };

  const handleOpenDeleteDialog = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    var _removeVerbiage = verbiageList.find(
      (f) => f.id === parseInt(event.currentTarget.value)
    );
    setRemoveVerbiage(_removeVerbiage);
    setOpenDeleteDialog(true);
  };

  const handleEditOrAdd = () => {
    if (editVerbiage == undefined) {
      console.log(addVerbiage);

      if (
        addVerbiage == undefined ||
        addVerbiage.code == undefined ||
        addVerbiage.code === "" ||
        addVerbiage.value == undefined ||
        addVerbiage.value === "" ||
        addVerbiage.languageId == undefined ||
        addVerbiage.languageId === 0
      ) {
        alert("Please fill up the details below to add a new verbiage.");
      } else {
        createVerbiageAPI({ ...addVerbiage })
          .then((response) => {
            if (response.resultCode == ARIA_SuccessCode) {
              setloadList(true);
              handleEditClose();
            } else {
              alert(response.resultDescription);
            }
          })
          .catch((err) => {
            alert(err);
          });
      }
    } else {
      if (
        editVerbiage.code == undefined ||
        editVerbiage.code === "" ||
        editVerbiage.value == undefined ||
        editVerbiage.value === "" ||
        editVerbiage.languageId == undefined ||
        editVerbiage.languageId === 0
      ) {
        alert("Please fill up the details below to edit verbiage.");
      } else {
        updateVerbiageAPI({ ...editVerbiage })
          .then((response) => {
            if (response.resultCode == ARIA_SuccessCode) {
              setloadList(true);
              handleEditClose();
            } else {
              alert(response.resultDescription);
            }
          })
          .catch((err) => {
            alert(err);
          });
      }
    }
  };

  const handleDelete = () => {
    if (deleteConfirmation !== removeVerbiage?.code) {
      alert("Please key in the verbiage code to confirm deletion.");
    } else {
      deleteVerbiageAPI({ ...removeVerbiage })
        .catch((err) => {
          alert(err);
        })
        .finally(() => {
          setDeleteConfirmation("");
          setloadList(true);
          handleEditClose();
        });
    }
  };

  const handleEditClose = () => {
    setAddVerbiage(undefined);
    setEditVerbiage(undefined);
    setRemoveVerbiage(undefined);
    setOpenEditDialog(false);
    setOpenDeleteDialog(false);
  };

  const handleBatchUploadClose = () => {
    setloadList(true);
    setSuccessfulUploadCount(0);
    setSuccessfulUploadCreateCount(0);
    setSuccessfulUploadUpdateCount(0);
    setErrorUploadCount(0);
    setIsUploading(false);
  };

  return (
    <>
      <MainDrawer title="Verbiage Management">
        {/* Add and Edit Dialog */}
        <Dialog
          open={openEditDialog}
          onClose={handleEditClose}
          aria-labelledby="form-dialog-title"
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle id="form-dialog-title">
            {editVerbiage != undefined ? "Edit Verbiage" : "Add Verbiage"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {editVerbiage != undefined
                ? "Change the detail below and save it to update Verbiage details, ."
                : "Fill up the details below to add a new verbiage."}
            </DialogContentText>
            <form noValidate autoComplete="off">
              <div>
                <TextField
                  margin="dense"
                  id="code"
                  label="Verbiage Code"
                  type="text"
                  disabled={role?.identityRoles !== ID_HOST}
                  value={
                    editVerbiage != undefined
                      ? editVerbiage.code
                      : addVerbiage?.code
                  }
                  onChange={(e) => {
                    editVerbiage != undefined
                      ? setEditVerbiage({
                          ...editVerbiage,
                          code: e.currentTarget.value,
                        })
                      : setAddVerbiage({
                          ...addVerbiage,
                          code: e.currentTarget.value,
                        });
                  }}
                  fullWidth
                />
                <TextField
                  margin="dense"
                  id="value"
                  label="Value"
                  multiline
                  type="text"
                  value={
                    editVerbiage != undefined
                      ? editVerbiage.value
                      : addVerbiage?.value
                  }
                  onChange={(e) => {
                    editVerbiage != undefined
                      ? setEditVerbiage({
                          ...editVerbiage,
                          value: e.currentTarget.value,
                        })
                      : setAddVerbiage({
                          ...addVerbiage,
                          value: e.currentTarget.value,
                        });
                  }}
                  fullWidth
                />
                <TextField
                  margin="dense"
                  id="language"
                  label="Language"
                  select
                  disabled={role?.identityRoles !== ID_HOST}
                  value={
                    editVerbiage !== undefined
                      ? editVerbiage.languageId
                      : addVerbiage !== undefined &&
                        addVerbiage.languageId !== undefined
                      ? addVerbiage.languageId
                      : 1
                  }
                  onChange={(e) => {
                    editVerbiage != undefined
                      ? setEditVerbiage({
                          ...editVerbiage,
                          languageId: Number.parseInt(e.target.value),
                        })
                      : setAddVerbiage({
                          ...addVerbiage,
                          languageId: Number.parseInt(e.target.value),
                        });
                  }}
                  fullWidth
                >
                  {languageList.map((language, index) => (
                    <MenuItem key={index} value={language.id}>
                      {language.description}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleEditClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleEditOrAdd} color="primary">
              {editVerbiage != undefined ? `Save & Update` : `Add Verbiage`}
            </Button>
          </DialogActions>
        </Dialog>

        {/* delete Dialog */}
        {removeVerbiage != undefined ? (
          <Dialog
            open={openDeleteDialog}
            onClose={handleEditClose}
            aria-labelledby="form-dialog-title"
            fullWidth
            maxWidth="sm"
          >
            <DialogTitle id="form-dialog-title">Delete Verbiage</DialogTitle>
            <DialogContent>
              <form noValidate autoComplete="off">
                <div>
                  <TextField
                    margin="dense"
                    id="code"
                    label="Verbiage Code"
                    type="text"
                    disabled
                    value={removeVerbiage.code}
                    fullWidth
                  />
                  <TextField
                    margin="dense"
                    id="value"
                    label="Value"
                    type="text"
                    disabled
                    value={removeVerbiage.value}
                    fullWidth
                  />
                  <TextField
                    margin="dense"
                    id="lanaguage"
                    label="Language"
                    select
                    disabled
                    value={removeVerbiage.languageId}
                    fullWidth
                  >
                    {languageList.map((language, index) => (
                      <MenuItem key={index} value={language.id}>
                        {language.description}
                      </MenuItem>
                    ))}
                  </TextField>

                  <DialogContentText
                    color="textPrimary"
                    style={{ marginTop: "18px" }}
                  >
                    {`Are you sure you want to delete ${removeVerbiage.code}?`}
                  </DialogContentText>
                  <DialogContentText>
                    Please type the following to confirm:
                  </DialogContentText>
                  <DialogContentText>
                    <i>
                      <b>{removeVerbiage.code}</b>
                    </i>
                  </DialogContentText>
                  <TextField
                    margin="dense"
                    variant="outlined"
                    id="name"
                    type="text"
                    value={deleteConfirmation}
                    onChange={(e) =>
                      setDeleteConfirmation(e.currentTarget.value)
                    }
                    fullWidth
                  />
                </div>
              </form>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleEditClose} color="primary">
                Cancel
              </Button>
              <Button onClick={handleDelete} color="primary">
                {`Delete`}
              </Button>
            </DialogActions>
          </Dialog>
        ) : null}

        {/* Batch Upload Dialog */}
        {isUploading ? (
          <Dialog open={isUploading}>
            <DialogTitle id="form-dialog-title">
              Uploading batch update
            </DialogTitle>
            <DialogContent>
              {isLoading ? <LinearProgress /> : null}
              <GridContainer>
                <GridItem>
                  <Typography>{`Successfully uploaded : ${successfulUploadCount}`}</Typography>
                  <Typography>{`Successfully Created : ${successfulUploadCreateCount}`}</Typography>
                  <Typography>{`Successfully Updated : ${successfulUploadUpdateCount}`}</Typography>
                  <Typography>{`Error upload : ${errorUploadCount}`}</Typography>
                </GridItem>
              </GridContainer>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleBatchUploadClose} color="primary">
                {`Okay`}
              </Button>
            </DialogActions>
          </Dialog>
        ) : null}

        {/* Data Grid */}
        <div style={{ height: "80vh" }}>
          <div style={{ display: "flex", height: "100%" }}>
            <div style={{ flexGrow: 1 }}>
              <DataGrid
                pageSize={size}
                rowsPerPageOptions={[10, 20, 30]}
                onPageSizeChange={(size) => {
                  setSize(size);
                  setloadList(true);
                }}
                disableColumnMenu
                density="compact"
                components={{
                  LoadingOverlay: CustomLoadingOverlay,
                  NoRowsOverlay: CustomNoRowsOverlay,
                  Toolbar: CustomToolbar,
                }}
                pagination
                loading={isLoading}
                paginationMode="server"
                rowCount={row}
                filterMode="server"
                onPageChange={(newPage) => {
                  setIndex(++newPage);
                  setloadList(true);
                }}
                rows={verbiageList}
                columns={headerColumn}
                getRowId={(row) => row.id}
              />
            </div>
          </div>
        </div>
      </MainDrawer>
    </>
  );
};
