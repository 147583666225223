import moment from 'moment';

export function getDateWithFormat(date: Date, format: string) {
  return moment.utc(date).local().format(format);
}

export function getCurrentDateTime() {
  return moment.utc(new Date).local().format('DDMMYYYYhhmmss');
}

export function getLocalDate(date: Date): Date {
  return moment.utc(date).local().toDate();
}

export function getDate(date: Date) {
  return moment.utc(date).local().format('DD-MMMM-YYYY');
}

export function getShortDate(date: Date): string {
  return moment.utc(date).local().format('DD-MMM-YYYY');
}

export function getTime(date: Date) {
  return moment.utc(date).local().format('HH:mm');
}

export function getShortDateTime(date: Date) {
  return moment.utc(date).local().format('DD-MMM-YYYY HH:mm');
}

export function subtractDay(date: Date, day: number) {
  return moment(date).subtract(day, 'day').toDate();
}

export function addDay(date: Date, day: number) {
  return moment(date).add(day, 'day').toDate();
}

export function calculateAge(date: Date) {
  return moment().diff(moment(date, 'DD/MM/YYYY'), 'years');
}

export function convertStringDateToServerFormat(date: Date): Date {
  return new Date(moment(moment(date).format('YYYY-MM-DD')).toLocaleString());
}

export function convertDateTimeToDate(date: Date): string {
  return moment(date).format('YYYY-MM-DD')
}

export function convertDateTimeToTime(date: Date): string {
  return moment(date).format('hh:mm a')
}

export function convertDateTimeToString(date: Date): string {
  return moment.utc(date).local().format('DD-MM-YYYY hh:mm:ss a')
}