export function convertFileToBase64(file: File): Promise<string | null> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    // reader.onload = () => resolve(reader.result);

    reader.onload = () =>
      resolve(
        reader != null && reader.result != null
          ? (reader.result as string).substr((reader.result as string).indexOf(',') + 1)
          : null
      );
    reader.onerror = reject;
  });
}

export function checkSupportedUploadImage(files: File[], uploadSize: number = 1) : Error []{
  const errs: Error[] = [];

  if (files.length > uploadSize) {
    const e: Error = {
      name: 'error',
      message: 'Only 1 images can be uploaded at a time'
    };
    errs.push(e);
  }

  const types = ['image/png', 'image/jpeg']; //, 'image/gif']

  files.forEach((file, _) => {
    if (types.every((type) => file.type !== type)) {
      const e: Error = {
        name: 'error',
        message: `'${file.type}' is not a supported format`
      };

      errs.push(e);
    }

    // if (file.size > 3000000) {
    // alert(`'${file.name}' is too large, please pick a smaller file`);
    // errs.push(`'${file.name}' is too large, please pick a smaller file`);
    // }
  });

  return errs;
}

export function getFilenameForImageUrl(imageUrl: string) {
  return imageUrl.replace(/^.*[\\\/]/, '')
}
