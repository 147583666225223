import { combineReducers } from "redux";
import { RootState } from "../store/state";
import appReducer from "../reducers/app";
import accountReducer from "../reducers/account";
import componentReducer from "../reducers/component";
import componentTypeReducer from "../reducers/componentType";
import configurationGroupReducer from "../reducers/configurationGroup";
import configurationReducer from "../reducers/configuration";
import countryReducer from "./country";
import featureReducer from "../reducers/feature";
import functionReducer from "../reducers/function";
import kioskLocationReducer from "../reducers/kioskLocation";
import hotelGroupReducer from "../reducers/hotelGroup";
import hotelReducer from "../reducers/hotel";
import identityRoleReducer from "../reducers/identityRole";
import menuReducer from "../reducers/menu";
import reservationReducer from "./reservation";
import roleReducer from "./role";
import languageReducer from "../reducers/language";
import languageSettingReducer from "../reducers/languageSetting";
import paymentReducer from "../reducers/payment";
import themeReducer from "../reducers/theme";
import verbiageReducer from "../reducers/verbiages";

export type { RootState };

export const rootReducer = combineReducers<RootState>({
  appState: appReducer,
  account: accountReducer,
  appConfigState: configurationReducer,
  component: componentReducer,
  componentType : componentTypeReducer,
  configurationGroup: configurationGroupReducer,
  country: countryReducer,
  featureState: featureReducer,
  functionState: functionReducer,
  hotelGroupState: hotelGroupReducer,
  hotelState: hotelReducer,
  kioskLocationState: kioskLocationReducer,
  languageState: languageReducer,
  languageSettingState: languageSettingReducer,
  identityUserRole: identityRoleReducer,
  menuState: menuReducer,
  reservationState: reservationReducer,
  roleState: roleReducer,
  themes: themeReducer,
  paymentState: paymentReducer,
  verbiages: verbiageReducer,
});
