import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store/state";
import { ResponseModel } from "../models/common/ResponseModel";
import { AxiosError } from "axios";
import { ARIA_SuccessCode, GetComponentTypesResponseModel } from "models";
import { getComponentTypeListAPI } from "services/componentTypeServices";

const initialState: RootState.ComponentTypeState = {
  componentTypeList: [],
  error: undefined,
  isLoading: false,
};

//Actions
//More info here https://redux-toolkit.js.org/api/createAsyncThunk
export const fetchComponentTypeList = createAsyncThunk<
  GetComponentTypesResponseModel,
  void,
  { rejectValue: AxiosError<Error> }
>("componentType/getComponentTypeList", async (_, thunkApi) => {
  try {
    var pageIndex = 1;

    let data: GetComponentTypesResponseModel =
      await getComponentTypeListAPI({ pageIndex });

    let tempData = data.result.items;

    while (data.result.pageCount > pageIndex) {
      pageIndex++;
      let nextData: GetComponentTypesResponseModel =
        await getComponentTypeListAPI({ pageIndex });

      tempData.push(...nextData.result.items);
    }

    data.result.items = tempData;

    return data;
  } catch (err) {
    let error = err as AxiosError<Error>; // cast the error for access
    if (!error.response) {
      throw err;
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    return thunkApi.rejectWithValue(error);
  }
});

//Slice
//More info here https://redux-toolkit.js.org/api/createSlice
export const componentTypeSlice = createSlice({
  name: "componentType",
  initialState,
  reducers: {
    //local reducer
    resetComponentTypeState() {
      return initialState;
    },
  },
  //services reducers
  extraReducers: (builder) => {
    builder.addCase(
      fetchComponentTypeList.fulfilled,
      (state, { payload }) => {
        if (payload.resultCode === ARIA_SuccessCode) {
          state.componentTypeList = payload.result.items;
          state.error = undefined;
        } else {
          state.error = { ...payload };
        }

        state.error = undefined;
        state.isLoading = false;
      }
    ),
      builder.addCase(fetchComponentTypeList.pending, (state, _) => {
        state.isLoading = true;
      }),
      builder.addCase(
        fetchComponentTypeList.rejected,
        (state, action) => {
          if (action.payload != undefined) {
            let error: AxiosError<Error> = action.payload;

            const serverError: ResponseModel = {
              resultCode: error?.response?.status.toString() ?? "",
              resultDescription: error?.response?.data.message ?? "",
            };

            state.error = serverError;
            state.isLoading = false;
          }
        }
      );
  },
});

export const { resetComponentTypeState } = componentTypeSlice.actions;

export default componentTypeSlice.reducer;
