import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "reducers";

import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { IconButton, TextField, MenuItem, Typography } from "@material-ui/core";
import { GridColDef, GridColumns } from "@material-ui/data-grid";
import { GridContainer } from "components/GridContainer";
import { GridItem } from "components/GridItem";
import CloseIcon from "@material-ui/icons/Close";
import {
  GRID_FILTER_TYPE_DATETIME,
  GRID_FILTER_TYPE_HOTELGROUP,
  GRID_FILTER_TYPE_NUMBER,
  GRID_FILTER_TYPE_CHILDROLE,
  GRID_FILTER_TYPE_COUNTRY,
  GRID_FILTER_TYPE_BOOLEAN,
  GRID_FILTER_TYPE_KIOSK_LOCATION,
  GRID_FILTER_TYPE_LANGUAGE,
  GRID_FILTER_TYPE_IDENTITYROLE,
  GRID_FILTER_TYPE_COMPONENTTYPE,
} from "./type";
import { IdentityRoleList } from "models";

export interface GridColFilterDef {
  index: number;
  operator?: string;
  columnName?: GridColDef;
  filterOperator?: string;
  value: string;
}

interface IProps {
  filterItem: GridColFilterDef;
  headerColumns: GridColumns;
  onFilterChange: (filter: GridColFilterDef) => void;
  onFilterRemove: (index: number) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    addFilterGrid: {
      width: "42px",
    },
    operatorGrid: {
      width: "60px",
    },
    columnGrid: {
      flex: 1,
      minWidth: "180px",
    },
    filterOperatorGrid: {
      flex: 1,
      minWidth: "180px",
    },
    filterOperatorTextField: {
      flex: 1,
    },
    filterOperatorSelectorField: {
      width: "12vw",
    },
  })
);

export const operators = [
  {
    name: "And",
    value: "and",
  },
  {
    name: "Or",
    value: "or",
  },
];

export const stringFilterOperators = [
  {
    name: "Contains",
    value: "substringof",
  },
  {
    name: "Equal",
    value: "eq",
  },
  // {
  //   name: "Start with",
  //   value: "startswith",
  // },
  // {
  //   name: "End with",
  //   value: "endswith",
  // },
];

export const filterOperators = [
  {
    name: "Equal",
    value: "eq",
  },
  // {
  //   name: "Not Equal",
  //   value: "ne",
  // },
  {
    name: "Less Than",
    value: "lt",
  },
  {
    name: "Greater Than",
    value: "gt",
  },
  {
    name: "Less Than or Equal",
    value: "le",
  },
  {
    name: "Greater Than or Equal",
    value: "ge",
  },
];

export const idFilterOperators = [
  {
    name: "Equal",
    value: "eq",
  },
];

export const booleanList = [
  {
    name: "Active",
    value: "true",
  },
  {
    name: "Inactive",
    value: "false",
  },
];

export const DataGridFilterSelection: React.FC<IProps> = ({
  filterItem,
  headerColumns,
  onFilterChange,
  onFilterRemove,
}) => {
  const classes = useStyles();

  const { hotelGroupList } = useSelector(
    (state: RootState) => state.hotelGroupState
  );
  const { role, childRoleList } = useSelector(
    (state: RootState) => state.roleState
  );

  const { countryList } = useSelector((state: RootState) => state.country);

  const { componentTypeList } = useSelector(
    (state: RootState) => state.componentType
  );

  const { kioskLocationList } = useSelector(
    (state: RootState) => state.kioskLocationState
  );

  const { languageList } = useSelector(
    (state: RootState) => state.languageState
  );

  const [selectedOperator, setSelectedOperator] = React.useState(
    filterItem.index == 0
      ? undefined
      : filterItem.index > 0 && filterItem.operator != undefined
      ? filterItem.operator
      : operators[0].value
  );

  const [selectedColoumn, setSelectedColoumn] = React.useState(
    filterItem.columnName?.headerName
  );

  const [selectedFilterOperator, setSelectedFilterOperator] = React.useState(
    filterItem.filterOperator != undefined
      ? filterItem.filterOperator
      : filterItem.columnName?.type === GRID_FILTER_TYPE_NUMBER ||
        filterItem.columnName?.type === GRID_FILTER_TYPE_DATETIME
      ? filterOperators[0].value
      : filterItem.columnName?.type === GRID_FILTER_TYPE_BOOLEAN ||
        filterItem.columnName?.type === GRID_FILTER_TYPE_CHILDROLE ||
        filterItem.columnName?.type === GRID_FILTER_TYPE_COMPONENTTYPE ||
        filterItem.columnName?.type === GRID_FILTER_TYPE_COUNTRY ||
        filterItem.columnName?.type === GRID_FILTER_TYPE_HOTELGROUP ||
        filterItem.columnName?.type === GRID_FILTER_TYPE_KIOSK_LOCATION ||
        filterItem.columnName?.type === GRID_FILTER_TYPE_LANGUAGE
      ? idFilterOperators[0].value
      : stringFilterOperators[0].value
  );

  const [value, setValue] = React.useState("");
  const [item, setItem] = React.useState(filterItem);

  // React.useEffect(() => {
  //   var _item = {
  //     ...item,
  //     operator: selectedOperator,
  //     filterOperator: selectedFilterOperator,
  //   };

  //   setItem(_item);
  // }, []);

  React.useEffect(() => {
    if (filterItem.index != 0) {
      if (filterItem.operator == undefined) {
        filterItem.operator = selectedOperator;
      }
    }

    if (filterItem.filterOperator == undefined) {
      filterItem.filterOperator = selectedFilterOperator;
    }

    setItem(filterItem);
    setValue(filterItem.value);
  }, [filterItem]);

  React.useEffect(() => {
    onFilterChange(item);
  }, [item]);

  const onOperatorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOperator(event.target.value);
    setItem({ ...item, operator: event.target.value });
  };

  const onColomnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedColoumn(event.target.value);

    const _item = {
      ...item,
      columnName: headerColumns.find(
        (x) => x.headerName === event.target.value
      ),
    };

    if (_item.columnName?.type !== item.columnName?.type) {
      if (
        _item.columnName?.type === GRID_FILTER_TYPE_NUMBER ||
        _item.columnName?.type === GRID_FILTER_TYPE_DATETIME
      ) {
        _item.filterOperator = filterOperators[0].value;
        setSelectedFilterOperator(filterOperators[0].value);
      } else if (
        _item.columnName?.type === GRID_FILTER_TYPE_BOOLEAN ||
        _item.columnName?.type === GRID_FILTER_TYPE_CHILDROLE ||
        _item.columnName?.type === GRID_FILTER_TYPE_COMPONENTTYPE ||
        _item.columnName?.type === GRID_FILTER_TYPE_COUNTRY ||
        _item.columnName?.type === GRID_FILTER_TYPE_HOTELGROUP ||
        _item.columnName?.type === GRID_FILTER_TYPE_KIOSK_LOCATION ||
        _item.columnName?.type === GRID_FILTER_TYPE_LANGUAGE
      ) {
        _item.filterOperator = idFilterOperators[0].value;
        setSelectedFilterOperator(idFilterOperators[0].value);
      } else {
        _item.filterOperator = stringFilterOperators[0].value;
        setSelectedFilterOperator(stringFilterOperators[0].value);
      }
    }

    setItem(_item);
    setValue("");
  };

  const onFilterOperatorChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSelectedFilterOperator(event.target.value);
    setItem({ ...item, filterOperator: event.target.value });
  };

  const onValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    setItem({ ...item, value: event.target.value });
  };

  return (
    <>
      <GridContainer
        direction="row"
        alignItems="center"
        justifyContent="flex-start"
        spacing={1}
        style={{ padding: "8px" }}
      >
        <GridItem className={classes.addFilterGrid}>
          {filterItem.index > 0 ? (
            <IconButton size="small" onClick={() => onFilterRemove(item.index)}>
              <CloseIcon />
            </IconButton>
          ) : null}
        </GridItem>
        <GridItem className={classes.operatorGrid}>
          {filterItem.index > 0 ? (
            <TextField
              select
              label="Operators"
              value={selectedOperator}
              onChange={onOperatorChange}
            >
              {operators.map((menu, index) => (
                <MenuItem key={index} value={menu.value}>
                  {menu.name}
                </MenuItem>
              ))}
            </TextField>
          ) : null}
        </GridItem>
        <GridItem className={classes.columnGrid}>
          <TextField
            id="column"
            select
            label="Columns"
            fullWidth
            value={selectedColoumn}
            onChange={onColomnChange}
          >
            {headerColumns.map((headerColumn, index) => (
              <MenuItem key={index} value={headerColumn.headerName}>
                {headerColumn.headerName}
              </MenuItem>
            ))}
          </TextField>
        </GridItem>
        <GridItem className={classes.filterOperatorGrid}>
          {item.columnName?.type == GRID_FILTER_TYPE_NUMBER ||
          item.columnName?.type === GRID_FILTER_TYPE_DATETIME ? (
            <TextField
              select
              fullWidth
              label="Operators"
              value={selectedFilterOperator}
              onChange={onFilterOperatorChange}
            >
              {filterOperators.map((headerColumn, index) => (
                <MenuItem key={index} value={headerColumn.value}>
                  {headerColumn.name}
                </MenuItem>
              ))}
            </TextField>
          ) : item.columnName?.type === GRID_FILTER_TYPE_BOOLEAN ||
            item.columnName?.type === GRID_FILTER_TYPE_CHILDROLE ||
            item.columnName?.type === GRID_FILTER_TYPE_COMPONENTTYPE ||
            item.columnName?.type === GRID_FILTER_TYPE_COUNTRY ||
            item.columnName?.type === GRID_FILTER_TYPE_HOTELGROUP ||
            item.columnName?.type === GRID_FILTER_TYPE_KIOSK_LOCATION ||
            item.columnName?.type === GRID_FILTER_TYPE_LANGUAGE ? (
            <TextField
              select
              fullWidth
              label="Operators"
              value={selectedFilterOperator}
              onChange={onFilterOperatorChange}
            >
              {idFilterOperators.map((headerColumn, index) => (
                <MenuItem key={index} value={headerColumn.value}>
                  {headerColumn.name}
                </MenuItem>
              ))}
            </TextField>
          ) : (
            <TextField
              select
              fullWidth
              label="Operators"
              value={selectedFilterOperator}
              onChange={onFilterOperatorChange}
            >
              {stringFilterOperators.map((headerColumn, index) => (
                <MenuItem key={index} value={headerColumn.value}>
                  {headerColumn.name}
                </MenuItem>
              ))}
            </TextField>
          )}
        </GridItem>

        {item.columnName?.type == GRID_FILTER_TYPE_NUMBER ? (
          <GridItem className={classes.filterOperatorTextField}>
            <Typography color="textSecondary" variant="body2">
              Value
            </Typography>
            <TextField
              value={value}
              fullWidth
              onChange={onValueChange}
              type={"number"}
              size="small"
            />
          </GridItem>
        ) : item.columnName?.type == GRID_FILTER_TYPE_DATETIME ? (
          <GridItem className={classes.filterOperatorTextField}>
            <Typography color="textSecondary" variant="body2">
              Value
            </Typography>
            <TextField
              value={value}
              fullWidth
              onChange={onValueChange}
              type="date"
              size="small"
            />
          </GridItem>
        ) : item.columnName?.type == GRID_FILTER_TYPE_IDENTITYROLE ? (
          <GridItem className={classes.filterOperatorTextField}>
            <TextField
              select
              className={classes.filterOperatorSelectorField}
              value={value == "" ? `0` : value}
              label="Value"
              size="small"
              onChange={onValueChange}
            >
              {IdentityRoleList.map((identityRole, index) => (
                <MenuItem key={index} value={identityRole}>
                  {identityRole}
                </MenuItem>
              ))}
            </TextField>
          </GridItem>
        ) : item.columnName?.type == GRID_FILTER_TYPE_BOOLEAN ? (
          <GridItem className={classes.filterOperatorTextField}>
            <TextField
              select
              className={classes.filterOperatorSelectorField}
              value={value == "" ? `0` : value}
              label="Value"
              size="small"
              onChange={onValueChange}
            >
              {booleanList.map((booleanValue, index) => (
                <MenuItem key={index} value={booleanValue.value}>
                  {booleanValue.name}
                </MenuItem>
              ))}
            </TextField>
          </GridItem>
        ) : item.columnName?.type == GRID_FILTER_TYPE_HOTELGROUP ? (
          <GridItem className={classes.filterOperatorTextField}>
            <TextField
              select
              className={classes.filterOperatorSelectorField}
              value={value == "" ? `0` : value}
              label="Value"
              size="small"
              onChange={onValueChange}
            >
              {hotelGroupList.map((hotelGroup, index) => (
                <MenuItem key={index} value={hotelGroup.id}>
                  {hotelGroup.name}
                </MenuItem>
              ))}
            </TextField>
          </GridItem>
        ) : item.columnName?.type == GRID_FILTER_TYPE_CHILDROLE ? (
          <GridItem className={classes.filterOperatorTextField}>
            <TextField
              select
              className={classes.filterOperatorSelectorField}
              value={value == "" ? `0` : value}
              label="Value"
              size="small"
              onChange={onValueChange}
            >
              {childRoleList.map((supervisorRole, index) => (
                <MenuItem key={index} value={supervisorRole.id}>
                  {role?.identityRoles === "Host" &&
                  supervisorRole.hotelGroupId != 0 &&
                  hotelGroupList.length > 0
                    ? `${supervisorRole.name} (${
                        hotelGroupList.find(
                          (x) => x.id === supervisorRole.hotelGroupId
                        )?.name
                      })`
                    : supervisorRole.name}
                </MenuItem>
              ))}
            </TextField>
          </GridItem>
        ) : item.columnName?.type == GRID_FILTER_TYPE_COUNTRY ? (
          <GridItem className={classes.filterOperatorTextField}>
            <TextField
              select
              className={classes.filterOperatorSelectorField}
              value={value == "" ? `0` : value}
              label="Value"
              size="small"
              onChange={onValueChange}
            >
              {countryList.map((country, index) => (
                <MenuItem key={index} value={country.id}>
                  {country.name}
                </MenuItem>
              ))}
            </TextField>
          </GridItem>
        ) : item.columnName?.type == GRID_FILTER_TYPE_COMPONENTTYPE ? (
          <GridItem className={classes.filterOperatorTextField}>
            <TextField
              select
              className={classes.filterOperatorSelectorField}
              value={value == "" ? `0` : value}
              label="Value"
              size="small"
              onChange={onValueChange}
            >
              {componentTypeList.map((componentType, index) => (
                <MenuItem key={index} value={componentType.code}>
                  {componentType.name}
                </MenuItem>
              ))}
            </TextField>
          </GridItem>
        ): item.columnName?.type == GRID_FILTER_TYPE_KIOSK_LOCATION ? (
          <GridItem className={classes.filterOperatorTextField}>
            <TextField
              select
              className={classes.filterOperatorSelectorField}
              value={value == "" ? `0` : value}
              label="Value"
              size="small"
              onChange={onValueChange}
            >
              {kioskLocationList.map((kioskLocation, index) => (
                <MenuItem key={index} value={kioskLocation.code}>
                  {kioskLocation.description}
                </MenuItem>
              ))}
            </TextField>
          </GridItem>
        ) : item.columnName?.type == GRID_FILTER_TYPE_LANGUAGE ? (
          <GridItem className={classes.filterOperatorTextField}>
            <TextField
              select
              className={classes.filterOperatorSelectorField}
              value={value == "" ? `0` : value}
              label="Value"
              size="small"
              onChange={onValueChange}
            >
              {languageList.map((language, index) => (
                <MenuItem key={index} value={language.id}>
                  {language.description}
                </MenuItem>
              ))}
            </TextField>
          </GridItem>
        ) : (
          <GridItem className={classes.filterOperatorTextField}>
            <Typography color="textSecondary" variant="body2">
              Value
            </Typography>
            <TextField
              value={value}
              fullWidth
              onChange={onValueChange}
              type="text"
              size="small"
            />
          </GridItem>
        )}

        <GridItem></GridItem>
      </GridContainer>
    </>
  );
};
