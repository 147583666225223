import {
  CreateRoleRequestModel,
  CreateRoleResponseModel,
  DeleteRoleRequestModel,
  DeleteRoleResponseModel,
  GetRoleRequestModel,
  GetRoleResponseModel,
  GetRolesRequestModel,
  GetRolesResponseModel,
  UpdateRoleRequestModel,
  UpdateRoleResponseModel,
} from "models";
import { getServerFilter } from "utils/filterHelper";
import {
  getApiKeyForHotel,
  getLoginUser,
  privateHttpClient,
} from "utils/httpClient";

export const createRoleAPI = async (
  request: CreateRoleRequestModel
): Promise<CreateRoleResponseModel> => {
  const { data } = await privateHttpClient.post(
    "/api/appRole",
    {
      ...request,
    },
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${getLoginUser().token}`,
        ApiKey: getApiKeyForHotel(),
      },
    }
  );

  return data;
};

export const getRoleAPI = async (
  request: GetRoleRequestModel
): Promise<GetRoleResponseModel> => {
  const { data } = await privateHttpClient.get(`/api/appRole/${request.id}`, {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${getLoginUser().token}`,
      ApiKey: getApiKeyForHotel(),
    },
  });

  return data;
};

export const getRoleListAPI = async (
  request: GetRolesRequestModel
): Promise<GetRolesResponseModel> => {
  const { data } = await privateHttpClient.get("/api/appRole", {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${getLoginUser().token}`,
      ApiKey: getApiKeyForHotel(),
    },
    params: {
      ...request,
      filter: getServerFilter(request?.filter),
    },
  });

  return data;
};

export const updateRoleAPI = async (
  request: UpdateRoleRequestModel
): Promise<UpdateRoleResponseModel> => {
  const { data } = await privateHttpClient.put(
    "/api/appRole",
    {
      ...request,
    },
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${getLoginUser().token}`,
        ApiKey: getApiKeyForHotel(),
      },
    }
  );

  return data;
};

export const deleteRoleAPI = async (
  request: DeleteRoleRequestModel
): Promise<DeleteRoleResponseModel> => {
  const { data } = await privateHttpClient.delete("/api/appRole", {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${getLoginUser().token}`,
      ApiKey: getApiKeyForHotel(),
    },
    data: {
      ...request,
    },
  });

  return data;
};
