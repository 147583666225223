export const HttpMethodList = [
    {
      value: 'GET',
      label: 'GET'
    },
    {
      value: 'POST',
      label: 'POST'
    },
    {
      value: 'PUT',
      label: 'PUT'
    },
    {
      value: 'DELETE',
      label: 'DELETE'
    }
  ];
  