import React, { createRef } from "react";
// @material-ui/core components
import Button, { ButtonProps } from "@material-ui/core/Button";
import Papa from "papaparse";

interface IProps extends ButtonProps {
  onProcessing: () => void;
  onExtractCompleted: (result: any) => void;
}

export const ImportCSVButton: React.FC<IProps> = ({
  children,
  onProcessing,
  onExtractCompleted,
  ...rest
}) => {
  const uploadCsvRef = createRef<
    HTMLInputElement & { link: HTMLAnchorElement }
  >();

  const handleOnChange = (event: React.FormEvent<HTMLInputElement>) => {
    onProcessing();

    if (
      event.currentTarget.files !== undefined &&
      event.currentTarget.files !== null &&
      event.currentTarget.files.length > 0
    ) {
      // Passing file data (event.target.files[0]) to parse using Papa.parse
      Papa.parse(event.currentTarget.files[0], {
        header: true,
        skipEmptyLines: true,
        complete: (results: any) => {
          onExtractCompleted(results.data);
        },
      });
    }
  };

  const buttonClick = () => {
    uploadCsvRef.current?.click();
  };

  return (
    <>
      <Button color="primary" onClick={buttonClick} {...rest}>
        {children}
      </Button>
      <input
        ref={uploadCsvRef}
        hidden
        type={"file"}
        accept={".csv"}
        onChange={handleOnChange}
      />
    </>
  );
};
