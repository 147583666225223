import {
  CreateLanguageRequestModel,
  CreateLanguageResponseModel,
  DeleteLanguageRequestModel,
  DeleteLanguageResponseModel,
  GetLanguageRequestModel,
  GetLanguageResponseModel,
  GetLanguagesRequestModel,
  GetLanguagesResponseModel,
  UpdateLanguageRequestModel,
  UpdateLanguageResponseModel,
} from "models";
import { getServerFilter } from "utils/filterHelper";
import {
  getApiKeyForHotel,
  getLoginUser,
  privateHttpClient,
} from "../utils/httpClient";

export const createLanguageAPI = async (
  request: CreateLanguageRequestModel
): Promise<CreateLanguageResponseModel> => {
  const { data } = await privateHttpClient.post(
    "/api/language",
    {
      ...request,
    },
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${getLoginUser().token}`,
        ApiKey: getApiKeyForHotel(),
      },
    }
  );

  return data;
};

export const getLanguageAPI = async (
  request: GetLanguageRequestModel
): Promise<GetLanguageResponseModel> => {
  const { data } = await privateHttpClient.get(`/api/language/${request.id}`, {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${getLoginUser().token}`,
      ApiKey: getApiKeyForHotel(),
    },
  });

  return data;
};

export const getLanguageListAPI = async (
  request: GetLanguagesRequestModel
): Promise<GetLanguagesResponseModel> => {
  const { data } = await privateHttpClient.get("/api/language", {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${getLoginUser().token}`,
      ApiKey: getApiKeyForHotel(),
    },
    params: {
      ...request,
      filter: getServerFilter(request?.filter),
    },
  });

  return data;
};

export const updateLanguageSettingAPI = async (
  request: UpdateLanguageRequestModel
): Promise<UpdateLanguageResponseModel> => {
  const { data } = await privateHttpClient.put(
    "/api/language",
    {
      ...request,
    },
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${getLoginUser().token}`,
        ApiKey: getApiKeyForHotel(),
      },
    }
  );

  return data;
};

export const deleteLanguageAPI = async (
  request: DeleteLanguageRequestModel
): Promise<DeleteLanguageResponseModel> => {
  const { data } = await privateHttpClient.delete("/api/language", {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${getLoginUser().token}`,
      ApiKey: getApiKeyForHotel(),
    },
    data: {
      ...request,
    },
  });

  return data;
};
