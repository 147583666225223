import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store/state";
import { ResponseModel } from "../models/common/ResponseModel";
import { AxiosError } from "axios";
import { ARIA_SuccessCode, GetIdentityRoleResponseModel } from "models";
import { getIdentityUserRoleList } from "services/IdentityRoleService";

const initialState: RootState.IdentityRoleState = {
  identityUserRoleList: [],
  error: undefined,
  isLoading: false,
};

//Actions
//More info here https://redux-toolkit.js.org/api/createAsyncThunk
export const fetchIdentityUserRoleList = createAsyncThunk<
  GetIdentityRoleResponseModel,
  void,
  { rejectValue: AxiosError<Error> }
>("identityUserRole/fetchIdentityUserRoleList", async (_, thunkApi) => {
  try {
    let data: GetIdentityRoleResponseModel = await getIdentityUserRoleList();
    return data;
  } catch (err) {
    let error = err as AxiosError<Error>; // cast the error for access
    if (!error.response) {
      throw err;
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    return thunkApi.rejectWithValue(error);
  }
});

//Slice
//More info here https://redux-toolkit.js.org/api/createSlice
export const identityUserRoleSlice = createSlice({
  name: "identityUserRole",
  initialState,
  reducers: {
    //local reducer
  },
  //services reducers
  extraReducers: (builder) => {
    builder.addCase(
      fetchIdentityUserRoleList.fulfilled,
      (state, { payload }) => {
        if (payload.resultCode === ARIA_SuccessCode) {
          state.identityUserRoleList = payload.result;
          state.error = undefined;
        } else {
          state.error = { ...payload };
        }

        state.isLoading = false;
      }
    ),
      builder.addCase(fetchIdentityUserRoleList.pending, (state, _) => {
        state.isLoading = true;
      }),
      builder.addCase(fetchIdentityUserRoleList.rejected, (state, action) => {
        if (action.payload != undefined) {
          let error: AxiosError<Error> = action.payload;

          const serverError: ResponseModel = {
            resultCode: error?.response?.status.toString() ?? "",
            resultDescription: error?.response?.data.message ?? "",
          };

          state.error = serverError;
          state.isLoading = false;
        }
      });
  },
});

export const {} = identityUserRoleSlice.actions;

export default identityUserRoleSlice.reducer;
