import React from "react";
import { Badge, Button, Popover } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import FilterListIcon from "@material-ui/icons/FilterList";
import { GridColumns } from "@material-ui/data-grid";
import {
  DataGridFilterSelection,
  //   filterOperators,
  GridColFilterDef,
} from "components/DataGridFilterSelection";

//Icons
import AddIcon from "@material-ui/icons/Add";
import { GridContainer } from "components/GridContainer";
import { GridItem } from "components/GridItem";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popover: {
      padding: theme.spacing(2),
    },
  })
);

interface IProps {
  headerColumns: GridColumns;
  filterList: GridColFilterDef[];
  onFilterApply: (filter: GridColFilterDef[]) => void;
}

export const DataGridFilterToolbar: React.FC<IProps> = ({
  headerColumns,
  filterList,
  onFilterApply,
  ...rest
}) => {
  const classes = useStyles();

  const [items, setItems] = React.useState(filterList);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const [initialFilter] = React.useState<GridColFilterDef>({
    index: 0,
    columnName: headerColumns[0],
    value: "",
  });

  React.useEffect(() => {
    if (filterList.length == 0) {
      setItems([initialFilter]);
    } else {
      setItems(filterList);
    }
  }, [filterList]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onFilterModelChange = (filter: GridColFilterDef) => {
    // console.log("onFilterModelChange");
    // console.log(filter);
    items[filter.index] = filter;
    setItems(items);
  };

  const open = Boolean(anchorEl);

  const onAddFilterClick = () => {
    var currentToCompare = items.slice();
    currentToCompare.push(initialFilter);
    setItems(currentToCompare);
  };

  const onRemoveFilterClick = (index: number) => {
    var currentToCompare = items.filter((_data, idx) => idx !== index);
    setItems(currentToCompare);
  };

  const onApplyFilterClick = () => {
    // console.log("onApplyFilterClick");
    onFilterApply(items);
    setAnchorEl(null);
  };

  const onClearFilterClick = () => {
    onFilterApply([]);
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        startIcon={
          <Badge badgeContent={filterList.length} color="primary">
            <FilterListIcon />
          </Badge>
        }
        color="primary"
        onClick={handleClick}
      >
        Filters
      </Button>
      <Popover
        open={open}
        className={classes.popover}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <GridContainer direction="column" style={{ padding: "8px" }}>
          <GridItem>
            {items.map((filter, index) => (
              <DataGridFilterSelection
                key={index}
                filterItem={{ ...filter, index: index }}
                headerColumns={headerColumns.filter(
                  (i) => i.filterable != false
                )}
                onFilterChange={onFilterModelChange}
                onFilterRemove={onRemoveFilterClick}
              />
            ))}
          </GridItem>
          <GridItem>
            <GridContainer
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <GridItem>
                <Button
                  startIcon={<AddIcon />}
                  color="primary"
                  onClick={onAddFilterClick}
                >
                  Add Filter
                </Button>
              </GridItem>
              <GridItem>
                <Button
                  color="primary"
                  style={{ marginRight: "8px" }}
                  variant="contained"
                  onClick={onClearFilterClick}
                >
                  Clear
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={onApplyFilterClick}
                >
                  Apply
                </Button>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </Popover>
    </>
  );
};
