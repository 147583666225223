import React, { useState } from "react";
// @material-ui/core components
import CodeMirror from "@uiw/react-codemirror";
import { json } from "@codemirror/lang-json";
import { Button, Typography } from "@material-ui/core";

interface IProps {
  title?: string;
  value: string;
  onChange: (value: string) => void;
}

export const CodeMirrorTextField: React.FC<IProps> = ({
  title = "Default Value",
  value,
  onChange,
}) => {
  const [editingValue, setEditingValue] = useState<string>("");

  React.useEffect(() => {
    try {
      setEditingValue(JSON.stringify(JSON.parse(value), null, 2));
    } catch (err) {}
  }, []);

  return (
    <div style={{ position: "relative" }}>
      <Typography color="textSecondary" variant="caption">
        {title}
      </Typography>

      <CodeMirror
        value={editingValue}
        theme={"dark"}
        extensions={[json()]}
        onChange={(value) => {
          onChange(value);
          setEditingValue(value);
        }}
      />
      <Button
        color="primary"
        variant="text"
        style={{ position: "absolute", top: "24px", right: "4px " }}
        onClick={() => {
          setEditingValue(JSON.stringify(JSON.parse(editingValue), null, 2));
          onChange(JSON.stringify(JSON.parse(editingValue), null, 2));
        }}
      >
        Beautify
      </Button>
    </div>
  );
};
