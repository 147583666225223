import React, { useState } from "react";
import { useSelector } from "react-redux";

import { RootState } from "reducers";

//Component
import {
  DataGrid,
  GridColDef,
  GridColumns,
  GridRowParams,
  GridToolbarContainer,
  MuiEvent,
} from "@material-ui/data-grid";
import CustomLoadingOverlay from "components/DataGridLoadingOverlay";
import CustomNoRowsOverlay from "components/DataGridNoRowsOverlay";
import { ARIA_SuccessCode, Reservation } from "models";
import { DataGridFilterToolbar } from "components/DataGridFilterToolbar";
import { GridColFilterDef } from "components/DataGridFilterSelection";
//Icon

//Helper
import {
  convertDateTimeToDate,
  convertDateTimeToString,
} from "utils/dateHelper";
// import { ExportButton } from "components/ExportButton";
import { MainDrawer } from "components/MainDrawer";
import { getReservationListAPI } from "services/reservationService";
// import {
//   Button,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
//   TextField,
// } from "@material-ui/core";
import { CodeMirrorDialog } from "components";
import { spiltTextAndAcronyms } from "utils/stringHelper";

export const ReservationManagementScreen = () => {
  const { selectedHotel } = useSelector((state: RootState) => state.hotelState);
  //   const dispatch = useDispatch();

  const [index, setIndex] = useState<number>(1);
  const [size, setSize] = useState<number>(10);
  const [row, setRow] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);
  // const [isExporting, setIsExporting] = useState(false);

  const [requestDateTime, setRequestDateTime] = useState<Date>(new Date());

  const [errorLogList, setErrorLogList] = useState<Reservation[]>([]);
  // const [exportList, setExportList] = useState<Data>([]);
  const [filterList, setFilterList] = useState<GridColFilterDef[]>([]);

  const [viewingErrorLog, setViewingErrorLog] = useState<
    string | undefined
  >();

  //Setting up the each column header item
  const confirmationNoHeader: GridColDef = {
    field: "confirmationNo",
    headerName: "Confirmation No",
    renderHeader: (params: any) => <strong>{"Confirmation No"}</strong>,
    minWidth: 160,
  };

  const arrivalDateHeader: GridColDef = {
    field: "arrivalDate",
    headerName: "Arrival Date",
    type: "dateTime",
    renderHeader: (params: any) => <strong>{"Arrival Date"}</strong>,
    renderCell: (params: any) => (
      <label>{convertDateTimeToDate(params.value)}</label>
    ),
    minWidth: 160,
  };

  const departureDateHeader: GridColDef = {
    field: "departureDate",
    headerName: "Departure Date",
    type: "dateTime",
    renderHeader: (params: any) => <strong>{"Departure Date"}</strong>,
    renderCell: (params: any) => (
      <label>{convertDateTimeToDate(params.value)}</label>
    ),
    minWidth: 160,
  };

  const guestNameHeader: GridColDef = {
    field: "guest",
    headerName: "Guest Name",
    type: "object",
    description: "guest/name",
    renderHeader: (params: any) => <strong>{"Guest Name"}</strong>,
    renderCell: (params: any) => <label>{params.value.name}</label>,
    minWidth: 200,
    flex: 1,
  };

  const eventStatusHeader: GridColDef = {
    field: "reservationStatusEvents",
    headerName: "last status",
    filterable: false,
    renderHeader: (params: any) => <strong>{"Last Status"}</strong>,
    renderCell: (params: any) => 
    (
      
      <label>{ params.value.at(-1) != null ? spiltTextAndAcronyms(params.value.at(-1).status) : "NA" }</label>
    ),
    minWidth: 180,
  };

  const createdHeader: GridColDef = {
    field: "created",
    headerName: "Created Date",
    type: "dateTime",
    renderHeader: (params: any) => <strong>{"Created Date"}</strong>,
    renderCell: (params: any) => (
      <label>{convertDateTimeToString(params.value)}</label>
    ),
    minWidth: 180,
  };

  const headerColumn: GridColumns = [
    confirmationNoHeader,
    arrivalDateHeader,
    departureDateHeader,
    guestNameHeader,
    eventStatusHeader,
    createdHeader,
  ];

  React.useEffect(() => {
    if (selectedHotel != undefined) {
      setIsLoading(true);
      setErrorLogList([]);
      setRequestDateTime(new Date());

      getReservationListAPI({
        filter: filterList,
        pageIndex: index,
        pageSize: size,
        orderBy: "created desc",
      })
        .then((response) => {
          if (response.resultCode === ARIA_SuccessCode) {
            setErrorLogList(response.result.items);
            setRow(response.result.rowCount);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [index, size, filterList, selectedHotel]);

  // const getExportTransaction = async () => {
  //   setIsLoading(true);
  //   var _exportIndex = 1;
  //   var exportList: ErrorLog[] = [];

  //   try {
  //     do {
  //       var response = await getErrorLogListAPI({
  //         filter: filterList,
  //         pageIndex: _exportIndex,
  //         pageSize: size,
  //         orderBy: "created desc",
  //       });

  //       exportList.push(...response.result.items);

  //       _exportIndex++;
  //     } while (response.result.pageCount >= _exportIndex);

  //     setExportList(exportList);
  //     setIsExporting(true);
  //     setIsLoading(false);
  //   } catch (err) {
  //     console.log("Oposs.. Something is wrong");
  //   }
  // };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <DataGridFilterToolbar
          headerColumns={headerColumn}
          filterList={filterList}
          onFilterApply={(items) => {
            setFilterList(items.slice());
          }}
        />
        {/* <ExportButton
          filename="ErrorLog"
          data={exportList}
          headers={[
            { label: "Date", key: "created" },
            { label: "Platform", key: "platform" },
            { label: "Session", key: "session" },
            { label: "Confirmation No", key: "confirmationNo" },
            { label: "Error Message", key: "errorMessage" },
            { label: "Serverity", key: "serverityNumber" },
            { label: "Serverity Description", key: "serverity" },
          ]}
          readyToExport={isExporting}
          onClick={getExportTransaction}
          onFinishDownload={() => {
            setExportList([]);
            setIsExporting(false);
          }}
        >
          Export
        </ExportButton> */}
        <div style={{ flexGrow: 1 }}></div>
        <div style={{ paddingLeft: "8px" }}>
          <b>Data as at </b>: {convertDateTimeToString(requestDateTime)}
        </div>
      </GridToolbarContainer>
    );
  }

  const handleOpenEditDialog = (
    params: GridRowParams,
    event: MuiEvent<React.SyntheticEvent>,
    details?: any
  ) => {
    var _updateVerbiage = errorLogList.find((f) => f.id === params.id);
    // setViewingErrorLog(_updateVerbiage);
    if(_updateVerbiage != undefined){
      setViewingErrorLog(
        JSON.stringify(_updateVerbiage, null, 2)
      )
    }

    // setOpenEditDialog(true);
  };

  const handleEditClose = () => {
    setViewingErrorLog(undefined);
    // setOpenEditDialog(false);
  };

  return (
    <>
      <MainDrawer title="Reservation Details">
        {/* Add and Edit Dialog */}
        {viewingErrorLog != undefined ? (

          <CodeMirrorDialog
            open={true}
            title={'Viewing reservations'}
            editingContent={viewingErrorLog}
            onHandleCloseClick={handleEditClose}
            onHandleBeautifyClick={() =>
              setViewingErrorLog(JSON.stringify(JSON.parse(viewingErrorLog), null, 2))
            }
          />
        ) : null}

        {/* Data Grid */}
        <div style={{ height: "80vh" }}>
          <div style={{ display: "flex", height: "100%" }}>
            <div style={{ flexGrow: 1 }}>
              <DataGrid
                pageSize={size}
                rowsPerPageOptions={[10, 20, 30]}
                onPageSizeChange={(size) => {
                  setSize(size);
                }}
                disableColumnMenu
                density="compact"
                components={{
                  LoadingOverlay: CustomLoadingOverlay,
                  NoRowsOverlay: CustomNoRowsOverlay,
                  Toolbar: CustomToolbar,
                }}
                pagination
                loading={isLoading}
                paginationMode="server"
                rowCount={row}
                filterMode="server"
                onPageChange={(newPage) => {
                  setIndex(++newPage);
                }}
                rows={errorLogList}
                onRowClick={handleOpenEditDialog}
                columns={headerColumn}
                getRowId={(row) => row.id}
              />
            </div>
          </div>
        </div>
      </MainDrawer>
    </>
  );
};
