import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store/state";
import { ResponseModel } from "../models/common/ResponseModel";
import { AxiosError } from "axios";
import { ARIA_SuccessCode, GetConfigurationGroupsResponseModel } from "models";
import { getConfigurationGroupListAPI } from "services/configurationGroupService";

const initialState: RootState.ConfigurationGroupState = {
  configurationGroupList: [],
  error: undefined,
  isLoading: false,
};

//Actions
//More info here https://redux-toolkit.js.org/api/createAsyncThunk
export const fetchConfigurationGroupList = createAsyncThunk<
  GetConfigurationGroupsResponseModel,
  void,
  { rejectValue: AxiosError<Error> }
>("configurationGroup/getConfigurationGroupList", async (_, thunkApi) => {
  try {
    var pageIndex = 1;

    let data: GetConfigurationGroupsResponseModel =
      await getConfigurationGroupListAPI({ pageIndex });

    let tempData = data.result.items;

    while (data.result.pageCount > pageIndex) {
      pageIndex++;
      let nextData: GetConfigurationGroupsResponseModel =
        await getConfigurationGroupListAPI({ pageIndex });

      tempData.push(...nextData.result.items);
    }

    data.result.items = tempData;

    return data;
  } catch (err) {
    let error = err as AxiosError<Error>; // cast the error for access
    if (!error.response) {
      throw err;
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    return thunkApi.rejectWithValue(error);
  }
});

//Slice
//More info here https://redux-toolkit.js.org/api/createSlice
export const configurationGroupSlice = createSlice({
  name: "configurationGroup",
  initialState,
  reducers: {
    //local reducer
    resetHotelGroupState() {
      return initialState;
    },
  },
  //services reducers
  extraReducers: (builder) => {
    builder.addCase(
      fetchConfigurationGroupList.fulfilled,
      (state, { payload }) => {
        if (payload.resultCode === ARIA_SuccessCode) {
          state.configurationGroupList = payload.result.items;
          state.error = undefined;
        } else {
          state.error = { ...payload };
        }

        state.error = undefined;
        state.isLoading = false;
      }
    ),
      builder.addCase(fetchConfigurationGroupList.pending, (state, _) => {
        state.isLoading = true;
      }),
      builder.addCase(
        fetchConfigurationGroupList.rejected,
        (state, action) => {
          if (action.payload != undefined) {
            let error: AxiosError<Error> = action.payload;

            const serverError: ResponseModel = {
              resultCode: error?.response?.status.toString() ?? "",
              resultDescription: error?.response?.data.message ?? "",
            };

            state.error = serverError;
            state.isLoading = false;
          }
        }
      );
  },
});

export const { resetHotelGroupState } = configurationGroupSlice.actions;

export default configurationGroupSlice.reducer;
