import React from "react";
// @material-ui/core components
import { CircularProgress, IconButton, Typography } from "@material-ui/core";

import ImageIcon from "@material-ui/icons/Image";
import CancelIcon from "@material-ui/icons/Cancel";
import { deleteImageAPI, uploadImageAPI } from "services/imageService";
import { ARIA_SuccessCode } from "models";
import { getFilenameForImageUrl } from "utils/fileHelper";
import { getCurrentDateTime } from "utils/dateHelper";

interface IProps {
  defaultValue?: string;
  label?: string;
  allowDeleteImage?: boolean;
  onUploadSuccess?: (imageUrl: string | undefined) => void;
}

export const UploadImageView: React.FC<IProps> = ({
  defaultValue,
  label,
  allowDeleteImage = false,
  onUploadSuccess,
}) => {
  const [imageUrl, setImageUrl] = React.useState<string | undefined>(
    defaultValue
  );
  const [imageFilename, setImageFilename] = React.useState<string>("");
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    if (imageUrl) setImageFilename(getFilenameForImageUrl(imageUrl));
  }, []);

  async function onSelectFileToUpload(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    if (event.target.files != null) {
      setIsLoading(true);
      const errs = [];
      const files = Array.from(event.target.files);

      if (files.length > 3) {
        const msg = "Only 3 images can be uploaded at a time";
        alert(msg);
      }

      //   const formData = new FormData();
      const types = ["image/png", "image/jpeg"]; //, 'image/gif']

      files.forEach((file, i) => {
        if (types.every((type) => file.type !== type)) {
          alert(`'${file.type}' is not a supported format`);
          errs.push(`'${file.type}' is not a supported format`);
          //   event.target.value = "";
        }

        //3MB
        if (file.size > 3000000) {
          alert(
            `'${file.name}' is too large, please pick a smaller image file`
          );
          errs.push(
            `'${file.name}' is too large, please pick a smaller image file`
          );
          //   event.target.value = "";
        }

        // formData.append(i, file);
      });

      if (errs.length) {
        setIsLoading(false);
        //   return errs.forEach(err => this.toast(err, 'custom', 2000, toastColor))
      } else {
        if (files && files.length) {
          deleteImage();
          //upload into aria server
          const formData = new FormData();
          //   var uploadImageFile: File = { ...files[0], name: "testing.jpg" };
          formData.append(
            "FileModel.ImageFile",
            files[0],
            `${getCurrentDateTime()}_${getFilenameForImageUrl(files[0].name)}`
          );

          uploadImageAPI(formData)
            .then((response) => {
              if (response.resultCode === ARIA_SuccessCode) {
                setImageUrl(response.result.fileUri);
                if (onUploadSuccess) onUploadSuccess(response.result.fileUri);
              }
            })
            .catch((err) => {
              alert("Please try again later...");
            })
            .finally(() => {
              setIsLoading(false);
            });
        }
      }
    } else {
      setIsLoading(false);
    }
  }

  const deleteImage = () => {
    deleteImageAPI({ filename: imageFilename }).finally(() => {
      if (onUploadSuccess) onUploadSuccess(undefined);
    });

    setImageUrl(undefined);
  };

  return (
    <>
      <Typography variant="caption" color="textSecondary">
        {label}
      </Typography>
      <div
        style={{
          textAlign: "center",
        //   padding: "16px",
          margin: "8px",
          position: "relative",
        }}
      >
        {isLoading ? (
          <CircularProgress style={{ zIndex: 1, position: "absolute" }} />
        ) : null}
        {imageUrl !== undefined && imageUrl !== null ? (
          <img
            style={{ maxHeight: "300px", maxWidth: "480px" }}
            src={imageUrl}
            alt="Logo"
          />
        ) : (
          <ImageIcon />
        )}
        {allowDeleteImage ? (
          <IconButton
            style={{
              zIndex: 1,
              position: "absolute",
              top: "-8px",
              right: "-8px",
              visibility: imageUrl == undefined ? "hidden" : "visible",
            }}
            onClick={deleteImage}
          >
            <CancelIcon />
          </IconButton>
        ) : null}
      </div>
      <div className="button">
        <label htmlFor="single"></label>
        <input
          type="file"
          id="single"
          disabled={isLoading}
          onChange={onSelectFileToUpload}
        />
      </div>
    </>
  );
};
