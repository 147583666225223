import {
    CreateComponentRequestModel,
    CreateComponentResponseModel,
    DeleteComponentRequestModel,
    DeleteComponentResponseModel,
    GetComponentRequestModel,
    GetComponentResponseModel,
    GetComponentsRequestModel,
    GetComponentsResponseModel,
    UpdateComponentRequestModel,
    UpdateComponentResponseModel,
  } from "models";
  
  import { getServerFilter } from "utils/filterHelper";
  import {
    getApiKeyForHotel,
    getLoginUser,
    privateHttpClient,
  } from "utils/httpClient";
  
  export const createComponentAPI = async (
    request: CreateComponentRequestModel
  ): Promise<CreateComponentResponseModel> => {
    const { data } = await privateHttpClient.post(
      "/api/component",
      {
        ...request,
      },
      {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${getLoginUser().token}`,
          ApiKey: getApiKeyForHotel(),
        },
      }
    );
  
    return data;
  };
  
  export const getComponentAPI = async (
    request: GetComponentRequestModel
  ): Promise<GetComponentResponseModel> => {
    const { data } = await privateHttpClient.get(
      `/api/component/${request.id}`,
      {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${getLoginUser().token}`,
          ApiKey: getApiKeyForHotel(),
        },
      }
    );
  
    return data;
  };
  
  export const getComponentListAPI = async (
    request: GetComponentsRequestModel
  ): Promise<GetComponentsResponseModel> => {
    const { data } = await privateHttpClient.get("/api/component", {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${getLoginUser().token}`,
        ApiKey: getApiKeyForHotel(),
      },
      params: {
        ...request,
        filter: getServerFilter(request?.filter),
      },
    });
  
    return data;
  };
  
  export const updateComponentAPI = async (
    request: UpdateComponentRequestModel
  ): Promise<UpdateComponentResponseModel> => {
    const { data } = await privateHttpClient.put(
      "/api/component",
      {
        ...request,
      },
      {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${getLoginUser().token}`,
          ApiKey: getApiKeyForHotel(),
        },
      }
    );
  
    return data;
  };
  
  export const deleteComponentAPI = async (
    request: DeleteComponentRequestModel
  ): Promise<DeleteComponentResponseModel> => {
    const { data } = await privateHttpClient.delete("/api/component", {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${getLoginUser().token}`,
        ApiKey: getApiKeyForHotel(),
      },
      data: {
        ...request,
      },
    });
  
    return data;
  };
  