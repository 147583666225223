import React, { useState } from "react";
// @material-ui/core components
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { DynamicTypography } from "components/DynamicTypography";
import { GridContainer } from "components/GridContainer";
import { GridItem } from "components/GridItem";
import { TextField } from "@material-ui/core";

interface IProps {
  open: boolean;
  onCancelClicked?: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

export const Base64ToFileDialog: React.FC<IProps> = ({
  open,
  onCancelClicked,
}) => {
  const [base64String, setBase64String] = useState<string>("");

  React.useEffect(() => {
    if (!open) {
      setBase64String("");
    }
  }, [open]);

  const onDownloadClicked = () => {
    // Convert Base64 string to binary data
    const binaryString = atob(base64String);

    // Convert binary data to ArrayBuffer
    const arrayBuffer = new ArrayBuffer(binaryString.length);
    const uint8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < binaryString.length; i++) {
      uint8Array[i] = binaryString.charCodeAt(i);
    }

    // Create Blob from ArrayBuffer
    const blob = new Blob([arrayBuffer], { type: "application/octet-stream" });
    // const blob = new Blob([arrayBuffer], { type: "application/vnd.apple.pkpass" });

    // Create URL from Blob
    const url = URL.createObjectURL(blob);

    // Create anchor element to trigger download
    const a = document.createElement("a");
    a.href = url;
    a.download = "Generic.pkpass";

    // Trigger download
    a.click();

    // Revoke URL to free up memory
    URL.revokeObjectURL(url);
  };

  return (
    <Dialog open={open} fullWidth maxWidth="xl">
      <DialogTitle>Base64 to Image</DialogTitle>
      <DialogContent>
        <DynamicTypography
          content={"Place the base64 string to convert to image"}
        />
        <GridContainer
          spacing={2}
          direction="column"
          alignItems="center"
          alignContent="center"
        >
          <GridItem style={{ width: "90vw" }}>
            <TextField
              variant="outlined"
              value={base64String}
              multiline
              maxRows={10}
              fullWidth
              onChange={(event) => setBase64String(event.currentTarget.value)}
            />
          </GridItem>
          <GridItem>
            <Button onClick={onDownloadClicked}>Download</Button>
          </GridItem>
        </GridContainer>
      </DialogContent>

      <DialogActions>
        <Button onClick={onCancelClicked}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};
