import {
  DeleteImageRequestModel,
  DeleteImageResponseModel,
  UploadImageRequestModel,
  UploadImageResponseModel,
} from "models";
import {
  getApiKeyForHotel,
  getLoginUser,
  privateHttpClient,
} from "utils/httpClient";

export const uploadImageAPI = async (
  request: UploadImageRequestModel
): Promise<UploadImageResponseModel> => {
  const { data } = await privateHttpClient.post("/api/image", request, {
    headers: {
      "Content-type": "multipart/form-data",
      Authorization: `Bearer ${getLoginUser().token}`,
      ApiKey: getApiKeyForHotel(),
    },
  });

  return data;
};

export const deleteImageAPI = async (
  request: DeleteImageRequestModel
): Promise<DeleteImageResponseModel> => {
  const { data } = await privateHttpClient.delete("/api/image", {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${getLoginUser().token}`,
      ApiKey: getApiKeyForHotel(),
    },
    data: {
      ...request,
    },
  });

  return data;
};
