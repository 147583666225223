import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';

// @material-ui/core components
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Slide, TextField } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { TransitionProps } from '@material-ui/core/transitions';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { BottomDecisionButton, GridContainer, GridItem } from '../../components';

import { DocumentTypeList, GenderList, UpdateGuestRequestModel, Guest } from 'models';
import { displayLastFourChar, getVerbiage } from 'utils/stringHelper';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { DateType } from '@date-io/type';
import { convertStringDateToServerFormat } from 'utils/dateHelper';
import { updateGuestForReservation } from 'reducers/reservation';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'fixed',
    backgroundColor: theme.palette.primary.main
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  }
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface IProps extends DialogProps {
  updateUser: Guest | undefined;
  onCompleted: () => void;
}

export const GuestInfoDialog: React.FC<IProps> = ({ updateUser, open, onCompleted, children, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { verbiages } = useSelector((state: RootState) => state.verbiages);
  const { countryList } = useSelector((state: RootState) => state.country);
  const { selectedHotel } = useSelector((state: RootState) => state.hotelState);
  const { isLoading, reservationServiceError } = useSelector((state: RootState) => state.reservationState);

  const [nationalities] = React.useState(countryList.filter((x) => x.nationality != null));

  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [selectedGender, setSelectedGender] = React.useState('');
  const [dateOfBirth, setDateOfBirth] = React.useState<DateType | null>(null);

  const [selectedDocumentType, setSelectedDocumentType] = React.useState<string | null>('');
  const [documentNo, setDocumentNo] = React.useState('');
  const [documentExpiryDate, setDocumentExpiryDate] = React.useState<DateType | null>(null);
  const [documentIssuedCountryId, setDocumentIssuedCountryId] = React.useState<number>(1);

  const [selectedNationality, setSelectedNationality] = React.useState('');

  const [firstNameHelpText, setFirstNameHelpText] = React.useState<string | null>(null);
  const [lastNameHelpText, setLastNameHelpText] = React.useState<string | null>(null);
  const [isDocumentNoTextOnFocus, setIsDocumentNoTextOnFocus] = React.useState(false);
  const [documentNoHelpText, setDocumentNoHelpText] = React.useState<string | null>(null);

  const [isUpdating, setIsUpdating] = React.useState(false);

  React.useEffect(() => {
    if (isUpdating == true && reservationServiceError == undefined) {
      setIsUpdating(false);
      onCompleted();
    }
  }, [isUpdating, reservationServiceError]);

  React.useEffect(() => {
    if (updateUser != undefined) {
      if (updateUser.gender != null) {
        setSelectedGender(updateUser.gender);
      }

      if (updateUser.firstName) {
        setFirstName(updateUser.firstName);
      }

      if (updateUser.lastName) {
        setLastName(updateUser.lastName);
      }

      if (updateUser.travelDocuments != undefined && updateUser.travelDocuments.length > 0) {
        const { documentType, issuedCountryId, documentNo } = updateUser.travelDocuments[0];

        setDocumentNo(documentNo);

        if (updateUser.travelDocuments[0].expiryDate != undefined) {
          setDocumentExpiryDate(new Date(updateUser.travelDocuments[0].expiryDate));
        }

        if (documentType != undefined) {
          const docType = DocumentTypeList.find((x) => x.value.toLowerCase() === documentType.toLowerCase());
          
          if (docType != undefined) {
            setSelectedDocumentType(docType.value.toLowerCase());
          }
        }

        if (issuedCountryId != undefined && issuedCountryId > 0) {
          setDocumentIssuedCountryId(issuedCountryId);
        }
      } else {
        setDocumentNo('');
        setDocumentExpiryDate(new Date());
        setSelectedDocumentType('');
        setDocumentIssuedCountryId(0);
      }

      if (updateUser.dateOfBirth) {
        setDateOfBirth(new Date(updateUser.dateOfBirth));
      }

      if (updateUser.nationality !== undefined && updateUser.nationality.code !== undefined) {
        const nationality = countryList.find((x) => x.countryCodeAlpha2 === updateUser.nationality!!.code);
        if (nationality != undefined) setSelectedNationality(nationality.nationality);
      }
    }
  }, [updateUser]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedGender(event.target.value);
  };

  const handleDocumentTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDocumentType(event.target.value);
  };

  const handleNationalityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedNationality(event.target.value);
  };

  const handleBirthDateChange = (date: MaterialUiPickersDate, value?: string | null | undefined) => {
    setDateOfBirth(date);
  };

  const handleDocumentExpiryDateChange = (date: MaterialUiPickersDate, value?: string | null | undefined) => {
    setDocumentExpiryDate(date);
  };

  const handleClose = () => {
    onCompleted();
  };

  const handleUpdateGuestInfo = async () => {
    if (selectedHotel != null && updateUser != undefined) {
      const guestInfo: UpdateGuestRequestModel = {
        profileId: updateUser.profileId,
        hotelId: selectedHotel.id,
        firstName: firstName,
        lastName: lastName,
        gender: selectedGender.toUpperCase(),
        dateOfBirth: dateOfBirth != null ? convertStringDateToServerFormat(dateOfBirth) : undefined,
        documentNo: documentNo,
        documentType: selectedDocumentType != null ? selectedDocumentType.toUpperCase() : DocumentTypeList[0].value,
        documentExpiryDate:
          documentExpiryDate != null ? convertStringDateToServerFormat(documentExpiryDate) : undefined,
        documentIssuedCountryId: documentIssuedCountryId,
        nationality: selectedNationality
      };

      await dispatch(updateGuestForReservation({ guestInfo: guestInfo }));
      setIsUpdating(true);
    }
  };

  return (
    <Dialog keepMounted fullScreen open={open} TransitionComponent={Transition} {...rest}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton disabled={isLoading} edge="start" color="inherit" onClick={handleClose} aria-label="close">
            <ArrowBackIosIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {/* Header Title */}
            {`${getVerbiage(verbiages, 'weblbl017')}`}
          </Typography>
          <IconButton disabled={isLoading} edge="start" color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Toolbar />

      <GridContainer style={{ textAlign: 'center' }} spacing={3}>
        <GridItem xs={12} style={{ marginTop: '8px' }}>
          <Typography align="center" variant="body1">
            {/* Sub Title */}
            <b>{getVerbiage(verbiages, 'weblbl018')}</b>
          </Typography>
        </GridItem>

        {/* First Name */}
        <GridItem xs={12}>
          <TextField
            required
            style={{ width: '90vw' }}
            error={firstNameHelpText != null}
            helperText={firstNameHelpText}
            label={`${getVerbiage(verbiages, 'weblbl019')}`}
            variant="outlined"
            value={firstName}
            onChange={(e) => {
              if (e.currentTarget.value == '') {
                setFirstNameHelpText(getVerbiage(verbiages, 'webmsg008'));
              } else {
                setFirstNameHelpText(null);
              }

              setFirstName(e.currentTarget.value);
            }}
          />
        </GridItem>

        {/* Last Name */}
        <GridItem xs={12}>
          <TextField
            required
            style={{ width: '90vw' }}
            error={lastNameHelpText != null}
            helperText={lastNameHelpText}
            id="outlined-required"
            label={`${getVerbiage(verbiages, 'weblbl020')}`}
            variant="outlined"
            value={lastName}
            onChange={(e) => {
              if (e.currentTarget.value == '') {
                setLastNameHelpText(getVerbiage(verbiages, 'webmsg001'));
              } else {
                setLastNameHelpText(null);
              }

              setLastName(e.currentTarget.value);
            }}
          />
        </GridItem>
        <GridItem xs={12}>
          <TextField
            select
            label={`${getVerbiage(verbiages, 'weblbl021')}`}
            style={{ width: '90vw' }}
            value={selectedGender}
            onChange={handleChange}
            SelectProps={{
              native: true
            }}
            variant="outlined"
          >
            {GenderList.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </TextField>
        </GridItem>
        <GridItem xs={12}>
          <KeyboardDatePicker
            label={`${getVerbiage(verbiages, 'weblbl022')}`}
            style={{ width: '90vw' }}
            format="dd / MM / yyyy"
            value={dateOfBirth}
            inputVariant={'outlined'}
            maxDate={new Date()}
            onChange={handleBirthDateChange}
            KeyboardButtonProps={{
              'aria-label': 'change date'
            }}
          />
        </GridItem>

        <GridItem>
          <Typography></Typography>
        </GridItem>

        {/* Document Type */}
        <GridItem xs={12}>
          <TextField
            select
            disabled={selectedDocumentType != ''}
            label={`${getVerbiage(verbiages, 'weblbl023')}`}
            style={{ width: '90vw' }}
            value={selectedDocumentType}
            onChange={handleDocumentTypeChange}
            SelectProps={{
              native: true
            }}
            variant="outlined"
          >
            {DocumentTypeList.map((type) => (
              <option disabled={type.value == ''} key={type.value} value={type.value}>
                {type.label}
              </option>
            ))}
          </TextField>
        </GridItem>

        {/* Document No */}
        <GridItem xs={12}>
          <TextField
            required
            style={{ width: '90vw' }}
            error={documentNoHelpText != null}
            helperText={documentNoHelpText}
            onBlur={() => setIsDocumentNoTextOnFocus(false)}
            onFocus={() => setIsDocumentNoTextOnFocus(true)}
            label={`${getVerbiage(verbiages, 'weblbl024')}`}
            variant="outlined"
            value={isDocumentNoTextOnFocus ? documentNo : displayLastFourChar(documentNo)}
            onChange={(e) => {
              if (e.currentTarget.value == '') {
                setDocumentNoHelpText(getVerbiage(verbiages, 'webmsg009'));
              } else {
                setDocumentNoHelpText(null);
              }

              setDocumentNo(e.currentTarget.value);
            }}
          />
        </GridItem>

        {/* Expiry Date */}
        {selectedDocumentType !== DocumentTypeList[1].value ? (
          <GridItem xs={12}>
            <KeyboardDatePicker
              label={`${getVerbiage(verbiages, 'weblbl078')}`}
              style={{ width: '90vw' }}
              format="dd / MM / yyyy"
              value={documentExpiryDate}
              inputVariant={'outlined'}
              minDate={new Date()}
              onChange={handleDocumentExpiryDateChange}
              KeyboardButtonProps={{
                'aria-label': 'change date'
              }}
            />
          </GridItem>
        ) : null}

        {/* Nationality */}
        <GridItem xs={12}>
          <TextField
            select
            label={`${getVerbiage(verbiages, 'weblbl025')}`}
            style={{ width: '90vw' }}
            value={selectedNationality}
            onChange={handleNationalityChange}
            SelectProps={{
              native: true
            }}
            variant="outlined"
          >
            {nationalities.map((country) => (
              <option key={country.id} value={country.nationality}>
                {country.nationality}
              </option>
            ))}
          </TextField>
        </GridItem>
        <BottomDecisionButton
          leftButtonTitle={getVerbiage(verbiages, 'webbtn007')}
          leftButtonOnClick={handleUpdateGuestInfo}
          disableLeftButton={isLoading}
          rightButtonTitle={getVerbiage(verbiages, 'webbtn008')}
          rightButtonOnClick={handleClose}
          disableRightButton={isLoading}
        ></BottomDecisionButton>
      </GridContainer>
    </Dialog>
  );
};
