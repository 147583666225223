import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "reducers";
// @material-ui/core components
import Button, { ButtonProps } from "@material-ui/core/Button";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    MuiButtonOutlined: {
      textTransform: "none",
    },
  })
);

interface IProps extends ButtonProps {
  code: string;
  // ids: string[];
}

export const AccessControlButton: React.FC<IProps> = ({
  code,
  children,
  className,
  ...rest
}) => {
  const classes = useStyles();

  const { role } = useSelector((state: RootState) => state.roleState);

  return (
    <>
      {role?.functions.some((func) => func.code === code) ? (
        <Button
          color="primary"
          variant="contained"
          className={classes.MuiButtonOutlined + " " + className}
          {...rest}
        >
          {children}
        </Button>
      ) : null}
    </>
  );
};
