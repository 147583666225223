import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "reducers";

//Component
import { Button } from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";

//Helper
import {
  getConfigurationList,
  updateConfiguration,
} from "services/configurationService";
import {
  ConfigurationFilterResponseModel,
  UpdateConfigurationResponseModel,
} from "models/backend/ConfigurationModel";
import { ARIA_SuccessCode, Configuration } from "models";
import { DataGrid } from "@material-ui/data-grid";
import CustomLoadingOverlay from "components/DataGridLoadingOverlay";
import CustomNoRowsOverlay from "components/DataGridNoRowsOverlay";
import { CodeMirrorDialog } from "components/CodeMirrorDialog";
import { MainDrawer } from "components/MainDrawer";
import { fetchFeatureList } from "reducers/feature";
import { ConfigurationDialog } from "components";

export const ConfigurationScreen = () => {
  const dispatch = useDispatch();
  const { selectedHotel } = useSelector((state: RootState) => state.hotelState);
  const { featureList } = useSelector((state: RootState) => state.featureState);

  const [editingConfiguration, setEditingConfiguration] = React.useState<
    Configuration | undefined
  >(undefined);
  const [editingValue, setEditingValue] = React.useState("");
  const [openEditConfigDialog, setOpenEditConfigDialog] = React.useState(false);
  const [openProEditConfigDialog, setOpenProEditConfigDialog] =
    React.useState(false);

  const [index, setIndex] = React.useState<number>(1);
  const [size] = React.useState<number>(10);
  const [isLoading, setIsLoading] = React.useState(false);
  const [row, setRow] = React.useState<number>(0);
  const [configurationList, setConfigurationList] = React.useState<
    Configuration[]
  >([]);

  //Setting up the each column header item
  const configurationValueHeader = {
    field: "featureId",
    // headerName: "Configuration Name",
    sortable: false,
    renderHeader: () => <strong>{"Configuration Name "}</strong>,
    renderCell: (params: any) => (
      <label>
        {featureList.find((x) => x.id === params.value) != undefined
          ? featureList.find((x) => x.id === params.value)?.name
          : ""}
      </label>
    ),
    flex: 1,
  };

  const isCompleteHeader = {
    field: "isCompleteConfig",
    headerName: " ",
    sortable: false,
    width: 30,
    renderCell: (params: any) => (
      <>
        {params.value ? null : (
          <WarningIcon titleAccess="Warning : Incomplete configuration settings." />
        )}
      </>
    ),
  };

  const actionsHeader = {
    field: "id",
    // headerName: "Actions",
    renderHeader: () => <strong>{"Actions"}</strong>,
    width: 90,
    filterable: false,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (params: any) => (
      <Button
        id="edit_function"
        variant="contained"
        color="primary"
        size="small"
        value={params.value}
        onClick={handleOpenEditDialog}
      >
        Edit
      </Button>
    ),
  };

  const headerColumn = [
    configurationValueHeader,
    isCompleteHeader,
    actionsHeader,
  ];

  React.useEffect(() => {
    dispatch(fetchFeatureList());
  }, []);

  React.useEffect(() => {
    if (selectedHotel != undefined) {
      setIsLoading(true);

      getConfigurationList(
        selectedHotel.configurationGroupId,
        index,
        size
      ).then((response: ConfigurationFilterResponseModel) => {
        setConfigurationList(response.result.items);
        setRow(response.result.rowCount);
        setIsLoading(false);
      });
    }
  }, [selectedHotel, index]);

  const handleOpenEditDialog = (event: React.MouseEvent<HTMLButtonElement>) => {
    //find the editing configuration
    var _editingConfiguration = configurationList.find(
      (config) => config.id === parseInt(event.currentTarget.value)
    );

    if (_editingConfiguration !== undefined) {
      setOpenEditConfigDialog(true);
      const _feature = featureList.find(
        (f) => f.id === _editingConfiguration!!.featureId
      );
      setEditingConfiguration({ ..._editingConfiguration, feature: _feature });

      setEditingValue(
        JSON.stringify(JSON.parse(_editingConfiguration.value), null, 2)
      );
    }
  };

  const handleSaveConfiguration = () => {
    //find the editing configuration
    if (editingConfiguration != undefined) {
      try {
        updateConfiguration({
          ...editingConfiguration,
          value: JSON.stringify(JSON.parse(editingValue)),
        })
          .then((response: UpdateConfigurationResponseModel) => {
            if (response.resultCode === ARIA_SuccessCode) {
              var config = configurationList.find(
                (c) => c.id === editingConfiguration.id
              );

              if (config != undefined) {
                config.value = editingValue;
              }

              setOpenProEditConfigDialog(false);
              setOpenEditConfigDialog(false);
              setEditingConfiguration(undefined);
              setEditingValue("");
            }
          })
          .catch((err) => {
            alert(err);
          });
      } catch (err) {
        alert("JSON Syntax Error!!");
      }
    }
  };

  return (
    <>
      <MainDrawer title="Configurations">
        {editingConfiguration != undefined ? (
          <>
            <CodeMirrorDialog
              open={openProEditConfigDialog}
              title={
                editingConfiguration != undefined
                  ? `Editing ${editingConfiguration.feature?.name}`
                  : `Editing Configuration`
              }
              editingContent={editingValue}
              onChange={(value) => setEditingValue(value)}
              onHandleCloseClick={() => setOpenProEditConfigDialog(false)}
              onHandleSaveClick={handleSaveConfiguration}
              onHandleBeautifyClick={() =>
                setEditingValue(
                  JSON.stringify(JSON.parse(editingValue), null, 2)
                )
              }
            />

            <ConfigurationDialog
              editingConfigInJson={editingConfiguration.value}
              configTitle={editingConfiguration.feature!!.name}
              open={openEditConfigDialog}
              onChange={(value) => setEditingValue(value)}
              onHandleCloseClick={() => setOpenEditConfigDialog(false)}
              onHandleProClick={() => setOpenProEditConfigDialog(true)}
              onHandleSaveClick={handleSaveConfiguration}
            />
          </>
        ) : null}

        {/* Data Grid */}
        <div style={{ height: "80vh" }}>
          <div style={{ display: "flex", height: "100%" }}>
            <div style={{ flexGrow: 1 }}>
              <DataGrid
                pageSize={size}
                loading={isLoading}
                disableColumnFilter
                disableColumnMenu
                density="compact"
                components={{
                  LoadingOverlay: CustomLoadingOverlay,
                  NoRowsOverlay: CustomNoRowsOverlay,
                }}
                pagination
                paginationMode="server"
                rowCount={row}
                onPageChange={(newPage) => setIndex(++newPage)}
                rows={configurationList}
                columns={headerColumn}
                getRowId={(row) => row.id}
              />
            </div>
          </div>
        </div>
      </MainDrawer>
    </>
  );
};
