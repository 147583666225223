export const MenuTypeList = [
    {
      value: 'Module',
      label: 'Module'
    },
    {
      value: 'Menu',
      label: 'Menu'
    }
  ];
  