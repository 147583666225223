import { DataGridProps } from "@material-ui/data-grid";
import CustomLoadingOverlay from "components/DataGridLoadingOverlay";
import CustomNoRowsOverlay from "components/DataGridNoRowsOverlay";

interface IProps extends DataGridProps {
  
}

export const DataGrid: React.FC<IProps> = ({ ...rest }) => {

  return (
    <div style={{ height: "80vh" }}>
      <div style={{ display: "flex", height: "100%" }}>
        <div style={{ flexGrow: 1 }}>
          <DataGrid
            disableColumnFilter
            disableColumnMenu
            density="compact"
            components={{
              LoadingOverlay: CustomLoadingOverlay,
              NoRowsOverlay: CustomNoRowsOverlay,
            }}
            pagination
            {...rest}
          />
        </div>
      </div>
    </div>
  );
};
