import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "reducers";

//Component
import { Data } from "react-csv/components/CommonPropTypes";
import { ExportButton } from "components/ExportButton";
import { MainDrawer } from "components/MainDrawer";
import { AccessControlButton, UploadImageView } from "components";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import {
  DataGrid,
  GridColDef,
  GridColumns,
  GridToolbarContainer,
} from "@material-ui/data-grid";
import CustomLoadingOverlay from "components/DataGridLoadingOverlay";
import CustomNoRowsOverlay from "components/DataGridNoRowsOverlay";
import { ARIA_SuccessCode, RoomType } from "models";
import { DataGridFilterToolbar } from "components/DataGridFilterToolbar";
import { GridColFilterDef } from "components/DataGridFilterSelection";
//Icon

import ImageIcon from "@material-ui/icons/Image";
//Helper
import { convertDateTimeToString } from "utils/dateHelper";
import {
  PM_ADD_ROOM_TYPE_CODE,
  PM_DELETE_ROOM_TYPE_CODE,
  PM_EDIT_ROOM_TYPE_CODE,
} from "models/backend/constant/PermissionCode";
import { fetchConfigurationGroupList } from "reducers/configurationGroup";
import { fetchLanguageSettingList } from "reducers/languageSetting";
import {
  createRoomTypeAPI,
  deleteRoomTypeAPI,
  getRoomTypeListAPI,
  updateRoomTypeAPI,
} from "services/roomTypeService";
import { GRID_FILTER_TYPE_NUMBER } from "components/DataGridFilterSelection/type";

export const RoomTypeManagementScreen = () => {
  const dispatch = useDispatch();

  const { selectedHotel } = useSelector((state: RootState) => state.hotelState);

  const [index, setIndex] = useState<number>(1);
  const [size, setSize] = useState<number>(10);
  const [row, setRow] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isExporting, setIsExporting] = useState(false);

  const [requestDateTime, setRequestDateTime] = useState<Date>(new Date());

  const [roomTypeManagementList, setRoomTypeManagementList] = useState<
    RoomType[]
  >([]);
  const [exportList, setExportList] = useState<Data>([]);
  const [filterList, setFilterList] = useState<GridColFilterDef[]>([]);

  const [loadList, setloadList] = useState(true);

  const [addRoomType, setAddRoomType] = useState<any | undefined>();
  const [editRoomType, setEditRoomType] = useState<RoomType | undefined>();
  const [removeRoomType, setRemoveRoomType] = useState<RoomType | undefined>();
  const [deleteConfirmation, setDeleteConfirmation] = useState("");

  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  //Setting up the each column header item
  const roomNameHeader: GridColDef = {
    field: "description",
    headerName: "Room Name",
    sortable: false,
    renderHeader: () => <strong>{"Room Name"}</strong>,
    minWidth: 200,
    flex: 1,
  };

  const roomTypeCodeHeader: GridColDef = {
    field: "code",
    headerName: "Code",
    sortable: false,
    renderHeader: (params: any) => <strong>{"Code"}</strong>,
    minWidth: 80,
  };

  const maxAdultHeader: GridColDef = {
    field: "maxAdultNo",
    headerName: "Max Adult",
    sortable: false,
    type: GRID_FILTER_TYPE_NUMBER,
    renderHeader: () => <strong>{"Max Adult"}</strong>,
    minWidth: 100,
    // flex: 1,
  };

  const maxChildHeader: GridColDef = {
    field: "maxChildNo",
    headerName: "Max Child",
    sortable: false,
    type: GRID_FILTER_TYPE_NUMBER,
    renderHeader: () => <strong>{"Max Child"}</strong>,
    minWidth: 100,
    // flex: 1,
  };

  const roomTypePhotoheader: GridColDef = {
    field: "photoUrl",
    headerName: "Room Photo",
    sortable: false,
    filterable: false,
    renderHeader: (params: any) => <strong>{"Room Photo"}</strong>,
    renderCell: (params: any) => (
      <div>
        {params.value !== null &&
        params.value !== "" &&
        params.value !== undefined ? (
          <img
            style={{ maxHeight: "180px", maxWidth: "360px" }}
            src={params.value}
            alt="Logo"
          />
        ) : (
          <ImageIcon />
        )}
      </div>
    ),
    minWidth: 320,
  };

  const actionsHeader: GridColDef = {
    field: "id",
    headerName: "Actions",
    minWidth: 240,
    filterable: false,
    sortable: false,
    disableColumnMenu: true,
    renderHeader: () => <strong>{"Actions"}</strong>,
    renderCell: (params: any) => (
      <strong>
        <AccessControlButton
          code={PM_EDIT_ROOM_TYPE_CODE}
          variant="contained"
          color="primary"
          size="small"
          value={params.value}
          onClick={handleOpenEditDialog}
        >
          Edit
        </AccessControlButton>
        <AccessControlButton
          code={PM_ADD_ROOM_TYPE_CODE}
          variant="contained"
          color="primary"
          size="small"
          value={params.value}
          style={{ marginLeft: 12 }}
          onClick={handleDuplicateFunctionClick}
        >
          Clone
        </AccessControlButton>
        <AccessControlButton
          code={PM_DELETE_ROOM_TYPE_CODE}
          variant="contained"
          color="primary"
          size="small"
          value={params.value}
          style={{ marginLeft: 12 }}
          onClick={handleOpenDeleteDialog}
        >
          Delete
        </AccessControlButton>
      </strong>
    ),
  };

  const headerColumn: GridColumns = [
    roomNameHeader,
    roomTypeCodeHeader,
    maxAdultHeader,
    maxChildHeader,
    roomTypePhotoheader,
    actionsHeader,
  ];

  React.useEffect(() => {
    dispatch(fetchConfigurationGroupList());
    dispatch(fetchLanguageSettingList());
  }, []);

  React.useEffect(() => {
    if (selectedHotel != undefined ) {
      setloadList(true);
    }
  }, [selectedHotel]);

  React.useEffect(() => {
    if (selectedHotel != undefined && loadList) {
      setIsLoading(true);
      setRoomTypeManagementList([]);
      setRequestDateTime(new Date());

      getRoomTypeListAPI({
        filter: filterList,
        pageIndex: index,
        pageSize: size,
      })
        .then((response) => {
          if (response.resultCode === ARIA_SuccessCode) {
            setRoomTypeManagementList(response.result.items);
            setRow(response.result.rowCount);
          }
        })
        .finally(() => {
          setloadList(false);
          setIsLoading(false);
        });
    }
  }, [index, size, filterList, selectedHotel, loadList]);

  const getExportTransaction = async () => {
    setIsLoading(true);
    var _exportIndex = 1;
    var exportList: RoomType[] = [];

    try {
      do {
        var response = await getRoomTypeListAPI({
          filter: filterList,
          pageIndex: _exportIndex,
          pageSize: size,
          //   orderBy: "module",
        });

        exportList.push(...response.result.items);

        _exportIndex++;
      } while (response.result.pageCount >= _exportIndex);

      setExportList(exportList);
      setIsExporting(true);
      setIsLoading(false);
    } catch (err) {
      console.log("Oposs.. Something is wrong");
    }
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <DataGridFilterToolbar
          headerColumns={headerColumn}
          filterList={filterList}
          onFilterApply={(items) => {
            setFilterList(items.slice());
            setloadList(true);
          }}
        />
        <ExportButton
          filename="RoomType"
          data={exportList}
          headers={[
            { label: "Room Name", key: "description" },
            { label: "Code", key: "code" },
            { label: "Max Adult", key: "maxAdultNo" },
            { label: "Max Child", key: "maxChildNo" },
            { label: "Room Photo", key: "photoUrl" },
          ]}
          readyToExport={isExporting}
          onClick={getExportTransaction}
          onFinishDownload={() => {
            setExportList([]);
            setIsExporting(false);
          }}
        >
          Export
        </ExportButton>
        <div style={{ flexGrow: 1 }}></div>
        <AccessControlButton
          code={PM_ADD_ROOM_TYPE_CODE}
          variant="contained"
          color="primary"
          size="small"
          onClick={handleOpenCreateDialog}
        >
          New Room Type
        </AccessControlButton>
        <div style={{ paddingLeft: "8px" }}>
          <b>Data as at </b>: {convertDateTimeToString(requestDateTime)}
        </div>
      </GridToolbarContainer>
    );
  }

  const handleOpenCreateDialog = () => {
    setOpenEditDialog(true);
  };

  const handleDuplicateFunctionClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    var _updateFunction = roomTypeManagementList.find(
      (f) => f.id === parseInt(event.currentTarget.value)
    );

    setAddRoomType(_updateFunction);
    setOpenEditDialog(true);
  };

  const handleOpenEditDialog = (event: React.MouseEvent<HTMLButtonElement>) => {
    var updateFunction = roomTypeManagementList.find(
      (f) => f.id === parseInt(event.currentTarget.value)
    );
    setEditRoomType(updateFunction);
    // setSelectedIsAudited(updateFunction.IsAudited)
    setOpenEditDialog(true);
  };

  const handleOpenDeleteDialog = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    var _removeFunction = roomTypeManagementList.find(
      (f) => f.id === parseInt(event.currentTarget.value)
    );
    setRemoveRoomType(_removeFunction);
    setOpenDeleteDialog(true);
  };

  const handleEditOrAdd = () => {
    if (editRoomType == null) {
      if (
        addRoomType === null ||
        addRoomType.description === null ||
        addRoomType.description === "" ||
        addRoomType.code === null ||
        addRoomType.code === "" ||
        addRoomType.maxAdultNo < 0 ||
        addRoomType.maxChildNo < 0
      ) {
        alert("Please fill up the details below to add a new room type.");
      } else {
        createRoomTypeAPI({ ...addRoomType })
          .catch((err) => {
            alert(err);
          })
          .finally(() => {
            setloadList(true);
            handleEditClose();
          });
      }
    } else {
      if (
        editRoomType.description === null ||
        editRoomType.description === "" ||
        editRoomType.code === null ||
        editRoomType.code === "" ||
        editRoomType.maxAdultNo < 0 ||
        editRoomType.maxChildNo < 0
      ) {
        alert("Please fill up the details below to edit room type.");
      } else {
        updateRoomTypeAPI({ ...editRoomType })
          .catch((err) => {
            alert(err);
          })
          .finally(() => {
            setloadList(true);
            handleEditClose();
          });
      }
    }
  };

  const handleDelete = () => {
    if (deleteConfirmation !== removeRoomType?.description) {
      alert("Please key in the room name to confirm deletion.");
    } else {
      deleteRoomTypeAPI({ ...removeRoomType })
        .catch((err) => {
          alert(err);
        })
        .finally(() => {
          setDeleteConfirmation("");
          setloadList(true);
          handleEditClose();
        });
    }
  };

  const handleEditClose = () => {
    setAddRoomType(undefined);
    setEditRoomType(undefined);
    setRemoveRoomType(undefined);
    setOpenEditDialog(false);
    setOpenDeleteDialog(false);
  };

  return (
    <>
      <MainDrawer title="Room Type Management">
        {/* Add and Edit Dialog */}
        <Dialog
          open={openEditDialog}
          onClose={handleEditClose}
          aria-labelledby="form-dialog-title"
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle id="form-dialog-title">
            {editRoomType != undefined ? "Edit Room Type" : "Add Room Type"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {editRoomType != undefined
                ? "Change the detail below and save it to update room details, ."
                : "Fill up the details below to add a new room type."}
            </DialogContentText>
            <form noValidate autoComplete="off">
              <div>
                <TextField
                  margin="dense"
                  id="name"
                  label="Room Name"
                  type="text"
                  value={
                    editRoomType != undefined
                      ? editRoomType.description
                      : addRoomType?.description != undefined
                      ? addRoomType?.description
                      : ""
                  }
                  onChange={(e) => {
                    editRoomType != undefined
                      ? setEditRoomType({
                          ...editRoomType,
                          description: e.currentTarget.value,
                        })
                      : setAddRoomType({
                          ...addRoomType,
                          description: e.currentTarget.value,
                        });
                  }}
                  fullWidth
                />
                <TextField
                  margin="dense"
                  id="code"
                  label="Code"
                  type="text"
                  value={
                    editRoomType != undefined
                      ? editRoomType.code
                      : addRoomType?.code != undefined
                      ? addRoomType?.code
                      : ""
                  }
                  onChange={(e) => {
                    editRoomType != undefined
                      ? setEditRoomType({
                          ...editRoomType,
                          code: e.currentTarget.value,
                        })
                      : setAddRoomType({
                          ...addRoomType,
                          code: e.currentTarget.value,
                        });
                  }}
                  fullWidth
                />
                <TextField
                  margin="dense"
                  id="maxadult"
                  label="Max adult"
                  type="number"
                  value={
                    editRoomType != undefined
                      ? editRoomType.maxAdultNo
                      : addRoomType?.maxAdultNo != undefined
                      ? addRoomType?.maxAdultNo
                      : "0"
                  }
                  onChange={(e) => {
                    editRoomType != undefined
                      ? setEditRoomType({
                          ...editRoomType,
                          maxAdultNo: parseInt(e.currentTarget.value),
                        })
                      : setAddRoomType({
                          ...addRoomType,
                          maxAdultNo: parseInt(e.currentTarget.value),
                        });
                  }}
                  fullWidth
                />
                <TextField
                  margin="dense"
                  id="maxchild"
                  label="Max child"
                  type="number"
                  value={
                    editRoomType != undefined
                      ? editRoomType.maxChildNo
                      : addRoomType?.maxChildNo != undefined
                      ? addRoomType?.maxChildNo
                      : "0"
                  }
                  onChange={(e) => {
                    editRoomType != undefined
                      ? setEditRoomType({
                          ...editRoomType,
                          maxChildNo: parseInt(e.currentTarget.value),
                        })
                      : setAddRoomType({
                          ...addRoomType,
                          maxChildNo: parseInt(e.currentTarget.value),
                        });
                  }}
                  fullWidth
                />
                <UploadImageView
                  label="Room Image"
                  defaultValue={
                    editRoomType != undefined
                      ? editRoomType.photoUrl
                      : addRoomType?.photoUrl != undefined
                      ? addRoomType?.photoUrl
                      : undefined
                  }
                  onUploadSuccess={(imageUrl) => {
                    editRoomType != undefined
                      ? setEditRoomType({
                          ...editRoomType,
                          photoUrl: imageUrl,
                        })
                      : setAddRoomType({
                          ...addRoomType,
                          photoUrl: imageUrl,
                        });
                  }}
                />
              </div>
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleEditClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleEditOrAdd} color="primary">
              {editRoomType != null ? `Save & Update` : `Add Room Type`}
            </Button>
          </DialogActions>
        </Dialog>

        {/* delete Dialog */}
        {removeRoomType != undefined ? (
          <Dialog
            open={openDeleteDialog}
            onClose={handleEditClose}
            aria-labelledby="form-dialog-title"
            fullWidth
            maxWidth="sm"
          >
            <DialogTitle id="form-dialog-title">Delete Room Type</DialogTitle>
            <DialogContent>
              <form noValidate autoComplete="off">
                <div>
                  <TextField
                    margin="dense"
                    id="name"
                    label="Room Name"
                    type="text"
                    disabled
                    value={removeRoomType.description}
                    fullWidth
                  />
                  <TextField
                    margin="dense"
                    id="code"
                    label="Code"
                    type="text"
                    disabled
                    value={removeRoomType.code}
                    fullWidth
                  />

                  <DialogContentText
                    color="textPrimary"
                    style={{ marginTop: "18px" }}
                  >
                    {`Are you sure you want to delete ${removeRoomType.description}?`}
                  </DialogContentText>
                  <DialogContentText>
                    Please type the following to confirm:
                  </DialogContentText>
                  <DialogContentText>
                    <i>
                      <b>{removeRoomType.description}</b>
                    </i>
                  </DialogContentText>
                  <TextField
                    margin="dense"
                    variant="outlined"
                    id="name"
                    type="text"
                    value={deleteConfirmation}
                    onChange={(e) =>
                      setDeleteConfirmation(e.currentTarget.value)
                    }
                    fullWidth
                  />
                </div>
              </form>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleEditClose} color="primary">
                Cancel
              </Button>
              <Button onClick={handleDelete} color="primary">
                {`Delete`}
              </Button>
            </DialogActions>
          </Dialog>
        ) : null}

        {/* Data Grid */}
        <div style={{ height: "80vh" }}>
          <div style={{ display: "flex", height: "100%" }}>
            <div style={{ flexGrow: 1 }}>
              <DataGrid
                pageSize={size}
                rowsPerPageOptions={[10, 20, 30]}
                onPageSizeChange={(size) => {
                  setSize(size);
                  setloadList(true);
                }}
                disableColumnMenu
                density="compact"
                components={{
                  LoadingOverlay: CustomLoadingOverlay,
                  NoRowsOverlay: CustomNoRowsOverlay,
                  Toolbar: CustomToolbar,
                }}
                pagination
                loading={isLoading}
                paginationMode="server"
                rowCount={row}
                rowHeight={180}
                filterMode="server"
                onPageChange={(newPage) => {
                  setIndex(++newPage);
                  setloadList(true);
                }}
                rows={roomTypeManagementList}
                columns={headerColumn}
                getRowId={(row) => row.id}
              />
            </div>
          </div>
        </div>
      </MainDrawer>
    </>
  );
};
