import React, { useState } from "react";

//Component
import {
  DataGrid,
  GridColDef,
  GridColumns,
  GridToolbarContainer,
} from "@material-ui/data-grid";
import CustomLoadingOverlay from "components/DataGridLoadingOverlay";
import CustomNoRowsOverlay from "components/DataGridNoRowsOverlay";
import { ARIA_SuccessCode, HotelGroup } from "models";
import { DataGridFilterToolbar } from "components/DataGridFilterToolbar";
import { GridColFilterDef } from "components/DataGridFilterSelection";
//Icon

//Helper
import { convertDateTimeToString } from "utils/dateHelper";
import { Data } from "react-csv/components/CommonPropTypes";
import { ExportButton } from "components/ExportButton";
import { MainDrawer } from "components/MainDrawer";
import { AccessControlButton } from "components";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import {
  PM_ADD_HOTEL_CODE,
  PM_DELETE_HOTEL_CODE,
  PM_EDIT_HOTEL_CODE,
} from "models/backend/constant/PermissionCode";
import {
  createHotelGroupAPI,
  deleteHotelGroupAPI,
  getHotelGroupListAPI,
  updateHotelGroupAPI,
} from "services/hotelGroupService";

export const HotelGroupManagementScreen = () => {
  const [index, setIndex] = useState<number>(1);
  const [size, setSize] = useState<number>(10);
  const [row, setRow] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isExporting, setIsExporting] = useState(false);

  const [requestDateTime, setRequestDateTime] = useState<Date>(new Date());

  const [hotelGroupManagementList, setHotelGroupManagementList] = useState<
    HotelGroup[]
  >([]);
  const [exportList, setExportList] = useState<Data>([]);
  const [filterList, setFilterList] = useState<GridColFilterDef[]>([]);

  const [loadList, setloadList] = useState(true);

  const [addHotelGroup, setAddHotelGroup] = useState<any | undefined>();
  const [editHotelGroup, setEditHotelGroup] = useState<HotelGroup | undefined>();
  const [removeHotelGroup, setRemoveHotelGroup] = useState<HotelGroup | undefined>();
  const [deleteConfirmation, setDeleteConfirmation] = useState("");

  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  //Setting up the each column header item
  const hotelGroupCodeHeader: GridColDef = {
    field: "code",
    headerName: "Code",
    sortable: false,
    renderHeader: (params: any) => <strong>{"Code"}</strong>,
    minWidth: 200,
    flex: 1,
  };

  const hotelGroupNameHeader: GridColDef = {
    field: "name",
    headerName: "Name",
    sortable: false,
    renderHeader: (params: any) => <strong>{"Name"}</strong>,
    minWidth: 200,
    flex: 1,
  };

  const actionsHeader: GridColDef = {
    field: "id",
    headerName: "Actions",
    minWidth: 240,
    filterable: false,
    sortable: false,
    disableColumnMenu: true,
    renderHeader: () => <strong>{"Actions"}</strong>,
    renderCell: (params: any) => (
      <strong>
        <AccessControlButton
          code={PM_EDIT_HOTEL_CODE}
          variant="contained"
          color="primary"
          size="small"
          value={params.value}
          onClick={handleOpenEditDialog}
        >
          Edit
        </AccessControlButton>
        <AccessControlButton
          code={PM_ADD_HOTEL_CODE}
          variant="contained"
          color="primary"
          size="small"
          value={params.value}
          style={{ marginLeft: 12 }}
          onClick={handleDuplicateFunctionClick}
        >
          Clone
        </AccessControlButton>
        <AccessControlButton
          code={PM_DELETE_HOTEL_CODE}
          variant="contained"
          color="primary"
          size="small"
          value={params.value}
          style={{ marginLeft: 12 }}
          onClick={handleOpenDeleteDialog}
        >
          Delete
        </AccessControlButton>
      </strong>
    ),
  };

  const headerColumn: GridColumns = [
    hotelGroupNameHeader,
    hotelGroupCodeHeader,
    actionsHeader,
  ];

  React.useEffect(() => {
    if (loadList) {
      setIsLoading(true);
      setHotelGroupManagementList([]);
      setRequestDateTime(new Date());

      getHotelGroupListAPI({
        filter: filterList,
        pageIndex: index,
        pageSize: size,
        // orderBy: "module",
      })
        .then((response) => {
          if (response.resultCode === ARIA_SuccessCode) {
            setHotelGroupManagementList(response.result.items);
            setRow(response.result.rowCount);
          }
        })
        .finally(() => {
          setloadList(false);
          setIsLoading(false);
        });
    }
  }, [index, size, filterList, loadList]);

  const getExportTransaction = async () => {
    setIsLoading(true);
    var _exportIndex = 1;
    var exportList: HotelGroup[] = [];

    try {
      do {
        var response = await getHotelGroupListAPI({
          filter: filterList,
          pageIndex: _exportIndex,
          pageSize: size,
          //   orderBy: "module",
        });

        exportList.push(...response.result.items);

        _exportIndex++;
      } while (response.result.pageCount >= _exportIndex);

      setExportList(exportList);
      setIsExporting(true);
      setIsLoading(false);
    } catch (err) {
      console.log("Oposs.. Something is wrong");
    }
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <DataGridFilterToolbar
          headerColumns={headerColumn}
          filterList={filterList}
          onFilterApply={(items) => {
            setFilterList(items.slice());
            setloadList(true);
          }}
        />
        <ExportButton
          filename="Hotel"
          data={exportList}
          headers={[
            { label: "Name", key: "name" },
            { label: "Code", key: "code" },
          ]}
          readyToExport={isExporting}
          onClick={getExportTransaction}
          onFinishDownload={() => {
            setExportList([]);
            setIsExporting(false);
          }}
        >
          Export
        </ExportButton>
        <div style={{ flexGrow: 1 }}></div>
        <AccessControlButton
          code={PM_ADD_HOTEL_CODE}
          variant="contained"
          color="primary"
          size="small"
          onClick={handleOpenCreateDialog}
        >
          New Hotel Group
        </AccessControlButton>
        <div style={{ paddingLeft: "8px" }}>
          <b>Data as at </b>: {convertDateTimeToString(requestDateTime)}
        </div>
      </GridToolbarContainer>
    );
  }

  const handleOpenCreateDialog = () => {
    setOpenEditDialog(true);
  };

  const handleDuplicateFunctionClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    var _updateHotelGroup = hotelGroupManagementList.find(
      (f) => f.id === parseInt(event.currentTarget.value)
    );

    setAddHotelGroup(_updateHotelGroup);
    setOpenEditDialog(true);
  };

  const handleOpenEditDialog = (event: React.MouseEvent<HTMLButtonElement>) => {
    var updateHotelGroup = hotelGroupManagementList.find(
      (f) => f.id === parseInt(event.currentTarget.value)
    );
    setEditHotelGroup(updateHotelGroup);
    setOpenEditDialog(true);
  };

  const handleOpenDeleteDialog = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    var _removeHotelGroup = hotelGroupManagementList.find(
      (f) => f.id === parseInt(event.currentTarget.value)
    );
    setRemoveHotelGroup(_removeHotelGroup);
    setOpenDeleteDialog(true);
  };

  const handleEditOrAdd = () => {
    if (editHotelGroup == null) {
      if (
        addHotelGroup === null ||
        addHotelGroup.name === null ||
        addHotelGroup.name === "" ||
        addHotelGroup.code === null ||
        addHotelGroup.code === ""
      ) {
        alert("Please fill up the details below to add a new hotel Group.");
      } else {
        createHotelGroupAPI({ ...addHotelGroup })
          .catch((err) => {
            alert(err);
          })
          .finally(() => {
            setloadList(true);
            handleEditClose();
          });
      }
    } else {
      if (
        editHotelGroup.name === null ||
        editHotelGroup.name === "" ||
        editHotelGroup.code === null ||
        editHotelGroup.code === ""
      ) {
        alert("Please fill up the details below to edit hotel group.");
      } else {
        updateHotelGroupAPI({ ...editHotelGroup })
          .catch((err) => {
            alert(err);
          })
          .finally(() => {
            setloadList(true);
            handleEditClose();
          });
      }
    }
  };

  const handleDelete = () => {
    if (deleteConfirmation !== removeHotelGroup?.name) {
      alert("Please key in the hotel group name to confirm deletion.");
    } else {
      deleteHotelGroupAPI({ ...removeHotelGroup })
        .catch((err) => {
          alert(err);
        })
        .finally(() => {
          setDeleteConfirmation("");
          setloadList(true);
          handleEditClose();
        });
    }
  };

  const handleEditClose = () => {
    setAddHotelGroup(undefined);
    setEditHotelGroup(undefined);
    setRemoveHotelGroup(undefined);
    setOpenEditDialog(false);
    setOpenDeleteDialog(false);
  };

  return (
    <>
      <MainDrawer title="Hotel Group Management">
        {/* Add and Edit Dialog */}
        <Dialog
          open={openEditDialog}
          onClose={handleEditClose}
          aria-labelledby="form-dialog-title"
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle id="form-dialog-title">
            {editHotelGroup != undefined ? "Edit Hotel Group" : "Add Hotel Group"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {editHotelGroup != undefined
                ? "Change the detail below and save it to update hotel group details, ."
                : "Fill up the details below to add a new hotel group."}
            </DialogContentText>
            <form noValidate autoComplete="off">
              <div>
                <TextField
                  margin="dense"
                  id="name"
                  label="Hotel Group Name"
                  type="text"
                  value={
                    editHotelGroup != undefined
                      ? editHotelGroup.name
                      : addHotelGroup?.name != undefined
                      ? addHotelGroup?.name
                      : ""
                  }
                  onChange={(e) => {
                    editHotelGroup != undefined
                      ? setEditHotelGroup({
                          ...editHotelGroup,
                          name: e.currentTarget.value,
                        })
                      : setAddHotelGroup({
                          ...addHotelGroup,
                          name: e.currentTarget.value,
                        });
                  }}
                  fullWidth
                />
                <TextField
                  margin="dense"
                  id="code"
                  label="Code"
                  type="text"
                  value={
                    editHotelGroup != undefined
                      ? editHotelGroup.code
                      : addHotelGroup?.code != undefined
                      ? addHotelGroup?.code
                      : ""
                  }
                  onChange={(e) => {
                    editHotelGroup != undefined
                      ? setEditHotelGroup({
                          ...editHotelGroup,
                          code: e.currentTarget.value,
                        })
                      : setAddHotelGroup({
                          ...addHotelGroup,
                          code: e.currentTarget.value,
                        });
                  }}
                  fullWidth
                />
              </div>
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleEditClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleEditOrAdd} color="primary">
              {editHotelGroup != null ? `Save & Update` : `Add Hotel`}
            </Button>
          </DialogActions>
        </Dialog>

        {/* delete Dialog */}
        {removeHotelGroup != undefined ? (
          <Dialog
            open={openDeleteDialog}
            onClose={handleEditClose}
            aria-labelledby="form-dialog-title"
            fullWidth
            maxWidth="sm"
          >
            <DialogTitle id="form-dialog-title">Delete Hotel Group</DialogTitle>
            <DialogContent>
              <form noValidate autoComplete="off">
                <div>
                  <TextField
                    margin="dense"
                    id="name"
                    label="Hotel Group Name"
                    type="text"
                    disabled
                    value={removeHotelGroup.name}
                    fullWidth
                  />
                  <TextField
                    margin="dense"
                    id="code"
                    label="Code"
                    type="text"
                    disabled
                    value={removeHotelGroup.code}
                    fullWidth
                  />

                  <DialogContentText
                    color="textPrimary"
                    style={{ marginTop: "18px" }}
                  >
                    {`Are you sure you want to delete ${removeHotelGroup.name}?`}
                  </DialogContentText>
                  <DialogContentText>
                    Please type the following to confirm:
                  </DialogContentText>
                  <DialogContentText>
                    <i>
                      <b>{removeHotelGroup.name}</b>
                    </i>
                  </DialogContentText>
                  <TextField
                    margin="dense"
                    variant="outlined"
                    id="name"
                    type="text"
                    value={deleteConfirmation}
                    onChange={(e) =>
                      setDeleteConfirmation(e.currentTarget.value)
                    }
                    fullWidth
                  />
                </div>
              </form>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleEditClose} color="primary">
                Cancel
              </Button>
              <Button onClick={handleDelete} color="primary">
                {`Delete`}
              </Button>
            </DialogActions>
          </Dialog>
        ) : null}

        {/* Data Grid */}
        <div style={{ height: "80vh" }}>
          <div style={{ display: "flex", height: "100%" }}>
            <div style={{ flexGrow: 1 }}>
              <DataGrid
                pageSize={size}
                rowsPerPageOptions={[10, 20, 30]}
                onPageSizeChange={(size) => {
                  setSize(size);
                  setloadList(true);
                }}
                disableColumnMenu
                density="compact"
                components={{
                  LoadingOverlay: CustomLoadingOverlay,
                  NoRowsOverlay: CustomNoRowsOverlay,
                  Toolbar: CustomToolbar,
                }}
                pagination
                loading={isLoading}
                paginationMode="server"
                rowCount={row}
                filterMode="server"
                onPageChange={(newPage) => {
                  setIndex(++newPage);
                  setloadList(true);
                }}
                rows={hotelGroupManagementList}
                columns={headerColumn}
                getRowId={(row) => row.id}
              />
            </div>
          </div>
        </div>
      </MainDrawer>
    </>
  );
};
