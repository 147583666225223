import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store/state";
import { ResponseModel } from "../models/common/ResponseModel";
import { AxiosError } from "axios";
import { ARIA_SuccessCode, GetFeaturesResponseModel } from "models";
import { getFeatureListAPI } from "services/featureService";

const initialState: RootState.FeatureState = {
  featureList: [],
  error: undefined,
  isLoading: false,
};

//Actions
//More info here https://redux-toolkit.js.org/api/createAsyncThunk
export const fetchFeatureList = createAsyncThunk<
  GetFeaturesResponseModel,
  void,
  { rejectValue: AxiosError<Error> }
>("feature/getFeatureList", async (_, thunkApi) => {
  try {
    var pageIndex = 1;

    let data: GetFeaturesResponseModel = await getFeatureListAPI({
      pageIndex,
      pageSize: 100,
    });

    let tempData = data.result.items;

    while (data.result.pageCount > pageIndex) {
      pageIndex++;
      let nextData: GetFeaturesResponseModel = await getFeatureListAPI({
        pageIndex,
        pageSize: 100,
      });

      tempData.push(...nextData.result.items);
    }

    data.result.items = tempData;

    return data;
  } catch (err) {
    let error = err as AxiosError<Error>; // cast the error for access
    if (!error.response) {
      throw err;
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    return thunkApi.rejectWithValue(error);
  }
});

//Slice
//More info here https://redux-toolkit.js.org/api/createSlice
export const featureSlice = createSlice({
  name: "feature",
  initialState,
  reducers: {
    //local reducer
    resetFeatureState() {
      return initialState;
    },
  },
  //services reducers
  extraReducers: (builder) => {
    builder.addCase(fetchFeatureList.fulfilled, (state, { payload }) => {
      if (payload.resultCode === ARIA_SuccessCode) {
        state.featureList = payload.result.items;
        state.error = undefined;
      } else {
        state.error = { ...payload };
      }

      state.error = undefined;
      state.isLoading = false;
    }),
      builder.addCase(fetchFeatureList.pending, (state, _) => {
        state.isLoading = true;
      }),
      builder.addCase(fetchFeatureList.rejected, (state, action) => {
        if (action.payload != undefined) {
          let error: AxiosError<Error> = action.payload;

          const serverError: ResponseModel = {
            resultCode: error?.response?.status.toString() ?? "",
            resultDescription: error?.response?.data.message ?? "",
          };

          state.error = serverError;
          state.isLoading = false;
        }
      });
  },
});

export const { resetFeatureState } = featureSlice.actions;

export default featureSlice.reducer;
