import { useState, useContext, createContext, FC } from "react";
//Services
// import RoleService from "services/RoleService"

import { AccountLoginResponseModel } from "models/backend/AccountModel";
import { accountLoginAPI } from "services/accountService";
import { useDispatch } from "react-redux";
import { resetHotelState } from "reducers/hotel";
import { resetRoleState } from "reducers/role";
import { resetMenuState } from "reducers/menu";

export interface IAuthenticate {
  user?: LoginUser;
  signin: (email: string, password: string) => Promise<LoginUser>;
  signout: () => void;
}

export interface LoginUser {
  name?: string;
  token?: string;
}

const authContext = createContext<Partial<IAuthenticate>>({});

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export const ProvideAuth: FC = ({ children }) => {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext);
};

// Provider hook that creates auth object and handles state
export function useProvideAuth() {
  const dispatch = useDispatch();

  var existingUser = sessionStorage.getItem("user");

  const [user, setUser] = useState<LoginUser | undefined>(
    existingUser ? JSON.parse(existingUser) : undefined
  );
  // const [route, setRoute] = useState(
  //   JSON.parse(sessionStorage.getItem("route")) == null
  //     ? []
  //     : JSON.parse(sessionStorage.getItem("route"))
  // );
  // const [menu, setMenu] = useState(
  //   JSON.parse(sessionStorage.getItem("menu")) == null
  //     ? []
  //     : JSON.parse(sessionStorage.getItem("menu"))
  // );
  // const [accessControlList, setAccessControlList] = useState(
  //   JSON.parse(sessionStorage.getItem("function")) == null
  //     ? []
  //     : JSON.parse(sessionStorage.getItem("function"))
  // );

  // ... to save the user to state.
  const signin = async (email: string, password: string) => {
    return accountLoginAPI({ username: email, password} ).then(
      (response: AccountLoginResponseModel) => {
        const user: LoginUser = {
          name: email,
          token: response.result.accessToken,
        };

        updateUser(user);

        return user;
      }
    );
  };

  const signout = () => {
    setUser(undefined);

    dispatch(resetHotelState());
    dispatch(resetRoleState());
    dispatch(resetMenuState());

    window.sessionStorage.removeItem("user");
    window.sessionStorage.removeItem("route");
    window.sessionStorage.removeItem("menu");
    window.sessionStorage.removeItem("function");
  };

  const updateUser = (user: LoginUser) => {
    window.sessionStorage.setItem("user", JSON.stringify(user));
    setUser(user);
  };

  // const getUserPermission = (user: LoginUser) => {
  //   const { RoleId } = jwt(user.token)

  //   return RoleService.getRolesById(RoleId)
  //     .then(response => {

  //       const menusList = response.Menus

  //       // console.log({...user, role: response.Name})
  //       updateUser({...user, role: response.Name})

  //       updateMenuAndRoute(menusList)
  //       updateAccessControlList(response.Functions)

  //       return true
  //     });
  // };

  // const updateMenuAndRoute = (menusList) => {

  //   const moduleList = menusList.filter(menu => menu.Type === "Module")
  //   const menuList = menusList.filter(menu => menu.Type === "Menu")
  //   var menuNavListing = []

  //   moduleList.forEach((module) => {
  //     var header = {
  //       title: module.Name,
  //       dropdownItem: []
  //     }

  //     const list = menuList.filter(menu => menu.ParentId === module.Id)

  //     list.forEach((menu) => {

  //       header.dropdownItem.push({
  //         title: menu.Name,
  //         uri: menu.Path
  //       })
  //     })

  //     menuNavListing.push(header)
  //   })

  //   window.sessionStorage.setItem("menu", JSON.stringify(menuNavListing))
  //   setMenu(menuNavListing)

  //   window.sessionStorage.setItem("route", JSON.stringify(menuList))
  //   setRoute(menuList)
  // };

  // const updateAccessControlList = (functionList) => {
  //   const functions = functionList.map(x => x.Code)
  //   window.sessionStorage.setItem("function", JSON.stringify(functions))

  //   setAccessControlList(functions)
  // };

  // Return the user object and auth methods
  return {
    user,
    // menu,
    // route,
    // accessControlList,
    signin,
    signout,
    // getUserPermission,
    // updateMenuAndRoute,
    // updateAccessControlList,
  };
}
